import React from 'react';
import { NavigationAction } from './types';
import { Icon } from '@ui/Icon';

interface BuildLanguageItemArgs {
  language: NavigationAction;
  title: string;
  selectedLanguage: string;
}

export const buildLanguageItem = ({
  language,
  title,
  selectedLanguage,
}: BuildLanguageItemArgs) => ({
  id: language,
  title,
  rightElement:
    selectedLanguage === language
      ? (active: boolean) => (
          <Icon icon="check" color={active ? 'white' : 'black'} />
        )
      : undefined,
});
