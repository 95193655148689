import template from './template.html';
import controller from './controller.js';

export const Component = {
  controller,
  template: () => template,
  controllerAs: 'vm',
  bindings: {
    bot: '=',
    user: '=',
  },
};
