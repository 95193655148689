export default class NullController {
  constructor($rootScope, $scope, BotService) {
    'ngInject';

    this.BotService = BotService;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
  }

  $onInit() {
    this.BotService.show().then((bot) => {
      let targetBlock = '';

      if (bot.first_block) {
        targetBlock = bot.first_block;
      } else if (
        bot.groups &&
        bot.groups[0] &&
        bot.groups[0].blocks &&
        bot.groups[0].blocks[0]
      ) {
        targetBlock = bot.groups[0].blocks[0]; // eslint-disable-line prefer-destructuring
      }

      if (bot.first_block && this.$rootScope.botPageTab === 'structure') {
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/structure/${targetBlock}`,
        );
      }
    });
    this.show = true;
  }
}
