import React, { useMemo, useState } from 'react';
import { CheckBox } from '@ui/CheckBox';
import { Flex } from '@ui/Flex';
import { Platform } from '@globals';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { useWhatsappEnabled } from '@utils/Whatsapp';

export interface AnalyticsPlatformSelectorProps {
  onChange?: (platforms: Set<Platform>) => void;
}

export const AnalyticsPlatformSelector: React.FC<AnalyticsPlatformSelectorProps> =
  ({ onChange }) => {
    const { t } = useSafeTranslation();
    const [state, setState] = useState<Set<Platform>>(
      () => new Set([Platform.facebook, Platform.instagram]),
    );
    const { isWhatsappEnabled } = useWhatsappEnabled();
    const platforms = useMemo<{ id: Platform; title: string }[]>(() => {
      const items = [
        {
          id: Platform.facebook,
          title: t('pages.Keywords.PlatformSelector.facebook'),
        },
        {
          id: Platform.instagram,
          title: t('pages.Keywords.PlatformSelector.instagram'),
        },
      ];

      if (isWhatsappEnabled) {
        // for future
        items.push({
          id: Platform.whatsapp,
          title: t('pages.Keywords.PlatformSelector.whatsapp'),
        });
      }
      return items;
    }, [isWhatsappEnabled, t]);

    if (isWhatsappEnabled) {
      return null;
    }

    return (
      <>
        <Flex gapFactor={6}>
          {platforms.map(({ id, title }) => (
            <label key={id}>
              <Flex gapFactor={2}>
                <CheckBox
                  checked={state.has(id)}
                  onChange={() => {
                    const updatedState = new Set(state);
                    if (updatedState.has(id)) {
                      updatedState.delete(id);
                    } else {
                      updatedState.add(id);
                    }
                    setState(updatedState);
                    onChange?.(updatedState);
                  }}
                />
                <Type size="15px">{title}</Type>
              </Flex>
            </label>
          ))}
        </Flex>
        <Spacer factor={6} />
      </>
    );
  };
