import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Avatar } from '@ui/Avatar';
import * as navigationCSS from '@components/LeftNavigationPanel/components/NavItems/NavItem.css';
import { ReactComponent as NotificationIcon } from '@ui/_deprecated/Icon/icons/notification.svg';
import { UserNavigationItemCommonProps } from '../types';
import * as css from './UserNavigationItemSmall.css';
import * as commonCSS from '../UserNavigationItemButton.css';

export interface UserNavigationItemSmallProps
  extends UserNavigationItemCommonProps {}

export const UserNavigationItemSmall: React.FC<UserNavigationItemSmallProps> =
  ({ src, isPremium, hasNotification }) => (
    <Flex
      alignItems="center"
      justifyContent="center"
      className={cn(navigationCSS.wrapper, css.wrapper)}
    >
      {hasNotification ? (
        <NotificationIcon className={cn(commonCSS.notification, css.icon)} />
      ) : (
        <Avatar src={src} customSize={20} />
      )}
      {isPremium && (
        <Icon
          icon="star"
          color="blue"
          size="16px"
          className={css.premiumIcon}
        />
      )}
    </Flex>
  );
