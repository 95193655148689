import { clone, propEq } from 'ramda';
import {
  createPlugin,
  generatePluginId,
} from '../../../modern-components/Plugins/common/createPlugin';
import { getPluginsDefaultData } from '../../../modern-components/Plugins/common/pluginConst';
import { updateBlockValidStatusInGqlCache } from '../../../modern-components/Aside/BlocksGroupsQueries';
import { removeTypename } from '../../../utils/GQL/utils';
import ApolloService from '../../services/ApolloService';
import { BLOCK_LINKS_QUERY } from '../../../modern-components/BlockLinks/BlockLinks.queries';
import { PluginType } from '../../../modern-components/Plugins/common/PluginTypes';

const nanoid = require('nanoid');

const EMPTY_USER_FILTER = {
  operation: 'and',
  parameters: [],
};
/**
 *
 */
export default class PluginListController {
  /**
   * {*} -
   * @param {*} $scope -
   * @param {*} $rootScope -
   * @param {*} $timeout -
   * @param {*} PluginCardService -
   * @param {*} BotService -
   * @param {*} StoreService -
   */
  constructor(
    $scope,
    $rootScope,
    $timeout,
    PluginCardService,
    BotService,
    StoreService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.PluginCardService = PluginCardService;
    this.BotService = BotService;
    this.StoreService = StoreService;
    this.pluginCreateProc = false;
  }

  /**
   * -
   * @param {*} type -
   * @param {*} param -
   * @param {*} index -
   */
  addPlugin(type, param, index) {
    const data = {
      plugin_id: type,
      config: {},
      position: this.block.cards?.length ?? 0,
    };
    switch (type) {
      case 'text': {
        Object.assign(data.config, {
          text: '',
          buttons: [],
        });
        break;
      }
      case 'gallery':
        Object.assign(data.config, {
          gallery_cards: [
            {
              title: '',
              subtitle: '',
              item_url: '',
              buttons: [],
              new: true,
            },
          ],
        });
        break;
      case 'list':
        Object.assign(data.config, {
          list_cards: [
            {
              title: '',
              subtitle: '',
              item_url: '',
              image_url: '',
              buttons: [],
              new: true,
            },
            {
              title: '',
              subtitle: '',
              item_url: '',
              image_url: '',
              buttons: [],
            },
          ],
          top_element_style: 'compact',
          buttons: [],
        });
        break;
      case 'image':
        Object.assign(data.config, {
          url: '',
        });
        break;
      case 'json':
        Object.assign(data.config, {
          url: '',
          method: 'GET',
          user_attributes: [],
        });
        break;
      case 'form_v2':
        Object.assign(data.config, {
          form_properties: [],
        });
        break;
      case 'email':
        Object.assign(data.config, {
          title: '',
          emails: [],
          body: '',
        });
        break;
      case 'search/bing_plugin':
        Object.assign(data.config, {
          query: '',
          title: '',
          number_of_pages: 3,
          subscriptions_available: true,
          api_token: '',
        });
        break;
      case 'search/swiftype_plugin':
        Object.assign(data.config, {
          query: '',
          title: '',
          number_of_pages: 3,
          subscriptions_available: true,
          allow_read_in_bot: false,
          api_token: '',
        });
        break;
      case 'search/google_plugin':
        Object.assign(data.config, {
          query: '',
          api_key: '',
          api_cx: '',
          title: '',
          number_of_pages: 3,
          subscriptions_available: true,
        });
        break;
      case 'subscription_broadcasting_plugin':
        Object.assign(data.config, {
          title: 'Here is a digest based on your subscriptions:',
          subscription_title: "Here's what I've found about",
        });
        break;
      case 'communication':
        Object.assign(data.config, {
          image_url: null,
          title: 'You started chat session with our operator',
          subtitle:
            'To stop this chat just press Stop Chat button, or send "stop chat" message.',
          button_text: 'Stop Chat',
          stop_message:
            'Chat with manager is stopped. You can continue chatting with this Chatbot.',
          idle_time_in_hours: 12,
          send_admin_notifications: true,
        });
        break;
      case 'integration/ifttt_instagram_plugin':
      case 'integration/ifttt_twitter_plugin':
      case 'integration/ifttt_youtube_plugin':
      case 'integration/zapier_plugin':
      case 'integration/zapier_rss_plugin':
      case 'integration/zapier_youtube_plugin':
      case 'integration/zapier_sheets_plugin':
      case 'integration/zapier_calendar_plugin':
      case 'integration/zapier_wordpress_plugin':
      case 'integration/zapier_slack_plugin':
      case 'integration/zapier_twitter_plugin':
      case 'integration/zapier_instagram_plugin':
      case 'integration/zapier_vimeo_plugin':
        Object.assign(data.config, {
          view: 'all',
        });
        break;
      case 'autoposting/rss_plugin':
      case 'autoposting/facebook_plugin':
        Object.assign(data.config, {
          url: '',
        });
        break;
      case 'autoposting/twitter_plugin':
        Object.assign(data.config, {
          account_name: '',
        });
        break;
      case 'autoposting/ifttt_plugin':
        Object.assign(data.config, {
          integration: null,
        });
        break;
      case 'autoposting/ifttt_instagram_plugin':
        Object.assign(data.config, {
          integration: 'instagram',
        });
        break;
      case 'autoposting/ifttt_twitter_plugin':
        Object.assign(data.config, {
          integration: 'twitter',
        });
        break;
      case 'autoposting/ifttt_youtube_plugin':
        Object.assign(data.config, {
          integration: 'youtube',
        });
        break;
      case 'integration':
      case 'plugin':
        if (param) {
          data.initParam = param;
        }
        break;
      case 'typing':
        Object.assign(data.config, {
          timeout_milliseconds: 4000,
        });
        break;
      case 'audio':
        Object.assign(data.config, {
          url: '',
        });
        break;
      case 'video':
        Object.assign(data.config, {
          url: '',
        });
        break;
      case 'setup_variable_plugin':
        Object.assign(data.config, {
          items: [
            {
              variable_name: '',
              value: '',
            },
          ],
        });
        break;
      case 'go_to_block_plugin':
        Object.assign(data.config, {
          user_filter: EMPTY_USER_FILTER,
          action: {
            random: false,
            items: [
              {
                item_type: 'block',
                blocks: [],
              },
            ],
          },
        });
        break;
      case 'add_to_sequence':
        Object.assign(data.config, {
          user_filter: EMPTY_USER_FILTER,
          action: {
            random: false,
            items: [
              {
                item_type: 'group',
                groups: [],
              },
            ],
          },
        });
        break;
      case 'remove_from_sequence':
        Object.assign(data.config, {
          user_filter: EMPTY_USER_FILTER,
          action: {
            random: false,
            items: [
              {
                item_type: 'group',
                groups: [],
              },
            ],
          },
        });
        break;
      case 'quick_reply':
        Object.assign(data.config, {
          process_text_by_ai: true,
        });
        break;
      case this.PluginCardService.PLUGIN_TYPES.collectPhone:
        Object.assign(data.config, {
          text: 'Please share your phone number - you may see a button with a suggested number, or can manually type one in.',
        });
        break;
      case this.PluginCardService.PLUGIN_TYPES.collectEmail:
        Object.assign(data.config, {
          text: 'Please share your email address - you may see a button with a suggested email, or can manually type one in.',
        });
        break;
      case this.PluginCardService.PLUGIN_TYPES.comment:
        Object.assign(data.config, {
          text: '',
        });
        break;
      case this.PluginCardService.PLUGIN_TYPES.googleSheet:
        Object.assign(data.config, {
          spreadsheet_id: null,
          user_attributes: [],
        });
        break;
      case this.PluginCardService.PLUGIN_TYPES.event_trigger:
        Object.assign(data.config, {});
        break;
      default: {
        break;
      }
    }

    if (typeof index !== 'undefined') {
      let lCards = clone(this.block.cards);
      this.block.cards.length = 0;
      lCards.splice(index, 0, data);
      lCards = lCards.map((card, i) => {
        Object.assign(card, { position: i });
        return card;
      });

      this.cardsListObj = window.angular.element('.cards-list');

      this.cardsListObj.css({
        'min-height': this.cardsListObj.height(),
      });

      const rCol = document.querySelector('.rcol');
      const { scrollTop } = rCol;
      const afterObjPos = document.querySelectorAll('.card-item')[index];
      const afrerPos = afterObjPos.offsetTop + afterObjPos.offsetHeight + 100;

      this.$timeout(() => {
        lCards.forEach((item) => this.block.cards.push(item));
        setTimeout(() => {
          this.cardsListObj.css({
            'min-height': '0',
          });

          if (scrollTop + window.innerHeight < afrerPos) {
            rCol.scrollTop = afrerPos;
          } else {
            rCol.scrollTop = scrollTop;
          }
        });
      });
    } else {
      this.block.cards.push(data);
    }

    if (getPluginsDefaultData()[type] && type !== PluginType.email) {
      const optimisticId = generatePluginId();
      data.id = optimisticId;
      createPlugin(optimisticId, {
        botId: this.$rootScope.stateParams.botId,
        pluginType: type,
        blockId: this.$rootScope.stateParams.blockId,
        position: index !== undefined ? index : this.block.cards.length,
      }).then((pluginData) => {
        this.$scope.$evalAsync(() => {
          Object.assign(data, removeTypename(pluginData));
        });
      });
    }

    if (
      type.indexOf('autoposting') !== -1 &&
      this.block.cards.filter(
        (item) => item.plugin_id.indexOf('autoposting') !== -1,
      ).length <= 1
    ) {
      this.$rootScope.$broadcast('autopost:title', this.block.cards);
    }

    this.$timeout(() => {
      this.$scope.$broadcast('$buttonUpdate');
    });
  }

  /**
   * -
   * @param {*} data -
   * @return {Promise<T>} -
   */
  savePlugin(data) {
    const saveData = data;

    if (!data.id) {
      if (this.pluginCreateProc) {
        return;
      }

      this.pluginCreateProc = true;
    }

    if (data.id === 'new') {
      delete data.id;
    }

    data.blockId = this.block.id;

    if (data.position === undefined) {
      data.position = this.block.cards.findIndex((item) => item.id === data.id);
      if (data.position === undefined) {
        data.position = this.block.cards.length;
      }
    }

    /* eslint-disable-next-line consistent-return */
    return this.PluginCardService.save(data)
      .then(async (response) => {
        if (!saveData.id) {
          this.pluginCreateProc = false;
        }
        if (response !== undefined) {
          data.id = response && response.result.id;
          data.localization = response.result.localization;
          data.is_valid = true;
          if (response.result.config && response.result.config.user_filter) {
            const userFilterValid = response.result.config.user_filter.valid;
            data.is_valid = userFilterValid;
            data.config.user_filter.valid = userFilterValid;
          }
        }
        this.$rootScope.$broadcast('$pluginSaveResultOk', data);
        this.$rootScope.$broadcast('$pluginUpdated', data, this.block.cards);
        updateBlockValidStatusInGqlCache({ block: this.block });
        await ApolloService.query({
          query: BLOCK_LINKS_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            botId: this.$rootScope.stateParams.botId,
            blockId: this.block.id,
          },
        });
        return data;
      })
      .catch((err) => {
        console.error('plugin save error', err);
        if (
          err &&
          err.data &&
          err.data.result === 'Bad Data: Plugins limit exceeded'
        ) {
          if (this.block) {
            this.block.cards.splice(this.block.cards.indexOf(data), 1);
          }

          this.pluginCreateProc = false;
          this.$rootScope.$broadcast('$pluginLimitReach');
          throw err;
        }

        if (
          err.data &&
          err.data.result &&
          err.data.result.config &&
          err.data.result.config.user_filter
        ) {
          const userFilterValid = err.data.result.config.user_filter.valid;
          data.config.user_filter.valid = userFilterValid;
        }
        if (err.data && err.data.card_id) {
          if (!saveData.id) {
            this.pluginCreateProc = false;
          }
          data.is_valid = false;
          this.$rootScope.$broadcast('$pluginSaveResultError', err);
          this.$rootScope.$broadcast('$pluginUpdated', data, this.block.cards);
          data.id = err.data.card_id;
          data.localization = err.data.localization;
          updateBlockValidStatusInGqlCache({ block: this.block });
          return data;
        }
        throw new Error(
          `Plugin save error: ${JSON.stringify(
            {
              data,
              error: err,
            },
            null,
            2,
          )}`,
        );
      });
  }

  /**
   * -
   * @param {*} data -
   * @return {*} -
   */
  remove(data) {
    if (data.id) {
      const index = this.block.cards.findIndex(propEq('id', data.id));
      this.block.cards.splice(index, 1);
      if (data.plugin_id.indexOf('autoposting') !== -1) {
        this.$rootScope.$broadcast('autopost:title', this.block.cards);
      }
      return this.PluginCardService.remove(data).then(() => {
        this.$scope.$emit('card-sort-stop');
        this.$scope.$broadcast('$buttonUpdate');

        this.$rootScope.$broadcast('$buttonUpdate');
        this.$rootScope.$broadcast('$pluginUpdated', data, this.block.cards);
        updateBlockValidStatusInGqlCache({ block: this.block });

        return ApolloService.query({
          query: BLOCK_LINKS_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            botId: this.$rootScope.stateParams.botId,
            blockId: this.block.id,
          },
        });
      });
    }

    const index = this.block.cards.findIndex(
      propEq('$$hashKey', data.$$hashKey),
    );
    if (index === -1) {
      return this.removeEmpty(data);
    }
    this.block.cards.splice(index, 1);
    this.$rootScope.$broadcast('$pluginUpdated', data, this.block.cards);
    updateBlockValidStatusInGqlCache({ block: this.block });
    this.$timeout(() => {
      this.$scope.$broadcast('$buttonUpdate');
    });
    return false;
  }

  /**
   * -
   * @param {*} data -
   * @return {boolean} -
   */
  removeEmpty(data) {
    const removed = this.block.cards.splice(data.position, 1);
    if (removed.length === 0) {
      this.block.cards.splice(-1, 1);
    }
    this.$rootScope.$broadcast('$pluginUpdated', data, this.block.cards);
    updateBlockValidStatusInGqlCache({ block: this.block });
    return false;
  }
}
