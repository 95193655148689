import i18next from 'i18next';
import { path, pipe, find, propEq, clone } from 'ramda';
import debounce from 'lodash-es/debounce';
import { getBotFeatures } from '@utils/Data/Bot';
import { getPlansPageUrl } from '@utils/Routing';
import { canView } from '../../../common/services/RoleService';
import { isEqual, cleanFilter } from '../../../common/services/Segmentation';
import { pluralNumberFormatter } from '../../../common/services/PluralsService';
/**
 * @description container to working with omnibox in broadcast
 */
export default class BroadcastOmniboxContainerController {
  constructor(
    $rootScope,
    BlockService,
    Segmentation,
    BotService,
    PeopleService,
    MonetizationService,
    SettingsFactory,
    StatisticService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.BlockService = BlockService;
    this.BotService = BotService;
    this.PeopleService = PeopleService;
    this.MonetizationService = MonetizationService;
    this.SettingsFactory = SettingsFactory;
    this.StatisticService = StatisticService;
    this.Segmentation = Segmentation;

    this.userCountString = '';
    this.userCountLimitationString = 'all users';
    this.userCountUpdating = false;

    this.userFilterReady = false;
    this.userFilterState = {
      valid: null,
    };

    this.dialogsPricingEnabled = false;
    getBotFeatures(this.$rootScope.stateParams.botId).then(
      ({ dialogs_pricing_enabled }) => {
        this.dialogsPricingEnabled = dialogs_pricing_enabled;
      },
    );
  }

  /**
   * @description: ng method;
   * @param {*} changesObj -
   * @return {void} -
   */
  async $onChanges(changesObj) {
    if (changesObj.block && this.block && this.block.id) {
      if (!this.block.user_filter) {
        this.block.user_filter = this.Segmentation.empty();
        this.block.user_filter.valid = true; // empty filter is considered valid
      }

      this._updateUserFilterState(this.block.user_filter);
      if (this.block.user_filter.valid) {
        this.userFilterReady = false;
        this._updateUsersCount(this.block.user_filter);
      }
    }
    // when bot exist and id is changed and it is limited do request and show counter;
    this.showUserLimitiation = Boolean(path(['user_limit_reached'], this.bot));
    const currentBotId = path(['bot', 'currentValue', 'id'], changesObj);
    const previosBotId = path(['bot', 'previousValue', 'id'], changesObj);
    const valueOfPageId = path(['status', 'page']);
    if (currentBotId && currentBotId !== previosBotId) {
      if (this.showUserLimitiation) {
        const stats = await this.BotService.basicStats();
        const currentBotPageId = valueOfPageId(this.bot);
        const pageStats = pipe(
          find(propEq('page_id', currentBotPageId)),
          path(['current_week_total_subscribed_users']),
        )(stats);
        if (pageStats) {
          this.userCountLimitationString = pluralNumberFormatter(
            pageStats,
            'user',
            'users',
          );
        }
      }
    }
  }

  debounceOnFilterChange = debounce((filter) => {
    this._saveBlock();
  }, 500);

  /**
   * @description when omnibox changes filter we save block;
   * @param {Segmentation} filter -
   * @private
   */
  _onFilterChange(filter) {
    if (this.block.id) {
      if (!isEqual(this.block.user_filter, filter)) {
        this.debounceOnFilterChange();
      }
      this.block.user_filter = filter;
    }
  }

  /**
   * @description: -
   * @return {void} -
   * @private
   */
  _saveBlock() {
    if (this.userFilterReady) {
      const block = clone(this.block);
      block.user_filter = cleanFilter(block.user_filter);
      this.BlockService.update2(block).then((response) => {
        if (response && response.user_filter) {
          block.user_filter = response.user_filter;
        }
        this._updateUserFilterState(block.user_filter);
        if (block.user_filter.valid) {
          this._updateUsersCount(block.user_filter);
        }
      });
    }
  }

  _updateUserFilterState(userFilter) {
    if (userFilter && userFilter.valid != null) {
      this.userFilterState.valid = userFilter.valid;

      // TODO:
      // This should be unnecessary, but we have to do it for now,
      // because there's lots of manual mutations which do not account
      // for everything and some values become stale.
      if (this.block && this.block.user_filter) {
        this.block.user_filter.valid = userFilter.valid;
      }
    }
  }

  /**
   * @description: on filter change we should update counter;
   * @param {Segmentation} filter -
   * @private
   * @return {void} -
   */
  _updateUsersCount(filter = {}) {
    this.userCountUpdating = true;
    this.BotService.userCount(filter, false)
      .then((res) => {
        const n = Number(res);
        this.userCountUpdating = false;
        this.userFilterReady = true;
        if (!Number.isNaN(n)) {
          this.userCountString = i18next.t(
            'components.broadcast.users.userCountString',
            { count: n },
          );
        }
      })
      .catch(() => {
        this.userCountUpdating = false;
        this.userFilterReady = true;
        this.userCountString = '';
      });
  }

  /**
   * @description: when omnibox loads data we loads counter @todo? why not load counter immediately on display ?
   * @return {void} -
   * @private
   */
  _onUsersFilterReady() {
    this.userFilterReady = true;
  }

  /**
   * -
   * @private
   */
  _navigateToPeopeTabWithFilter() {
    this.PeopleService.navigatePeopleTabWithFilter(this.block.user_filter, {
      openProPopUpForNonPro: canView('pro'),
    });
  }

  /**
   * -
   */
  navigateToSettingsAndScrollToMonetization() {
    this.SettingsFactory.gotoSettingsAndScrollToPro();
  }

  _clickToUpgradeToPro(e) {
    e.preventDefault(); // link click;
    this.$rootScope.stateHistory.push(
      getPlansPageUrl(
        this.$rootScope.stateParams.botId,
        this.dialogsPricingEnabled,
      ),
    );
  }
}
