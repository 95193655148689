import {
  filter,
  contains,
  __,
  pipe,
  prop,
  not,
  ifElse,
  isNil,
  always,
  converge,
  equals,
  identity,
  curry,
  propEq,
  head,
  cond,
  T,
  keys,
  gt,
  is,
  map,
} from 'ramda';
import moment from 'moment';
import {
  OPERATION_ATTRIBUTE_ITEMS,
  STRING_OPERATIONS_CODES,
  OPERATION_SEQUENCE_ITEMS,
  DATE_ATTRIBUTES_MAP,
  OPERATION_SEGMENT_ITEMS,
  DEFAULT_OPERATION_CODES,
  MULTIVALUES_OPERATIONS_CODES,
  ATTRIBUTES_TO_FORMAT_FROM_NOW,
  ATTRIBUTE_TYPE_CODES,
  OPERATION_TAG_ITEMS,
} from './OmniboxServiceConsts';

const getName = prop('name');
const isStringOperationCode = contains(__, STRING_OPERATIONS_CODES);
const isStringOperationItem = pipe(prop('code'), isStringOperationCode);
const dateOperations = filter(pipe(isStringOperationItem, not)); // not string;
const isAttributeDateAttribute = pipe(
  getName,
  contains(__, keys(DATE_ATTRIBUTES_MAP)),
);
/**
 * @param {timestamp} date -
 * @return {String} -
 */
const dateFromNow = (date) => moment(Number(date)).fromNow();
const dateFromNowOrNever = ifElse(isNil, always('never'), dateFromNow);
/**
 * @param {timestamp} date -
 * @return {String} -
 */
const getYear = (date) => moment(Number(date)).format('YYYY');
/**
 * @return {timestamp} -
 */
const now = () => Date.now().valueOf();
const thisYear = pipe(now, getYear);
const isDateThisYear = converge(equals, [thisYear, getYear]);
const getDateFormatingOptions = ifElse(
  isDateThisYear,
  always('MMM DD'),
  always('MMM DD, YYYY'),
);
const formatDate = curry((format, date) => moment(Number(date)).format(format));
const formatDateOmitThisYear = converge(formatDate, [
  getDateFormatingOptions,
  identity,
]);
const attributeShouldFormatAsFromNow = pipe(
  getName,
  contains(__, ATTRIBUTES_TO_FORMAT_FROM_NOW),
);
const getValues = pipe(prop('values'), head);
const isTimeZone = propEq('name', 'timezone');
const graterThen0 = gt(__, 0);
const safeParseInt = (numString) => {
  const maybeNumber = parseInt(numString, 10);
  return Number.isNaN(maybeNumber) ? numString : maybeNumber;
};

const emptyIfNotNumber = (mayBeNumberAsString) => {
  const maybeNumber = parseInt(mayBeNumberAsString, 10);
  return Number.isNaN(maybeNumber) ? '' : String(maybeNumber); // dobule conversion to remove + sign from autocomplite;
};
/**
 * @param {Number} timezone -
 * @return {String} -
 */
const timeZoneNumberToGMTString = (timezone) =>
  `${graterThen0(timezone) ? '+' : ''}${timezone} GMT`;
const gmtTimezoneStringToInt = (timezoneString) => {
  if (timezoneString === undefined) {
    return timezoneString;
  }
  timezoneString = String(timezoneString);
  const value = timezoneString.split(' GMT')[0];
  return emptyIfNotNumber(value);
};
const isNumber = is(Number);
const attribueOperationToString = (_code) => {
  const operation = OPERATION_ATTRIBUTE_ITEMS.find(
    ({ code }) => _code === code,
  );
  return operation ? operation.name : '';
};

const isFilterItemAttributeIsDate = ({ name }) => !!DATE_ATTRIBUTES_MAP[name];

const attributeOpperationItems = ifElse(
  isFilterItemAttributeIsDate,
  always(dateOperations(OPERATION_ATTRIBUTE_ITEMS)),
  always(OPERATION_ATTRIBUTE_ITEMS),
);

const attributeOpperationItemsNames = pipe(
  attributeOpperationItems,
  map(getName),
);

const formatTimezone = cond([
  [isNumber, timeZoneNumberToGMTString],
  [isNil, always('not set')], // TODO: refactor to conts?
  [T, identity],
]);

export const attributeValueToString = pipe(
  cond([
    [isNil, always('')],
    [
      pipe(getName, isNil),
      () => {
        throw new Error(
          '.attributeValueToString: Attribute must have name attribute',
        );
      },
    ],
    [
      attributeShouldFormatAsFromNow,
      pipe(getValues, safeParseInt, dateFromNowOrNever),
    ],
    [
      isAttributeDateAttribute,
      pipe(getValues, safeParseInt, formatDateOmitThisYear),
    ],
    [isTimeZone, pipe(getValues, safeParseInt, formatTimezone)],
    [T, getValues], // just take values
  ]),
);

/**
 * {name, values:[string]} -> string }
 */
export const attributeFromStringToValue = cond([
  [isNil, always('')],
  [isTimeZone, pipe(getValues, gmtTimezoneStringToInt)],
  [T, getValues],
]);

/**
 * @description OmniboxService
 */
export class OmniboxService {
  /**
   * @param {*} UserFilterService -
   */
  constructor(UserFilterService) {
    'ngInject';

    this.UserFilterService = UserFilterService;
    this.dateAttributesList = DATE_ATTRIBUTES_MAP;
    this.defaultOperationItem = DEFAULT_OPERATION_CODES;
    this.attributeValueToString = attributeValueToString;
    this.attributeOpperationItems = attributeOpperationItems;
    this.attributeOpperationItemsNames = attributeOpperationItemsNames;
    this.attribueOperationToString = attribueOperationToString;
  }

  /**
   * @description get operations items
   * @param {String} filterItem -
   * @return {String} -
   */
  getOperationsItems(filterItem) {
    const selectorType = this.getOmniboxSelectorTypeByFilterItem(filterItem);
    switch (selectorType) {
      case 'attribute':
        return this.isFilterItemAttributeIsDate(filterItem)
          ? dateOperations(OPERATION_ATTRIBUTE_ITEMS)
          : OPERATION_ATTRIBUTE_ITEMS;
      case 'sequence':
        return OPERATION_SEQUENCE_ITEMS;
      case 'segment':
        return OPERATION_SEGMENT_ITEMS;
      case 'tag':
        return OPERATION_TAG_ITEMS;
      default:
        console.error(`Unknown selector type: ${selectorType}`);
        return [];
    }
  }

  /**
   * @param {Object} filterItem -
   * @return {string} -
   */
  getOmniboxSelectorTypeByFilterItem(filterItem) {
    switch (filterItem?.type) {
      case ATTRIBUTE_TYPE_CODES.sequence:
        return 'sequence';
      case ATTRIBUTE_TYPE_CODES.segment:
        return 'segment';
      case ATTRIBUTE_TYPE_CODES.tag:
        return 'tag';
      default:
        return 'attribute';
    }
  }

  /**
   * @param {Object} filterItem -
   * @return {Boolean} -
   */
  isFilterItemAttributeIsDate(filterItem) {
    return this.dateAttributesList[filterItem.name];
  }

  /**
   * @param {String} renderCode -
   * @param {[Object]} attributes -
   * @return {Object} -
   */
  getAttributeByRenderCode(renderCode, attributes) {
    const [name, type = ATTRIBUTE_TYPE_CODES.system] = renderCode.split('____');
    return this.UserFilterService.getAttributeByNameAndType(
      attributes,
      name,
      type,
    );
  }

  /**
   * @param {Object} filterItem -
   * @return {Boolean} -
   */
  isMultiValuesFilterItem(filterItem) {
    return MULTIVALUES_OPERATIONS_CODES.includes(filterItem.operation);
  }

  /**
   * @param {Object} filterItem -
   * @return {boolean} -
   * @private
   */
  _isOmniboxSelectorIsAttributeType(filterItem) {
    return filterItem.type === 'attribute';
  }

  /**
   * @param {String} type -
   * @return {Object} -
   * @private
   */
  _getDefaultOperationCodeByType(type) {
    return this.defaultOperationItem[type];
  }

  /**
   * @param {String} type -
   * @return {String} -
   * @private
   */
  _getDefaultNameItemByType(type) {
    let name;
    switch (type) {
      case ATTRIBUTE_TYPE_CODES.sequence:
        name = 'triggered sequences';
        break;
      default:
        name = null;
        break;
    }
    return name;
  }
}
