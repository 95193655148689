import memoize from 'lodash-es/memoize';
import i18next from 'i18next';
import { invertObj } from 'ramda';
import { RespondIntervalItem, RespondIntervalItemsIds } from './types';

export const getRespondIntervalItems = memoize(
  () =>
    [
      {
        id: RespondIntervalItemsIds.always,
        title: i18next.t('components.settings.defaultAnswer.always'),
      },
      {
        id: RespondIntervalItemsIds.onceIn24h,
        title: i18next.t('components.settings.defaultAnswer.onceIn24h'),
      },
    ] as RespondIntervalItem[],
);

export const RESPOND_INTERVAL_ITEM_TO_HOURS: Record<
  RespondIntervalItemsIds,
  number
> = {
  [RespondIntervalItemsIds.always]: 0,
  [RespondIntervalItemsIds.onceIn24h]: 24,
};

export const HOURS_TO_RESPOND_INTERVAL_ITEM = invertObj(
  RESPOND_INTERVAL_ITEM_TO_HOURS,
);
