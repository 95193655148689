import { log } from 'cf-common/src/logger';

export default class MenuTreeController {
  constructor($scope, $timeout, $element) {
    'ngInject';

    this.$scope = $scope;

    this.editLevels = [null, null, null];

    this.editIndexes = [0, null, null];

    $scope.$watch('$menuTree.item', () => {
      if (this.item) {
        this.editLevels[0] = this.item;
        this.editLevels[1] = null;
        this.editLevels[2] = null;
      }
    });
  }

  $onInit() {
    this.$scope.$on('$editSubmenu', (event, data) => {
      try {
        event.stopPropagation();
      } catch (error) {
        log.verbose({ error });
      }
      if (Number.isNaN(parseInt(data.index, 10))) {
        for (let i = data.level; i < 3; i++) {
          this.editLevels[i] = null;

          this.editIndexes[i] = null;

          this.$scope.$broadcast('$resetEditSubmenu', {
            index: null,
            level: i - 1,
          });
        }
      } else {
        this.editLevels[data.level + 1] = this.editLevels[data.level].items[
          data.index
        ];
        this.editIndexes[data.level + 1] = data.index;
      }
    });

    this.$scope.$on('$removeSubmenu', (event, data) => {
      event.stopPropagation();

      if (
        this.editLevels[data.level + 1] ===
        this.editLevels[data.level].items[data.index]
      ) {
        this.editLevels[data.level + 1] = null;
        if (data.level === 0) {
          this.editLevels[2] = null;
        }
      }
      this.editLevels[data.level].items.splice(data.index, 1);

      this.$scope.$emit('$saveMenu');
    });
  }

  $onDestroy() {}

  reset() {
    this.editLevels[2] = null;
    //   this.editLevels[1] = null;
    //    this.$scope.$broadcast('$resetEditSubmenu', {
    //      index: null,
    //      level: 0
    //    });
    this.$scope.$broadcast('$resetEditSubmenu', {
      index: null,
      level: 1,
    });
  }
}
