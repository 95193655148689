import ng from 'angular';
import controller from '../card-plugin-base-controller/controller';
import template from './template.html';
import './assets/less/event-trigger.less';

export default ng
  .module('app.common.plugins.eventTrigger', [])
  .component('eventTrigger', {
    require: {
      PluginListController: '^^pluginList',
    },
    controllerAs: 'vm',
    controller,
    bindToController: true,
    bindings: {
      // plugin entity
      item: '=',
      block: '=',
    },
    template: () => template,
  }).name;
