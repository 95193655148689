import React from 'react';
import { showIntercom } from '@utils/Intercom';
import * as css from './BotLimitReachedDialog.css';
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
} from '../../../../../modern-ui/Dialog';
import face from './images/pensive-face.png';
import { Flex } from '../../../../../modern-ui/Flex';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
} from '../../../../../modern-ui/_deprecated/Button';

interface BotLimitReachedDialogProps {
  onRequestClose: () => void;
}

export const BotLimitReachedDialog: React.FC<BotLimitReachedDialogProps> = ({
  onRequestClose,
}) => (
  <Dialog className={css.paymentsHistoryDialog}>
    <DialogContent>
      <img src={face} alt="" className={css.face} />
      <div className={css.title}>You have exceeded plan limit</div>
      <div className={css.text}>
        Contact us to update your plan to add more bots or remove a bot from the
        current plan.
      </div>
      <Flex justifyContent="flex-end">
        <Button
          intent={ButtonIntent.primary}
          colorWay={ButtonColorWay.blue}
          onClick={() => {
            showIntercom();
          }}
        >
          Contact us
        </Button>
      </Flex>
    </DialogContent>
    <DialogCloseButton onClick={onRequestClose} />
  </Dialog>
);
