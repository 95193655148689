import template from './menu-tree.html';
import controller from './controller.js';

export const Component = {
  controller,
  template: () => template,
  controllerAs: '$menuTree',
  bindings: {
    item: '=',
    bot: '=',
  },
};
