import i18next from 'i18next';
import moment from 'moment';
import throttle from 'lodash-es/throttle';
import debounce from 'lodash-es/debounce';
import memoize from 'lodash-es/memoize';

import { sendEvent } from '@utils/Analytics';
import { STANDARD_MESSAGING_ID } from '@components/BroadcastingTags/TagsData';

import StructureContentController from '../structure/content.controller';
import { canEdit } from '../../common/services/RoleService';

export const addRandomPostfix = (title) =>
  `${title}|${String(Math.random()).substr(2, 12)}`;

const within24hTitle = 'within 24h window';
const within24hParameter = {
  default: { type: 'system', name: '', operation: 'is', values: [] },
  within24h: {
    type: 'system',
    name: within24hTitle,
    operation: 'is',
    values: ['Yes'],
  },
};

// Only for backwards compatibility because backend will always set tag explicitly
const DEFAULT_TAG = {
  tag: 'NON_PROMOTIONAL_SUBSCRIPTION',
  title: 'Subscription',
};
/**
 * -
 */
export default class ContentController extends StructureContentController {
  /**
   * init
   */
  $onInit() {
    super.$onInit();

    this.RoleService = { canEdit };
    this.sendEvent = sendEvent;

    this.$allowEdit = true;
    if (this.$rootScope.stateParams.broadcastType === 'schedule') {
      this.loadBroadcast();
    } else if (this.$rootScope.stateParams.broadcastType === 'auto') {
      this.loadAutopostBroadcast();
    } else if (this.$rootScope.stateParams.broadcastType === 'now') {
      // eslint-disable-next-line chatfuel/no-use-localStorage
      this.$allowEdit = !window.localStorage.getItem(
        `auto-${this.$rootScope.stateParams.blockId}`,
      );
      this.nowCloneAllow = !this.$allowEdit;
    } else if (this.$rootScope.stateParams.broadcastType === 'sequence') {
      this.loadSequenceBroadcast();
    } else {
      this.$rootScope.stateHistory.push(
        `/bot/${this.$rootScope.stateParams.botId}/broadcast`,
      );
    }

    const [sunday, ...restDays] = moment.weekdaysShort();
    this.days = [...restDays, sunday];

    this.daysIndex = [6, 0, 1, 2, 3, 4, 5];

    this.is24hError = false;

    this.startOfBotsDay = moment().startOf('day');
    this.$scope.$watch(
      () => this.broadcast?.use_bot_timezone,
      (useBotTimezone) => {
        if (!useBotTimezone || useBotTimezone === 'false') {
          this.startOfBotsDay = moment().startOf('day').valueOf();
        } else {
          this.startOfBotsDay = moment
            .utc()
            .local(true)
            .add({ second: this.bot.timezone_offset })
            .startOf('day')
            .valueOf();
        }
      },
    );
    this.$datepicker = {
      datepickerMode: 'day',
      showWeeks: false,
      /**
       * -
       * @param {*} dt -
       * @returns {string|void} -;
       */
      customClass: (dt) =>
        dt.date.valueOf() <= this.startOfBotsDay ? 'prev-day' : undefined,
    };
    this.repeatType = {
      no_repeat: i18next.t(
        'components.broadcast.controller.repeatType.no_repeat',
      ),
      everyday: i18next.t(
        'components.broadcast.controller.repeatType.everyday',
      ),
      everyweek: i18next.t(
        'components.broadcast.controller.repeatType.everyweek',
      ),
      monthly_repeat: i18next.t(
        'components.broadcast.controller.repeatType.monthly_repeat',
      ),
      workdays: i18next.t(
        'components.broadcast.controller.repeatType.workdays',
      ),
      weekly_repeat: i18next.t(
        'components.broadcast.controller.repeatType.weekly_repeat',
      ),
    };

    this.triggerType = {
      first_interaction: i18next.t(
        'components.broadcast.controller.triggerType.first_interaction',
      ),
      last_interaction: i18next.t(
        'components.broadcast.controller.triggerType.last_interaction',
      ),
      user_filter: i18next.t(
        'components.broadcast.controller.triggerType.user_filter',
      ),
      abandoned_cart_update: i18next.t(
        'components.broadcast.controller.triggerType.abandoned_cart_update',
      ),
    };
    this.listeners = [];
    this.listeners.push(
      this.$scope.$on('$popupValueChange', (event, res) => {
        if (res.cardId === this.broadcast.id) {
          this.showPreferences(res.value);
        }
        if (res.cardId === 'repeatTypeSelector') {
          this.getRepeatDays(res);
        }
        if (res.cardId === 'sequenceTriggerType') {
          this.$timeout(() => {
            this.changeSequence(res);
          });
        }
      }),
    );
    this.listeners.push(
      this.$scope.$on('autopost:titleUpdate', (event, blockTitle) => {
        this.block.title = blockTitle;
      }),
    );
    this.listeners.push(
      this.$scope.$on('$saveSequenceUserFilter', () => {
        this.changeSequence();
      }),
    );
    this.userFilter = {};
  }

  getSendInTypes = memoize((count) => ({
    minute: i18next.t('modernUi.TimeoutInput.minutes', {
      count,
    }),
    hour: i18next.t('modernUi.TimeoutInput.hours', {
      count,
    }),
    day: i18next.t('modernUi.TimeoutInput.days', {
      count,
    }),
  }));

  get timeIntervalUnits() {
    return this.getSendInTypes(this.broadcast?.wait_interval);
  }

  /**
   *
   */
  $onDestroy() {
    this.$document.off('mousedown');
    this.listeners.forEach((fn) => fn.call());
  }

  /**
   * --
   * @param {*} res  - *
   */
  getRepeatDays(res) {
    this.$timeout(() => {
      this.switchRepeatType(res);
    });
  }

  /**
   * @returns {Promise} -
   */
  loadBot() {
    return this.BotService.list().then((bots) => {
      this.bot = bots.find(
        (item) => item.id === this.$rootScope.stateParams.botId,
      );
      this.timezoneType = {
        false: i18next.t('components.broadcast.controller.timezoneTypes.user'),
        true: i18next.t('components.broadcast.controller.timezoneTypes.bot', {
          timezone: this.$getTimeZone(),
        }),
      };
      return bots;
    });
  }

  /**
   *
   */
  loadBroadcast() {
    this.loadBot()
      .then((bot) =>
        this.BroadcastService.showSchedule(
          this.$rootScope.stateParams.broadcastId,
        ).then((broadcast) => {
          if (this.$rootScope.stateParams.blockId !== broadcast.block_id) {
            this.$rootScope.stateHistory.push(
              `/bot/${this.$rootScope.stateParams.botId}/broadcast/${broadcast.block_id}/${broadcast.id}/schedule`,
            );
          }
          return { bot, broadcast };
        }),
      )
      .then(({ bot, broadcast }) => {
        // deadline - time w/o bot time zone
        this.broadcast = broadcast;
        this.selectedDate = new Date(this.broadcast.broadcast_start_timestamp);
        this.$allowEdit =
          this.broadcast.broadcast_start_timestamp >=
            moment.utc().startOf('day') || this.broadcast.type !== 'no_repeat';
        if (this.broadcast.type === 'weekly_repeat') {
          const bitMask = parseInt(
            this.broadcast.weekly_schedule
              .map((item) => (item ? 1 : 0))
              .join(''),
            2,
          );
          if (bitMask === 127) {
            this.broadcast.type = 'everyday';
          }
          if (bitMask === 3) {
            this.broadcast.type = 'everyweek';
          }
          if (bitMask === 124) {
            this.broadcast.type = 'workdays';
          }
        }
      })
      .catch(() => {
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/broadcast`,
        );
      });
  }

  /**
   *
   */
  loadSequenceBroadcast() {
    this.loadBot()
      .then((bot) => {
        return this.BroadcastService.showSequence(
          this.$rootScope.stateParams.broadcastId,
        ).then((broadcast) => {
          if (this.$rootScope.stateParams.blockId !== broadcast.block_id) {
            this.$rootScope.stateHistory.push(
              `/bot/${this.$rootScope.stateParams.botId}/broadcast/${broadcast.block_id}/${broadcast.id}/sequence`,
            );
          }
          return { bot, broadcast };
        });
      })
      .then(({ bot, broadcast }) => {
        this.broadcast = broadcast;
        this.$allowEdit = true;
        if (!this.broadcast.send_day_time) {
          this.broadcast.send_day_time = 0;
        }
        if (broadcast.trigger_type !== 'abandoned_cart_update') {
          delete this.triggerType.abandoned_cart_update;
        }
      })
      .catch(() => {
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/broadcast`,
        );
      });
    this.throttleHandler = throttle((title) => {
      this.saveSequence(title);
    }, 1000);
  }

  fixUseBotTimezone(broadcast) {
    if (!('use_bot_timezone' in broadcast)) {
      broadcast.use_bot_timezone = false;
    }
    if (!broadcast.use_bot_timezone || broadcast.use_bot_timezone === 'false') {
      broadcast.use_bot_timezone = false;
    }
  }

  /**
   * Gets timestamp in utc tz from timepicker and datepicker
   * @param date {Date} Date in users TZ
   * @param time {number} time in utc TZ
   *
   * @return {moment.Moment} in utc TZ
   */
  getBroadcastTs(date, time) {
    const dateM = moment(date).utc();
    const timeM = moment.utc(time);

    return moment.utc({
      year: dateM.year(),
      month: dateM.month(),
      date: dateM.date(),
      hour: timeM.hour(),
      minute: timeM.minute(),
      second: timeM.second(),
    });
  }

  /**
   *
   */
  loadAutopostBroadcast() {
    this.loadBot();
  }

  // @todo: move generation title to separate method
  /**
   * @returns {*} -
   */
  changeBroadcast() {
    if (!this.broadcast) {
      return undefined;
    }
    if (this.broadcast.trigger_type) {
      return this.changeSequence();
    }
    if (
      this.broadcast.type === 'weekly_repeat' ||
      this.broadcast.type === 'everyday' ||
      this.broadcast.type === 'everyweek' ||
      this.broadcast.type === 'workdays'
    ) {
      return this.changeBroadcastWeekly();
    }
    if (this.broadcast.type === 'monthly_repeat') {
      return this.changeBroadcastMonthly();
    }
    this.$deadlineErrorShow = false;

    this.loadBot()
      .then(() => {
        this.fixUseBotTimezone(this.broadcast);
        const now = moment();
        const timestamp = this.getBroadcastTs(
          this.selectedDate,
          this.broadcast.broadcast_start_timestamp,
        );
        const realBroadcastTime = timestamp.clone();

        if (this.broadcast.use_bot_timezone) {
          realBroadcastTime.subtract({ second: this.bot.timezone_offset });
        } else {
          realBroadcastTime.subtract({ minute: now.utcOffset() });
        }

        this.broadcast.broadcast_start_timestamp = timestamp.valueOf();
        const title = timestamp.format('MMM Do HH:mm');
        this.block.title = addRandomPostfix(title);
        this.broadcast.block_title = this.block.title;

        if (realBroadcastTime.valueOf() < now) {
          throw Error('You can not choose this time');
        }

        this.$scope.$emit('broadcast:update', this.broadcast.id, {
          type:
            this.broadcast.type === 'everyday' ||
            this.broadcast.type === 'everyweek' ||
            this.broadcast.type === 'workdays'
              ? 'weekly_repeat'
              : this.broadcast.type,
          broadcast_start_timestamp: this.broadcast.broadcast_start_timestamp,
          block_title: this.block.title,
          enabled: this.broadcast.enabled,
        });

        return this.updateBlock();
      })
      .then(() => {
        return this.BroadcastService.updateSchedule(
          this.broadcast.id,
          Object.assign({}, this.broadcast, {
            type:
              this.broadcast.type === 'everyday' ||
              this.broadcast.type === 'everyweek' ||
              this.broadcast.type === 'workdays'
                ? 'weekly_repeat'
                : this.broadcast.type,
            broadcast_start_timestamp: this.broadcast.broadcast_start_timestamp,
            use_bot_timezone: this.broadcast.use_bot_timezone,
            block_title: this.block.title,
          }),
        );
      })
      .catch((err) => {
        if (err instanceof Error) {
          this.$deadlineError = err.message;
          this.$deadlineErrorShow = true;
          this.$scope.$evalAsync(() => undefined);
        } else if (err.statusText === 'Conflict') {
          this.$deadlineError = i18next.t(
            'components.broadcast.controller.errors.conflict',
          );
          this.$deadlineErrorShow = true;
        }
      });

    return undefined;
  }

  /**
   *
   */
  changeBroadcastWeekly() {
    this.loadBot()
      .then(() => {
        this.fixUseBotTimezone(this.broadcast);

        const timestamp = this.getBroadcastTs(
          this.selectedDate,
          this.broadcast.broadcast_start_timestamp,
        );
        this.broadcast.broadcast_start_timestamp = timestamp.valueOf();

        this.block.title = this.changeBroadcastDays(true);
        this.broadcast.block_title = this.block.title;

        return this.updateBlock();
      })
      .then(() => {
        return this.BroadcastService.updateSchedule(
          this.broadcast.id,
          Object.assign({}, this.broadcast, {
            type:
              this.broadcast.type === 'everyday' ||
              this.broadcast.type === 'everyweek' ||
              this.broadcast.type === 'workdays'
                ? 'weekly_repeat'
                : this.broadcast.type,
            broadcast_start_timestamp: this.broadcast.broadcast_start_timestamp,
            weekly_day_time: this.broadcast.weekly_day_time,
            weekly_schedule: this.broadcast.weekly_schedule,
            use_bot_timezone: this.broadcast.use_bot_timezone,
          }),
        );
      })
      .then(() => {
        this.$scope.$emit('broadcast:update', this.broadcast.id, {
          type:
            this.broadcast.type === 'everyday' ||
            this.broadcast.type === 'everyweek' ||
            this.broadcast.type === 'workdays'
              ? 'weekly_repeat'
              : this.broadcast.type,
          broadcast_start_timestamp: this.broadcast.broadcast_start_timestamp,
          block_title: this.block.title,
          enabled: this.broadcast.enabled,
        });
      });
  }

  /**
   *
   */
  changeBroadcastMonthly() {
    this.loadBot()
      .then(() => {
        this.fixUseBotTimezone(this.broadcast);
        const timestamp = this.getBroadcastTs(
          this.selectedDate,
          this.broadcast.broadcast_start_timestamp,
        );
        this.broadcast.broadcast_start_timestamp = timestamp.valueOf();

        this.broadcast.monthly_day = timestamp.date();
        const title = i18next.t(
          'components.broadcast.controller.titleDayAndTime',
          {
            day: timestamp.format('Do'),
            time: timestamp.format('HH:mm'),
          },
        );

        this.block.title = addRandomPostfix(title);
        this.broadcast.block_title = this.block.title;

        return this.updateBlock();
      })
      .then(() => {
        return this.BroadcastService.updateSchedule(
          this.broadcast.id,
          Object.assign({}, this.broadcast, {
            type: this.broadcast.type,
            broadcast_start_timestamp: this.broadcast.broadcast_start_timestamp,
            monthly_day: this.broadcast.monthly_day,
            monthly_day_time: this.broadcast.monthly_day_time,
            use_bot_timezone: this.broadcast.use_bot_timezone,
            block_title: this.block.title,
          }),
        );
      })
      .then(() => {
        this.$scope.$emit('broadcast:update', this.broadcast.id, {
          type: this.broadcast.type,
          broadcast_start_timestamp: this.broadcast.broadcast_start_timestamp,
          block_title: this.block.title,
          enabled: this.broadcast.enabled,
        });
      });
  }

  /**
   * -
   * @param {string} id -
   * @return {Tag} -
   */
  getMessageTagForId(id) {
    return (
      (this.tagList && this.tagList.find((tag) => tag.tag === id)) ||
      DEFAULT_TAG
    );
  }

  isMoreThan24hWait() {
    return (
      (this.broadcast?.wait_interval_time_unit === 'minute' &&
        this.broadcast?.wait_interval > 1440) ||
      (this.broadcast?.wait_interval_time_unit === 'hour' &&
        this.broadcast?.wait_interval > 24) ||
      (this.broadcast?.wait_interval_time_unit === 'day' &&
        this.broadcast?.wait_interval > 1)
    );
  }

  update24hError() {
    this.is24hError =
      (!this.block.message_tag ||
        this.block.message_tag === STANDARD_MESSAGING_ID) &&
      this.broadcast?.trigger_type === 'last_interaction' &&
      this.isMoreThan24hWait();
  }

  /**
   *
   */
  changeSequence() {
    this.update24hError();

    let title = this.normalizeNum();
    const userFilterAttr = this.broadcast.user_filter_trigger;
    switch (this.broadcast.trigger_type) {
      case 'first_interaction':
        title = i18next.t(
          'components.broadcast.controller.triggerTitles.first_interaction',
          { title },
        );
        break;
      case 'last_interaction':
        title = i18next.t(
          'components.broadcast.controller.triggerTitles.last_interaction',
          { title },
        );
        break;
      case 'user_filter':
        title = i18next.t(
          'components.broadcast.controller.triggerTitles.user_filter',
          {
            title,
            attributeName: `{{${userFilterAttr.parameters[0].name || ''}}}`,
            interpolation: { maxReplaces: 2 },
          },
        );
        break;
      case 'abandoned_cart_update':
        title = i18next.t(
          'components.broadcast.controller.triggerTitles.abandoned_cart_update',
          { title },
        );
        break;
      default:
        break;
    }
    this.block.title = addRandomPostfix(title);
    this.broadcast.block_title = title;
    this.throttleHandler(title);
  }

  /**
   * -
   * @param {*} title  -
   */
  saveSequence(title) {
    this.updateBlock().then(() => {
      this.BroadcastService.updateSequence(
        this.broadcast.id,
        Object.assign({}, this.broadcast, {}),
      ).then(() => {
        this.$scope.$emit(
          'broadcast:update',
          this.broadcast.id,
          Object.assign({}, this.broadcast, {
            block_title: title,
          }),
        );
      });
    });
  }

  getUserFilterParameters = (id) => {
    const parameters = this.block.user_filter?.parameters || [];
    if (id === STANDARD_MESSAGING_ID) {
      const isParametersEmpty =
        parameters.length === 0 ||
        (parameters[0]?.name === '' && parameters[0]?.values?.length === 0);
      if (isParametersEmpty) return [within24hParameter.within24h];
    } else {
      const isOnly24hParamExists =
        parameters.length === 1 && parameters[0].name === within24hTitle;
      if (isOnly24hParamExists) return [];
    }
    return parameters;
  };

  onSelectTag = ({ id, purpose, topicId }) => {
    this.block.message_tag = id;
    this.block.otn_purpose = purpose;
    this.block.user_filter.parameters = this.getUserFilterParameters(id);

    this.block.notification_topic_id = topicId;

    if (this.$rootScope.stateParams.broadcastType === 'schedule') {
      this.broadcast.type = 'no_repeat';
      this.cleanRepeatType();
    }

    this.update24hError();
    this.saveMessageTag();
  };

  cleanRepeatType() {
    delete this.broadcast.monthly_day;
    delete this.broadcast.monthly_day_time;
    delete this.broadcast.weekly_day_time;
    delete this.broadcast.weekly_schedule;

    this.changeBroadcast();
  }

  /**
   * -
   */
  saveMessageTag() {
    this.BlockService.save({
      id: this.block.id,
      title: this.block.title,
      message_tag: this.block.message_tag,
      user_filter: this.block.user_filter,
      parent_group: this.block.parent_group,
      referral: this.block.referral,
      referral_active: this.block.referral_active,
      otn_purpose: this.block.otn_purpose,
      notification_topic_id: this.block.notification_topic_id,
    });
  }

  toggleEnabled = () => {
    this.$scope.$evalAsync(() => {
      this.broadcast.enabled = !this.broadcast.enabled;
      this.changeEnabled();
    });
  };

  /**
   * called when scheduled for later broadcast need to be enabled;
   * @returns {*} -
   */
  changeEnabled = () => {
    this.broadcast.block_title = this.block.title;

    if (this.$rootScope.stateParams.broadcastType === 'sequence') {
      return this.changeEnabledSequence();
    }
    this.BroadcastService.updateSchedule(
      this.broadcast.id,
      Object.assign({}, this.broadcast, {
        type:
          this.broadcast.type === 'everyday' ||
          this.broadcast.type === 'everyweek' ||
          this.broadcast.type === 'workdays'
            ? 'weekly_repeat'
            : this.broadcast.type,
        enabled: this.broadcast.enabled,
      }),
    )
      .then(() => {
        this.$scope.$emit(
          'broadcast:update',
          this.broadcast.id,
          Object.assign({}, this.broadcast, {
            type:
              this.broadcast.type === 'everyday' ||
              this.broadcast.type === 'everyweek' ||
              this.broadcast.type === 'workdays'
                ? 'weekly_repeat'
                : this.broadcast.type,
            enabled: this.broadcast.enabled,
          }),
        );
      })
      .catch((e) => {
        if (
          e &&
          e.data &&
          e.data.result === 'Bad Data: Big broadcasts limit exceeded'
        ) {
          this.broadcast.enabled = false;
          this.showBroadcastLimitNotifications();
        }
      });

    return undefined;
  };

  /**
   *
   */
  changeEnabledSequence() {
    this.BroadcastService.updateSequence(
      this.broadcast.id,
      Object.assign({}, this.broadcast, {
        enabled: this.broadcast.enabled,
      }),
    ).then(() => {
      this.$scope.$emit(
        'broadcast:update',
        this.broadcast.id,
        Object.assign({}, this.broadcast, {
          enabled: this.broadcast.enabled,
        }),
      );
    });
  }

  /**
   * onDeleteBlock
   * @param  {Object} e -
   * @return {undefined} -
   */
  onDeleteBlock = (e) => {
    e.preventDefault();
    this.BlockService.show(this.$rootScope.stateParams.blockId).then(
      (block) => {
        const context = this.$interpolate(
          this.ModalService.templates.bcBlock(),
        )({
          block:
            this.$rootScope.stateParams.broadcastType === 'now'
              ? i18next.t('components.broadcast.controller.sendNow')
              : i18next.t('components.broadcast.controller.this'),
        });
        this.ModalService.confirm(context).then(
          (isConfirmed) => isConfirmed && this.deleteBlock(),
        );
      },
    );
  };

  /**
   *
   */
  deleteBlock() {
    switch (this.$rootScope.stateParams.broadcastType) {
      case 'schedule': {
        this.$scope.$emit(
          'broadcast:remove',
          this.$rootScope.stateParams.broadcastId,
        );
        break;
      }
      case 'sequence': {
        this.$scope.$emit(
          'broadcast:removeSequence',
          this.$rootScope.stateParams.broadcastId,
        );
        break;
      }
      case 'now':
      case 'auto': {
        this.$scope.$emit('block:remove', this.$rootScope.stateParams.blockId);
        break;
      }
      default:
        break;
    }
  }

  /**
   *
   */
  sendBroadcastNow = () => {
    this.BlockService.broadcastNow(this.$rootScope.stateParams.blockId)
      .then(() => {
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/broadcast/sent`,
        );
      })
      .catch((e) => {
        if (
          e &&
          e.data &&
          e.data.result === 'Bad Data: Big broadcasts limit exceeded'
        ) {
          this.showBroadcastLimitNotifications();
        }
        //  alert(JSON.stringify(err.data || this.API_ERROR_MSG));
      });
  };

  /**
   * hide notification
   */
  hideNotification = debounce(() => {
    this.$scope.$evalAsync(() => {
      this.showBroadcastLimitNotification = false;
    });
  }, 5000);

  /**
   * show free plan limitation notification for broadcasts
   */
  showBroadcastLimitNotifications() {
    this.showBroadcastLimitNotification = true;
    sendEvent('/pro_events/limitation_shown/big_broadcast_number');
    this.hideNotification();
  }

  /**
   *
   */
  onSaveBlockTitle() {
    this.$scope.$emit('block:update', this.block.id, this.block.title);
  }

  /**
   * -
   * @param {*} timezoneType -
   */
  showPreferences(timezoneType) {
    if (this.broadcast) {
      if (!('use_bot_timezone' in this.broadcast)) {
        this.broadcast.use_bot_timezone = false;
      }
      this.broadcast.use_bot_timezone = timezoneType;

      if (this.$rootScope.stateParams.broadcastType === 'schedule') {
        this.BroadcastService.updateSchedule(
          this.broadcast.id,
          Object.assign({}, this.broadcast, {
            type:
              this.broadcast.type === 'everyday' ||
              this.broadcast.type === 'everyweek' ||
              this.broadcast.type === 'workdays'
                ? 'weekly_repeat'
                : this.broadcast.type,
            use_bot_timezone: this.broadcast.use_bot_timezone,
          }),
        );
      }
      if (this.$rootScope.stateParams.broadcastType === 'sequence') {
        this.BroadcastService.updateSequence(
          this.broadcast.id,
          Object.assign({}, this.broadcast, {
            type:
              this.broadcast.type === 'everyday' ||
              this.broadcast.type === 'everyweek' ||
              this.broadcast.type === 'workdays'
                ? 'weekly_repeat'
                : this.broadcast.type,
            use_bot_timezone: this.broadcast.use_bot_timezone,
          }),
        );
      }
    }
  }

  /**
   * -
   * @param {*} isSave -
   * @returns {*} -
   */
  changeBroadcastDays(isSave) {
    const titles = {
      0: i18next.t('components.broadcast.controller.broadcastDays.never'),
      127: i18next.t('components.broadcast.controller.broadcastDays.everyDay'),
      3: i18next.t('components.broadcast.controller.broadcastDays.weekends'),
      124: i18next.t('components.broadcast.controller.broadcastDays.workdays'),
    };

    const bitMask = parseInt(
      this.broadcast.weekly_schedule.map((item) => (item ? 1 : 0)).join(''),
      2,
    );

    let title = '';

    if (titles[bitMask]) {
      title += titles[bitMask];
    } else {
      title += this.daysIndex
        .filter((item) => this.broadcast.weekly_schedule[item])
        .map((item) => this.days[item])
        .join(', ');
    }

    title += moment
      .utc(this.broadcast.broadcast_start_timestamp)
      .format(' HH:mm');

    const blockTitle = addRandomPostfix(title);
    this.block.title = blockTitle;
    this.broadcast.block_title = blockTitle;

    if (!isSave) {
      return this.BroadcastService.updateSchedule(
        this.broadcast.id,
        Object.assign({}, this.broadcast, {
          weekly_schedule: this.broadcast.weekly_schedule,
        }),
      )
        .then(() => this.updateBlock(blockTitle))
        .then(() => {
          this.$scope.$emit(
            'broadcast:update',
            this.broadcast.id,
            Object.assign({}, this.broadcast, { block_title: blockTitle }),
          );
        });
    }
    return blockTitle;
  }

  updateBlock = (blockTitle) =>
    this.BlockService.update({
      id: this.block.id,
      title: blockTitle ?? this.block.title,
      user_filter: this.block.user_filter,
      message_tag: this.block.message_tag,
      otn_purpose: this.block.otn_purpose,
      notification_topic_id: this.block.notification_topic_id,
    });

  /**
   * -
   */
  resend = () => {
    /**
     * -
     * @param {*} bl  -
     */
    this.BlockService.clone(this.block.id).then((newBlock) => {
      this.BlockService.update(newBlock).then(() => {
        if (this.$rootScope.stateParams.broadcastType === 'now') {
          this.$rootScope.stateHistory.push(
            `/bot/${this.$rootScope.stateParams.botId}/broadcast/${newBlock.id}/0/now`,
          );
        } else if (this.$rootScope.stateParams.broadcastType === 'auto') {
          this.$scope.$emit('broadcast:copyAutopost', { block: newBlock });
        } else if (this.$rootScope.stateParams.broadcastType === 'sequence') {
          this.$scope.$emit('broadcast:copySequence', {
            block: newBlock,
            broadcast: this.broadcast,
          });
        } else {
          this.$scope.$emit('broadcast:copy', {
            block: newBlock,
            broadcast: this.broadcast,
          });
        }
      });
    });
  };

  /**
   * @returns {string} -
   */
  $getTimeZone() {
    if (!this.bot) {
      return '';
    }
    return `UTC${moment()
      .utcOffset(this.bot.timezone_offset / 60)
      .format('Z')}`;
  }

  /**
   * @returns {String} -
   */
  normalizeNum() {
    if (
      this.broadcast.wait_interval < 0 ||
      this.broadcast.wait_interval === undefined ||
      this.broadcast.wait_interval === null
    ) {
      this.broadcast.wait_interval = 1;
    }
    return `${this.broadcast.wait_interval} ${
      this.timeIntervalUnits[this.broadcast.wait_interval_time_unit]
    }`;
  }

  /**
   * @returns {*} -
   */
  switchRepeatType() {
    const timestamp = this.getBroadcastTs(
      this.selectedDate,
      this.broadcast.broadcast_start_timestamp,
    );
    sendEvent({
      category: 're-engage',
      action: 'schedule',
      label: this.repeatType[this.broadcast.type]
        .replace(/[.:]/g, '')
        .toLowerCase(),
      propertyBag: {
        date: timestamp.format('YYYY-MM-DD'),
        time: timestamp.format('HH:mm'),
        timezone: this.$getTimeZone(),
      },
    });
    // seconds from start of the day
    const timeInSeconds = moment
      .duration({
        hour: timestamp.hour(),
        minute: timestamp.minute(),
      })
      .seconds();
    switch (this.broadcast.type) {
      case 'no_repeat':
        this.cleanRepeatType();
        return this.broadcast;
      case 'weekly_repeat':
        delete this.broadcast.monthly_day;
        delete this.broadcast.monthly_day_time;
        this.broadcast.weekly_day_time = timeInSeconds;
        this.broadcast.weekly_schedule = [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ];
        this.changeBroadcastWeekly();
        return this.broadcast;
      case 'monthly_repeat':
        delete this.broadcast.weekly_day_time;
        delete this.broadcast.weekly_schedule;
        this.broadcast.monthly_day_time = timeInSeconds;
        this.broadcast.monthly_day = 1;
        this.changeBroadcastMonthly();
        return this.broadcast;
      case 'everyday':
        delete this.broadcast.monthly_day;
        delete this.broadcast.monthly_day_time;
        this.broadcast.weekly_day_time = timeInSeconds;
        this.broadcast.weekly_schedule = [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ];
        this.changeBroadcastWeekly();
        return this.broadcast;
      case 'everyweek':
        delete this.broadcast.monthly_day;
        delete this.broadcast.monthly_day_time;
        this.broadcast.weekly_day_time = timeInSeconds;
        this.broadcast.weekly_schedule = [
          false,
          false,
          false,
          false,
          false,
          true,
          true,
        ];
        this.changeBroadcastWeekly();
        return this.broadcast;
      case 'workdays':
        delete this.broadcast.monthly_day;
        delete this.broadcast.monthly_day_time;
        this.broadcast.weekly_day_time = timeInSeconds;
        this.broadcast.weekly_schedule = [
          true,
          true,
          true,
          true,
          true,
          false,
          false,
        ];
        this.changeBroadcastWeekly();
        return this.broadcast;
      default:
        return this.broadcast;
    }
  }
}
