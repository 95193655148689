import ng from 'angular';

import Component from './component';

import './assets/less/quick-reply-plugin.less';

import quickReplies from './quick-replies';

export default ng
  .module('app.common.plugins.pluginCardQuickReplies', [quickReplies])
  .directive('pluginCardQuickReplies', Component).name;
