import React, { useCallback, useMemo } from 'react';
import { Router } from 'react-router-dom';
import { propEq } from 'ramda';
import { ApolloProvider } from '@apollo/react-common';
import { globalHistory, useCurrentBotId } from '@utils/Routing';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import client from '../../../common/services/ApolloService';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './DefaultAnswer.css';
import { useAiDefaultAnswer } from '@utils/Data/Ai/PredefinedIntents';
import { ACTION_FIELD_BY_PLATFORM } from '@utils/Data/Ai/consts';
import { AiIntentActionItemType, Platform } from '@globals';
import { useBlocksSelectorData } from '@components/BlocksSelector2';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Respond } from '@pages/BotPage/KeywordsPage/components/common/Respond';
import { RespondLoader } from '@pages/BotPage/KeywordsPage/components/common/RespondLoader';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import {
  getRespondIntervalItems,
  HOURS_TO_RESPOND_INTERVAL_ITEM,
  RESPOND_INTERVAL_ITEM_TO_HOURS,
} from './consts';
import { RespondIntervalItem } from './types';
import { useWhatsappEnabled } from '@utils/Whatsapp';

const DefaultAnswer: React.FC = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const {
    aiDefaultAnswerIntent,
    updateAiDefaultIntent,
    aiDefaultAnswerRespondInterval,
    updateAiDefaultAnswerRespondInterval,
    loading: defaultAnswerLoading,
  } = useAiDefaultAnswer(botId);
  const { isWhatsappEnabled } = useWhatsappEnabled();

  const { loading: blocksSelectorDataLoading } = useBlocksSelectorData();

  const respondRender = useCallback(
    (platform: Platform) => {
      const defaultAnswer =
        aiDefaultAnswerIntent?.[ACTION_FIELD_BY_PLATFORM[platform]]?.items[0];
      const type = defaultAnswer?.item_type || AiIntentActionItemType.text;
      const text = defaultAnswer?.text || '';
      const blocks = defaultAnswer?.blocks || [];
      return (
        <Respond
          className={css.respond}
          platform={platform}
          type={type}
          text={text}
          blocks={blocks}
          eventLabel="default answer"
          data-testid={`configure-tab__default-answer-block__response${platform}`}
          onTypeChange={(updatedType) => {
            updateAiDefaultIntent({
              actionField: ACTION_FIELD_BY_PLATFORM[platform],
              type: updatedType,
              blocks,
              text,
            });
          }}
          onTextChange={(value) => {
            updateAiDefaultIntent({
              blocks,
              text: value,
              type,
              actionField: ACTION_FIELD_BY_PLATFORM[platform],
            });
          }}
          onBlocksChange={(updatedBlocks) => {
            updateAiDefaultIntent({
              blocks: updatedBlocks,
              text,
              type,
              actionField: ACTION_FIELD_BY_PLATFORM[platform],
            });
          }}
        />
      );
    },
    [aiDefaultAnswerIntent, updateAiDefaultIntent],
  );

  const currentRespondIntervalItem = useMemo(
    () =>
      getRespondIntervalItems().find(
        propEq(
          'id',
          HOURS_TO_RESPOND_INTERVAL_ITEM[aiDefaultAnswerRespondInterval],
        ),
      ),
    [aiDefaultAnswerRespondInterval],
  );

  const loading = blocksSelectorDataLoading || defaultAnswerLoading;

  const renderPlatforms = () => {
    if (isWhatsappEnabled) {
      return loading ? <RespondLoader /> : respondRender(Platform.whatsapp);
    }

    return (
      <>
        <Flex alignItems="center">
          <Icon icon="facebook" size="20px" />
          <Spacer horizontalFactor={2} factor={0} />
          <Type size="15px" weight="semibold">
            {t('components.settings.defaultAnswer.Facebook')}
          </Type>
        </Flex>
        <Spacer factor={3} />
        {loading ? <RespondLoader /> : respondRender(Platform.facebook)}

        <Spacer factor={6} />
        <Flex alignItems="center">
          <Icon icon="instagram" size="20px" />
          <Spacer horizontalFactor={2} factor={0} />
          <Type size="15px" weight="semibold">
            {t('components.settings.defaultAnswer.Instagram')}
          </Type>
        </Flex>
        <Spacer factor={3} />
        {loading ? <RespondLoader /> : respondRender(Platform.instagram)}
      </>
    );
  };

  return (
    <div
      data-testid="configure-tab__default-answer-block"
      className="border-box"
    >
      <Type size="24px" weight="light">
        {t('components.settings.defaultAnswer.title')}
      </Type>
      <Spacer factor={5} />

      <div className={css.note}>
        {t('components.settings.defaultAnswer.description')}
      </div>

      <Spacer factor={4} />

      <Flex alignItems="center">
        <Type size="15px">
          {t('components.settings.defaultAnswer.respondIntervalText')}
        </Type>
        <Spacer factor={0} horizontalFactor={3} />
        <ComboboxWithTriangleButton<RespondIntervalItem>
          onChange={(item) => {
            updateAiDefaultAnswerRespondInterval(
              RESPOND_INTERVAL_ITEM_TO_HOURS[item.id],
            );
          }}
          items={getRespondIntervalItems()}
          intent="secondary"
          loading={loading}
          containerClassName={css.dropdown}
          selectedItem={currentRespondIntervalItem}
          className={css.dropdownButton}
          data-testid="configure-tab__default-answer-block__respond-interval"
        />
      </Flex>

      <Spacer factor={7} />

      {renderPlatforms()}
    </div>
  );
};

export const DefaultAnswerWrapper: React.FC = () => {
  return (
    <Router history={globalHistory}>
      <ApolloProvider client={client}>
        <DefaultAnswer />
      </ApolloProvider>
    </Router>
  );
};
