import ng from 'angular';
import { react2angular } from 'react2angular';
import { UserNavigationItemWithData } from './UserNavigationItem/UserNavigationItemWithData';
import UserService from './service';

export default ng
  .module('app.common.userControl', [])
  .factory('UserService', UserService) // TODO: not used here, but used by some other components; refactor
  .component(
    'userNavigationItem',
    react2angular(UserNavigationItemWithData, []),
  ).name;
