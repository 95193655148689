import gql from 'graphql-tag';
import { assoc } from 'ramda';
import { Level, log } from 'cf-common/src/logger';
import {
  serverStorageItemGet,
  ServerStorageItemKeys,
} from '../../utils/ServerStorage';
import { removeTypename } from '../../utils/GQL/utils';
import { sendEvent } from '../../utils/Analytics';
import ApolloService from './ApolloService';

export default class StatisticService {
  constructor(StoreService, $rootScope) {
    'ngInject';

    this.StoreService = StoreService;
    this.ApolloService = ApolloService;
    this.$rootScope = $rootScope;
    this._userQuery = gql`
      query USER_QUERY_GTM_EXPOSE {
        me {
          id
          name
          email
          date_added
          abt_serialized
          flags
          page_stats {
            likes
            connected_pages
            connected_page_likes
            pages
            users
            pro_pages
            pro_page_users
          }
        }
      }
    `;
  }

  /**
   * @description: This method is responsible for downloading use and expose specific properties to GTM
   *               So you downloading specific data for specific purpose;
   *               I think it is valid to put GQL request directly here and not in separate service like (MeService + UserQueryConstant + etc);
   *               it waits for user token, request user info and set dataLayer
   * @return {Promise<void>}
   */
  async initCurrentUserVarsInGTM() {
    try {
      const [
        {
          data: { me: meData },
        },
        leadsQualificationData,
      ] = await Promise.all([
        this.ApolloService.query({
          query: this._userQuery,
          fetchPolicy: 'network-only',
        }),
        serverStorageItemGet(ServerStorageItemKeys.leadsQualificationData),
      ]);

      const {
        flags,
        page_stats: pageStats,
        ...userData
      } = assoc('createdAt', parseInt(meData.date_added, 10), meData);

      sendEvent(
        removeTypename({
          event: 'user_loaded',
          ...userData,
          ...pageStats,
          ...(leadsQualificationData || {}),
        }),
      );
      sendEvent({
        event: 'state_changed',
        botId: this.$rootScope.stateParams?.botId,
      });
      this.appcuesTrackBotId();
    } catch (error) {
      log.warn({ error, msg: 'Failed to initialize current user vars in GTM' });
    }
  }

  appcuesTrackBotId() {
    this.$rootScope.$on('$stateChangeSuccess', (event, toState, toParams) =>
      sendEvent({
        event: 'state_changed',
        botId: toParams && toParams.botId,
      }),
    );
  }
}
