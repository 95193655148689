import './assets/less/broadcast-omnibox-container.less';

import ng from 'angular';
import { ngWarningText } from '../../../modern-ui/WarningText/WarningText.angular';
import template from './broadcast-omnibox-container.html';
import controller from './controller';

export default ng
  .module('app.ui.broadcastOmniboxContainer', [ngWarningText])
  .component('broadcastOmniboxContainer', {
    template: () => template,
    controllerAs: 'vm',
    controller,
    bindings: {
      /**
       * @param block -
       * @param bot -
       */
      block: '<',
      bot: '<',
    },
  }).name;
