import React, { CSSProperties } from 'react';
import OutsideClick from 'react-outsideclick';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Flex } from '@ui/Flex';
import { Divider, Menubox, MenuItem } from '@ui/Menu';
import { Menu, useNestedMenu, NestedMenuNodeDisplayMode } from '@ui/NestedMenu';
import { Type } from '@ui/Type';
import { ReactComponent as NotificationIcon } from '@ui/_deprecated/Icon/icons/notification.svg';
import { MenuOption, NavigationAction } from './types';
import * as css from './UserNavigationItem.css';

interface MenuGroup {
  name: string | null;
  children: MenuOption[];
}

function itemToString(item: MenuOption) {
  return item?.title ?? '';
}

function countLengths(groups: MenuGroup[]) {
  return groups.reduce((sum, group) => sum + group.children.length, 0);
}

interface UserNavigationMenuProps {
  setOpen: (open: boolean) => void;
  menuOptions: MenuGroup[];
  onSelect: (item: MenuOption) => void;
}

export const UserNavigationMenu = ({
  setOpen,
  menuOptions,
  onSelect,
}: UserNavigationMenuProps) => {
  const { isSmallScreenSize } = useDeviceMedia();

  let allItems = menuOptions.flatMap((option) => option.children);

  if (isSmallScreenSize) {
    allItems = allItems.flatMap((option) =>
      option.children ? option.children : option,
    );
  }

  const nestedMenuProps = useNestedMenu<MenuOption>({
    isRoot: true,
    allItems,
    onChange: (item) => {
      setOpen(false);
      onSelect(item);
    },
  });

  const {
    highlightedIndex,
    setHighlightedIndex,
    setOpenedMenuIndex,
    onChange,
  } = nestedMenuProps;

  const languagesLength =
    menuOptions
      .flatMap((option) => option.children)
      .find(({ id }) => id === NavigationAction.languageSelector)?.children
      ?.length || 0;

  return (
    <OutsideClick onClickOutside={() => setOpen(false)}>
      <Menubox className={css.menubox}>
        {menuOptions.map((group, groupIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={groupIndex}>
            {group.name && (
              <MenuItem title={group.name} className={css.groupTitle} />
            )}
            {group.children.map((item, itemIndex) => {
              const shift =
                isSmallScreenSize && item.id === NavigationAction.logout
                  ? languagesLength
                  : 0;
              const delta =
                groupIndex === 0
                  ? 0
                  : countLengths(menuOptions.slice(0, groupIndex));
              const index = itemIndex + delta + shift;

              if (item.id === NavigationAction.languageSelector) {
                if (!isSmallScreenSize) {
                  return (
                    <Menu<MenuOption>
                      key={item.title}
                      indexDelta={index}
                      items={[item]}
                      getTitleStyles={() => ({
                        width: 130,
                      })}
                      getRightElement={({ item, active }) =>
                        item.rightElement?.(active)
                      }
                      {...nestedMenuProps}
                    />
                  );
                }

                return (
                  <>
                    <Divider />
                    <Menu<MenuOption>
                      key={item.title}
                      indexDelta={index}
                      items={[item].map((item) => ({
                        ...item,
                        displayMode: NestedMenuNodeDisplayMode.structure,
                      }))}
                      getTitleStyles={() => ({
                        width: 118,
                      })}
                      getRightElement={({ item, active }) =>
                        item.rightElement?.(active)
                      }
                      renderGroupView={(item) => (
                        <div className={css.groupWrapper}>
                          <Type size="15px" weight="bold">
                            {item.title}
                          </Type>
                        </div>
                      )}
                      {...nestedMenuProps}
                    />
                  </>
                );
              }

              return (
                <div
                  key={item.title}
                  className={css.fullWidth}
                  onMouseEnter={() => {
                    setOpenedMenuIndex(-1);
                    setHighlightedIndex(index);
                  }}
                  onMouseLeave={() => {
                    setHighlightedIndex(-1);
                  }}
                >
                  <MenuItem
                    type="item"
                    onClick={() => onChange(item)}
                    style={
                      item.nestingLevel
                        ? ({
                            paddingLeft: `calc(var(--unit) * 2 * ${item.nestingLevel} + 12px)`,
                          } as CSSProperties)
                        : undefined
                    }
                    active={index === highlightedIndex}
                    titleElement={
                      <>
                        {item.warning && (
                          <Type size="12px" color="red">
                            {item.warning}
                          </Type>
                        )}
                        <Flex alignItems="center" title={item.fullTitle}>
                          <span>{itemToString(item)}</span>
                          {item.icon === 'notification' && (
                            <NotificationIcon
                              className={css.notificationWarning}
                            />
                          )}
                        </Flex>
                      </>
                    }
                  />
                </div>
              );
            })}
            {groupIndex < menuOptions.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Menubox>
    </OutsideClick>
  );
};
