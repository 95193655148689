import { isEuRulesError } from '@utils/EuRules/isEuRulesError';
import { pollForCardUploadStatus } from '../cardMediaUpload';
import { toaster } from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2/ServiceMessage';
import { isWhiteLabelDomain } from '../../../utils/WhiteLabelUtils';
import { sendPluginHelpOpenGaEvent } from '../../../modern-components/Plugins/common/PluginHeader';
import { createSetUpZapierInFlowsButton } from '../../../modern-components/FlowBuilder/views/components/Zapier/SetUpZapierInFlows/SetUpZapierInFlows';

const SAVE_MEDIA_UPLOAD_ERROR_MES = 'SaveMediaUpload Error';
export default class CardPluginBaseController {
  constructor(
    $scope,
    $rootScope,
    $interpolate,
    ModalService,
    BotService,
    BlockService,
    $sce,
    $timeout,
    $element,
    StoreService,
    PluginCardService,
  ) {
    'ngInject';

    this.ModalService = ModalService;
    this.BotService = BotService;
    this.PluginCardService = PluginCardService;
    this.BlockService = BlockService;
    this.StoreService = StoreService;

    this.$timeout = $timeout;
    this.$element = $element;
    this.$sce = $sce;
    this.$interpolate = $interpolate;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$showSelect = false;
    this.API_URL = this.StoreService.getFullApiUrl();
    this.botId = this.$rootScope.stateParams.botId;
    this.page = null;
    this.showFooter = false;
    this.hasValidationError = false;

    this.validation = {
      title: true,
      subtitle: true,
      button: true,
    };

    this.storiesNumbers = {
      1: '1 story',
      2: '2 stories',
      3: '3 stories',
      4: '4 stories',
      5: '5 stories',
      6: '6 stories',
      7: '7 stories',
      8: '8 stories',
      9: '9 stories',
    };

    this.listeners = [];
    this.cardPollingMap = {};

    this.listeners.push(
      $scope.$on('$angularClick', () => {
        $scope.$evalAsync(() => {
          this.$showSelect = false;
          this.$showHelp = false;
        });
      }),
    );

    this.listeners.push(
      $scope.$on('$pluginHasErrorValidation', (e, data) => {
        if (data.id === this.item.id) {
          this.hasValidationError = data.isError;
          this.errorCode = data.errorCode;
        }
      }),
    );

    this.$timeout(() => {
      if (
        this.item &&
        (this.item.config.subscriptions_block ||
          this.item.config.no_subscriptions_block_id)
      ) {
        this.BlockService.list().then((res) => {
          this.blockList = res;
          if (this.item.config.subscriptions_block) {
            this.currentBlock = [
              this.blockList.find(
                (block) => block.id === this.item.config.subscriptions_block,
              ),
            ];
          } else if (this.item.config.no_subscriptions_block_id) {
            this.currentBlock = [
              this.blockList.find(
                (block) =>
                  block.id === this.item.config.no_subscriptions_block_id,
              ),
            ];
          }
        });
      } else {
        this.$timeout(() => {
          this.currentBlock = [];
          if (!this.item.id) {
            this.save(true);
          }
          if (!this.item.validation_details) {
            this.item.validation_details = {};
          }
          if (this.item.is_valid === false) {
            this.data = this.item.validation_details || {};
            this.data.card_id = this.item.id;
            this.$rootScope.$broadcast('$pluginSaveResultError', {
              data: this.data,
            });
          } else {
            this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
          }
        });
      }
    });
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
    Object.values(this.cardPollingMap).forEach(({ cancel }) => cancel());
  }

  load($query) {
    return this.BlockService.list($query);
  }

  getCurrentItem() {
    return this.item || this.pluginEntity;
  }

  save() {
    return this.PluginListController.savePlugin(this.getCurrentItem());
  }

  saveMediaUpload({ cardId }) {
    if (this.cardPollingMap[cardId]) {
      this.cardPollingMap[cardId].cancel();
      delete this.cardPollingMap[cardId];
    }

    const currentItem = this.getCurrentItem();
    if (!currentItem) {
      return undefined;
    }

    return this.PluginListController.savePlugin(currentItem)
      .then(() => {
        const currentItem = this.item || this.pluginEntity;
        if (!currentItem.is_valid) {
          return Promise.reject(new Error(SAVE_MEDIA_UPLOAD_ERROR_MES));
        }
        return undefined;
      })
      .then(() => {
        const { promise, cancel } = pollForCardUploadStatus(cardId);
        this.cardPollingMap[cardId] = { cancel };
        return promise;
      })
      .then(({ errorMessage }) => {
        this.hasValidationError = false;
        currentItem.is_valid = true;
        return errorMessage;
      })
      .catch((error) => {
        const botNotConnectedRE = /Bot \w+ not connected to page/;
        if (botNotConnectedRE.test(error.message)) {
          toaster.show({
            type: ServiceMessageType.error,
            payload: {
              message: 'Bot is not connected to a page. Cannot upload media.',
            },
          });
        }
        if (isEuRulesError(error.message)) {
          this.hasValidationError = false;
          currentItem.is_valid = true;
          return '';
        }
        this.hasValidationError = true;
        currentItem.is_valid = false;
        return error.message;
      });
  }

  saveSearch() {
    this.PluginListController.savePlugin(this.item)
      .then((res) => {
        this.saveError = null;
      })
      .catch((e) => {
        if (
          e.data.message ===
            'Failed to check Google API with this key and cx: 400' ||
          e.data.message === 'Failed to check Bing API with this token: 401'
        ) {
          this.saveError = 'tokenError';
        } else {
          throw e;
        }
      });
  }

  setNumberPages(n) {
    this.item.config.number_of_pages = parseInt(n, 10);
    this.save();
  }

  saveInSub() {
    if (this.currentBlock && this.currentBlock.length > 0) {
      this.item.config.no_subscriptions_block_id = this.currentBlock[0].id;
    }
    if (this.currentBlock && this.currentBlock.length > 0) {
      this.item.config.subscriptions_block = this.currentBlock[0].id;
    }
  }

  onTagRemoved() {
    this.$timeout(() => {
      const tInput = document.activeElement;
      tInput.blur();
      this.$timeout(() => {
        tInput.focus();
      });
    });
    this.saveInSub();
  }

  isWhiteLabelDomain = () => isWhiteLabelDomain();

  sendPluginHelpOpenGaEvent = () => {
    sendPluginHelpOpenGaEvent(this.item.plugin_id);
  };

  createSetUpZapierInFlowsButton(pluginType) {
    return createSetUpZapierInFlowsButton(pluginType);
  }
}
