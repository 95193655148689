import { log } from 'cf-common/src/logger';

export default class EventListner {
  constructor($window) {
    'ngInject';

    this.$window = $window;
    this.isPassiveOptionsOnAddEventListerSupported = false;
    this._detectPassiveFeature();
  }

  _detectPassiveFeature() {
    // reference https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
    try {
      const options = Object.defineProperty({}, 'passive', {
        get: () => {
          this.isPassiveOptionsOnAddEventListerSupported = true;
          return undefined;
        },
      });
      this.$window.addEventListener('test', null, options);
    } catch (error) {
      log.verbose({ error });
    }
  }

  addEventListener(target, name, handler, options = {}) {
    const { isPassiveOptionsOnAddEventListerSupported: supported } = this;
    const names = name.split(' ');
    names.forEach((name) => {
      target.addEventListener(
        name,
        handler,
        supported ? options : !!options.capture,
      );
    });
  }

  removeEventListener(target, name, hadler, options = {}) {
    const names = name.split(' ');
    names.forEach((name) => {
      target.removeEventListener(name, hadler, !!options.capture);
    });
  }
}
