import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import gql from 'graphql-tag';
import { compose, path } from 'ramda';
import copy from 'clipboard-copy';

import { sendEvent } from '@utils/Analytics';

import { Autofocus } from '@ui/Autofocus';
import { Button } from '@ui/Button';
import { Input } from '@ui/Input';
import { Modal } from '@ui/Modal';
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeading,
} from '@ui/Dialog';

import client from '../../../../common/services/ApolloService';

import { RolesList } from '../RolesList';
import { IAdminRole, RoleIds } from '../RolesList/AdminsRoles';

import { BotLimitReachedDialog } from './BotLimitReachedDialog/BotLimitReachedDialog';
import { WhiteLabelInviteDialog } from './WhiteLabelInviteDialog/WhiteLabelInviteDialog';

import * as css from './InviteAdminPopup.css';
import { CREATE_BOT_INVITE_MUTATION } from '@utils/Data/Invite';

interface IInviteAdminPopupState {
  selectedRole?: IAdminRole;
  inviteToken: string | undefined;
  showBotLimitReachedDialog: boolean;
}

interface IInviteAdminPopupProps {
  onRequestClose: () => void;
  botId: string;
  showWhiteLabelRole: boolean;
  showBotLimitReachedPopup?: boolean;
}

export const GET_BOT_INVITES_QUERY = gql`
  query GET_BOT_INVITES_QUERY($botId: String!) {
    bot(id: $botId) {
      id
      invites {
        id
        author
        bot
        created
        roleId
        login
      }
    }
  }
`;

const createPredefinedRoleInvite = async (botId: string, roleId: string) => {
  const result = await client.mutate({
    mutation: CREATE_BOT_INVITE_MUTATION,
    variables: {
      botId,
      roleId,
    },
    refetchQueries: [
      {
        query: GET_BOT_INVITES_QUERY,
        variables: {
          botId,
        },
      },
    ],
  });

  return path<string | undefined>(['data', 'createBotInvite'], result);
};

class InviteAdminPopupComponent extends React.Component<
  IInviteAdminPopupProps & WithTranslation,
  IInviteAdminPopupState
> {
  state: IInviteAdminPopupState = {
    selectedRole: undefined,
    inviteToken: undefined,
    showBotLimitReachedDialog: false,
  };

  selectedPermissions: any;

  createInviteToken = () => {
    const { inviteToken } = this.state;
    const { protocol, host } = window.location;
    return `${protocol}//${host}/invite/${inviteToken || '...'}`;
  };

  handleRoleClick = async (selectedRole: IAdminRole) => {
    sendEvent({
      category: 'team',
      action: 'invite teammate',
      label: selectedRole.title,
      propertyBag: {
        role: selectedRole.title,
      },
    });

    if (
      this.props.showBotLimitReachedPopup &&
      selectedRole.id === RoleIds.whiteLabel
    ) {
      this.setState({
        showBotLimitReachedDialog: true,
      });
      return;
    }

    const { botId } = this.props;
    this.setState({
      selectedRole,
    });

    if (selectedRole.isPredefined) {
      const inviteToken = await createPredefinedRoleInvite(
        botId,
        selectedRole.id,
      );
      if (inviteToken) {
        this.setState({
          inviteToken,
        });
      }
    }
  };

  handleCopyClick = () => {
    copy(this.createInviteToken());
    sendEvent({
      category: 'team',
      action: 'invite link copied',
    });
  };

  renderRolesListDialog() {
    const { t, showWhiteLabelRole } = this.props;
    return (
      <DialogContent>
        <DialogHeading>
          {t('components.settings.admins.InviteAdminPopup.roleListHeader')}
        </DialogHeading>
        <div className={css.listTitle}>
          <span>
            {t('components.settings.admins.InviteAdminPopup.roleListInfo')}
          </span>
        </div>
        <RolesList
          onRoleClick={this.handleRoleClick}
          showTriangles
          showWhiteLabelRole={showWhiteLabelRole}
          showCustomRole
        />
      </DialogContent>
    );
  }

  renderPredefinedRoleInviteDialog() {
    const { inviteToken } = this.state;
    const { t, onRequestClose } = this.props;
    return (
      <DialogContent>
        <DialogHeading>
          {t('components.settings.admins.InviteAdminPopup.roleInviteHeader')}
        </DialogHeading>
        <div className={css.inviteLinkTitle}>
          {t('components.settings.admins.InviteAdminPopup.roleInviteInfo')}
        </div>
        <div className={css.linkBox}>
          <div
            className={cn(css.link, {
              [css.disabledLink]: !inviteToken,
            })}
          >
            <Autofocus
              selectAll
              shouldFocus={!!inviteToken}
              render={({ bind }) => (
                <Input
                  data-testid="admins__invitation-link-input"
                  readOnly
                  value={this.createInviteToken()}
                  {...bind}
                />
              )}
            />
          </div>
          <Button
            data-testid="admins__invitation-link-copy-button"
            disabled={!inviteToken}
            style={{ minWidth: 144 }}
            intent="primary"
            onClick={compose(onRequestClose, this.handleCopyClick)}
          >
            {t('components.settings.admins.InviteAdminPopup.copy')}
          </Button>
        </div>
      </DialogContent>
    );
  }

  render() {
    const { selectedRole, showBotLimitReachedDialog } = this.state;
    const { onRequestClose, botId } = this.props;
    return (
      <Modal onDismiss={onRequestClose}>
        {showBotLimitReachedDialog && (
          <BotLimitReachedDialog
            onRequestClose={() => {
              this.setState({
                showBotLimitReachedDialog: false,
              });
            }}
          />
        )}
        {!showBotLimitReachedDialog && (
          <div className={css.fixedVirtualHeight}>
            <Dialog
              className={css.popup}
              data-testid="admins__invite-teammate-popup"
            >
              {selectedRole ? (
                selectedRole.isPredefined ? (
                  this.renderPredefinedRoleInviteDialog()
                ) : (
                  <WhiteLabelInviteDialog
                    botId={botId}
                    onRequestClose={onRequestClose}
                  />
                )
              ) : (
                this.renderRolesListDialog()
              )}
              <DialogCloseButton onClick={onRequestClose} />
            </Dialog>
          </div>
        )}
      </Modal>
    );
  }
}

export const InviteAdminPopup = withTranslation()(InviteAdminPopupComponent);
