import ng from 'angular';
import DomainValidationService from './DomainValidationService';
import ClipboardService from './ClipboardService';
import EventListener from './EventListener';
import NamingService from './NamingService';
import SegmentService from './SegmentService';
import Constants from './Constants';
import CaretService from './CaretService';
import MonetizationService from './MonetizationService';
import StatisticService from './StatisticService';
import ColorService from './ColorService';
import BotGQService from './BotGQService';
import CollectionService from './CollectionService';
import OneElementQueueService from './OneElementQueueService';
import * as BotUserVariable from './BotUserVariable';
import * as CollectionUtils from './CollectionUtils';
import * as DOMService from './DOMService';
import * as Segmentation from './Segmentation';
import * as UserFilterComponentService from './UserFilterComponentService';
import { CurrencyService } from './CurrencyService';
import { LocalStorageService } from './LocalStorageService';
import ApolloService from './ApolloService';
import { UserFilterService } from './UserFilterService';
import { OmniboxService } from './OmniboxService';
import { TagsService } from './TagsService';

export default ng
  .module('app.services', [])
  .service('DomainValidationService', DomainValidationService)
  .service('ClipboardService', ClipboardService)
  .service('EventListenerService', EventListener)
  .service('NamingService', NamingService)
  .service('SegmentService', SegmentService)
  .service('TagsService', TagsService)
  .service('Constants', Constants)
  .service('CaretService', CaretService)
  .service('MonetizationService', MonetizationService)
  .service('StatisticService', StatisticService)
  .service('ColorService', ColorService)
  .service('BotGQService', BotGQService)
  .service('CollectionService', CollectionService)
  .service('CurrencyService', CurrencyService)
  .service('LocalStorageService', LocalStorageService)
  .service('OneElementQueueService', OneElementQueueService)
  .service('UserFilterService', UserFilterService)
  .service('OmniboxService', OmniboxService)
  .constant('BotUserVariable', BotUserVariable)
  .constant('CollectionUtils', CollectionUtils)
  .constant('DOMService', DOMService)
  .constant('Segmentation', Segmentation)
  .constant('ApolloService', ApolloService)
  .constant('UserFilterComponentService', UserFilterComponentService).name;
