import template from './template.html';
import controller from './controller';

export default {
  require: {
    PluginController: '^^plugin',
    CardController: '^^pluginCard',
  },
  template: () => template,
  controller,
  controllerAs: 'vm',
  transclude: true,
  bindings: {
    item: '=',
    index: '=',
  },
};
