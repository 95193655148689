import i18next from 'i18next';
import {
  clone as cloneDeep,
  filter,
  isNil,
  compose,
  transduce,
  flip,
  append,
  propEq,
  map,
  path,
  last,
} from 'ramda';
import gql from 'graphql-tag';
import {
  connectPage,
  ConnectPageField,
  ConnectPageOrigin,
  getConnectPageUrlParams,
} from '@components/ConnectPageDialog';
import { log } from 'cf-common/src/logger';
import { getBotFeatures } from '@utils/Data/Bot';
import { getBillingPageUrl, getPlansPageUrl } from '@utils/Routing';
import { canEdit, canView } from '../../common/services/RoleService';
import { sendEvent } from '../../utils/Analytics';
import { SKIP_REQUEST_ERROR } from './service';
import { renderDropdownIcon } from './DropdownIcon';
import { extractGQLErrorData } from '../../utils/GQL/utils';
import { toaster } from '../../services/MessageService';
import { ServiceMessageType } from '../../modern-ui/ServiceMessage2';
import { normalizeSpacesInNamesOnFilter } from '../../common/services/Segmentation';
import { removeDialogsFromGqlCache } from '../../utils/Data/LiveChat/Dialogs/removeDialogsFromGqlCache';
import { updateDialogsCountInGqlCache } from '../../utils/Data/LiveChat/Dialogs/updateDalogsCountInGqlCache';
import { Platform } from '../../../@types/globalTypes';
import {
  refetchAttributesQueryObservables,
  refetchCommonAttributesQueryObservables,
  refetchCommonAttributesQueryObservablesImmediately,
} from '../../utils/AttributesUtils/AttributesUtils';

const numberFormater = (n) =>
  n.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1 ');

/**
 * @description: responsible for People Tab controller
 */
export default class UsersController {
  /**
   *
   * @param {*} $scope
   * @param {*} $rootScope
   * @param {*} $timeout
   * @param {*} UserService
   * @param {*} PeopleService
   * @param {*} BotService
   * @param {*} StoreService
   * @param {*} UserFilterService
   * @param {*} SegmentService
   * @param {*} MonetizationService
   * @param {*} $log
   * @param {*} Segmentation
   * @param {*} OMNIBOX_BOT_FRAGMENT_SEGMENTS_AND_VAR_SUGGEST_QUERY
   * @param {*} OMNIBOX_BOT_FRAGMENT_VARS_VALUE_SUGGEST_QUERY
   * @param OMNIBOX_BOT_FRAGMENT_BLOCKS_SUGGEST_QUERY
   * @param {*} UserFilterComponentService
   * @param {*} ApolloService
   * @param {*} SettingsFactory
   * @param StatisticService
   */
  constructor(
    $scope,
    $rootScope,
    $timeout,
    UserService,
    PeopleService,
    BotService,
    StoreService,
    UserFilterService,
    SegmentService,
    MonetizationService,
    $log,
    Segmentation,
    OMNIBOX_BOT_FRAGMENT_SEGMENTS_AND_VAR_SUGGEST_QUERY,
    OMNIBOX_BOT_FRAGMENT_VARS_VALUE_SUGGEST_QUERY,
    OMNIBOX_BOT_FRAGMENT_BLOCKS_SUGGEST_QUERY,
    UserFilterComponentService,
    ApolloService,
    SettingsFactory,
    StatisticService,
  ) {
    'ngInject';

    this.log = $log.log.bind($log, 'UsersController');
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.UserService = UserService;
    this.PeopleService = PeopleService;
    this.BotService = BotService;
    this.StoreService = StoreService;
    this.SegmentService = SegmentService;
    this.Segmentation = Segmentation;
    this.MonetizationService = MonetizationService;
    this.ApolloService = ApolloService;
    this.segmentsAndVarSuggestQuery =
      OMNIBOX_BOT_FRAGMENT_SEGMENTS_AND_VAR_SUGGEST_QUERY;
    this.blocksSuggestQuery = OMNIBOX_BOT_FRAGMENT_BLOCKS_SUGGEST_QUERY;
    this.UserFilterComponentService = UserFilterComponentService;
    this.variableQueryObservalbe = ApolloService.watchQuery({
      query: OMNIBOX_BOT_FRAGMENT_VARS_VALUE_SUGGEST_QUERY,
    });
    // to save results for segmentsAndVarSuggestQuery
    this.segmentsAndVarSuggestQueryData = undefined;
    this.blocksQueryData = undefined;
    this.varSuggestQueryData = undefined;
    this.varValueSuggestQuerySubscription = undefined;
    this.RoleService = { canEdit, canView };
    this.StatisticService = StatisticService;

    this.generateName = SegmentService.generateNextNameForSegments;
    this.generateNameDuringCloning = SegmentService.generateNameDuringCloning;

    this.UserFilterService = UserFilterService;
    this.createFilter = Segmentation.empty;
    this.removeParametersWithEmptyName =
      Segmentation.removeParametersWithEmptyName;
    this.isFilterEmpty = Segmentation.isEmpty.bind(Segmentation);

    this.botId = $rootScope.stateParams.botId;
    this.dialogsPricingEnabled = false;

    // Its for holding observable for variable suggest we will unsubscribe on unmount
    // and change variables to handle update suggest list as user type;
    this.varValueSuggestObservable = null;

    // You can set {title, subtitle} object to show alert; used for showing alert after segment validation;
    this.alertErrorMessage = null;
    this._gotoSettingsAndScrollToPro =
      SettingsFactory.gotoSettingsAndScrollToPro;

    /**
     *
     * @private
     * @description private filter to abstract filter prop to rest fo the class.
     * There is a getter for this.filter which return this.currentlySelectedSegment or this._fitler;
     *
     * basically you should refactor this class to get rid of this.filter references in load user logic;
     *
     */
    this._filter = this.isFilterEmpty(
      this.$rootScope.stateLocation.state.filter,
    )
      ? this.createFilter()
      : this.$rootScope.stateLocation.state.filter;

    // ** segments **//
    /**
     * we will watch query and save subscription to be abel to unsubscribe;
     * @type {Obervable}
     */
    this.segmentsQuerySubscription = null;
    this.segments = [];
    this.currentlySelectedSegmentIndex = null;
    this._loadSegments();

    this.className = 'users-tab';
    this.loading = true;

    this.userView = {
      countString: '',
      totalCount: 0,
    };

    this.sequencesList = [];
    this.sequencesNameList = [];

    this.checkboxes = [];
    this.checked = [];

    this.checkedUsers = [];
    this.segmentsListWithOldAndNewNames = null;

    this.BotService.cachedList().then((bots) => {
      this.bot = bots.find(
        (item) => item.id === this.$rootScope.stateParams.botId,
      );

      const isProOrTrial = this.isBotProOrTrial();
      if (
        this.$rootScope.stateLocation.state.openProPopUpForNonPro &&
        !isProOrTrial
      ) {
        this.gotoUpgradePage();
      }
      // reset filter for not pro
      if (!isProOrTrial && !this.isFilterEmpty(this.filter)) {
        this.filter = this.createFilter();
        this.getUsers(this.sortOpt);
      }
      this.preloadSequences();

      const isEnableWhatsapp = this.bot.allowedPlatforms.includes(
        Platform.whatsapp,
      );
      this.isSyncPanelVisible = !isEnableWhatsapp;
      this.isWaUserImportVisible =
        !!isEnableWhatsapp && this.RoleService.canEdit('people');
      this.isSequenceButtonVisible =
        this.$rootScope.isAutomateEnabled &&
        this.RoleService.canEdit('people') &&
        !isEnableWhatsapp;

      if (isEnableWhatsapp) {
        this.includeOnlyParameters = ['attribute', 'segment', 'block', 'tag'];
      }
    });
    this.subscribeOnSegments();
    this.subscribeOnBlocks();
    this.renderDropdownIcon = renderDropdownIcon;

    this._showConnectPageModal();

    this.isSequenceButtonVisible = false;
    this.isSyncPanelVisible = false;
    this.isWaUserImportVisible = false;
    this.includeOnlyParameters = undefined;
  }

  preloadSequences() {
    if (this.filter && this.filter.parameters) {
      for (let i = 0; i < this.filter.parameters.length; i++) {
        const parameter = this.filter.parameters[i];
        if (
          parameter &&
          parameter.type ===
            this.Segmentation.SEGMENTATION_PARAMETERS_CODE.sequence
        ) {
          this.subscribeOnSequences(this.filter, i);
        }
      }
    }
  }

  /**
   * @description if segments selected you are get filter for this segement; if no segments are showing you display general filter which can be saved as segemnt;
   * @return {Segmentation} -
   */
  get currentlySelectedSegment() {
    const segments = this.segments || [];
    return segments[this.currentlySelectedSegmentIndex];
  }

  /**
   * @description get Segmentation for _filter or selected Segment
   * @return {Segmentation| null} -
   */
  get filter() {
    return this.currentlySelectedSegment
      ? this.currentlySelectedSegment.segmentation
      : this._filter;
  }

  // noinspection JSAnnotator
  /**
   * @description: setter for filter - depending on where segment is selected it sets segment or filter;
   * @param {Segmentation} filter -
   */
  set filter(filter) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.currentlySelectedSegment
      ? (this.currentlySelectedSegment.segmentation = filter)
      : (this._filter = filter);
  }

  subscribeOnSegments() {
    const { segmentsAndVarSuggestQuery, ApolloService, botId } = this;
    ApolloService.watchQuery({
      query: segmentsAndVarSuggestQuery,
      variables: {
        botId,
      },
      errorPolicy: 'all',
    }).subscribe(({ data }) => {
      this.segmentsAndVarSuggestQueryData = data;
    });
  }

  subscribeOnBlocks() {
    const { blocksSuggestQuery, ApolloService, botId } = this;
    ApolloService.watchQuery({
      query: blocksSuggestQuery,
      variables: {
        botId,
      },
      errorPolicy: 'all',
    }).subscribe(({ data }) => {
      this.blocksQueryData = data;
    });
  }

  subscribeOnSequences(filter, parameterIndex = 0) {
    if (!filter) {
      return;
    }

    const {
      UserFilterComponentService,
      botId,
      varValueSuggestQuerySubscription,
      variableQueryObservalbe,
    } = this;
    const variables = UserFilterComponentService.getVarSuggestVariablesForGQL(
      { filter, parameterIndex },
      botId,
    );
    if (!variables.variable) {
      return;
    }
    if (varValueSuggestQuerySubscription) {
      variableQueryObservalbe.fetchMore({
        updateQuery: UserFilterComponentService.variableSuggestUpdate,
        variables,
      });
    } else {
      variableQueryObservalbe.setVariables(variables);
      this.varValueSuggestQuerySubscription = variableQueryObservalbe.subscribe(
        ({ data }) => {
          this.varSuggestQueryData = data;
        },
      );
    }
  }

  /**
   * @description returns number of users currently selected; used in delete button;
   *              @fixme code duplication with string generating method "1 of 2 users selected";
   * @return {number} -
   */
  selectedUsersCount() {
    return this.noUsersInTableAreSelected()
      ? this.usersCurrentCount
      : this.checkedCount;
  }

  /**
   * @description: show if any users are selected in table;
   * @return {boolean} - true if no users are selected;
   */
  noUsersInTableAreSelected() {
    return !this.checkedCount;
  }

  /**
   * generate plural string for delete user popup;
   * @return {string} -
   */
  getUserCountString() {
    return i18next.t('components.users.peopleCount', {
      count: this.selectedUsersCount(),
    });
  }

  /**
   * @description: setup watch collection to track checkbox count;
   * @return {void} -
   */
  $onInit() {
    getBotFeatures(this.botId).then(({ dialogs_pricing_enabled }) => {
      this.dialogsPricingEnabled = dialogs_pricing_enabled;
    });

    this.$scope.$watchCollection('vm.checkboxes', () => {
      this.checkedCount = this.checkboxes
        .map((item, i) => (item ? this.usersList.users[i] : null))
        .filter((item) => !!item).length;
      this.titleStringCompiller();
    });

    const unwatch = this.$scope.$watch('vm.segments', () => {
      const selectedSegmentId = this.$rootScope.stateParams.segmentId;
      if (!selectedSegmentId) {
        unwatch();
      }

      if (this.segments.length) {
        const index = this.segments.findIndex(
          (segment) => segment.id === selectedSegmentId,
        );
        if (index !== -1) {
          this.onSegmentSelected(this.segments[index], index);
          this.$rootScope.stateHistory.push(
            `/bot/${this.$rootScope.stateParams.botId}/users/${selectedSegmentId}`,
          );
        } else {
          this.$rootScope.stateHistory.push(
            `/bot/${this.$rootScope.stateParams.botId}/users`,
          );
        }

        unwatch();
      }
    });
  }

  /**
   * @description: clean up;
   * @return {void} -
   */
  $onDestroy() {
    if (this.segmentsQuerySubscription) {
      this.segmentsSubscription.unsubscribe();
    }
  }

  /**
   * @description:  user delete button click handler;
   * @return {void} -
   */
  onUserDelete = () => {
    // set filter to delete
    // modal will be shown see template;
    this.deleteUserFilter = this.mergeFilterAndCheckedUsers();
  };

  /**
   * @description: delete users according to filter in this.deleteUserFilter;
   * @return {void} -
   */
  async deleteUsers() {
    try {
      const { ApolloService, botId } = this;
      const filterToDelete = this.deleteUserFilter;
      this.deleteUserFilter = null;
      const ids = filterToDelete.parameters.flatMap(({ values }) => values);
      removeDialogsFromGqlCache(ids, botId);
      updateDialogsCountInGqlCache(-ids.length, botId);
      // set loading user delete takes like 1 sec to sync with elastic for simple queres;
      this.loading = true;
      this.usersList = {
        users: [],
      };

      sendEvent({
        category: 'people tab',
        action: 'delete',
        label: 'user',
        propertyBag: {
          'number of users': this.selectedUsersCount(),
        },
      });

      await ApolloService.mutate({
        mutation: gql`
          mutation DELETE_USER(
            $botId: String!
            $segmentation: SegmentationInput!
          ) {
            deleteUsers(botId: $botId, segmentation: $segmentation)
          }
        `,
        variables: {
          segmentation: filterToDelete,
          botId,
        },
      });
      // set loading user delete takes like 1 sec to sync with elastic for simple queres;
      await this.$timeout(1000);
      this.getUsers(this.sortOpt, undefined, true);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @description: -
   * @return {boolean} -
   */
  isFilterEmptyAndSegmentsNotSelected() {
    return (
      isNil(this.currentlySelectedSegmentIndex) &&
      this.isFilterEmpty(this.filter)
    );
  }

  /**
   * @description: on omnibox clear button handler
   * @return {void} -
   */
  onClearButton() {
    this.currentlySelectedSegmentIndex = null;
    this.filter = this.createFilter();
    this.getUsers(this.sortOpt, null, true);
  }

  /**
   * @description display clear button if there is selected segment or filter is not empty
   * @return {void} -
   */
  isClearButtonDisabled() {
    return !this.currentlySelectedSegment && this.isFilterEmpty(this.filter);
  }

  /**
   * @description duplicate segment;
   * @param {Segment} segment -
   */
  async onSegmentDuplicate(segment) {
    // FIXME: error handler;
    // TODO: code duplication easy to fix if createNewSegment was curry function;

    const name = this.generateNameDuringCloning(segment, this.allSegments);
    const seg = cloneDeep(segment);
    this.currentlySelectedSegmentIndex = this.segments.length;
    if (this.isFilterEmpty(seg.segmentation)) {
      seg.segmentation.parameters = [];
    }

    let segmentId;
    try {
      const res = await this.SegmentService.createNewSegment(
        this.$rootScope.stateParams.botId,
        {
          name,
          segmentation: seg.segmentation,
        },
      );
      segmentId = path(['data', 'createSegment', 'id'], res);
    } catch (e) {
      segmentId = null;
      this._handleSegmentError(e);
    }

    if (segmentId) {
      this.$rootScope.stateHistory.push(
        `/bot/${this.$rootScope.stateParams.botId}/users/${segmentId}`,
      );
    }
  }

  /**
   * @description update segment
   * @param {HTMLClickSegment} e -
   * @param {Segment} segment - new segment
   */
  async onSegmentUpdate(e, segment) {
    try {
      this.segmentsListWithOldAndNewNames = [...this.allSegments, segment];
      await this.SegmentService.updateSegment(this.botId, segment);
    } catch (e) {
      this._handleSegmentError(e);
    } finally {
      this.segmentsListWithOldAndNewNames = null;
    }
  }

  /**
   * @description: save segment handler;
   * @return {void} -
   */
  onSaveToSegment = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.currentlySelectedSegment
      ? this.onSegmentDuplicate(this.currentlySelectedSegment)
      : this.onSegmentAdd(this.filter);
  };

  /**
   * @description - handle segment save error and shor alert if any;
   * @param {{graphQLErrors}} e -
   * @private
   * @return {void} -
   */
  _handleSegmentError(e) {
    const { message } = extractGQLErrorData(e);
    const errorMessage = last(
      (typeof message === 'string' ? message : '').split('Bad Data:'),
    );
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: `Could not save segment: ${errorMessage}`,
      },
    });
    console.error(e);
  }

  /**
   * @description - add segments
   * @param {Segmentation} segmentation -
   */
  async onSegmentAdd(segmentation) {
    sendEvent({
      category: 'people tab',
      action: 'add',
      label: 'segment',
      propertyBag: {
        'people total': this.usersTotalCount,
      },
    });

    const name = this.generateName(
      this.segmentsListWithOldAndNewNames || this.allSegments,
    );
    segmentation = segmentation || this.createFilter();
    // we create with empty filter;
    segmentation = this.removeParametersWithEmptyName(segmentation);
    this.currentlySelectedSegmentIndex = this.segments.length;

    let segmentId;
    try {
      const res = await this.SegmentService.createNewSegment(
        this.$rootScope.stateParams.botId,
        {
          name,
          segmentation,
        },
      );
      const segmentId = path(['data', 'createSegment', 'id'], res);
    } catch (e) {
      segmentId = null;
      this._handleSegmentError(e);
    }
    if (segmentId) {
      this.$rootScope.stateHistory.push(
        `/bot/${this.$rootScope.stateParams.botId}/users/${segmentId}`,
      );
    }
  }

  /**
   * @description: -
   * @param {HTMLClickEvent} e -
   * @param {Segmentation} segment -
   * @param {number} index segment index to delete
   */
  onSegmentDelete(e, segment, index) {
    sendEvent({
      category: 'people tab',
      action: 'delete',
      label: 'segment',
      propertyBag: {
        'people total': this.usersTotalCount,
      },
    });

    this.currentlySelectedSegmentIndex = null;
    this.SegmentService.deleteSegment(
      this.$rootScope.stateParams.botId,
      segment,
      index,
    );
    this.$rootScope.stateHistory.push(
      `/bot/${this.$rootScope.stateParams.botId}/users`,
    );
  }

  /**
   * @description -
   * @private
   * @return {void} -
   */
  _loadSegments() {
    this.segmentsSubscription = this.SegmentService.subscribeForSegments(
      this.botId,
      (segments) => {
        const filterRemovedSegments = filter(propEq('removed', false));
        const fixEmptySegments = map((segment) => {
          if (segment.segmentation.parameters.length === 0) {
            return {
              ...segment,
              segmentation: this.Segmentation.empty(),
            };
          }

          return segment;
        });

        const transducer = compose(
          cloneDeep,
          filterRemovedSegments,
          fixEmptySegments,
        );
        this.segments = transduce(transducer, flip(append), [], segments || []);
        this.allSegments = cloneDeep(segments);
      },
    );
  }

  /**
   * @description: -
   * @param {object} segment
   * @param {number} segmentIndex - select segment at index
   * return {void} -
   */
  onSegmentSelected(segment, segmentIndex) {
    sendEvent({
      category: 'people tab',
      action: 'click',
      label: 'segment',
      propertyBag: {
        'people total': this.usersTotalCount,
      },
    });
    this.currentlySelectedSegmentIndex = segmentIndex;
    this.getUsers(this.sortOpt);
    this.preloadSequences();
    this.$rootScope.stateHistory.push(
      `/bot/${this.$rootScope.stateParams.botId}/users/${segment.id}`,
    );
  }

  onFilterItemAdd() {
    sendEvent({
      category: 'people tab',
      action: 'add',
      label: 'user attribute',
      propertyBag: {
        segmentId:
          this.currentlySelectedSegment && this.currentlySelectedSegment.id,
      },
    });
  }

  onFilterItemRemove() {
    sendEvent({
      category: 'people tab',
      action: 'delete',
      label: 'user attribute',
      propertyBag: {
        segmentId:
          this.currentlySelectedSegment && this.currentlySelectedSegment.id,
      },
    });
  }

  onFilterChange(changeEvent) {
    let filter;
    let parameterIndex = 0;

    // eslint-disable-next-line prefer-const,prefer-destructuring
    filter = changeEvent.value;
    // eslint-disable-next-line prefer-destructuring
    parameterIndex = changeEvent.parameterIndex;

    const { Segmentation } = this;
    const isFilterChange = Segmentation.isEqual(filter, this.filter);
    this.filter = filter;
    if (isFilterChange) {
      return;
    }
    this.subscribeOnSequences(filter, parameterIndex);

    if (this.currentlySelectedSegment) {
      const newSegment = {
        ...this.currentlySelectedSegment,
        segmentation: normalizeSpacesInNamesOnFilter(filter),
      };
      this.onSegmentUpdate(null, newSegment);
    }

    this.getUsers(this.sortOpt);
  }

  /**
   * @description load users for this.filter
   * @param {Object} sortOpt - people sort state (ex. {col: 'column name', desc: true|false})
   * @param {number} offset - current user request offset
   * @param {boolean} force - reload filter event if filter not changed
   */
  async getUsers(sortOpt, offset, force) {
    if (!this.filter.parameters || !sortOpt) {
      return;
    }

    const req = JSON.stringify({
      offset,
      sortOpt,
      filter: this.filter,
    });

    if (this.lastReq === req && !force) {
      return;
    }

    try {
      this.loading = true;

      this.lastReq = req;

      this.currentOffset = offset || 0;

      const descOptions = sortOpt && sortOpt.col ? sortOpt.desc : null;
      const sort = sortOpt && sortOpt.col ? sortOpt.col.sort : null;
      const filterToQuery = normalizeSpacesInNamesOnFilter(
        this.Segmentation.removeEmptyFromValues(this.filter),
      );
      const lUsersList = await this.PeopleService.list(
        offset,
        descOptions,
        sort,
        filterToQuery,
        this.pageId,
      );

      if (!offset) {
        this.usersList = {
          users: [],
        };
      }

      this.lastResultCount = lUsersList.users.length;
      this.usersTotalCount = lUsersList.total_count;
      this.usersCurrentCount = lUsersList.count;
      this.usersList.users.push(...lUsersList.users);

      this.titleStringCompiller();

      this.$scope.$broadcast('$tableDataUpdated', this.currentOffset);
    } catch (error) {
      if (error.message === SKIP_REQUEST_ERROR) {
        // eslint-disable-next-line no-console
        log.verbose({ msg: 'Skip users request' });
      } else {
        log.error({
          error,
          msg: 'Users loading error in PT',
          data: { label: 'automate_fetch_users' },
        });
      }
    } finally {
      if (!this.PeopleService.usersRequestQueueOperator.inProgress) {
        this.loading = false;
      }
      this.$scope.$evalAsync();
    }
  }

  /**
   * @description - load more users;
   */
  loadMore() {
    this.currentOffset += 50;
    this.getUsers(this.sortOpt, this.currentOffset);
  }

  /**
   * @description set counter string like you "2 of 40 users selected"
   */
  titleStringCompiller() {
    this.userView = {};
    if (window.angular.isNumber(this.usersTotalCount)) {
      this.userView.countString = `${numberFormater(
        this.usersTotalCount,
      )} ${window.i18next.t('components.users.peopleTotal')}`;
      this.userView.totalCount = this.usersTotalCount;
      this.userView.countSelectedString = this.checkedCount
        ? this.checkedCount
        : this.usersCurrentCount !== this.usersTotalCount
        ? numberFormater(this.usersCurrentCount)
        : numberFormater(this.usersTotalCount);
      this.userView.countSelectedString += ` ${window.i18next.t(
        'components.users.outOf',
      )} `;
    }
  }

  /**
   * @description despire name it choose selected by checkbox users over filter. for selected users creates Segmentation object;
   * @return {Segmentation} -
   */
  mergeFilterAndCheckedUsers() {
    let filter;
    if (!this.checkedCount) {
      // eslint-disable-next-line prefer-destructuring
      filter = this.filter;
    } else {
      filter = {
        parameters: [
          {
            name: 'messenger user id',
            values: this.getCheckedUsers()
              .map((user) => user.messenger_user_id)
              .filter(Boolean),
            operation: 'is',
          },
          {
            name: 'instagram user id',
            values: this.getCheckedUsers()
              .map((user) => user.instagram_user_id)
              .filter(Boolean),
            operation: 'is',
          },
          {
            name: 'whatsapp user id',
            values: this.getCheckedUsers()
              .map((user) => user.whatsapp_user_id)
              .filter(Boolean),
            operation: 'is',
          },
        ],
        operation: 'or',
      };
    }
    return filter;
  }

  getCurrentSortAndFilterParams = () => {
    const { sortOpt } = this;
    const hasSort = sortOpt && sortOpt.col;
    const filter = this.mergeFilterAndCheckedUsers();

    return {
      desc: hasSort ? Boolean(sortOpt.desc) : null,
      sortBy: hasSort ? sortOpt.col.sort || '' : '',
      filter: filter || {},
    };
  };

  /**
   * @description it change sorting opitions and reload users;
   * @param {Object} sortOpt - people sort state (ex. {col: 'column name', desc: true|false})
   */
  sortUpdate(sortOpt) {
    this.sortOpt = sortOpt;
    this.getUsers(sortOpt);
  }

  /**
   * @description:gets -
   * @return {Array<User>} - list of users;
   */
  getCheckedUsers() {
    return this.checkedCount > 0
      ? this.usersList.users.filter(
          (user, i) => !!this.checkboxes[i] && user.user_id,
        )
      : this.usersList.users;
  }

  /**
   * @description load sequences list for omnibox suggest
   */
  getSequencesList() {
    this.sequencesList.length = 0;
    this.sequencesNameList.length = 0;

    this.BotService.getSuggestValuesAll({
      name: 'triggered sequences',
    }).then((res) => {
      res.forEach((sequence) => {
        if (!this.UserFilterService.isNotSet(sequence.code)) {
          this.sequencesList.push(sequence);
          this.sequencesNameList.push(sequence.name);
        }
      });
    });
  }

  /**
   * @description @todo
   * @param {*} sequenceName -
   * @param {*} mode -
   */
  onSequenceSelected(sequenceName, mode) {
    const filter = this.mergeFilterAndCheckedUsers();
    const sequence = this.sequencesList.find(
      (seq) => seq.name === sequenceName,
    );
    let serviceMethod;

    if (sequence) {
      // eslint-disable-next-line default-case
      switch (mode) {
        case 'subscribe':
          serviceMethod = this.BotService.subscribeUsersToSequence;
          break;
        case 'unsubscribe':
          serviceMethod = this.BotService.unsubscribeUsersFromSequence;
          break;
        default:
          throw new Error('Unknown mode for selected sequence');
      }

      sendEvent({
        category: 'people tab',
        action: mode,
        label: 'sequence',
        // TODO: check properties correctness
        propertyBag: {
          segmentId:
            this.currentlySelectedSegment && this.currentlySelectedSegment.id,
          subscribed: this.selectedUsersCount(),
          'number of people': this.usersTotalCount,
        },
      });

      serviceMethod([sequence.code], filter).then(() => {
        this.getUsers(this.sortOpt, null, true);
      });
    }
  }

  isBotProOrTrial() {
    return (
      // return !this.bot to avoid blinking of upgrade-to-pro-service-message for pro
      !this.bot ||
      this.MonetizationService.isBotProOrTrial(this.bot) ||
      this.isBotPro()
    );
  }

  isBotPro() {
    return (
      this.MonetizationService.isBotPro(this.bot) ||
      Boolean(this.bot.workspace_id)
    );
  }

  isUserDeletionDisabled() {
    return (
      !this.isBotPro() ||
      this.usersCurrentCount === 0 ||
      (this.isFilterEmptyAndSegmentsNotSelected() &&
        this.noUsersInTableAreSelected())
    );
  }

  /**
   * @private
   * @description Click event handler on Upgrade to PRO button, show PRO popup
   * @return {void}
   */
  _onUpgradeButtonClick = () => {
    sendEvent({
      category: 'people tab',
      action: 'click',
      label: 'upgrade to pro',
    });
    this.gotoUpgradePage();
  };

  /**
   * @private
   * @description Button "update to delete" click handler
   */
  _onUpdateToDeleteButtonClick = () => {
    sendEvent({
      category: 'billing',
      action: 'upgrade to delete users',
      label: 'plan limit reached',
      propertyBag: {
        dialogsPricingEnabled: this.dialogsPricingEnabled,
        botId: this.botId,
      },
    });
    this.gotoUpgradePage();
  };

  gotoUpgradePage = () => {
    this.$rootScope.stateHistory.push(
      getPlansPageUrl(
        this.$rootScope.stateParams.botId,
        this.dialogsPricingEnabled,
        'people-tab',
      ),
    );
  };

  /**
   * @private
   * @description Go to Settings tab and scroll to PRO panel
   * @return {void}
   */
  _gotoSettingsAndScrollToPro() {
    const hitUrl = `pro_events/show_pro_popup_in_people_tab/click`;
    sendEvent(hitUrl);
    this.$rootScope.stateHistory.push(
      getBillingPageUrl(
        this.$rootScope.stateParams.botId,
        this.dialogsPricingEnabled,
      ),
    );
  }

  /**
   * @description show users placeholder if users count = 0 and filter don't configured
   * @return {boolean} -
   * @private
   */
  _isShowUsersPlaceholder() {
    return (
      (this.isFilterEmpty(this.filter) &&
        (!this.usersList ||
          !this.usersList.users ||
          this.usersList.users.length === 0)) ||
      !(this.bot && this.bot.status && this.bot.status.page)
    );
  }

  _showConnectPageModal() {
    const urlParams = getConnectPageUrlParams();
    const botId = urlParams[ConnectPageField.botId];
    const origin = urlParams[ConnectPageField.origin];
    if (origin === ConnectPageOrigin.users && botId) {
      connectPage({
        botId,
        urlParams,
        onPageConnected: () => {
          window.location.reload();
        },
      });
    }
  }

  goConnect = () => {
    connectPage({
      botId: this.bot.id,
      urlParams: {
        [ConnectPageField.botId]: this.botId,
        [ConnectPageField.origin]: ConnectPageOrigin.users,
      },
      onPageConnected: () => {
        window.location.reload();
      },
    });
  };

  _onDataPreloadRequest({ parameterIndex }) {
    this.subscribeOnSequences(this.filter, parameterIndex);
    this.subscribeOnBlocks(this.filter, parameterIndex);
  }

  onImportUserDone = async () => {
    await refetchCommonAttributesQueryObservablesImmediately(this.botId);
    this.getUsers(this.sortOpt, null, true);
  };
}
