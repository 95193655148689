import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { getPluginName } from '../../pluginNames';

export default class CardLocationController extends CardPluginBaseController {
  $onInit() {
    this.pluginName = getPluginName('location_quick_reply_plugin');
    if (!this.item.id) {
      this.save();
    }
    if (this.item.is_valid === false) {
      this.data = this.item.validation_details || {};
      this.data.card_id = this.item.id;
      this.$rootScope.$broadcast('$pluginSaveResultError', { data: this.data });
    } else {
      this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
    }
  }
}
