import { Translation } from '@translations';
import React from 'react';
import { ReactComponent as AdminRoleIcon } from './icons/admin.svg';
import { ReactComponent as EditorRoleIcon } from './icons/editor.svg';
import { ReactComponent as MarketerRoleIcon } from './icons/marketer.svg';
import { ReactComponent as OperatorRoleIcon } from './icons/operator.svg';
import { ReactComponent as ViewerRoleIcon } from './icons/viewer.svg';
// import { ReactComponent as CustomRoleIcon } from './icons/custom.svg';
import { ReactComponent as WhiteLabelIcon } from './icons/white-label.svg';

export interface IAdminRole {
  id: string;
  title: string;
  titleI18nKey: Translation;
  noteI18nKey: Translation;
  enabled: boolean;
  icon: React.SFC<any>;
  shouldShowDivider?: boolean;
  isPredefined?: boolean;
}

export enum RoleIds {
  admin = 'ADMIN',
  editor = 'EDITOR',
  marketer = 'MARKETER',
  operator = 'OPERATOR',
  viewer = 'VIEWER',
  custom = 'CUSTOM',
  whiteLabel = 'WL',
}

export const ADMINS_ROLES: IAdminRole[] = [
  {
    id: RoleIds.admin,
    title: 'Admin',
    titleI18nKey: 'components.settings.admins.RolesList.admin',
    noteI18nKey: 'components.settings.admins.RolesList.adminNote',
    enabled: true,
    icon: AdminRoleIcon,
    isPredefined: true,
  },
  {
    id: RoleIds.editor,
    title: 'Editor',
    titleI18nKey: 'components.settings.admins.RolesList.editor',
    noteI18nKey: 'components.settings.admins.RolesList.editorNote',
    enabled: true,
    icon: EditorRoleIcon,
    isPredefined: true,
  },
  {
    id: RoleIds.marketer,
    title: 'Marketer',
    titleI18nKey: 'components.settings.admins.RolesList.marketer',
    noteI18nKey: 'components.settings.admins.RolesList.marketerNote',
    enabled: true,
    icon: MarketerRoleIcon,
    isPredefined: true,
  },
  {
    id: RoleIds.operator,
    title: 'Live Chat Operator',
    titleI18nKey: 'components.settings.admins.RolesList.operator',
    noteI18nKey: 'components.settings.admins.RolesList.operatorNote',
    enabled: true,
    icon: OperatorRoleIcon,
    isPredefined: true,
  },
  {
    id: RoleIds.viewer,
    title: 'Viewer',
    titleI18nKey: 'components.settings.admins.RolesList.viewer',
    noteI18nKey: 'components.settings.admins.RolesList.viewerNote',
    enabled: true,
    icon: ViewerRoleIcon,
    isPredefined: true,
  },
  {
    id: RoleIds.whiteLabel,
    title: 'White-label user',
    titleI18nKey: 'components.settings.admins.RolesList.whiteLabel',
    noteI18nKey: 'components.settings.admins.RolesList.whiteLabelNote',
    enabled: true,
    icon: WhiteLabelIcon,
    shouldShowDivider: true,
  },
];

export const ADMINS_ROLES_MAP = new Map<string, IAdminRole>(
  ADMINS_ROLES.map((role) => [role.id, role]),
);
