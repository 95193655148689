/**
 * Created by Zaur Dedegkaev on 29.05.17.
 */
import { module } from 'angular';

/**
 * @ngdoc directive
 * @name uniqueModelValue
 * @restrict A
 * @params Object {items: Array, index: Number}
 */
export default module('app.common.uniqueModelValue', []).directive(
  'uniqueModelValue',
  () => {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: (scope, elem, attr, ngModel) => {
        if (!attr.uniqueModelValue) {
          return;
        }
        const params = scope.$eval(attr.uniqueModelValue);
        const comparisonList = params.items.slice();
        const $index = params.index;

        comparisonList.splice($index, 1);

        ngModel.$parsers.unshift((value) => {
          const valid = !comparisonList.some(
            (listItem) => listItem.title === value.trim(),
          );
          ngModel.$setValidity('unique', valid);
          return valid ? value.trim() : ngModel.$modelValue.trim();
        });
      },
    };
  },
).name;
