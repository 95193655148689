import template from './users-table-attr-popup.html';
import controller from './controller';

export default {
  template: () => template,
  controllerAs: 'vm',
  controller,
  restrict: 'E',
  bindings: {
    attributes: '=',
  },
};
