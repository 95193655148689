import React, { forwardRef } from 'react';
import { MenuCombobox } from '@ui/SimpleCombobox';
import { MenuItem } from '@ui/Menu';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useLocaleSetter } from '@utils/Data/Admin/Locale/useLocaleSetter';
import { ButtonUnstyled } from '@ui/Button';

import { Icon } from '@ui/Icon';
import cn from 'classnames';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import css from './LocaleMenu.css';
import { Emoji } from '@ui/Emoji';
import { Emojis } from '@ui/Emoji/Emoji.types';

interface LocaleMenuProps {
  className?: string;
}

const LocaleButton = forwardRef<
  HTMLButtonElement,
  {
    flag: Emojis;
    title: string;
    isOpen: boolean;
    onClick: React.HTMLProps<HTMLButtonElement>['onClick'];
  }
>(({ flag, title, isOpen, ...props }, ref) => (
  <ButtonUnstyled {...props} ref={ref} className={css.titleContainer}>
    <Flex>
      <div className={css.flagWrapper}>
        <Emoji size={20} emoji={flag} />
      </div>
      <Spacer horizontalFactor={2} factor={0} />
      <Type weight="medium" color="accent1Normal">
        {title}
      </Type>
      <Spacer horizontalFactor={3} factor={0} />
      <Icon
        icon="arrow_right"
        size="20px"
        color="accent1Normal"
        className={cn(css.arrow, isOpen && css.rotate)}
      />
    </Flex>
  </ButtonUnstyled>
));

interface LocaleItem {
  id: string;
  flag: Emojis;
  title: string;
}

export const LocaleMenu: React.FC<LocaleMenuProps> = ({ className }) => {
  const { i18n } = useSafeTranslation();
  const setLocale = useLocaleSetter();

  const items: LocaleItem[] = [
    {
      id: 'en',
      flag: '🇬🇧',
      title: 'English',
    },
    {
      id: 'pt',
      flag: '🇧🇷',
      title: 'Português',
    },
    {
      id: 'es',
      flag: '🇪🇸',
      title: 'Español',
    },
  ];

  const selectedItem = items.find(({ id }) => i18n.language === id) || items[0];

  return (
    <MenuCombobox
      containerClassName={className}
      menuBoxClassName={css.menuBoxClassName}
      selectedItem={selectedItem}
      placement="bottom-end"
      onChange={(item) => setLocale(item.id)}
      items={items}
      renderInput={({ popperReference, downshift }) => (
        <LocaleButton
          {...downshift.getToggleButtonProps()}
          ref={popperReference.ref}
          {...selectedItem}
          isOpen={downshift.isOpen}
        />
      )}
    >
      {({
        downshift: { getItemProps, highlightedIndex },
        props: { item, index },
      }) => (
        <MenuItem
          title={item.title}
          active={index === highlightedIndex}
          key={item.id}
          className={cn(css.menuItem, index === highlightedIndex && css.active)}
          leftElement={
            <div className={css.flagWrapper}>
              <Emoji size={20} emoji={item.flag} />
            </div>
          }
          rightMenuElement={
            item === selectedItem ? (
              <Icon
                icon="check"
                size="20px"
                color="accent1Normal"
                className={css.checkIcon}
              />
            ) : null
          }
          {...getItemProps({ item })}
        />
      )}
    </MenuCombobox>
  );
};
