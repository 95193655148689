/* global angular */

import $ from 'jquery';

export default ($timeout, $rootScope) => {
  'ngInject';

  return (scope, element, attrs) => {
    let timeout;
    let oldval;
    let isCurrent = false;
    let cardId;
    let blockId;

    $rootScope.useCurrentFieldValidate = false;

    const status = angular.element('<div class="status" error=""></div>');
    const control = element;
    const input = control.find('input, textarea');
    const isRequired = control.hasClass('required');
    const parent = $(input).parents('.card-item');
    const isShowStatusIcon = attrs.smartSaveNoIcon !== 'true';
    const isCrossValidate = 'smartSaveCrossValidate' in attrs;

    if (isShowStatusIcon) {
      control.append(status);
    } else {
      input.css('paddingRight', 0); // prevent padding for status icon
    }

    const getItemId = () => {
      if (cardId) {
        return;
      }
      if (scope.$parent.vm.item) {
        cardId = scope.$parent.vm.item.id;
        blockId = scope.$parent.vm.item.blockId; // eslint-disable-line prefer-destructuring
      } else if (scope.$parent.$parent.vm.item) {
        cardId = scope.$parent.$parent.vm.item.id;
        blockId = scope.$parent.$parent.vm.item.blockId; // eslint-disable-line prefer-destructuring
      }
    };

    input.on('keyup input change', (event) => {
      $timeout.cancel(timeout);
      if (event.type === 'keyup' && event.keyCode === 13) {
        let blured = false;
        let found = false;
        getItemId();
        parent.find('*[smart-save]').each((i, item) => {
          if (found) {
            blured = true;
            angular
              .element(item)
              .find('input')
              .trigger('focus');
            return false;
          }
          if (angular.element(item).attr('smart-save') === attrs.smartSave) {
            found = true;
          }
          return undefined;
        });
        if (!blured) {
          input.triggerHandler('blur');
        }
      } else {
        timeout = $timeout(() => {
          $rootScope.fakeBlur = true;
          input.triggerHandler('blur');
        }, 2000);
      }
    });

    input.on('blur', (event) => {
      $timeout.cancel(timeout);
      // fixme @p1 remove code
      // eslint-disable-next-line no-constant-condition
      if (false && oldval === input.val()) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        return false;
      }
      oldval = input.val();
      if (!isRequired) {
        control.removeClass('error');
        control.removeClass('ok');
      } else {
        control.addClass('process');
        control.removeClass('error');
        control.removeClass('ok');
      }

      isCurrent = true;
      $rootScope.useCurrentFieldValidate = true;
      return undefined;
    });

    input.on('focus', () => {
      //  control.removeClass('process');
      control.removeClass('error');
      control.removeClass('ok');
    });
    // eslint-disable-next-line handle-callback-err
    scope.$on('$pluginSaveResultError', (err, apiError) => {
      getItemId();
      if (!cardId || cardId !== apiError.data.card_id) {
        return;
      }

      control.removeClass('process');

      let errorItem;

      if (apiError.data.fields) {
        errorItem = apiError.data.fields.find(
          (item) => item.field === attrs.smartSave,
        );
      }

      if (
        !errorItem &&
        (attrs.smartSave === 'api_key' || attrs.smartSave === 'api_cx')
      ) {
        errorItem = apiError.data.fields.find(
          (item) => item.field === 'api_token',
        );
      }

      if (errorItem) {
        if (
          errorItem.field === 'api_token' &&
          (attrs.smartSave === 'api_token' || attrs.smartSave === 'api_key') &&
          $rootScope.useCurrentFieldValidate &&
          !isCurrent
        ) {
          isCurrent = true;
        }

        if (
          $rootScope.useCurrentFieldValidate &&
          !isCurrent &&
          !isCrossValidate
        ) {
          return;
        }
        isCurrent = false;

        let errMes = errorItem.error.replace(`${errorItem.field} `, '');
        errMes = errMes.charAt(0).toUpperCase() + errMes.substr(1);
        if (errMes === 'May not be null') {
          errMes = 'Required field';
        }
        control.removeClass('ok');

        status.attr('error', errMes);
        control.addClass('error');

        $rootScope.$broadcast('$pluginHasErrorValidation', {
          id: cardId,
          blockId,
          isError: true,
          errorCode: errorItem.error,
        });
      } else if (isRequired && input.val() !== '') {
        control.removeClass('error');
        control.addClass('ok');
      }

      $rootScope.fakeBlur = false;
    });

    scope.$on('$pluginSaveResultOk', (e, ok) => {
      getItemId();
      if (!cardId || cardId !== ok.id) {
        return;
      }

      $rootScope.$broadcast('$pluginHasErrorValidation', {
        id: cardId,
        blockId,
        isError: false,
      });

      if ($rootScope.fakeBlur) {
        $rootScope.fakeBlur = false;
      }

      if (isRequired) {
        control.addClass('ok');
      }

      control.removeClass('error');
      control.removeClass('process');
    });

    scope.$on('$destroy', () => {
      input.off('keyup input change');
      input.off('blur');
      input.off('focus');
    });
  };
};
