import { sendEvent } from '../../../utils/Analytics';

export default class CardButtonsAddController {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  add($event) {
    $event.preventDefault();
    this.items.push({ title: '', new: true, block_id: [] });
    this.$scope.$emit('$buttonUpdate');
    sendEvent({
      category: 'plugin',
      action: 'button add',
      value: this.items.length,
      label: this.$scope.$parent.vm.plugin.plugin_id,
      propertyBag: {
        plugin: this.$scope.$parent.vm.plugin.plugin_id,
      },
    });
  }
}
