import debounce from 'lodash-es/debounce';

export default class SvpVariablesController {
  constructor(
    $scope,
    $timeout,
    $element,
    $rootScope,
    StoreService,
    PeopleService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$element = $element;
    this.$timeout = $timeout;
    this.StoreService = StoreService;
    this.PeopleService = PeopleService;

    this.notBlured = true;
    this.enter = false;

    $scope.$watch('$svpVariables.value', () => {
      if (this.value && this.input.innerText.trim() !== this.value.trim()) {
        this.localValue = this.value;
      }

      this.designInput();
    });

    $scope.$on('$showPopUp', () => {
      this.popUpOpen = null;
    });

    $scope.$on('$angularMouseUp', () => {
      this.hidePopup();
    });

    this.input = $element[0].querySelector('.cvp-input');
    this.popupScrollBox = $element[0].querySelector('.svp-popup');
  }

  $onInit() {
    this.wasBlured = this.isNew === 'false';

    this.designInput();
  }

  $onDestroy() {}

  elClick($event) {
    $event.stopPropagation();
    this.input.focus();
    this.placeCaretAtEnd(this.input);
  }

  onFocusInput() {
    if (this.updateVarsMap) {
      this.updateVarsMap();
    }
    this.$rootScope.$broadcast('$showPopUp');
    this.popUpOpen = true;
    this.popupAct = 0;
    this.input.classList.add('on-focus-now');
    this.hidePopup.cancel();
  }

  onBlurInput({ target }) {
    this.wasBlured = true;
    this.value = this.input.innerText.trim();
    this.input.innerText = this.value;
    if (this.save) {
      this.save();
    }
    this.input.classList.remove('on-focus-now');
    this.hidePopup();
    target.scrollLeft = 0;
  }

  filterShow() {
    const value = this.value.trim();
    let result = [];
    this.emptyPopUp = false;

    if (this.vars) {
      result = this.vars.filter(
        (attr) =>
          attr.type === this.PeopleService.getAttributeTypeCodes().custom &&
          value.toLowerCase() !== attr.name.toLowerCase() &&
          attr.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 &&
          (!this.items ||
            !this.items.find((item) => item.variable_name === attr.name)),
      );
      this.emptyPopUp = result.length > 0;
    }
    return result;
  }

  compileUserText(n) {
    return `${String(n).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')} ${
      Number(n) === 1 ? 'user' : 'users'
    }`;
  }

  onKeyDown(e) {
    const textVal = e.target.innerText.trim();

    if (textVal.length === 0) {
      this.localValue = '';
      e.target.innerText = '';
    }

    if (e.keyCode === 8) {
      if (textVal.length === 1) {
        this.localValue = '';
        e.target.innerText = '';
        this.update();
      }
    } else if (e.keyCode === 13) {
      e.stopPropagation();
      e.preventDefault();

      if (
        !this.vars ||
        !this.vars.some(
          (attr) => attr.name.toLowerCase() === this.value.trim().toLowerCase(),
        )
      ) {
        const val = this.filterShow()[this.popupAct];

        if (val) {
          this.localValue = val.name;
          e.target.innerText = this.localValue;
          this.update();
        }
      }

      this.popupAct = 0;

      this.enter = true;
      this.setFocusToValue();
      this.hidePopup();
      if (this.save) {
        this.save();
      }
    } else if (e.keyCode === 38) {
      if (this.popupAct > 0) {
        this.popupAct--;
      }
      this.setPopUpScroll();
      e.stopPropagation();
      e.preventDefault();
    } else if (e.keyCode === 40) {
      if (this.popupAct < Object.keys(this.filterShow()).length - 1) {
        this.popupAct++;
      }
      this.setPopUpScroll();
      e.stopPropagation();
      e.preventDefault();
    }
  }

  remove($event) {
    this.localValue = '';
    this.input.innerText = '';
    $event.stopPropagation();
    this.input.focus();
    this.update();
    if (this.save) {
      this.save();
    }
  }

  setVar(lVar) {
    this.value = lVar;
    this.setFocusToValue();
    if (this.save) {
      this.save();
    }
    this.hidePopup();
  }

  setFocusToValue() {
    setTimeout(() => {
      if (this.localValue.length > 0) {
        const valInp = this.$element[0].parentNode.parentNode.querySelector(
          '.attribute-values-adv .attr-input',
        );
        if (valInp) {
          valInp.focus();
        }
      }
    }, 100);
  }

  update(trim) {
    this.popUpOpen = true;
    if (trim) {
      this.value = this.input.innerText.trim();
    } else {
      this.value = this.input.innerText;
    }
  }

  setPopUpScroll() {
    const actItemObj = this.popupScrollBox.querySelector('.act');
    if (actItemObj) {
      if (actItemObj.offsetTop - 48 < this.popupScrollBox.scrollTop) {
        this.popupScrollBox.scrollTop = actItemObj.offsetTop - 48;
      } else if (
        actItemObj.offsetTop + actItemObj.offsetHeight + 48 >
        this.popupScrollBox.scrollTop + this.popupScrollBox.offsetHeight
      ) {
        this.popupScrollBox.scrollTop =
          actItemObj.offsetTop +
          actItemObj.offsetHeight -
          this.popupScrollBox.offsetHeight +
          48;
      }
    }
  }

  placeCaretAtEnd(el) {
    if (
      typeof window.getSelection !== 'undefined' &&
      typeof document.createRange !== 'undefined'
    ) {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  placeCaretAtStart() {
    if (this.notBlured) {
      this.$timeout(() => {
        this.input.focus();

        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(this.input, 0);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);

        this.notBlured = false;

        this.input.blur();
      }, 0).then(() => {
        this.notBlured = true;

        if (this.enter) {
          this.popupAct = 0;
          this.setFocusToValue();

          this.enter = false;
        }
      });
    }
  }

  designInput() {
    setTimeout(() => {
      const needToDesign = this.input.classList.contains('ng-not-empty');
      const designWrapper = this.$element[0].querySelector(
        '.cvp-input-box__design-input-wrapper',
      );

      if (needToDesign) {
        designWrapper.classList.add('active');
      } else {
        designWrapper.classList.remove('active');
      }
    }, 0);
  }

  hidePopup = debounce(() => {
    this.$scope.$evalAsync(() => {
      this.popUpOpen = null;
    });
  }, 150);
}
