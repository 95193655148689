import CardPluginBaseController from '../card-plugin-base-controller/controller';

export default class CardQuickRepliesController extends CardPluginBaseController {
  $onInit() {
    if (!this.item.id) {
      this.save();
    }
    if (!this.item.validation_details) {
      this.item.validation_details = {};
    }
    if (this.item.is_valid === false) {
      this.data = this.item.validation_details || {};
      this.data.card_id = this.item.id;
      this.$rootScope.$broadcast('$pluginSaveResultError', { data: this.data });
    } else {
      this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
    }

    this.trash = this.$element.find('.trash-popup');

    this.aWindow = window.angular.element(window);

    this.rCol = window.angular.element(document.querySelector('.rcol'));

    const trashRePos = () => {
      this.trash.css({
        left: this.rCol[0].scrollLeft + this.rCol.width() - 20,
      });
    };

    this.rCol.on('scroll', () => {
      trashRePos();
    });

    this.aWindow.on('resize', () => {
      trashRePos();
    });

    this.$scope.$on('$buttonUpdate', () => {
      this.$timeout(() => {
        trashRePos();
      }, 100);
    });

    this.$timeout(() => {
      trashRePos();
    }, 100);
  }

  $onDestroy() {
    this.rCol.off('scroll');
    this.aWindow.off('resize');
  }

  qrAvailable() {
    return this.PluginCardService.isQuickReplyAvailableInThisIndex(
      this.index,
      this.items,
    );
  }
}
