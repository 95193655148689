import i18next from 'i18next';
import { prop, clone } from 'ramda';
import { canEdit } from '../../../common/services/RoleService';
import { sendEvent } from '../../../utils/Analytics';

export default class BroadcastAutopostController {
  constructor(
    $scope,
    $rootScope,
    BotService,
    BlockService,
    BroadcastService,
    NamingService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.BroadcastService = BroadcastService;
    this.BlockService = BlockService;
    this.BotService = BotService;
    this.NamingService = NamingService;
    this.RoleService = { canEdit };

    this.listeners = [];
    this.autoposts = [];

    this.creating = false;

    this.listeners.push(
      $rootScope.$on('block:remove', (event, id) => {
        this.deleteSchedule(id);
      }),
    );

    this.listeners.push(
      $rootScope.$on('broadcast:copyAutopost', (event, data) => {
        this.copyAutopost(data);
      }),
    );

    this.listeners.push(
      $scope.$on('$pluginHasErrorValidation', (e, data) => {
        // this.updateValidateStatus(data.blockId);
      }),
    );

    this.listeners.push(
      $scope.$on('$pluginUpdated', (e, data, cards) => {
        this.updateValidateStatus(data.blockId, cards);
      }),
    );
    this.listeners.push(
      $rootScope.$on('autopost:title', (event, data) => {
        if (!data.length) {
          return;
        }
        const card = data.find(
          (item) => item.plugin_id.indexOf('autoposting') !== -1,
        );
        if (card) {
          this.updateTitle(card);
        }
      }),
    );
    this.listeners.push(
      $scope.$on('block:update', (event, id, title) => {
        this.autoposts.forEach((block) => {
          if (block.id === id) {
            block.title = title;
          }
        });
      }),
    );

    this.loadBot();
    this.loadSchedules();
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
  }

  updateTitle(card) {
    this.BlockService.show(this.$rootScope.stateParams.blockId).then((res) => {
      let title = card.plugin_id.slice(12).slice(0, -7).replace('_', ' ');
      const titleSplited = title.split(' ');
      if (titleSplited.length > 1) {
        title = `${titleSplited[0].toUpperCase()} ${titleSplited[1]
          .charAt(0)
          .toUpperCase()}${titleSplited[1].slice(1)}`;
      } else {
        title =
          title === 'ifttt'
            ? (title = title.toUpperCase())
            : title.charAt(0).toUpperCase() + title.slice(1);
      }
      if (res.title !== title) {
        const broadcasts = this.$getBroadcasts();
        res.title = this.NamingService.getIncrementName(
          title,
          broadcasts.map(prop('title')),
        );

        this.BlockService.update(res).then((block) => {
          this.$rootScope.$broadcast('block:update', block.id, block.title);
          this.$rootScope.$broadcast('autopost:titleUpdate', block.title);
        });
      }
    });
  }

  updateValidateStatus(blockId, cards) {
    if (!blockId) {
      // eslint-disable-next-line prefer-destructuring
      blockId = this.$rootScope.stateParams.blockId;
    }
    const bc = this.autoposts.find((item) => item.id === blockId);
    if (bc) {
      const isValid = cards.every((item) => {
        return item.is_valid === true;
      });
      bc.is_valid = isValid;
    }
  }

  loadSchedules() {
    this.BroadcastService.listAutopost().then((rows) => {
      rows.forEach((row) => {
        if (this.autoposts.find((item) => item.id === row.id)) {
          return;
        }

        this.autoposts.push(row);
      });

      this.$rootScope.$broadcast('$recurrentSchedulesLoaded', true);
    });
  }

  loadBot() {
    return this.BotService.show().then((bots) => {
      this.bot = bots;
      return this.bot;
    });
  }

  addAutopost() {
    if (this.creating) {
      return;
    }

    this.$scope.$evalAsync(() => {
      this.creating = true;
    });

    sendEvent({
      category: 're-engage',
      action: 'add',
      label: 'autopost from external source',
    });

    this.loadBot().then((bot) => {
      const broadcasts = this.$getBroadcasts();
      const title = this.NamingService.getIncrementName(
        i18next.t('components.broadcast.autopost.autoposting'),
        broadcasts.map(prop('title')),
      );
      const def = { title };
      return this.BlockService.broadcastAutopost(def).then((block) => {
        this.$scope.$evalAsync(() => {
          this.creating = false;
        });

        const broadcast = clone(def);

        broadcast.id = block.id;
        broadcast.title = block.title;

        this.autoposts.push(broadcast);
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/broadcast/${block.id}/auto`,
        );
      });
    });
  }

  $getBroadcasts() {
    return this.autoposts;
  }

  deleteSchedule(id) {
    const broadcast = this.autoposts.find((item) => item.id === id);

    if (!broadcast) {
      return;
    }

    this.BlockService.remove(id).then(() => {
      const i = this.autoposts.findIndex((item) => item.id === id);

      if (i !== -1) {
        this.autoposts.splice(i, 1);
      }

      this.$rootScope.stateHistory.push(
        `/bot/${this.$rootScope.stateParams.botId}/broadcast`,
      );
    });
  }

  copyAutopost(data) {
    const { block } = data;
    // eslint-disable-next-line prefer-destructuring
    block.title = block.title.split('|')[0].split(/ \d/)[0];
    const broadcasts = this.$getBroadcasts();
    if (broadcasts.find((item) => item.title === block.title)) {
      block.title = this.NamingService.getIncrementName(
        block.title,
        broadcasts.map(prop('title')),
      );
    }
    this.BlockService.update(block).then((bl) => {
      const broadcast = clone(bl);
      broadcast.id = bl.id;
      broadcast.title = bl.title;
      this.autoposts.push(broadcast);
      this.$rootScope.stateHistory.push(
        `/bot/${this.$rootScope.stateParams.botId}/broadcast/${bl.id}/auto`,
      );
    });
  }

  onMouseDownBlockItem(e) {
    const act = document.querySelector('.bc-temp-white.act');
    if (act && act !== e.currentTarget) {
      act.classList.remove('act');
      e.currentTarget.classList.add('act');
    }
  }

  onAutopostClick = ({ id }) => {
    this.$rootScope.stateHistory.push(
      `/bot/${this.$rootScope.stateParams.botId}/broadcast/${id}/auto`,
    );
  };
}
