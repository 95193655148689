/**
 * @description number format and add plurals text
 * @param {Number} n - number for format
 * @param {String} textOne -
 * @param {String} textOther -
 * @return {String} -
 */
export function pluralNumberFormatter(n, textOne, textOther) {
  let out;
  if (!Number.isNaN(Number(n))) {
    out = n.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1 ');
    if (textOne) {
      out = `${out} ${n !== 1 && textOther ? textOther : textOne}`;
    }
  }
  return out;
}
