export default class CardUrlController {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.validate();

    $scope.$on('gallery-sort-stop', () => {
      this.validate();
    });
    $scope.$on('$buttonUpdate', () => {
      this.validate();
    });
  }

  validate() {
    if (typeof this.item === 'string' && this.item.trim() === '') {
      this.item = '';
    }

    this.$errors = {};
    //   if (this.item && !/^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/.test(this.item)) {
    if (
      this.item &&
      !new RegExp(
        '^(https?://)?(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+(?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?)|localhost|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})(?::\\d+)?(?:/?|[/?]\\S+)$',
        'i',
      ).test(this.item)
    ) {
      this.$errors = { message: 'Invalid URL', show: true };
      return false;
    }
    return true;
  }

  save(event) {
    if (!this.validate()) {
      // return;
    }
    this.$scope.$emit('$validateListItem');
    this.CardController.save(this.item, this.index, event);
  }
}
