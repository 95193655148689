import { PLUGIN_TYPE as JSON_PLUGIN_TYPE } from '../../../modern-components/Plugins/JsonPlugin/JsonPluginConst';
import { isNewPlugin } from '../../../modern-components/Plugins/common/createPlugin';
import { showSomethingWentWrongToaster } from '../../../services/MessageService';

export default class PluginController {
  constructor($rootScope, $scope, PluginCardService, $element) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.PluginCardService = PluginCardService;
    this.$element = $element;
    $scope.JSON_PLUGIN_TYPE = JSON_PLUGIN_TYPE;
  }

  // Save Plugin
  save(data, index) {
    if (index) {
      if (this.item.plugin_id === 'gallery') {
        this.item.config.gallery_cards[index] = data;
      } else {
        this.item.config.list_cards[index] = data;
      }
    }
    if (!this.validate()) {
      //  return;
    }
    this.PluginListController.savePlugin(this.item);
  }

  validate() {
    switch (this.item.plugin_id) {
      case 'text':
        if (!this.item.config.text) {
          return false;
        }
        break;
      case 'gallery': {
        const cards = this.item.config.gallery_cards.filter(
          (card) => !!card.title,
        );
        if (
          !cards.length ||
          cards.length !== this.item.config.gallery_cards.length
        ) {
          return false;
        }
        break;
      }
      case 'list': {
        const listCards = this.item.config.list_cards.filter(
          (card) => !!card.title,
        );
        if (
          !listCards.length ||
          listCards.length !== this.item.config.list_cards.length
        ) {
          return false;
        }
        break;
      }
      default:
        return true;
    }
    return true;
  }

  // Remove Row
  remove(index) {
    if (isNewPlugin(this.item.id)) {
      showSomethingWentWrongToaster();
      return undefined;
    }

    if (index !== undefined) {
      this.item.config.gallery_cards.splice(index, 1);
      if (!this.item.config.gallery_cards.length) {
        return this.PluginListController.remove(this.item);
      }
      return this.PluginListController.savePlugin(this.item).then(() => {
        this.$scope.$emit('card-sort-stop');
        this.$scope.$broadcast('$buttonUpdate');
      });
    }
    return this.PluginListController.remove(this.item);
  }

  broadcastEvent(eventName) {
    this.$scope.$broadcast(eventName);
  }

  qrAvailable() {
    return (
      this.PluginListController.block.cards &&
      this.PluginListController.block.cards.length > 1 &&
      this.PluginCardService.isQuickReplyAvailableInThisIndex(
        this.PluginListController.block.cards.findIndex(
          (item) => item === this.item,
        ),
        this.PluginListController.block.cards,
      )
    );
  }

  qrNextTest() {
    if (
      this.item.plugin_id === 'quick_reply' &&
      this.PluginListController.block &&
      this.PluginListController.block.cards &&
      this.PluginListController.block.cards.length > 1
    ) {
      const ind = this.PluginListController.block.cards.findIndex(
        (item) => item === this.item,
      );
      if (ind + 1 < this.PluginListController.block.cards.length) {
        return (
          this.PluginListController.block.cards[ind + 1].plugin_id ===
          'quick_reply'
        );
      }
      return false;
    }
    return false;
  }
}
