import template from './users-table.html';
import controller from './controller';

export default {
  template: () => template,
  controllerAs: 'vm',
  controller,
  restrict: 'E',
  bindings: {
    checkboxes: '=',
    usersList: '<',
    loading: '<',
    sortUpdate: '&',
    cfDisabled: '<',
    showUsersPlaceholder: '<',
    showProPopUp: '&',
    upgradeTitle: '<',
    onUpgradeToProClick: '<',
    selectedUsers: '<',
  },
};
