export default class NullController {
  constructor($rootScope, $scope) {
    'ngInject';

    this.$scope = $scope;
    this.$scope.showInfo = false;
    this.botId = $rootScope.stateParams.botId;

    this.listeners = [];

    this.listeners.push(
      $scope.$on('$historyLoaded', (e, val) => {
        this.$scope.showInfo = !val;
      }),
    );
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
  }
}
