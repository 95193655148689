import ng from 'angular';
import template from './template.html';
import controller from './controller';
import './index.less';

export default ng
  .module('app.common.plugins.collectEmailPhonePlugin', [])
  .component('collectEmailPhonePlugin', {
    require: {
      PluginListController: '^^pluginList',
    },
    controllerAs: 'vm',
    controller,
    bindToController: true,
    bindings: {
      // plugin entity
      item: '=',
    },
    template: () => template,
  }).name;
