import moment from 'moment-timezone';
import {
  onPermissionsUpdate,
  unPermissionsUpdate,
  canEdit,
  canView,
} from '../../common/services/RoleService';

import { sendEvent } from '../../utils/Analytics';
import confirmWizard from './templates/confirm-wizard.html';
import { getBotFeatures } from '../../utils/Data/Bot';
import { getAdminFeatures } from '../../utils/Data/Admin';
import { isWhatsappEnabled } from '../../utils/Whatsapp/isWhatsappEnabled';

/**
 * @description Settings page controller
 */
export default class SettingsController {
  /**
   * constructor
   * @param {Object} $window -
   * @param {Object} $scope -
   * @param {Object} $element -
   * @param {Object} $rootScope -
   * @param {Object} $timeout -
   * @param {Object} $interpolate -
   * @param {Object} $document -
   * @param {Object} $cookies -
   * @param {Object} SettingsFactory -
   * @param {Object} BotService -
   * @param {Object} PageService -
   * @param {Object} UserService -
   * @param {Object} ModalService -
   * @param {Object} StoreService -
   * @param {Object} CacheControlService -
   * @param {Object} MonetizationService -
   */
  constructor(
    $window,
    $scope,
    $element,
    $rootScope,
    $timeout,
    $interpolate,
    $document,
    $cookies,
    SettingsFactory,
    BotService,
    UserService,
    ModalService,
    StoreService,
    CacheControlService,
    MonetizationService,
  ) {
    'ngInject';

    this.StoreService = StoreService;
    this.CacheControlService = CacheControlService;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$window = $window;
    this.$interpolate = $interpolate;
    this.$document = $document;
    this.$timeout = $timeout;
    this.$element = $element;
    this.$cookies = $cookies;
    this.RoleService = { canEdit, canView };
    this.isWhatsappEnabled = undefined;
    this.isWhatsappEnabledLoading = true;

    this.data = SettingsFactory.get($rootScope.stateParams.botId);

    this.className = 'settings';

    this.timeZones = this.getTimeZones();

    this.data.then((result) => {
      const usersTimezone = this.timeZones.find((elem) => {
        return elem.offset === result.timezone_offset;
      });
      this.timeZone = usersTimezone || moment.tz.guess();
    });
    this.botId = $rootScope.stateParams.botId;
    this.confirmWizard = confirmWizard;

    this.SettingsFactory = SettingsFactory;
    this.BotService = BotService;
    this.UserService = UserService;
    this.ModalService = ModalService;
    this.MonetizationService = MonetizationService;

    // TODO: it should be a popup in pro section
    this.paymentDetailsVisible = false;

    $timeout(() => {
      this.loadBotPlatform();
      this.getUserInfo();
    });

    this.listeners = [];

    this.listeners.push(
      $scope.$on('$errorButtonClick', (e) => {
        this.showError = false;
      }),
    );

    this.validatePatterns = {
      http: new RegExp(
        '^(https?://)?(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+(?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?)|localhost|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})(?::\\d+)?(?:/?|[/?]\\S+)$',
        'i',
      ),
    };
    onPermissionsUpdate(this.sentViewContentUpdateEvent);
    this.sentViewContentUpdateEvent();
    $scope.RoleService = $rootScope.RoleService;
  }

  showPaymentDetails = () => {
    this.paymentDetailsVisible = true;
  };

  hidePaymentDetails = () => {
    this.paymentDetailsVisible = false;
  };

  sentViewContentUpdateEvent = () => {
    setImmediate(() => this.$rootScope.$broadcast('$viewContentUpdate'));
  };

  /**
   * @description Callback on destroy
   * @return {void}
   */
  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
    unPermissionsUpdate(this.sentViewContentUpdateEvent);
  }

  /**
   * @description Timezone getter
   * @return {Object} -
   */
  getTimeZones() {
    return this.StoreService.getUTCList();
  }

  /**
   * @description Gets User Info and records it in controller
   * @return {void}
   */
  getUserInfo() {
    this.UserService.show(true).then((res) => {
      this.user = res;
      this.loadBot(true);
    });
  }

  loadBotPlatform() {
    this.BotService.getCurrentBotPlatforms().then((bot) => {
      this.isWhatsappEnabled = isWhatsappEnabled(bot.data.bot);
      this.isWhatsappEnabledLoading = false;
    });
  }

  /**
   * @description Loads Bot
   * @param {String} silence -
   * @return {void}
   */
  loadBot(silence) {
    this.BotService.cachedList(true).then((bots) => {
      this.bot = bots.find((bot) => bot.id === this.botId);
      this.$rootScope.$emit('$updateBot', this.bot);
    });
  }

  /**
   * updateTimeZone
   * @return {void}
   */
  updateTimeZone() {
    sendEvent({
      category: 'configure timezone',
      action: 'set timezone',
      value: this.timeZone.offset,
      propertyBag: {
        offset: this.timeZone.offset,
      },
    });

    this.SettingsFactory.update(this.botId, {
      timezone_offset: this.timeZone.offset,
      timezone_name: 'Unknown',
    }).then(() => {
      // refresh list of bots
      return this.BotService.cachedList(true);
    });
  }

  goToBot = (botId) => {
    this.$rootScope.stateHistory.push(`/bot/${botId}/structure`);
  };

  onUpdateBotRequest = (bot) => {
    this.$rootScope.$emit('$updateBot', bot);
  };

  showDefaultAnswer = () => {
    return !!this.user?.ab_flags?.show_keywords_tab;
  };
}
