import {
  toaster,
  Messages,
} from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2';
import { isFileSizeSameOrLess } from '../../../utils/fileUtils';

export default class CardPictureController {
  constructor($timeout, Upload, ModalService, StoreService) {
    'ngInject';

    this.$timeout = $timeout;
    this.Upload = Upload;
    this.ModalService = ModalService;
    this.StoreService = StoreService;
  }

  $onInit() {
    this.$timeout(() => {
      if (!this.item.id) {
        this.save();
      }
    });
  }

  edit() {
    this.ModalService.imageResize(this.item.config.url).then((result) => {
      if (result) {
        this.item.config.url = `${result}`;
        this.save();
      }
    });
  }

  upload(file, err) {
    if (err.length || (file && !isFileSizeSameOrLess(file, 10))) {
      return this.ModalService.imageSize();
    }
    if (file) {
      this.$loading = true;
      this.Upload.upload({
        url: `${this.StoreService.getApiUrl()}/imgupload`,
        data: { file },
      })
        .then((response) => {
          this.$timeout(() => {
            this.item.config.url = `${response.data.result}`;
            this.save();
            this.$loading = false;
          });
        })
        .catch(() => {
          this.$loading = false;
          toaster.show({
            type: ServiceMessageType.error,
            payload: {
              message: Messages.somethingWentWrong,
            },
          });
        });
    }

    return undefined;
  }

  save() {
    this.PluginListController.savePlugin(this.item);
  }
}
