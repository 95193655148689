import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { sendEvent } from '../../../utils/Analytics';

export default class TypingController extends CardPluginBaseController {
  $onInit() {
    this.sliderDelay = {
      options: {
        floor: 100,
        ceil: 20000,
        step: 100,
        showSelectionBar: true,
        onEnd: this.setDelay.bind(this),
        translate: (value) => `${value / 1000} sec`,
      },
    };
    if (!this.item.id) {
      this.save();
    }
    if (this.item.is_valid === false) {
      this.data = this.item.validation_details || {};
      this.data.card_id = this.item.id;
      this.$rootScope.$broadcast('$pluginSaveResultError', { data: this.data });
    } else {
      this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
    }
  }

  setDelay() {
    sendEvent({
      category: 'plugin typing',
      action: 'value set',
      value: this.item.config.timeout_milliseconds * 0.001,
    });
    this.save();
  }
}
