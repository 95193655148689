import { values, path, last, isEmpty, assocPath, prop, uniqBy } from 'ramda';
import { normalizeSpaces } from '../../utils/String/normalizeSpaces';

/**
 * @description get filter (Segmentation) and extract variables for GQL
 * @param {Segmentation} filter -
 * @param {String} botId - bot id string;
 * @param {number} parameterIndex - index of the parameter where change happen
 * @return {Object} - variables object
 */
export const getVarSuggestVariablesForGQL = (
  { filter, parameterIndex },
  botId,
) => {
  const values = path(['parameters', parameterIndex, 'values'], filter) || [];
  const prefix = last(values);
  const variable =
    normalizeSpaces(
      path(['parameters', parameterIndex, 'name'], filter) || '',
    ) || undefined;
  const type = path(['parameters', parameterIndex, 'type'], filter);
  const variables = {
    prefix,
    variable,
    botId,
    type,
  };

  return variables;
};

/**
 * @description updated cache after gql update of variable value suggests -  basically it merge old result with old one
 *              also make sure that updates stay unique by id;
 * @param {GQLUpdate} previousResult - previous GQL Query result
 * @param {GQLUpdate} fetchMoreResult - this newly fetched data;
 * @return {*} - returns merged data;
 */
export const variableSuggestUpdate = (previousResult, { fetchMoreResult }) => {
  if (!previousResult || isEmpty(previousResult)) {
    return fetchMoreResult;
  }
  const left = previousResult.bot.variableValueSuggest;
  const right = fetchMoreResult.bot.variableValueSuggest;
  // we need to merge to answers;
  const resultMap = {};
  left.forEach((suggest) => {
    resultMap[suggest.id] = suggest;
  });
  right.forEach((suggest) => {
    const result = resultMap[suggest.id];
    if (!result) {
      resultMap[suggest.id] = suggest;
      return;
    }
    resultMap[suggest.id] = assocPath(
      ['suggests'],
      uniqBy(prop('id'), [...result.suggests, ...suggest.suggests]),
      result,
    );
  });
  const result = values(resultMap);
  return assocPath(['bot', 'variableValueSuggest'], result, previousResult);
};
