import { filterXSS } from 'xss';
import { escape } from '../../../../utils/escape';

export default class AttributeValuesAdvController {
  constructor(
    $scope,
    $timeout,
    $element,
    $rootScope,
    $compile,
    StoreService,
    UserFilterService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$element = $element;
    this.$timeout = $timeout;
    this.$compile = $compile;
    this.StoreService = StoreService;
    this.UserFilterService = UserFilterService;
    this.touched = false;

    this.$scope.$watchCollection(
      () => [this.varName, this.touched],
      () => {
        if (this.varName && this.touched) {
          this.loadValues({ $attributeName: this.varName });
        }
      },
    );
  }

  $onInit() {
    this.wasBlured = this.isNew === 'false';

    this.aInput = this.$element.find('.attr-input');
    this.popup = this.$element.find('.attr-popup');
    this.input = this.aInput[0]; // eslint-disable-line prefer-destructuring

    this.$scope.$watch('$val.value', () => {
      if (
        ((this.value || this.value === '') &&
          this.input.innerText !== this.value) ||
        (!this.value &&
          this.value !== '' &&
          !this.UserFilterService.isNotSet(this.input.innerText))
      ) {
        setTimeout(() => {
          this.render(true);
        }, 0);
      }
    });

    this.$scope.$on('$showPopUp', () => {
      this.closePopUp();
    });

    this.$scope.$on('$angularMouseUp', () => {
      this.$timeout(() => {
        this.closePopUp();
      });
    });

    this.aInput.on('input', () => {
      this.render();
    });

    this.aInput.on('focus', () => {
      this.focused = true;
      this.touched = true;
      this.showPopUp();
    });

    this.aInput.on('blur', () => {
      this.$scope.$evalAsync(() => {
        this.focused = false;
        this.wasBlured = true;
        this.value = this.input.innerText.trim();
        this.save();
      });
    });

    this.aInput.on('keydown', (event) => {
      this.onInputKeydown(event);
    });

    this.aInput.on('mousedown', (event) => {
      this.onInputMousedown(event);
    });
  }

  $onDestroy() {
    this.aInput.off('input');
    this.aInput.off('focus');
    this.aInput.off('blur');
    this.aInput.off('mousedown');
    this.aInput.off('keydown');
  }

  onInputKeydown(e) {
    this.inputDataObject = this.getInsertInfo();

    if (e.keyCode === 8) {
      if (
        this.inputDataObject &&
        this.UserFilterService.isNotSet(this.inputDataObject.text)
      ) {
        if (this.inputDataObject.node) {
          this.inputDataObject.node.textContent = '';
        } else {
          this.input.innerText = '';
        }
        e.preventDefault();
      }
      this.showPopUp();
    } else if (e.keyCode === 13 && this.popUpOpen) {
      e.stopPropagation();
      e.preventDefault();

      const lVal = this.filterShow()[this.popupAct];

      if (lVal) {
        this.setVar(lVal.title);
      }

      this.popupAct = 0;
    } else if (e.keyCode === 38 && this.popUpOpen) {
      if (this.popupAct > 0) {
        this.popupAct--;
      }
      this.setPopUpScroll();
      e.stopPropagation();
      e.preventDefault();
    } else if (e.keyCode === 40 && this.popUpOpen) {
      if (this.popupAct < Object.keys(this.filterShow()).length - 1) {
        this.popupAct++;
      }
      this.setPopUpScroll();
      e.stopPropagation();
      e.preventDefault();
    } else if (e.keyCode === 16) {
      // nothing
    } else {
      this.showPopUp();
    }
    this.$scope.$evalAsync();
  }

  onInputMousedown() {
    this.showPopUp();
  }

  showPopUp() {
    if (this.inputDataObject) {
      this.query = this.inputDataObject.text;
    } else {
      this.query = '';
    }
    this.$timeout(() => {
      this.$rootScope.$broadcast('$showPopUp');
      this.inputDataObject = this.getInsertInfo();

      if (this.inputDataObject) {
        this.popup.css({
          left: this.inputDataObject.left,
          top: this.inputDataObject.top + 30,
        });

        this.popupAct = 0;
        this.popUpOpen = true;
        this.query = this.inputDataObject.text;
      } else {
        this.closePopUp();
      }
    }, 0);
  }

  closePopUp() {
    this.popUpOpen = false;
    this.inputDataObject = null;
  }

  render(update) {
    let lVal;
    if (update) {
      lVal = this.value ? this.value : '';
    } else {
      lVal = this.input.innerText;
    }

    lVal = escape(lVal);
    lVal = filterXSS(lVal);

    if (lVal.length > 0) {
      lVal = lVal.replace(/\{\{([^{}]*?)\}\}/g, (str, p1) => {
        return `<span class="var${
          this.UserFilterService.isNotSet(p1) ? ' not-set' : ''
        }" system-attribute system-attribute-top="true" system-attribute-text="${escape(
          p1,
        )}"><at ng-non-bindable><ab>{{</ab><av>${p1}</av><ab>}}</ab></at></span>`;
      });

      if (lVal.indexOf('<span') === -1 && lVal.indexOf('{{') === -1) {
        lVal = `<span class="glob${
          this.UserFilterService.isNotSet(lVal) ? ' not-set' : ''
        }" ng-non-bindable>${lVal}</span>`;
      }
    }

    this.getCaretPosition();
    this.input.innerHTML = lVal;

    this.setCurrentCursorPosition();
    this.$scope.$evalAsync(() => {
      window.angular.forEach(this.aInput.find('span'), (item) => {
        this.$compile(item)(this.$scope);
      });
      // this.setCaretPosition();
      this.setCurrentCursorPosition();
    });
  }

  getCaretPosition() {
    const element = this.input;

    if (document.activeElement !== element) {
      return undefined;
    }

    let caretOffset = 0;
    const doc = element.ownerDocument || element.document;
    const win = doc.defaultView || doc.parentWindow;
    let sel;
    if (typeof win.getSelection !== 'undefined') {
      sel = win.getSelection();
      if (sel.rangeCount > 0) {
        const range = win.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
      }
      // eslint-disable-next-line no-cond-assign
    } else if ((sel = doc.selection) && sel.type !== 'Control') {
      const textRange = sel.createRange();
      const preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint('EndToEnd', textRange);
      caretOffset = preCaretTextRange.text.length;
    }
    this.curPos = caretOffset;
    return caretOffset;
  }

  getInsertInfo() {
    if (document.activeElement !== this.input) {
      return false;
    }

    if (this.input.innerText.indexOf('{{') !== -1) {
      this.varMode = true;

      const sel = window.getSelection();
      let pos = sel.anchorNode.textContent.lastIndexOf('{{', sel.anchorOffset);

      if (
        pos !== -1 ||
        sel.anchorNode.parentNode.tagName.toLowerCase() === 'av'
      ) {
        if (pos === -1) {
          pos = 0;
        }

        const range = document.createRange();
        range.setStart(sel.anchorNode, pos);
        range.collapse(true);
        const parentRect = this.$element[0].getBoundingClientRect();

        const rangeRects = range.getClientRects();

        const sI = sel.anchorNode.textContent.lastIndexOf('{{');

        const out = {
          text: sel.anchorNode.textContent
            .substring(
              sI !== -1 ? sI + 2 : 0,
              sel.anchorNode.textContent.lastIndexOf('}}') === -1
                ? sel.anchorOffset
                : 9999,
            )
            .trim(),
          node: sel.anchorNode,
        };

        if (rangeRects.length === 0) {
          out.left = 6;
          out.top = 4;
        } else {
          out.left = rangeRects[0].left - parentRect.left;
          out.top = rangeRects[0].top - parentRect.top;
        }

        return out;
      }
      return false;
    }
    this.varMode = false;

    return {
      left: 6,
      top: 4,
      text: this.input.innerText,
    };
  }

  compileUserText(n) {
    return `${String(n).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')} ${
      Number(n) === 1 ? 'user' : 'users'
    }`;
  }

  filterShow() {
    const result = [];
    const val = this.query ? this.query.trim() : '';
    this.emptyPopUp = true;

    if (this.values) {
      this.values.forEach((value) => {
        if (
          val.toLowerCase() !== value.name.toLowerCase() &&
          value.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
        ) {
          result.push(value);
          this.emptyPopUp = false;
        }
      });
    }
    return result;
  }

  onPopUpClick(lVar, $event) {
    $event.stopPropagation();
    setTimeout(() => {
      this.setVar(lVar);
    }, 0);
  }

  setVar(lVar) {
    if (this.inputDataObject.node) {
      let sPos = this.inputDataObject.node.textContent.lastIndexOf('{{');

      if (sPos === -1) {
        sPos = 0;
      }

      if (this.inputDataObject.text.length) {
        let append = '';

        if (
          this.inputDataObject.node.textContent.indexOf('}}') !==
            this.inputDataObject.node.textContent.length - 2 &&
          this.inputDataObject.node.parentNode.tagName.toLowerCase() !== 'av'
        ) {
          append = '}}';
        }
        this.inputDataObject.node.textContent =
          this.inputDataObject.node.textContent.substring(0, sPos) +
          this.inputDataObject.node.textContent
            .substring(sPos, 999)
            .replace(this.inputDataObject.text, lVar + append);
      } else {
        this.inputDataObject.node.textContent = `${this.inputDataObject.node.textContent.substring(
          0,
          sPos,
        )}{{${lVar}}}`;
      }
    } else {
      this.input.innerText = lVar;
    }
    const val = this.input.innerText;

    this.value = val;

    setTimeout(() => {
      this.render(true);
      this.setCurrentCursorPosition();
    }, 1);

    setTimeout(() => {
      this.curPos = val.length;
      this.setCurrentCursorPosition();
    }, 100);

    this.input.focus();

    this.closePopUp();
    this.save();
  }

  setPopUpScroll() {
    const actItemObj = this.popup[0].querySelector('.act');
    if (actItemObj) {
      if (actItemObj.offsetTop - 48 < this.popup[0].scrollTop) {
        this.popup[0].scrollTop = actItemObj.offsetTop - 48;
      } else if (
        actItemObj.offsetTop + actItemObj.offsetHeight + 48 >
        this.popup[0].scrollTop + this.popup[0].offsetHeight
      ) {
        this.popup[0].scrollTop =
          actItemObj.offsetTop +
          actItemObj.offsetHeight -
          this.popup[0].offsetHeight +
          48;
      }
    }
  }

  setCurrentCursorPosition() {
    if (!this.focused) {
      return;
    }

    const el = this.input;
    const sPos = this.curPos;
    let charIndex = 0;
    const range = document.createRange();
    range.setStart(el, 0);
    range.collapse(true);
    const nodeStack = [el];
    let node;
    let foundStart = false;
    let stop = false;

    // eslint-disable-next-line no-cond-assign
    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType === 3) {
        const nextCharIndex = charIndex + node.length;
        if (!foundStart && sPos >= charIndex && sPos <= nextCharIndex) {
          range.setStart(node, sPos - charIndex);
          foundStart = true;
        }
        if (foundStart && sPos >= charIndex && sPos <= nextCharIndex) {
          range.setEnd(node, sPos - charIndex);
          stop = true;
        }
        charIndex = nextCharIndex;
      } else {
        let i = node.childNodes.length;
        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
}
