import { sendEvent } from '../../../../utils/Analytics';

export default class MenuLocalController {
  constructor($scope, $document, $element, BotService) {
    'ngInject';

    this.$scope = $scope;
    this.$document = $document;
    this.$element = $element;

    $scope.$on('$angularClick', () => {
      $scope.$evalAsync(() => {
        this.open = false;
      });
    });

    this.popLanguages = [
      'English',
      'French',
      'Arabic',
      'Spanish',
      'Thai',
      'Polish',
      'Vietnamese',
      'Portuguese',
      'Traditional Chinese',
      'Indonesian',
      'Turkish',
      'Italian',
      'German',
    ];

    BotService.getMenuLanguages().then((res) => {
      this.allLanguages = res;
      this.allLanguages.splice(0, 1);
    });
  }

  $onInit() {
    this.popup = this.$element.find('.menu-local-popup');
    angular.element(this.$document).on('keydown', (e) => {
      if (this.open) {
        const ind = this.allLanguages.findIndex(
          (lang) =>
            lang
              .toLowerCase()
              .indexOf(String.fromCharCode(e.keyCode).toLowerCase()) === 0,
        );
        if (ind !== -1) {
          this.popup.scrollTop(
            this.popup.find(`#i${ind}`).position().top + this.popup.scrollTop(),
          );
        }
      }
    });
  }

  $onDestroy() {
    angular.element(this.$document).off('keydown');
  }

  isNestedItemsExists(item) {
    if (Array.isArray(item)) {
      return item
        .find((item) => item.language === 'default')
        .items?.some((item) => item.items?.length);
    }
    return item.items.some((item) => item.items?.length);
  }

  sel(lang) {
    sendEvent({
      category: 'persistent menu',
      action: 'add',
      label: lang,
    });

    this.$scope.$emit('$selLanguage', lang);
  }

  remove(lang) {
    sendEvent({
      category: 'persistent menu',
      action: 'delete',
      label: lang,
    });

    this.$scope.$emit('$removeLanguage', lang);
  }

  onMenuClick() {
    sendEvent({
      category: 'persistent menu',
      action: 'click',
    });
  }

  testLang(lang) {
    return (
      this.menuItems &&
      this.menuItems.findIndex((item) => item.language === lang) !== -1
    );
  }
}
