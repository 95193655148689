import React from 'react';
import { ApolloConsumer } from '@apollo/react-common';
import ApolloClient from 'apollo-client';
import { Modal } from '../../../../modern-ui/Modal';
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeading,
} from '../../../../modern-ui/Dialog';
import { RolesList } from '../RolesList';
import { ROLES_AND_INVITES_QUERY_bot_admins as AdminType } from '../@types/ROLES_AND_INVITES_QUERY';
import { Icon, IconSize } from '../../../../modern-ui/_deprecated/Icon';
import { Button, ButtonIntent } from '../../../../modern-ui/_deprecated/Button';
import {
  ADMIN_ASSIGN_QUERY,
  SET_ADMIN_MUTATION,
} from '../../../../modern-components/AdminPermissionManagerDialog/AdminPermissionManagerDialog';
import { ROLES_AND_INVITES_QUERY } from '../AdminsConst';
import * as css from './EditRolePopup.css';
import { sendEvent } from '../../../../utils/Analytics';

interface IEditRolePopupProps {
  onRoleChangeWithReasons: (roleId: string) => void;
  onDismiss: () => void;
  admin: AdminType;
  defaultSelectedRoleId: string | undefined;
  onRemoveAdminRequest: (userId: string) => void;
  botId: string;
}

interface RolesListState {
  assigningTo?: string;
  selectedRoleId?: string;
}

export class EditRolePopup extends React.Component<
  IEditRolePopupProps,
  RolesListState
> {
  constructor(props: IEditRolePopupProps) {
    super(props);

    this.state = {
      assigningTo: undefined,
      selectedRoleId: props.defaultSelectedRoleId,
    };
  }

  handleRoleClick = async (roleId: string, client: any) => {
    const { botId, onRoleChangeWithReasons, admin } = this.props;

    const userId = admin.id;

    if (!onRoleChangeWithReasons) {
      return;
    }

    this.setState({
      assigningTo: roleId,
    });

    const response = await client.query({
      query: ADMIN_ASSIGN_QUERY,
      variables: {
        botId,
        userId,
        roleId,
      },
    });

    const {
      data: {
        bot: {
          canAssignAdmin: { mustBeFixedReasons, shouldBeFixedReasons },
        },
      },
    } = response;

    const reasonsExist =
      shouldBeFixedReasons.length > 0 || mustBeFixedReasons.length > 0;

    if (!reasonsExist) {
      await client.mutate({
        mutation: SET_ADMIN_MUTATION,
        variables: {
          botId,
          userId,
          roleId,
        },
        refetchQueries: [
          {
            query: ROLES_AND_INVITES_QUERY,
            variables: { botId },
          },
        ],
        awaitRefetchQueries: true,
      });
      this.setState({
        selectedRoleId: roleId,
      });
    } else {
      onRoleChangeWithReasons(roleId);
    }

    sendEvent({
      category: 'team',
      action: 'change permissions',
      propertyBag: {
        editedUserId: admin.id,
        permissions: roleId,
      },
    });

    this.setState({
      assigningTo: undefined,
    });
  };

  render() {
    const { admin, onDismiss, onRemoveAdminRequest } = this.props;
    const { assigningTo } = this.state;

    return (
      <Modal onDismiss={onDismiss}>
        <div className={css.fixedVirtualHeight}>
          <Dialog className={css.dialog}>
            <DialogContent>
              <DialogHeading>Change permissions</DialogHeading>
              <div className={css.subHead}>
                <div className={css.user}>
                  {admin.picture && (
                    <Icon
                      className={css.adminPicture}
                      size={IconSize.slarge}
                      url={admin.picture}
                      rounded
                    />
                  )}
                  {admin.name}
                </div>
                <Button
                  intent={ButtonIntent.secondary}
                  onClick={() => {
                    sendEvent({
                      category: 'team',
                      action: 'click remove teammate',
                      propertyBag: {
                        editedUserId: admin.id,
                      },
                    });
                    onRemoveAdminRequest(admin.id);
                  }}
                  tabIndex={-1}
                >
                  Remove Teammate
                </Button>
              </div>
              <ApolloConsumer>
                {(client: ApolloClient<any>) => (
                  <RolesList
                    onRoleClick={({ id }) => {
                      this.handleRoleClick(id, client);
                    }}
                    assigningTo={assigningTo}
                    selectedRoleId={this.state.selectedRoleId}
                  />
                )}
              </ApolloConsumer>
            </DialogContent>
            <DialogCloseButton onClick={onDismiss} />
          </Dialog>
        </div>
      </Modal>
    );
  }
}
