import CardPluginBaseController from '../card-plugin-base-controller/controller';

export default class CommentController extends CardPluginBaseController {
  $onInit() {
    this.ctx = {};
    this.textFieldFocus = false;
  }

  $postLink() {
    this._setFocusAfterCreatePlugin();
  }

  _setFocusAfterCreatePlugin() {
    if (!this.item.id) {
      this.ctx.focus();
    }
  }

  _onFocus() {
    this.textFieldFocus = true;
  }

  _onBlur() {
    this.save();
    this.textFieldFocus = false;
  }

  _onChange(value) {
    this.item.config.text = value;
  }
}
