export default class CardListController {
  constructor($scope, $element, $timeout) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.$timeout = $timeout;
    this.itemLength = 4;
    this.listeners = [];

    this.listenerItems = [
      {
        name: '$item:cover-remove',
        function: () => {
          this.itemLength = 4;
          this.item.config.top_element_style = 'compact';
          this.save();
        },
        oneTime: false,
      },
      {
        name: '$saveMenu',
        function: () => this.save(),
        oneTime: false,
      },
      {
        name: '$item:button-remove',
        function: () => this.save(),
        oneTime: false,
      },
      {
        name: '$angularMouseDown',
        function: () => {
          this.$scope.$evalAsync(() => {
            this.$scope.isNew = false;
          });
        },
        oneTime: true,
      },
      {
        name: '$item:thoth',
        function: () => {
          this.$scope.isNew = false;
        },
        oneTime: false,
      },
    ];
  }

  $onInit() {
    if (!this.item.id) {
      this.$scope.isNew = true;
      this.save();
    }

    this.initListeners();
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
  }

  initListeners() {
    this.listenerItems.forEach((item) => {
      const tmpListener = item.oneTime
        ? this.$scope.$on(item.name, () => {
            tmpListener();

            item.function();
          })
        : this.$scope.$on(item.name, item.function);

      this.listeners.push(tmpListener);
    });
  }

  addItem(param) {
    const newItem = {
      buttons: [],
      image_url: '',
      item_url: '',
      subtitle: '',
      title: '',
      new: true,
    };

    if (param === 'coverImage') {
      this.manageFirstItem(newItem);
    } else {
      this.item.config.list_cards.push(newItem);
    }
  }

  manageFirstItem(newItem) {
    this.item.config.top_element_style = 'large';
    this.item.config.list_cards.unshift(newItem);
    this.$scope.$broadcast('$item:cover-add');
    this.$scope.$broadcast('$buttonUpdate');

    setTimeout(() => {
      this.$element
        .find('.button-image--replace-gallery')
        .eq(0)
        .trigger('click');
    }, 0);

    this.save();
  }

  removeItem(index) {
    if (index === 0) {
      this.item.config.list_cards.shift();
      this.save();
    } else if (this.item.config.list_cards.length > 2) {
      this.item.config.list_cards.splice(index, 1);
      this.$scope.$broadcast(
        '$item:update',
        this.item.config.list_cards.length,
      );
      this.save();
    } else {
      this.item.config.list_cards[index] = {
        buttons: [],
        image_url: '',
        item_url: '',
        subtitle: '',
        title: '',
      };
      this.save();
    }

    this.$timeout(() => {
      this.$scope.$broadcast('$buttonUpdate');
      this.$scope.$broadcast('$validateListItem');
    });
  }

  save() {
    this.$scope.$emit('$buttonUpdate');
    this.PluginController.save(this.item, this.index);
  }

  convertToGallery = () => {
    const convertedPlugin = {
      ...this.item,
      plugin_id: 'gallery',
      config: {
        gallery_cards: this.item.config.list_cards,
      },
    };
    convertedPlugin.config.gallery_cards[0].buttons = [
      ...convertedPlugin.config.gallery_cards[0].buttons,
      ...this.item.config.buttons,
    ];
    this.item.plugin_id = convertedPlugin.plugin_id;
    this.item.config = convertedPlugin.config;
    this.save();
  };
}
