import React from 'react';
import { OriginalAccountType } from '@globals';
import { useAccountStatus } from '@pages/MultiSystemAuth/hooks/useAccountStatus';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Anchor } from '@ui/Links';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useIsWindowWidthLess } from '@utils/DOM/useIsWindowWidthLess';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface LogoutButtonProps {}

export const LogoutButton: React.FC<LogoutButtonProps> = () => {
  const { t } = useSafeTranslation();
  const { status } = useAccountStatus();
  const isShortLoginTestWidth = useIsWindowWidthLess(450);

  return (
    <Anchor href="/logout" noUnderline>
      <Flex alignItems="center">
        <Icon icon="logOut" color="accent1Normal" />
        <Spacer horizontalFactor={1} factor={1} />
        <Type size="15px" weight="medium" color="accent1Normal">
          {t(
            status?.original_account_type === OriginalAccountType.guest
              ? isShortLoginTestWidth
                ? 'common.User.UserNavigationItem.alreadyHaveAnAccountShort'
                : 'common.User.UserNavigationItem.alreadyHaveAnAccount'
              : 'common.User.UserNavigationItem.logOut',
          )}
        </Type>
      </Flex>
    </Anchor>
  );
};
