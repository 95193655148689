import pluginListTemplate from './templates/plugin.list.html';
import pluginTemplate from './templates/plugin.html';
import pluginRemoveTemplate from './templates/plugin.remove.html';
import cardTemplate from './templates/card.html';
import cardAddTemplate from './templates/card.add.html';
import cardPluginTemplate from './templates/card.plugin.html';
import cardImageTemplate from './templates/card.image.html';
import cardTitleTemplate from './templates/card.title.html';
import cardSubTitleTemplate from './templates/card.subtitle.html';
import cardUrlTemplate from './templates/card.url.html';
import cardButtonsTopTemplate from './templates/card.buttons.top.html';
import cardButtonsListTemplate from './templates/card.buttons.list.html';
import cardButtonsItemTemplate from './templates/card.buttons.item.html';
import cardButtonsAddTemplate from './templates/card.buttons.add.html';

import PluginListController from './controllers/plugin.list.controller';
import PluginController from './controllers/plugin.controller';
import PluginRemoveController from './controllers/plugin.remove.controller';
import CardController from './controllers/card.controller';
import CardAddController from './controllers/card.add.controller';
import CardPluginController from './controllers/card.plugin.controller';
import CardImageController from './controllers/card.image.controller';
import CardTitleController from './controllers/card.title.controller';
import CardSubTitleController from './controllers/card.subtitle.controller';
import CardUrlController from './controllers/card.url.controller';
import CardButtonsTopController from './controllers/card.buttons.top.controller';
import CardButtonsListController from './controllers/card.buttons.list.controller';
import CardButtonsItemController from './controllers/card.buttons.item.controller';
import CardButtonsAddController from './controllers/card.buttons.add.controller';
import CardCommunicationTitleController from './controllers/card.communication.title.controller';
import CardCommunicationSubtitleController from './controllers/card.communication.subtitle.controller';
import CardCommunicationButtonController from './controllers/card.communication.button.controller';
import CardCommunicationMessageController from './controllers/card.communication.message.controller';
import CardCommunicationImageController from './controllers/card.communication.image.controller';
import CardCommunicationButtonsTopController from './controllers/card.communication.buttons.top.controller';

export const PluginListComponent = () => ({
  template: pluginListTemplate,
  controller: PluginListController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    block: '=',
  },
});

export const PluginComponent = () => ({
  require: {
    PluginListController: '^^pluginList',
  },
  template: pluginTemplate,
  controller: PluginController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    item: '=',
  },
});

export const PluginRemoveComponent = () => ({
  require: {
    PluginController: '^^plugin',
  },
  template: pluginRemoveTemplate,
  controller: PluginRemoveController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    item: '=',
    block: '=',
    botId: '=',
    onToggleDisclosure: '&',
    hideOptionsButton: '=',
  },
});

export const CardComponent = () => ({
  require: {
    PluginController: '^^plugin',
  },
  template: cardTemplate,
  controller: CardController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    item: '=',
    index: '=',
    card: '=',
    plugin: '=',
  },
});

export const CardAddComponent = () => ({
  template: cardAddTemplate,
  controller: CardAddController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    items: '=',
  },
});

export const CardPluginComponent = () => ({
  require: {
    PluginListController: '^^pluginList',
  },
  template: cardPluginTemplate,
  controller: CardPluginController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    item: '=',
  },
});

export const CardTitleComponent = () => ({
  require: {
    CardController: '^^pluginCard',
  },
  template: cardTitleTemplate,
  controller: CardTitleController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    item: '=',
    index: '=',
    plugin: '=',
    setFocus: '=',
    placeholder: '@',
    noRequired: '@',
  },
});

export const CardSubTitleComponent = () => ({
  require: {
    CardController: '^^pluginCard',
  },
  template: cardSubTitleTemplate,
  controller: CardSubTitleController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    item: '=',
    index: '=',
  },
});

export const CardUrlComponent = () => ({
  require: {
    CardController: '^^pluginCard',
  },
  template: cardUrlTemplate,
  controller: CardUrlController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    item: '=',
    index: '=',
  },
});

export const CardImageComponent = () => ({
  require: {
    PluginController: '^^plugin',
    CardController: '^^pluginCard',
  },
  template: cardImageTemplate,
  controller: CardImageController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    item: '=',
    index: '=',
    loading: '=',
    plugin: '=',
  },
});

export const CardButtonsTopComponent = () => ({
  require: {
    CardController: '^^pluginCard',
    PluginController: '^^plugin',
  },
  template: cardButtonsTopTemplate,
  controller: CardButtonsTopController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    item: '=',
    index: '=',
    loading: '=',
  },
});

export const CardButtonsListComponent = () => ({
  require: {
    PluginController: '^^plugin',
  },
  template: cardButtonsListTemplate,
  controller: CardButtonsListController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    item: '=',
    items: '=',
    index: '=',
  },
});

export const CardButtonsItemComponent = () => ({
  require: {
    ButtonsListController: '^^pluginCardButtonsList',
  },
  template: cardButtonsItemTemplate,
  controller: CardButtonsItemController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    item: '=',
    items: '=',
    index: '=',
    itemIndex: '=',
    showShare: '@',
  },
});

export const CardButtonsAddComponent = () => ({
  template: cardButtonsAddTemplate,
  controller: CardButtonsAddController,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  transclude: true,
  scope: {
    items: '=',
    index: '=',
    itemIndex: '=',
  },
});
