/**
 * all application wide constance should be this folder;
 */

import ng from 'angular';

const ATTRIBUTE_TYPE_CODES = {
  custom: 'custom',
  system: 'system',
  segment: 'segment',
  sequence: 'sequence',
};

export default ng
  .module('app.constance', [])
  /**
   * @description: when you create attributes you have following attribue types;
   */
  .constant('ATTRIBUTE_TYPE_CODES', ATTRIBUTE_TYPE_CODES)
  /**
   * @description: default attribute code;
   */
  .constant('DEFAULT_ATTRIBUTE_TYPE_CODE', ATTRIBUTE_TYPE_CODES.custom)
  /**
   * @description: used to translate GQL ParametersOperator enum (see GQL Schema) to display string in UI;
   */
  .constant('PARAMETERS_OPERATOR_TO_STRING', {
    and: 'and',
    or: 'or',
  })
  .constant('SEGMENTATION_PARAMETER_TYPES', {
    segment: 'segment',
    sequence: 'sequence',
    system: 'system',
    custom: 'custom',
  })
  .constant('SEGMENTATION_PARAMETER_TYPES_STRINGS', [
    'sequence',
    'attribute',
    'segment',
  ])
  .constant('SEGMENTATION_PARAMETER_TYPE_TO_STRING', {
    segment: 'segment',
    sequence: 'sequence',
    system: 'attribute',
    custom: 'attribute',
  }).name;
