import template from './menu-subitem.html';
import controller from './controller.js';

export const Component = {
  controller,
  template: () => template,
  controllerAs: '$menuSubitem',
  bindings: {
    subitem: '=',
    index: '=',
    menu: '=',
    level: '@',
    allowDeletion: '=',
  },
};
