import { curry, reject } from 'ramda';
import keycode from 'keycode';

const equal = curry((x, y) => x === y);

export default class SegmentNameEdited {
  constructor(SegmentService) {
    'ngInject';

    if (!this.allSegments) {
      throw new Error('You must provide segments Array or empty array');
    }
    this.isFocued = false;
    // this is internal value of name; we will update it only on blur event and notify parent about change;
    this._value = null;
    this.SegmentsService = SegmentService;

    this.ctx = {}; // this need for chip input to add focus method; this is starnge. take a look on the chip-input api;
  }

  _isInputValid(value) {
    const filterdSegmenrs = this.segmnentWithoutCurrent(this.allSegments);
    return this.SegmentsService.isSegmentNameIsValidForSegments(
      value,
      filterdSegmenrs,
    );
  }

  $onChanges() {
    // sync on any params change this._value with this.segment.name;
    this._value = this.segment.name;
    this.segmnentWithoutCurrent = reject(equal(this.segment));
  }

  onKeyup(e) {
    const isEnter = keycode('Enter') === e.keyCode;
    if (isEnter) {
      this.onBlur(e);
    }
  }

  onBlur(e) {
    // content editable (I don't know why) triggers blur when input become empty;
    // fixme: find out why
    if (this._value) {
      this.isFocued = false;
      this.ctx.blur();
      this.onSegmentChange({
        $segment: {
          ...this.segment,
          name: this._value,
        },
      });
    }
  }
}
