import { getAttributesQueryObservable } from '../../utils/AttributesUtils/AttributesUtils';
import { Platform } from '../../../@types/globalTypes';
import { VariableSuggestType } from '../../utils/AttributesUtils/AttributesUtilsTypes';

export default class PublicVarsSuggestController {
  constructor(
    $scope,
    $timeout,
    $element,
    $rootScope,
    StoreService,
    PeopleService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$element = $element;
    this.StoreService = StoreService;
    this.PeopleService = PeopleService;

    if (!this.ngModel) {
      this.ngModel = [];
    }
    this.attributes = [];
    this.attributesSubscription = getAttributesQueryObservable(
      $rootScope.stateParams.botId,
      VariableSuggestType.template,
      Platform.facebook,
    ).subscribe((allBotAttributesForSuggest) => {
      this.attributes = this.PeopleService.filterAndSortCustomFirstAttributes(
        allBotAttributesForSuggest,
      );
    });

    $scope.$on('$angularMouseUp', () => {
      $timeout(() => {
        this.valuesPopUpOpen = null;
      });
    });
  }

  $onInit() {}

  $onDestroy() {
    if (this.attributesSubscription) {
      this.attributesSubscription.unsubscribe();
    }
  }

  selectVariable(variable) {
    this.ngModel.push(variable);
    this.inputModel = '';
    this.$scope.$emit('$saveVariables');
  }

  valuesClick($event) {
    $event.stopPropagation();
    $event.currentTarget.querySelector('input').focus();
    this.valuesPopUpOpen = true;
    this.valuePopupAct = 0;
  }

  filterShow() {
    return this.attributes
      .filter((attr) => {
        const currentModalDoNotHaveThisAttribute =
          this.ngModel.indexOf(attr.name) === -1;
        const showFilterIfCurrentInputIsEmpty =
          !this.inputModel || this.inputModel.length === 0;
        const currentInputMatchSomeModel =
          this.inputModel &&
          attr.name.toLowerCase().indexOf(this.inputModel.toLowerCase()) !== -1;
        return (
          currentModalDoNotHaveThisAttribute &&
          (showFilterIfCurrentInputIsEmpty || currentInputMatchSomeModel)
        );
      })
      .map((attr) => attr.name);
  }

  onInputFocus(e, index) {
    this.focused = true;
    window.angular.element(e.target).parent().parent().scrollLeft(0);
  }

  onInputBlur(e, index) {
    window.angular.element(e.target).parent().parent().scrollLeft(0);
    this.focused = false;
  }

  onKeyDown(e) {
    if (e.keyCode === 8) {
      if (!this.inputModel || this.inputModel.length === 0) {
        this.ngModel.pop();
        e.stopPropagation();
        e.preventDefault();
        this.$scope.$emit('$saveVariables');
      }
    } else if (e.keyCode === 13) {
      e.stopPropagation();
      e.preventDefault();

      const pars = this.filterShow();

      let count = 0;
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in pars) {
        count++;
        if (count - 1 === this.valuePopupAct) {
          this.ngModel.push(pars[key]);
          this.$scope.$emit('$saveVariables');
          break;
        }
      }

      if (count > 0) {
        e.target.value = '';
        window.angular.element(e.target).trigger('input');
      }

      this.valuePopupAct = 0;
    } else if (e.keyCode === 38) {
      if (this.valuePopupAct > 0) {
        this.valuePopupAct--;
      }
      e.stopPropagation();
      e.preventDefault();
    } else if (e.keyCode === 40) {
      if (this.valuePopupAct < Object.keys(this.filterShow()).length - 1) {
        this.valuePopupAct++;
      }
      e.stopPropagation();
      e.preventDefault();
    }
  }

  removeVar(index, $event) {
    $event.stopPropagation();
    //   this.valuesPopUpOpen = null;
    this.ngModel.splice(index, 1);
    this.$scope.$emit('$saveVariables');

    setTimeout(() => {
      this.$element.find('input').focus();
    }, 10);
  }
}
