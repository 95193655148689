export default class ListItemController {
  constructor(
    $rootScope,
    $scope,
    $element,
    $timeout,
    BotService,
    UserService,
    BlockService,
    ButtonService,
    CurrencyService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$element = $element;
    this.$timeout = $timeout;
    this.BotService = BotService;
    this.UserService = UserService;
    this.BlockService = BlockService;
    this.ButtonService = ButtonService;
    this.CurrencyService = CurrencyService;
    this.$buttonIsAdd = false;
    this.$error = {
      message: 'Set up at least one more item field: subtitle, button or image',
    };
    this.$open = false;

    this.buttonBuy = {};
    this.listeners = [];

    this.listenerItems = [
      {
        name: '$item:cover-add',
        function: () => {
          const coverHeading = this.$element[0].querySelector('textarea');
          if (this.index === 0) {
            this.$timeout(() => {
              coverHeading.focus();
              delete this.item.new;
            }, 50);
          }
        },
        oneTime: false,
      },
      {
        name: '$closePopup',
        function: () => {
          if (
            this.ButtonService.testError(this.item.buttons[0], 'block_id')
              .length > 1
          ) {
            this.item.buttons.pop();
          }
        },
        oneTime: false,
      },
      {
        name: '$validateListItem',
        function: () => {
          this.validate();
        },
        oneTime: false,
      },
      {
        name: '$saveMenu',
        function: () => {
          this.$buttonAdd = true;
        },
        oneTime: false,
      },
      {
        name: '$angularClick',
        function: () => {
          this.$scope.$evalAsync(() => {
            this.$openButtonPopup = false;
          });
        },
        oneTime: false,
      },
    ];
  }

  $onInit() {
    if (this.item.buttons.length > 0) {
      this.$buttonAdd = true;
    }

    this.setHeadingFocus();
    this.updateBotPaymentsStatus(false, true);
    this.elementOnFocus();
    this.elementOnBlur();
    this.validate();
    this.initListeners();
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
    this.$element.off('focus', 'input, textarea');
    this.$element.off('blur', 'input, textarea');
  }

  setHeadingFocus() {
    const headind = this.$element[0].querySelector('textarea');

    if (this.item.new) {
      this.$timeout(() => {
        headind.focus();
        delete this.item.new;
      }, 0);
    }
  }

  elementOnFocus() {
    this.$error = { message: null };

    this.validate();

    this.$element.on('focus', 'input, textarea', () => {
      this.$error.show = false;
      this.focused = true;
      if (this.blurTimeout) {
        this.$timeout.cancel(this.blurTimeout);
      }
    });
  }

  elementOnBlur() {
    this.$element.on('blur', 'input, textarea', () => {
      this.blurTimeout = this.$timeout(() => {
        this.focused = false;
      }, 10);
      this.$scope.$emit('$item:thoth');
    });
  }

  initListeners() {
    this.listenerItems.forEach((item) => {
      const tmpListener = item.oneTime
        ? this.$scope.$on(item.name, () => {
            tmpListener();

            item.function();
          })
        : this.$scope.$on(item.name, item.function);

      this.listeners.push(tmpListener);
    });

    this.initRootScopeListeners();
  }

  initRootScopeListeners() {
    this.listeners.push(
      this.$rootScope.$on('$set-payment-status-on', (e, type) => {
        // eslint-disable-next-line default-case
        switch (type) {
          case 'bot':
            this.bot.status.payments_status = 'enabled';
            this.botStatusPaymentEnabled = true;
            this.UserService.nativePaymentsForCurrentBot(false, this.user);
            break;

          case 'native':
            this.user.payments_access = true;
            this.UserService.nativePaymentsForCurrentBot(true, this.user);
            break;

          case 'stripe':
            this.bot.payments_stripe = true;
            this.UserService.nativePaymentsForCurrentBot(false, this.user);
            break;
        }

        this.paymentsAccess =
          this.user.payments_access || this.bot.payments_stripe;
      }),
    );
  }

  validate() {
    this.$error.show = false;
    this.$timeout(() => {
      if (
        (this.index > 0 ||
          this.plugin.config.top_element_style === 'compact') &&
        this.item.title &&
        !this.item.subtitle &&
        !this.item.buttons.length &&
        !this.item.image_url
      ) {
        this.$error.show = true;
        this.$error.message =
          'Set up at least one more item field: subtitle, button or image';
      } else if (
        this.item.title &&
        this.index === 0 &&
        !this.item.image_url &&
        this.plugin.config.top_element_style !== 'compact'
      ) {
        this.$error.show = true;
        this.$error.message = 'Set up cover image';
      }
    }, 0);
  }

  openPopUp($event) {
    this.$error.show = false;
    this.add();
  }

  removeButton($index) {
    this.item.buttons.splice($index, 1);
    this.$scope.$emit('$item:button-remove');
    this.validate();
  }

  editButton($event, id) {
    this.$buttonIsAdd = false;
    this.$timeout(() => {
      this.$rootScope.$emit('$onButtonPopupEditor', {
        item: this.item.buttons[id],
        scope: this.$scope,
        element: this.$element,
        parent_elem: '.buttons-wrp',
        hide_tabs: ['submenu', 'share'],
        plugin_type: this.plugin.plugin_id,
      });
    });
  }

  removeImg(id) {
    if (id === 0 && this.plugin.config.top_element_style === 'large') {
      this.$scope.$broadcast('$resetValidate');
      this.$scope.$broadcast('$removeForstItem');
      this.$scope.$emit('$item:cover-remove');
    }
  }

  add() {
    this.$buttonIsAdd = false;
    this.item.buttons.push({
      title: '',
      block_id: [],
      new: true,
    });
    this.$timeout(() => {
      this.$rootScope.$emit('$onButtonPopupEditor', {
        item: this.item.buttons[this.item.buttons.length - 1],
        scope: this.$scope,
        element: this.$element,
        parent_elem: '.buttons-wrp',
        hide_tabs: ['submenu', 'share'],
        plugin_type: this.plugin.plugin_id,
      });
    });
  }

  updateBotPaymentsStatus(paymentEnabled, useCache) {
    if (!paymentEnabled) {
      this.BotService.cachedList().then((bots) => {
        this.bot = bots.find(
          (item) => item.id === this.$rootScope.stateParams.botId,
        );
        if (this.bot) {
          this.botStatusConnected = Boolean(this.bot.status.page);
          this.botStatusPaymentEnabled =
            this.botStatusConnected &&
            this.bot.status.payments_status === 'enabled';

          if (!this.paymentsAccess) {
            this.paymentsAccess = this.bot.payments_stripe;
          }
        }
      });

      this.UserService.show(!useCache).then((res) => {
        this.user = res;
        if (!this.paymentsAccess) {
          this.paymentsAccess = this.user.payments_access;
        }
      });
    }
  }

  getWarringTTText() {
    let out = '';
    if (this.botStatusConnected) {
      if (this.botStatusPaymentEnabled) {
        out = 'To enable payments, update permissions in the payment setup';
      } else {
        out = 'To enable payments, complete the payment setup.';
      }
    } else {
      out =
        'To enable payments, connect your Facebook Business Page and complete the payment setup.';
    }
    return out;
  }

  validateButton() {
    return (
      this.item.buttons &&
      this.item.buttons.length &&
      this.ButtonService.testError(this.item.buttons[0], 'block_id').length ===
        0
    );
  }

  totalCalc() {
    let tot = 0;
    if (
      this.item.buttons[0] &&
      this.item.buttons[0].payment_summary &&
      this.item.buttons[0].payment_summary.price_list
    ) {
      this.item.buttons[0].payment_summary.price_list.forEach((item) => {
        tot += Math.round(item.amount * 100);
      });
    }

    return this.CurrencyService.formatPrice(
      tot,
      this.item.buttons[0].payment_summary.currency,
    );
  }
}
