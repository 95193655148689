import template from './support.html';
import { SupportController as controller } from './controller';

export default () => ({
  template,
  controller,
  controllerAs: '$ctrl', // vm overided
  restrict: 'E',
  replace: true,
});
