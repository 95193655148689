import ng from 'angular';
import gql from 'graphql-tag';
import template from './template.html';
import controller from './controller';
import './index.less';

export default ng
  .module('app.components.billingDetailsComponent', [])
  .constant(
    'GQL_BILLING_DETAILS',
    gql`
      query GQL_BILLING_DETAILS($botId: String!) {
        bot(id: $botId) {
          id
          billingDetails {
            id
            company_name
            email
            tax_id
            street
            city
            state
            postcode
            country
          }
        }
      }
    `,
  )
  .constant(
    'GQL_BILLING_DETAILS_MUTATION',
    gql`
      mutation UPDATE_BILLING_DETAILS_MUTATION(
        $details: BillingDetailsInput!
        $botId: String!
      ) {
        updateBillingDetails(botId: $botId, details: $details) {
          id
        }
      }
    `,
  )
  .component('billingDetailsComponent', {
    template: () => template,
    controllerAs: 'vm',
    controller,
    bindings: {
      popupOnly: '<',
      modalVisible: '<',
      onShowModal: '&',
      onCloseModal: '&',
    },
  }).name;
