import $ from 'jquery';
import { PLUGIN_TYPE as JSON_PLUGIN_TYPE } from '../../modern-components/Plugins/JsonPlugin/JsonPluginConst';
import { PLUGIN_TYPE as FACEBOOK_ANALYTICS_PLUGIN_TYPE } from '../../modern-components/Plugins/FacebookAnalyticsPlugin/FacebookAnalyticsPluginConst';
import { PLUGIN_TYPE as HANDOVER_PLUGIN_TYPE } from '../../modern-components/Plugins/HandoverPlugin/HandoverPluginConst';
import { PLUGIN_TYPE as ABT_PLUGIN_TYPE } from '../../modern-components/Plugins/AbtPlugin/AbtPluginConst';
import { PLUGIN_TYPE as ABANDONED_CART_PLUGIN_TYPE } from '../../modern-components/Plugins/AbandonedCartPlugin/AbandonedCartPluginConst';

export default class PluginContainerController {
  constructor($scope, $rootScope, PluginCardService) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.PluginCardService = PluginCardService;
    this.JSON_PLUGIN_ID = JSON_PLUGIN_TYPE;
    this.FACEBOOK_ANALYTICS_PLUGIN_TYPE = FACEBOOK_ANALYTICS_PLUGIN_TYPE;
    this.HANDOVER_PLUGIN_TYPE = HANDOVER_PLUGIN_TYPE;
    this.ABT_PLUGIN_TYPE = ABT_PLUGIN_TYPE;
    this.ABANDONED_CART_PLUGIN_TYPE = ABANDONED_CART_PLUGIN_TYPE;

    this.firstSort = true;

    this.$sortable = {
      //  handle: '.ui-sortable-handle',
      //  revert: 200,
      distance: 6,
      tolerance: 'pointer',
      animation: 200,
      cursor: 'ns-resize',
      containment: 'parent',
      forceHelperSize: true,
      forcePlaceholderSize: true,
      'ui-floating': true,
      start: (e, ui) => {
        if (this.firstSort) {
          $(e.target).sortable('refreshPositions');
          this.firstSort = false;
        }
      },
      axis: 'y',
      stop: (e, ui) => {
        this.$scope.$broadcast('buttons-sort-stop');
      },
    };
  }

  $onInit() {
    this.addCardResize();
    this.scopeListener = this.$scope.$on('$buttonUpdate', () => {
      this.addCardResize();
    });
  }

  $onDestroy() {
    this.scopeListener();
  }

  // calc add card height by buttons qty of last card
  addCardResize() {
    if (this.plugin.config && this.plugin.config.gallery_cards) {
      const cardHeight = this.plugin.config.square_ratio ? 448 : 340;
      if (
        this.plugin.config.gallery_cards[
          this.plugin.config.gallery_cards.length - 1
        ].buttons.length === 1 &&
        this.plugin.config.gallery_cards[
          this.plugin.config.gallery_cards.length - 1
        ].buttons[0].payment_summary
      ) {
        this.height = cardHeight;
      } else {
        this.height =
          cardHeight +
          this.plugin.config.gallery_cards[
            this.plugin.config.gallery_cards.length - 1
          ].buttons.length *
            65;
        if (
          this.plugin.config.gallery_cards[
            this.plugin.config.gallery_cards.length - 1
          ].buttons.length === 3
        ) {
          this.height = this.height - 49;
        }
      }
    }
  }
}
