import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Argument, AnchorNode, createMarkupNodes } from './createMarkupNodes';
import { Anchor } from '../../../../../modern-ui/Links';

export type Error = {
  amount: number;
  code: string;
  type: string;
  args: Argument[];
  description: string;
};

type PluralizationCategory = 'one' | 'few' | 'many' | 'other';

function getPluralizationCategory(
  n: number,
  languageCode = 'en',
): PluralizationCategory {
  // http://www.unicode.org/cldr/charts/28/supplemental/language_plural_rules.html
  if (languageCode !== 'en') {
    throw new Error(`Unsupported language: ${languageCode}`);
  }
  return n === 1 ? 'one' : 'other';
}

const unknownErrorRe = /Unknown error\.?$/;
const errorTemplates = {
  none: () => [{ type: 'text', text: 'failed to send.' }],
  some: (error: Error) =>
    createMarkupNodes(
      `failed to send because ${error.description}`,
      error.args,
    ),
  unknown: () => [
    {
      type: 'text',
      text: 'failed to send because of an error that shouldn’t occur frequently. Report this issue to support if you have a lot of these errors.',
    },
  ],
};

function getDescriptionNodes(error: Error) {
  let nodes;
  if (!error.description) {
    nodes = errorTemplates.none();
  } else if (unknownErrorRe.test(error.description)) {
    nodes = errorTemplates.unknown();
  } else {
    nodes = errorTemplates.some(error);
  }
  return nodes;
}

export const ErrorDescription: React.FC<{ error: Error }> = ({ error }) => {
  const { t } = useSafeTranslation();
  const pluralizationCategory = getPluralizationCategory(error.amount, 'en');
  const label = pluralizationCategory === 'one' ? 'message' : 'messages';
  const nodes = getDescriptionNodes(error);
  return (
    <React.Fragment>
      <strong>{error.amount}</strong> <span>{label}</span>{' '}
      <span>
        {nodes.map((node, index) => {
          if (node.type === 'text') {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index}>
                {node.text} {/* special link for 24h window error */}
                {node.text.includes('24h') && (
                  <Anchor
                    intent="external"
                    href={t('common.helpDocLinks.message_tags')}
                    target="_blank"
                  >
                    Learn more
                  </Anchor>
                )}
              </span>
            );
          }
          if (node.type === 'hyperlink') {
            return (
              <Anchor
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                href={(node as AnchorNode).value}
                target="_blank"
                intent="external"
              >
                {node.text}
              </Anchor>
            );
          }
          return null;
        })}
      </span>
    </React.Fragment>
  );
};
