/**
 * This component is only needed temporarily because we're using
 * apollo components with render props.
 * When we switch to hooks, we can just
 * call `toaster.show()` in `useEffect` directly
 */
import React, { useEffect } from 'react';
import { toaster } from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2';

export const ErrorToast: React.FC<{ message: string }> = ({ message }) => {
  useEffect(
    () => {
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message,
        },
      });
    },
    [message],
  );

  return null;
};
