import template from './users-table-col-selector.html';
import controller from './controller';

export default {
  template: () => template,
  controllerAs: 'vm',
  controller,
  restrict: 'E',
  bindings: {
    cols: '<',
  },
};
