export default class MenuTreeItemController {
  constructor($scope, $timeout, MonetizationService, $rootScope) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.MonetizationService = MonetizationService;
    this.$rootScope = $rootScope;
  }

  $onInit() {
    this.$sortable = {
      handle: '.v-drag',
      distance: 6,
      tolerance: 'pointer',
      animation: 200,
      cursor: 'ns-resize',
      containment: 'parent',
      forceHelperSize: true,
      forcePlaceholderSize: true,
      'ui-floating': true,
      start: (e, ui) => {
        if (this.firstSort) {
          window.$(e.target).sortable('refreshPositions');
          this.firstSort = false;
        }
      },
      axis: 'y',
      stop: (e, ui) => {
        if (this.tI) {
          this.$timeout(() => {
            this.tI = null;
            this.$scope.$emit('$saveMenu');
          }, 10);
        } else {
          this.$scope.$emit('$saveMenu');
        }

        this.$scope.$emit('$editSubmenu', {
          index: null,
          level: Number(this.level) + 1,
        });
      },
    };

    this.$scope.$on('$editSubmenu', (event, data) => {
      if (data.level === Number(this.level)) {
        this.currentItem = data.index;
      }
    });
    this.$scope.$on('$resetEditSubmenu', (event, data) => {
      if (data.level === Number(this.level)) {
        this.currentItem = data.index;
      }
    });
  }

  back() {
    this.$scope.$emit('$editSubmenu', {
      index: null,
      level: Number(this.level),
    });
  }

  addItem() {
    this.item.items.push({
      title: '',
      new: true,
    });

    this.$scope.$emit('$saveMenu');
  }
}
