import { canEdit } from '../../../common/services/RoleService';
import { sendEvent } from '../../../utils/Analytics';

/* eslint-disable chatfuel/no-use-localStorage */
export default class ThirdPartyAnalyticsController {
  constructor(
    $scope,
    $rootScope,
    StoreService,
    ModalService,
    $timeout,
    BotService,
    PageService,
    UserService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.StoreService = StoreService;
    this.ModalService = ModalService;
    this.$timeout = $timeout;
    this.BotService = BotService;
    this.PageService = PageService;
    this.UserService = UserService;
    this.RoleService = { canEdit };

    this.BotService.yandexLogin().then((res) => {
      this.yandex_url = `${this.StoreService.getApiUrl()}${res}`;
    });
    this.PageService.cachedList().then((res) => {
      this.pages = res;
    });

    $scope.RoleService = $rootScope.RoleService;
  }

  $onInit() {
    const analyticsArray = [0, 1, 2];
    this.analyticsOrderList = this.shuffle(analyticsArray);

    if (this.bot && this.bot.id) {
      this.page = this.bot.status.page_info;
      this.botanalyticsConnected = !!(
        this.page &&
        this.page.analytics &&
        this.page.analytics.botanalytics &&
        this.page.analytics.botanalytics.app_id
      );
      this.dashbotConnected = !!(
        this.page &&
        this.page.analytics &&
        this.page.analytics.dashbot &&
        this.page.analytics.dashbot.app_id
      );
    }
    this.UserService.show().then((res) => {
      this.user = res;
    });
    if (
      window.localStorage.getItem('third-party-analytics') === 'expand' ||
      this.bot.yandex_app ||
      this.botanalyticsConnected ||
      this.dashbotConnected
    ) {
      if (window.localStorage.getItem('third-party-analytics') === 'collapse') {
        this.showAllAnalytics = false;
      } else {
        this.showAllAnalytics = true;
      }
    } else {
      this.showAllAnalytics = false;
    }
  }

  isPageAdmin() {
    return !!(this.pages || []).find((page) => page.id === this.page.id);
  }

  isThisUserConnected() {
    if (
      this.page &&
      this.page.analytics &&
      this.page.analytics.dashbot &&
      this.page.analytics.dashbot.additional_fields
    ) {
      return !!(
        (this.user &&
          this.user.id ===
            this.page.analytics.dashbot.additional_fields.connected_user) ||
        this.connectedUser
      );
    }
    return !!(this.user && this.user.id === this.connectedUser);
  }

  openDashbotAnalytics() {
    window.open(
      `https://reports.dashbot.io/dashboard/${
        this.dashbotId || this.page.analytics.dashbot.app_id
      }`,
      '_blank',
    );
  }

  openBotAnalytics() {
    window.open(
      `http://${window.CHATFUEL_CONFIG.BOT_ANALYTICS_HOST}/user/redirect-socialaccount/facebook/?page_id=${this.bot.status.page}&referrer=1`,
      '_blank',
    );
  }

  shuffle(array) {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  connectAnalytics() {
    sendEvent({
      category: 'third-party analytics',
      action: 'connect',
      label: 'botanalytics',
    });

    if (
      !this.analyticsAuthorizationPage ||
      this.analyticsAuthorizationPage.closed
    ) {
      this.pageId = this.bot.status.page;
      this.analyticsAuthorizationPage = window.open(
        `http://${window.CHATFUEL_CONFIG.BOT_ANALYTICS_HOST}/user/redirect-socialaccount/facebook/?page_id=${this.pageId}&referrer=1&process=initialize`,
        '_blank',
      );
      this.analyticsAuthorizationPage.focus();
      window.acceptAnalytics = (url) => {
        if (
          url &&
          url.indexOf('/acceptor/third_party_analytics/success') !== -1
        ) {
          window.focus();
          // eslint-disable-next-line prefer-destructuring
          this.analyticsId = /analytics_id=([A-Za-z0-9_-]+)/.exec(url)[1];
          this.PageService.connectTPAnalytics(this.page.id, 'botanalytics', {
            app_id: this.analyticsId,
          }).then(() => {
            this.botanalyticsConnected = true;
          });
        } else if (
          url &&
          url.indexOf('/acceptor/third_party_analytics/canceled') !== -1
        ) {
          window.focus();
          this.botanalyticsConnected = false;
        }
      };
    } else {
      this.analyticsAuthorizationPage.focus();
    }
  }

  connectDashbotAnalytics() {
    sendEvent({
      category: 'third-party analytics',
      action: 'connect',
      label: 'dashbot.io',
    });

    if (
      !this.dashbotAuthorizationPage ||
      this.dashbotAuthorizationPage.closed
    ) {
      this.pageId = this.bot.status.page;
      this.dashbotAuthorizationPage = window.open(
        `https://reports.dashbot.io/partner/chatfuel/?page_id=${this.pageId}`,
        '_blank',
      );
      this.dashbotAuthorizationPage.focus();
      window.acceptAnalytics = (url) => {
        if (
          url &&
          url.indexOf('/acceptor/third_party_analytics/success') !== -1
        ) {
          window.focus();
          // eslint-disable-next-line prefer-destructuring
          this.dashbotId = /analytics_id=([A-Za-z0-9_-]+)/.exec(url)[1];
          this.PageService.connectTPAnalytics(this.page.id, 'dashbot', {
            app_id: this.dashbotId,
            additional_fields: { connected_user: this.user.id },
          }).then(() => {
            this.connectedUser = this.user.id;
            this.dashbotConnected = true;
          });
        } else if (
          url &&
          url.indexOf('/acceptor/third_party_analytics/canceled') !== -1
        ) {
          window.focus();
          this.dashbotConnected = false;
        }
      };
    } else {
      this.dashbotAuthorizationPage.focus();
    }
  }

  disconnectAnalytics(type) {
    this.ModalService.disconnectAnalytics(this.page, type).then((res) => {
      if (res) {
        if (type === 'dashbot') {
          this.dashbotConnected = false;
        } else {
          this.botanalyticsConnected = false;
        }
      }
    });
  }

  switchAnalytics() {
    sendEvent({
      category: 'analyze',
      action: 'show analytics tools',
      label: this.showAllAnalytics ? 'hide' : 'show',
    });

    this.showAllAnalytics = !this.showAllAnalytics;
    try {
      if (this.showAllAnalytics) {
        window.localStorage.setItem('third-party-analytics', 'expand');
      } else {
        window.localStorage.setItem('third-party-analytics', 'collapse');
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
}
