export default class PluginInsertController {
  constructor($rootScope, $element, $timeout, ModalService) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$element = $element;
    this.$timeout = $timeout;
    this.ModalService = ModalService;
  }

  add() {
    this.showModal = true;
    this.ModalService.pluginAdd(this.$element[0]).then((res) => {
      this.showModal = false;

      if (res && res.type) {
        this.$timeout(() => {
          this.$rootScope.$emit('plugin:add.adv', {
            type: res.type,
            index: this.index,
          });
        });
      }
    });
  }
}
