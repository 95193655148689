import controller from './controller';
import template from './template.html';

export default () => ({
  template,
  controller,
  controllerAs: 'vm',
  restrict: 'E',
  replace: true,
  bindToController: true,
  scope: {
    userFilter: '=',
  },
});
