import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { addAttributeToGQLCaches } from '../../../utils/AttributesUtils/AttributesUtils';
import { Platform } from '../../../../@types/globalTypes';

const MAX_FORM_MESSAGE_LENGTH = 640;

export default class CardFormController extends CardPluginBaseController {
  constructor(
    $scope,
    $rootScope,
    $interpolate,
    ModalService,
    BotService,
    BlockService,
    $sce,
    $timeout,
    $element,
    StoreService,
    PluginCardService,
  ) {
    'ngInject';

    super(
      $scope,
      $rootScope,
      $interpolate,
      ModalService,
      BotService,
      BlockService,
      $sce,
      $timeout,
      $element,
      StoreService,
      PluginCardService,
    );

    this.listener = $scope.$on('$popupValueChange', (event, res) => {
      $timeout(() => {
        if (res.cardId === this.item.id) {
          this.save();
        }
      });
    });

    this.userInputType = {
      text: 'None',
      phone: 'Phone',
      email: 'Email',
      number: 'Number',
    };
  }

  $onInit() {
    if (!this.item.config.form_properties.length) {
      this.addFormProperty();
    }
    if (!this.item.id) {
      this.save();
      this.setFocus();
    }
    if (!this.item.validation_details) {
      this.item.validation_details = {};
    }
    if (this.item.is_valid === false) {
      this.data = this.item.validation_details || {};
      this.data.card_id = this.item.id;
      this.$rootScope.$broadcast('$pluginSaveResultError', { data: this.data });
    } else {
      this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
    }

    this.docLink =
      'https://docs.chatfuel.com/plugins/collect-user-data/save-user-input';

    this.$scope.$evalAsync(() => {
      this.textarea = this.$element[0].querySelector('textarea');
      this.updateDiscount();
    });

    this.MAX_FORM_MESSAGE_LENGTH = MAX_FORM_MESSAGE_LENGTH;
  }

  updateDiscount() {
    this.leftSymbols = MAX_FORM_MESSAGE_LENGTH;
    if (this.textarea) {
      this.leftSymbols = MAX_FORM_MESSAGE_LENGTH - this.textarea.value.length;
    }
  }

  $onDestroy() {
    this.listener();
  }

  removeFormProperty(index) {
    this.item.config.form_properties.splice(index, 1);
    if (this.item.config.form_properties.length < 1) {
      this.addFormProperty();
    }
    this.save();
  }

  addFormProperty() {
    this.item.config.form_properties.push({
      caption: '',
      title: '',
      type: 'text',
    });
    this.setFocus();
  }

  updateFormProperty(attributeName) {
    this.save();
    addAttributeToGQLCaches(
      this.$rootScope.stateParams.botId,
      attributeName,
      Platform.facebook,
    );
  }

  setFocus() {
    setTimeout(() => {
      const inputs = this.$element[0].querySelectorAll('.input-caption');
      if (inputs.length) {
        inputs[inputs.length - 1].focus();
      }
    }, 100);
  }
}
