import { IDirectiveFactory, Injectable, IParseService, IScope } from 'angular';

const isStartScroll = (status: boolean, oldStatus: boolean) =>
  status !== oldStatus && status;

const SCROLL_MARGIN = 30;

export default (($parse: IParseService) => {
  'ngInject';

  return (
    scope: IScope,
    [element]: Element[],
    attrs: {
      scrollToThis: string;
      scrollToThisRootElSelector: string;
    },
  ) => {
    const parseInScope = (attrSting: string) => $parse(attrSting)(scope);
    let rootEl: HTMLDivElement | null = null;
    scope.$watch(
      () => parseInScope(attrs.scrollToThis),
      (status: boolean, oldStatus: boolean) => {
        if (isStartScroll(status, oldStatus)) {
          setTimeout(() => {
            if (rootEl) {
              const childRect = element.getBoundingClientRect();
              const parentRect = rootEl.getBoundingClientRect();
              const isOutBottom =
                parentRect.bottom - childRect.bottom < SCROLL_MARGIN;
              const isOutTop = childRect.top - parentRect.top < SCROLL_MARGIN;
              if (isOutBottom) {
                rootEl.scrollTop =
                  childRect.bottom +
                  rootEl.scrollTop -
                  parentRect.bottom +
                  SCROLL_MARGIN;
              }
              if (isOutTop) {
                rootEl.scrollTop =
                  childRect.top +
                  rootEl.scrollTop -
                  parentRect.top -
                  SCROLL_MARGIN;
              }
            }
          }, 120);
        }
      },
    );
    scope.$watch(
      () => attrs.scrollToThisRootElSelector,
      (rootElSelector: string) => {
        rootEl = document.querySelector(rootElSelector);
      },
    );
  };
}) as Injectable<IDirectiveFactory<IScope>>;
