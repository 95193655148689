import ng from 'angular';
import template from './users-attribute-edit.html';
import controller from './controller';
import './user-attr-edit.less';

export default ng
  .module('app.components.users.userAttributeEdit', [])
  .component('userAttributeEdit', {
    template: () => template,
    controllerAs: 'vm',
    controller,
    bindings: {
      /**
       *  boolean
       */
      loading: '<',

      /**
       *  number
       */
      numberOfUsers: '<',

      /**
       *   [{
       *      profile_pic: string,
       *      first_name: string,
       *      last_name: string,
       *  }]
       */
      usersToPreview: '<',

      /**
       *  [{ name: string, value:string, type?:'custom'|'system'}]
       */
      attributes: '<',

      /**
       * {attributes: [ {name:string, values:[string], type?:'custom'|'system'} ]
       */
      onChange: '<',

      /**
       *  {$event: HTML click event}
       */
      onSave: '&',

      /**
       *  {$event: HTML click event}
       */
      onCancel: '&',

      /**
       *  [{string}];
       */
      attributesSuggestsName: '<',
    },
  }).name;
