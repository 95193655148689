import template from './plugin-container.template.html';
import controller from './controller';

export default () => ({
  template,
  controller,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  scope: {
    bot: '=',
    plugin: '=',
    block: '=',
    index: '=',
    loading: '=',
    sort: '=',
  },
});
