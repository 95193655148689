import CardTextController from '../card-text-plugin/controller';

export default class CardCommunicationMessageController extends CardTextController {
  init() {
    this.discount = 80;
  }

  $onInit() {
    this.$scope.$evalAsync(() => {
      this.textarea = this.$element[0].querySelector('textarea');
      this.updateDiscount();
    });
  }

  save() {
    this.CardCommunicationController.saveCommunication();
  }
}
