import React from 'react';
import gql from 'graphql-tag';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ApolloProvider, Mutation, Query } from '@apollo/react-components';
import { MultiComboboxAction } from '@ui/MultiComboboxAction';
import { Button, ButtonIntent } from '../../../modern-ui/_deprecated/Button';
import { ReactComponent as DropdownIcon } from '../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { toaster } from '@services/MessageService';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { sendEvent } from '@utils/Analytics';
import { redirect } from '@utils/UrlUtils';
import { FieldsQuery, FieldsQueryVariables } from './@types/FieldsQuery';
import {
  GenerateUsersDocument,
  GenerateUsersDocumentVariables,
} from './@types/GenerateUsersDocument';
import { Router } from 'react-router-dom';
import { globalHistory } from '@utils/Routing';
import client from '@common/services/ApolloService';

function notifyAboutError() {
  toaster.show({
    type: ServiceMessageType.error,
    payload: {
      message: 'An error occured while generating document',
    },
  });
}

const FIELDS_QUERY = gql`
  query FieldsQuery($botId: String!, $platform: Platform = null) {
    bot(id: $botId) {
      id
      variableSuggest(suggestType: omnibox, platform: $platform, allPlatforms: false) {
        id
        type
        name
      }
    }
  }
`;

const GENERATE_USERS_DOCUMENT = gql`
  mutation GenerateUsersDocument(
    $botId: String!
    $params: ExportParams
    $filter: String
  ) {
    generateUsersExportDocument(
      botId: $botId
      params: $params
      filter: $filter
    ) {
      generatedId
      downloadUrl
    }
  }
`;

const EMPTY_ARRAY: any[] = [];

interface SortAndFilterParams {
  desc: boolean;
  sortBy: string;
  filter: any;
}

interface Props {
  botId: string;
  segment: any;
  affectedUsersCount: number;
  getCurrentSortAndFilterParams: () => SortAndFilterParams;
}

const ExportUsers: React.FC<Props> = ({
  botId,
  segment,
  affectedUsersCount,
  getCurrentSortAndFilterParams,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Query<FieldsQuery, FieldsQueryVariables>
      query={FIELDS_QUERY}
      variables={{ botId }}
      skip={!botId}
    >
      {({ data, error, loading }) => {
        if (error) {
          return <span>An error happened.</span>;
        }
        const hasData = Boolean(data && data.bot.variableSuggest);
        return (
          <Mutation<GenerateUsersDocument, GenerateUsersDocumentVariables>
            mutation={GENERATE_USERS_DOCUMENT}
            onCompleted={(data) => {
              if (!data) {
                notifyAboutError();
                throw new Error('Failed to receive download url');
              }
              const { downloadUrl } = data.generateUsersExportDocument;
              redirect(downloadUrl);
            }}
            onError={notifyAboutError}
          >
            {(generateUsersDocument, { loading: generating }) => (
              <MultiComboboxAction
                disabled={generating || loading}
                renderToggleButton={({ ref, onClick }) => (
                  <Button
                    data-testid="users__export-users-dropdown-button"
                    innerRef={ref}
                    intent={ButtonIntent.secondary}
                    onClick={onClick}
                    renderIconRight={() => <DropdownIcon />}
                  >
                    {t('components.users.exportUsers')}
                  </Button>
                )}
                options={
                  hasData
                    ? data!.bot.variableSuggest.map((option) =>
                        Object.assign(option, { displayName: option.name }),
                      )
                    : EMPTY_ARRAY
                }
                onSubmit={(fieldValues) => {
                  const { desc, sortBy, filter } =
                    getCurrentSortAndFilterParams();
                  const fields = fieldValues.map((fieldValue) => {
                    const [name, type] = fieldValue.split(/_(?=[^_]+$)/); // split by last "_"
                    return { name, type };
                  });

                  generateUsersDocument({
                    variables: {
                      botId,
                      filter: JSON.stringify(filter),
                      params: { desc, sortBy, fields },
                    },
                  });
                  sendEvent({
                    category: 'people tab',
                    action: 'download',
                    label: 'export users',
                    propertyBag: {
                      segmentId: segment && segment.id,
                      'list of attributes': fieldValues,
                      'number of users': affectedUsersCount,
                    },
                  });
                }}
                onSelectAll={() =>
                  sendEvent({
                    category: 'people tab',
                    action: 'select all',
                    label: 'export users',
                  })
                }
                onClearAll={() =>
                  sendEvent({
                    category: 'people tab',
                    action: 'clear all',
                    label: 'export users',
                  })
                }
              />
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export const ExportUsersWrapper: React.FC<Props> = (props) => (
  <ApolloProvider client={client}>
    <Router history={globalHistory}>
      <ExportUsers {...props} />
    </Router>
  </ApolloProvider>
);
