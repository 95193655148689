import './assets/less/users-table.less';
import './assets/less/famfamfam-flags.less';
import './assets/less/placeholder-block.less';

import ng from 'angular';
import Component from './component.js';
import usersTableColSelector from './users-table-col-selector';
import usersTableAttrPopup from './users-table-attr-popup';
import userEditAttributes from './users-attribute-edit';

export default ng
  .module('app.components.users.usersTable', [
    usersTableColSelector,
    usersTableAttrPopup,
    userEditAttributes,
  ])
  .component('usersTable', Component).name;
