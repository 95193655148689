import { sendEvent } from '../../utils/Analytics';

export default class LogoController {
  constructor(StoreService) {
    'ngInject';

    this.StoreService = StoreService;
    this.rootLink = StoreService.getMainUrl();
  }

  sendGoOutEvent() {
    sendEvent({
      category: 'header logo to landing',
      action: 'click',
    });
  }
}
