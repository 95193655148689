import { path } from 'ramda';

export default class CardTextController {
  constructor($scope, $element, $timeout, ValidationService, $rootScope) {
    'ngInject';

    this.ValidationService = ValidationService;
    this.$timeout = $timeout;
    this.$element = $element;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$errors = {};

    $scope.$on('$resetValidate', () => {
      this.$errors.show = false;
    });

    $scope.$on('$buttonUpdate', () => {
      this.validate();
      if ('resizeCard' in $scope) {
        $scope.resizeCard();
      }
    });
    this.init();
  }

  init() {
    this.type = 'text';
    this.field = 'text';
    this.$errors = { message: 'Text is Empty' };
  }

  $onInit() {
    this.ValidationService.get().then((data) => {
      this.validation = data;
      const discount = path([this.type, this.field, 'max'], this.validation);
      if (discount !== undefined) {
        this.setDiscount(this.validation[this.type][this.field].max);
        this.updateDiscount();
      }
    });

    this.$scope.$on('$item:remove', () => {
      this.validate();
    });

    if (
      this.PluginController &&
      this.PluginController.item.plugin_id === 'text'
    ) {
      if (!this.PluginController.item.id) {
        this.save();
        this.$timeout(() => {
          this.$element.find('textarea')[0].focus();
        });
      }
      if (this.PluginController.item.is_valid === false) {
        if (this.PluginController.item.validation_details) {
          this.PluginController.item.validation_details = {};
        }

        this.data = this.PluginController.item.validation_details || {};
        this.data.card_id = this.PluginController.item.id;
        this.$rootScope.$broadcast('$pluginSaveResultError', {
          data: this.data,
        });
        this.validate();
      } else {
        this.PluginController.item.is_valid = true;
        this.$rootScope.$broadcast(
          '$pluginSaveResultOk',
          this.PluginController.item,
        );
        this.PluginController.item.is_valid = true;
      }
    }

    this.$scope.$evalAsync(() => {
      this.textarea = this.$element[0].querySelector('textarea');
    });
  }

  setDiscount(max) {
    this.discount = max;
  }

  updateDiscount(e) {
    this.leftSymbols = this.discount;
    if (this.textarea) {
      this.leftSymbols = this.discount - this.textarea.value.length;
    }
  }

  validate() {
    if (typeof this.item === 'string' && this.item.trim() === '') {
      this.item = '';
    }
    const status = Boolean(this.item);
    if (this.$errors) {
      this.$errors.show = !status;
    }
    return status;
  }

  save() {
    if (!this.validate()) {
      // return;
    }

    this.PluginController.save(this.item, this.index);
  }

  onFocusHandler = () => {
    this.$errors.show = false;
    this.updateDiscount();
    this.focused = true;
  };

  onBlurHandler = () => {
    this.focused = false;
    this.save();
  };
}
