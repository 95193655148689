import ng from 'angular';
import datepickerPopup from 'angular-ui-bootstrap/src/datepickerPopup';
import datepicker from 'angular-ui-bootstrap/src/datepicker';
import moment from 'moment';
import component from './component';

import dayPickerTemplateUrl from './daypicker.tpl';

export default ng
  .module('app.broadcast.schedule', [datepicker, datepickerPopup])
  .directive('broadcastSchedule', component)
  .filter('filterFormattedDate', () => (date) =>
    date ? moment.utc(date).format('MMM Do') : '',
  )
  .filter('filterRBlockName', () => (text) => text.split('|')[0])
  .filter('filterBCTimeConvertor', () => (text) => {
    const time = /\s[0-9]{1,2}:[0-9]{2}\s[ap]m$/.exec(text);
    if (time) {
      const convertTo24Hour = (tl) => {
        const hours = parseInt(tl.substr(0, 2)); // eslint-disable-line radix
        if (tl.indexOf('am') !== -1 && hours === 12) {
          tl = tl.replace('12', '0');
        }
        if (tl.indexOf('pm') !== -1 && hours < 12) {
          tl = tl.replace(hours, hours + 12);
        }
        return tl.replace(/(am|pm)/, '');
      };
      text = text.replace(time[0], ` ${convertTo24Hour(time[0].trim())}`);
    }
    return text;
  })
  .config(($provide) => {
    'ngInject';

    $provide.decorator('uibDaypickerDirective', ($delegate) => {
      const directive = $delegate[0];
      directive.templateUrl = dayPickerTemplateUrl;

      return $delegate;
    });
  }).name;
