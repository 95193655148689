import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router-dom';

import { sendEvent } from '@utils/Analytics';
import { useAdminFeatures } from '@utils/Data/Admin';
import { paymentIntervalToEnum } from '@utils/Payment/paymentInterval';
import { isWhiteLabelAgencyUser } from '@utils/WhiteLabelUtils';
import { useLocaleSetter } from '@utils/Data/Admin/Locale/useLocaleSetter';
import { useIsFirstSessionTab } from '@utils/Routing';

import { NestedMenuNodeDisplayMode } from '@ui/NestedMenu/types';

import { DashboardTokenAPIDialog } from '@components/DashboardTokenAPI/DashboardTokenAPIDialog';
import { PaymentsHistoryModal } from '@components/Payments/PaymentHistory/PaymentsHistoryModal';
import { PremiumPromoModal } from '@components/Premium/PremiumPromo/PremiumPromoModal';
import { PaymentStatusModal } from '@components/Premium/Payments/PaymentStatus/PaymentStatusModal';
import { PaymentFlow } from '@components/Premium/Payments/PaymentFlow';
import { UpdatePaymentMethodFlow } from '@components/Premium/Payments/UpdatePaymentMethodFlow';
import { BillingDetailsModal } from '@components/Premium/BillingDetails';
import { SubscriptionStatus } from '@components/Premium/Payments/PaymentStatus/subscriptionStatus';
import {
  OpenableMenu,
  PopperModifiers,
  UserNavigationItem,
} from './UserNavigationItem';
import { ErrorToast } from './ErrorToast';

import { NavigationAction } from './types';
import { ADMIN_QUERY } from './queries';

import {
  AdminQuery,
  AdminQuery_me as User,
  AdminQuery_me_premiumAccount as PremiumAccount,
} from './@types/AdminQuery';
import { TabLinkSkeleton } from '@components/LeftNavigationPanel/components/TabLinkSkeleton/TabLinkSkeleton';
import { isSyntheticLogin } from '../../../utils/syntheticLogin';
import { Type } from '../../../modern-ui/Type';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { sendMessageToWebView } from '@utils/sendMessageToWebView';
import { buildLanguageItem } from './buildLanguageItem';

function compact(groups: any[]) {
  return groups.filter((item) => {
    if (!item) {
      return false;
    }
    if (Array.isArray(item)) {
      return item.length !== 0;
    }
    if ('children' in item) {
      return item.children.length !== 0;
    }
    return true;
  });
}

function isPremiumUser(user: User) {
  return (
    Boolean(user.premiumAccount) &&
    user.premiumAccount!.subscription_info != null
  );
}

function hasPremiumPlanPending(user: User) {
  return (
    Boolean(user.premiumAccount) &&
    user.premiumAccount!.subscription_info == null &&
    !user.workspacesAvailable
  );
}

function hasSubscriptionIssues(premiumAccount: PremiumAccount | null) {
  if (!premiumAccount || !premiumAccount.subscription_info) {
    return false;
  }
  const { status } = premiumAccount.subscription_info;
  return status !== null && status !== SubscriptionStatus.active;
}

interface MenuOptionsGetterProps {
  user: User;
  language: string;
  hasPremiumFeature: boolean;
  hasLanguageSelector: boolean;
  isFirstSessionTab: boolean;
  isLiveChatWebView: boolean;
  t: TFunction;
}

const getMenuOptionsForUser = ({
  user,
  language,
  hasPremiumFeature,
  hasLanguageSelector,
  isFirstSessionTab,
  isLiveChatWebView,
  t,
}: MenuOptionsGetterProps) => {
  const isWhiteLabelUser = isWhiteLabelAgencyUser(user);

  const isPremium = user.premium_account_id && user.premiumAccount;
  const hasWorkspace = user.workspacesAvailable;

  return compact([
    (user.premiumAccount && user.premiumAccount.old_premium) ||
    isWhiteLabelAgencyUser(user) ||
    hasPremiumFeature ||
    isLiveChatWebView
      ? null
      : isPremium && !hasWorkspace
      ? {
          name: 'Premium',
          children: compact([
            hasPremiumPlanPending(user)
              ? {
                  id: NavigationAction.premiumPayment,
                  title: t('common.User.UserNavigationItem.premiumSignUp'),
                  icon: 'notification',
                  nestingLevel: 1,
                }
              : {
                  id: NavigationAction.premiumStatus,
                  title: t('common.User.UserNavigationItem.viewStatus'),
                  nestingLevel: 1,
                  icon: hasSubscriptionIssues(user.premiumAccount)
                    ? 'notification'
                    : undefined,
                },
            !hasPremiumPlanPending(user)
              ? {
                  id: NavigationAction.updatePaymentMethod,
                  title: t('common.User.UserNavigationItem.updatePayment'),
                  nestingLevel: 1,
                }
              : null,
            {
              id: NavigationAction.billingDetails,
              title: t('common.User.UserNavigationItem.billingDetails'),
              nestingLevel: 1,
            },
          ]),
        }
      : null,
    {
      name: null,
      children: compact([
        isWhiteLabelUser || isFirstSessionTab || isLiveChatWebView
          ? null
          : {
              id: NavigationAction.paymentsHistory,
              title: t('common.User.UserNavigationItem.proPayments'),
              needTermsAccepted: true,
            },
      ]),
    },
    {
      name: null,
      children: compact([
        isWhiteLabelUser || isFirstSessionTab || isLiveChatWebView
          ? null
          : {
              id: NavigationAction.authorizationToken,
              title: t('common.User.UserNavigationItem.apiToken'),
            },
        hasLanguageSelector
          ? {
              id: NavigationAction.languageSelector,
              title: t('common.User.UserNavigationItem.language'),
              displayMode: NestedMenuNodeDisplayMode.nested,
              children: [
                buildLanguageItem({
                  language: NavigationAction.en,
                  title: 'English',
                  selectedLanguage: language,
                }),
                buildLanguageItem({
                  language: NavigationAction.es,
                  title: 'Español',
                  selectedLanguage: language,
                }),
                buildLanguageItem({
                  language: NavigationAction.pt,
                  title: 'Português',
                  selectedLanguage: language,
                }),
              ],
            }
          : null,
      ]),
    },
    {
      name: null,
      children: [
        {
          id: NavigationAction.logout,
          title: t('common.User.UserNavigationItem.logOut'),
        },
      ],
    },
  ]);
};

interface ChildrenProps {
  user: {
    id: string;
    name: string | null;
    picture: string | null;
    email: string | null;
    terms_accepted?: string | null;
  };
  small: boolean;
  hasNotification?: boolean;
  isPremium: boolean;
}

interface UserNavigationItemWithDataProps
  extends OpenableMenu,
    PopperModifiers {
  small: boolean;
  children: (props: ChildrenProps) => React.ReactNode;
}

export const UserNavigationItemWithData: React.FC<UserNavigationItemWithDataProps> =
  ({ small, children, ...props }) => {
    const history = useHistory();
    const { adminFeatures } = useAdminFeatures();
    const { loading, error, data } = useQuery<AdminQuery>(ADMIN_QUERY);
    const { t, i18n } = useSafeTranslation();
    const [openedDialog, setOpenedDialog] = useState<null | NavigationAction>(
      null,
    );
    const setLocale = useLocaleSetter();
    const isFirstSessionTab = useIsFirstSessionTab();
    const { isLiveChatWebView } = useIsLiveChatWebView();

    if (error) {
      return (
        <ErrorToast
          message={t('common.User.UserNavigationItem.userLoadingError')}
        />
      );
    }

    if (loading || !data || !('me' in data)) {
      return <TabLinkSkeleton small={small} />;
    }

    const { me } = data;

    const hasLanguageSelector = true;

    const menuOptions = getMenuOptionsForUser({
      user: me,
      language: i18n.language,
      hasPremiumFeature: !!adminFeatures?.premium,
      hasLanguageSelector,
      isFirstSessionTab,
      isLiveChatWebView,
      t,
    });
    const hasPermissionsTrouble =
      isSyntheticLogin() && !me.fbPermissions?.hasMinimalPermissions;
    return (
      <>
        {hasPermissionsTrouble ? (
          <Type size="11px" color="white" as="div" align="center">
            [no FB permissions]
          </Type>
        ) : null}
        <UserNavigationItem
          {...props}
          setOpen={() => {
            if (!openedDialog) {
              props.setOpen(false);
            }
          }}
          menuOptions={menuOptions}
          onSelect={(option) => {
            sendEvent({
              category: 'header dropdown profile',
              action: 'click',
              label: option.label ?? option.title,
            });

            switch (option.id) {
              case NavigationAction.authorizationToken:
              case NavigationAction.paymentsHistory:
              case NavigationAction.premiumPromo:
              case NavigationAction.premiumPayment:
              case NavigationAction.premiumStatus:
              case NavigationAction.updatePaymentMethod:
              case NavigationAction.billingDetails:
                setOpenedDialog(option.id);
                return;

              case NavigationAction.logout:
                sendMessageToWebView({ type: 'logOut' });
                history.push('/logout');
                break;

              case NavigationAction.en:
              case NavigationAction.es:
              case NavigationAction.pt:
                setLocale(option.id);
                break;
              default:
                break;
            }
          }}
        >
          {children({
            user: me,
            small,
            isPremium: !isWhiteLabelAgencyUser(me) && isPremiumUser(me),
            hasNotification:
              !(me.premiumAccount && me.premiumAccount.old_premium) &&
              !isWhiteLabelAgencyUser(me) &&
              (hasPremiumPlanPending(me) ||
                hasSubscriptionIssues(me.premiumAccount)),
          })}
        </UserNavigationItem>
        {openedDialog === NavigationAction.authorizationToken && (
          <DashboardTokenAPIDialog
            onRequestClose={() => setOpenedDialog(null)}
          />
        )}
        {openedDialog === NavigationAction.premiumPromo && (
          <PremiumPromoModal onDismiss={() => setOpenedDialog(null)} />
        )}
        {me.premiumAccount &&
          openedDialog === NavigationAction.premiumPayment && (
            <PaymentFlow
              amount={me.premiumAccount.plan_info.amount}
              currency={me.premiumAccount.plan_info.currency}
              intervalName={paymentIntervalToEnum(
                me.premiumAccount.plan_info.interval,
              )}
              planName={me.premiumAccount.product_info.name}
              onDismiss={() => setOpenedDialog(null)}
            />
          )}
        {openedDialog === NavigationAction.updatePaymentMethod && (
          <UpdatePaymentMethodFlow onDismiss={() => setOpenedDialog(null)} />
        )}
        {me.premiumAccount &&
          openedDialog === NavigationAction.premiumStatus && (
            <PaymentStatusModal
              onDismiss={() => setOpenedDialog(null)}
              onUpdateCard={() =>
                setOpenedDialog(NavigationAction.updatePaymentMethod)
              }
              planName={me.premiumAccount.product_info.name}
              amount={me.premiumAccount.plan_info.amount}
              currency={me.premiumAccount.plan_info.currency}
              intervalName={paymentIntervalToEnum(
                me.premiumAccount.plan_info.interval,
              )}
              subscriptionInfo={me.premiumAccount.subscription_info}
            />
          )}
        {openedDialog === NavigationAction.paymentsHistory && (
          <PaymentsHistoryModal
            onRequestClose={() => setOpenedDialog(null)}
            onRequestUpgradeToPro={() => {
              // TODO:
              // What should we do here?
              // eslint-disable-next-line no-console
              console.log('requested to redirect to pro');
            }}
          />
        )}
        {openedDialog === NavigationAction.billingDetails && (
          <BillingDetailsModal onRequestClose={() => setOpenedDialog(null)} />
        )}
      </>
    );
  };
