import controller from './controller';

export default () => ({
  controllerAs: '$sysAttr',
  controller,
  bindToController: true,
  restrict: 'A',
  scope: {
    systemAttributeBind: '=',
  },
});
