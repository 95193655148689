import ApolloService from '../../common/services/ApolloService';
import countries from './countries.json';

/**
 * update billing info
 * created as single component;
 * to not show progress on billing info loading - we start to loading on mouser enter event insted of ng click;
 */
export default class BillingDetailsController {
  /**
   *
   * @param {*} GQL_BILLING_DETAILS_MUTATION -
   * @param {*} GQL_BILLING_DETAILS -
   * @param {*} $rootScope -
   */
  constructor(GQL_BILLING_DETAILS_MUTATION, GQL_BILLING_DETAILS, $rootScope) {
    'ngInject';

    this.countries = countries;
    this.ApolloService = ApolloService;
    this.GQL_BILLING_DETAILS_MUTATION = GQL_BILLING_DETAILS_MUTATION;
    this.GQL_BILLING_DETAILS = GQL_BILLING_DETAILS;
    this.botId = $rootScope.stateParams.botId;
    // we need copy becouse we have cancel in interface;
    this.billingDetails = {}; // copy of billing details;
    // observer to track if there any subsciption;
    this.observer = null;
    this.observable = ApolloService.watchQuery({
      query: GQL_BILLING_DETAILS,
      notifyOnNetworkStatusChange: true,
      variables: {
        botId: this.botId,
      },
    });
  }

  $onInit() {
    if (this.popupOnly) {
      this.loadBillingDetails();
    }
  }

  /**
   * open billing detailes modal; need to prevetn default due to event is on link;
   * @param {HTMLEvent} e - <a/> click
   */
  showBillingDetails(e) {
    e.preventDefault();
    this.onShowModal();
  }

  /**
   * close modal;
   */
  close() {
    this.onCloseModal();
  }

  /**
   * subscribe and load billing details for bot
   * only if there is no subscription yet;
   */
  loadBillingDetails() {
    if (!this.observer) {
      this.observer = this.observable.subscribe(
        this.setBillingDetails.bind(this),
      );
    }
  }

  /**
   * setter for billing details (abstract copying logic of billigs details;
   * @param {GQL_BILLING_DETAILS} gqlData - GQL query for GQL_BILLING_DETAILS;
   */
  setBillingDetails(gqlData) {
    this.loading = gqlData.loading;
    if (this.loading) {
      // currently there is a bug in GQL Apollo it is not reporting loading state;
      // but when it will be fixed - you will get empty data and there will be throw
      // see https://github.com/apollographql/react-apollo/issues/1314
      return;
    }
    const {
      data: {
        bot: { billingDetails },
      },
    } = gqlData;
    this.billingDetails = { ...billingDetails };
    delete this.billingDetails.__typename;
    delete this.billingDetails.id;
  }

  /**
   * setters for billingDetails fields;
   * @param {string} name - property name to set
   * @param {string} value - value to set to name
   */
  setProperty(name, value) {
    this.billingDetails[name] = value;
  }

  /**
   * event handler on close;
   * @param {HTMLClickEvent} e -
   */
  onCancel(e) {
    this.close();
    this.resetBillingDetails();
  }

  /**
   * restore billigng details to last known server state;
   */
  resetBillingDetails() {
    const currentResult = this.observable && this.observable.currentResult();
    if (currentResult) {
      this.setBillingDetails(currentResult);
    }
  }

  /**
   * save current billing details to server;
   */
  onUpdate() {
    this.close();
    this.ApolloService.mutate({
      mutation: this.GQL_BILLING_DETAILS_MUTATION,
      variables: {
        botId: this.botId,
        details: this.billingDetails,
      },
      optimisticResponse: {
        updateBillingDetails: {
          id: this.botId,
          __typename: 'BillingDetails',
          ...this.billingDetails,
        },
      },
    });
  }

  /**
   * clean up subscriptions;
   */
  $onDestroy() {
    if (this.observer) {
      this.observer.unsubscribe();
    }
  }
}
