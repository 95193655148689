import { log } from 'cf-common/src/logger';

export default class RefLinkController {
  constructor($scope, $timeout, $rootScope, ModalService) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;

    this.ModalService = ModalService;
  }

  $onInit() {}

  encodeLink() {
    return `https://m.me/${this.page}?ref=${encodeURIComponent(
      this.block.referral,
    )}`;
  }

  showBlockLink() {
    this.onLinkLabelClick();
  }

  copyLink() {
    document.querySelector('.input-to-copy-link').select();
    try {
      document.execCommand('copy');
      this.copy = true;
      this.clearSelection();
    } catch (error) {
      log.verbose({ error });
    }
  }

  clearSelection() {
    if (document.selection) {
      document.selection.empty();
    } else if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }
  }

  closeTooltip() {
    this.$timeout(() => {
      this.copy = false;
    }, 500);
  }

  openPage() {
    window.open(
      `https://m.me/${this.page}?ref=${encodeURIComponent(
        this.block.referral,
      )}`,
      '_blank',
    );
  }
}
