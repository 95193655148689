export class SupportController {
  constructor($scope) {
    'ngInject';

    $scope.$on('$angularClick', () => {
      $scope.$evalAsync(() => {
        this.supportOpened = false;
      });
    });
  }
}
