import React, { useState, useEffect } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import gql from 'graphql-tag';
import { pathOr } from 'ramda';
import nanoid from 'nanoid';
import copy from 'clipboard-copy';
import { Query } from '@apollo/react-components';
import { getAdminFeatures } from '@utils/Data/Admin';
import { removeTypename } from '@utils/GQL/utils';
import { ServiceMessageType, toaster } from '@services/MessageService';
import { ErrorsCodes } from 'cf-common/src/errors';
import {
  Button,
  ButtonIntent,
} from '../../../../../modern-ui/_deprecated/Button';
import { DialogContent, DialogHeading } from '../../../../../modern-ui/Dialog';
import { AccessSettings } from '../../AccessSettings';
import {
  WLLoginsSuggest,
  LOGINS_LIST_QUERY,
  ILoginState,
} from './WLLoginsSuggest/WLLoginsSuggest';
import { Flex } from '../../../../../modern-ui/Flex';
import { ROLE_QUERY_bot_currentRole_permissions as IWhiteLabelPermissions } from '../../AccessSettings/@types/ROLE_QUERY';
import {
  hasAnyAccess,
  Permission,
} from '../../../../../common/services/RoleService';
import * as css from './WhiteLabelInviteDialog.css';
import {
  CreateWhiteLabelInviteMutation,
  CreateWhiteLabelInviteMutationVariables,
} from './@types/CreateWhiteLabelInviteMutation';
import client from '../../../../../common/services/ApolloService';
import { ReactComponent as BirdIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/bird.svg';
import { Input } from '../../../../../modern-ui/Input';
import { ROLES_AND_INVITES_QUERY } from '../../AdminsConst';
import { ROLES_AND_INVITES_QUERY as IRolesAndInvitesQuery } from '../../@types/ROLES_AND_INVITES_QUERY';
import {
  AddUserToBotWhiteLabelMutation,
  AddUserToBotWhiteLabelMutationVariables,
} from './@types/AddUserToBotWhiteLabelMutation';
import { GET_WHITE_LABEL_AGENCY_DOMAIN_QUERY } from '../../RolesList';
import { WhiteLabelRole } from '../../../../../utils/WhiteLabelUtils';
import { GetWhiteLabelAgencyDomainQuery } from '../../RolesList/@types/GetWhiteLabelAgencyDomainQuery';
import { PERMISSIONS_FRAGMENT } from '@utils/Data/Permissions/fragments';

interface ICustomRoleInviteDialogProps {
  botId: string;
  onRequestClose: () => void;
}

export const DEFAULT_WHITE_LABEL_ROLE_PERMISSIONS: IWhiteLabelPermissions = {
  __typename: 'Permissions',
  inbox: { permission: Permission.FORBIDDEN, __typename: 'Permission' },
  people: { permission: Permission.VIEW, __typename: 'Permission' },
  broadcasting: { permission: Permission.EDIT, __typename: 'Permission' },
  analyze: { permission: Permission.FORBIDDEN, __typename: 'Permission' },
};

const CREATE_WHITE_LABEL_INVITE_MUTATION = gql`
  mutation CreateWhiteLabelInviteMutation(
    $botId: String!
    $login: String!
    $permissions: WhiteLabelPermissionsInput!
    $max_bots_count: Int!
  ) {
    createWhiteLabelInvite(
      botId: $botId
      login: $login
      permissions: $permissions
      max_bots_count: $max_bots_count
    ) {
      invite {
        token
      }
      userError {
        code
      }
    }
  }
`;

const ADD_USER_TO_BOT_WHITE_LABEL_MUTATION = gql`
  mutation AddUserToBotWhiteLabelMutation(
    $botId: String!
    $login: String!
    $permissions: WhiteLabelPermissionsInput!
    $max_bots_count: Int!
  ) {
    addUserToBotWhiteLabel(
      botId: $botId
      login: $login
      permissions: $permissions
      max_bots_count: $max_bots_count
    ) {
      admin {
        id
        name
        picture
        role(botId: $botId) {
          id
          title
          permissions {
            ...permissionsFragment
          }
        }
        white_label_role
      }
      userError {
        code
      }
    }
  }
  ${PERMISSIONS_FRAGMENT}
`;

const createInviteToken = (domain: string, inviteToken: string) => {
  if (!domain) {
    return '';
  }
  const { protocol } = window.location;
  return `${protocol}//${domain}/invite-page/${inviteToken || '...'}`;
};

const createWhiteLabelInvite = async ({
  botId,
  login,
  permissions,
  max_bots_count,
}: CreateWhiteLabelInviteMutationVariables) => {
  const result = await client.mutate<
    CreateWhiteLabelInviteMutation,
    CreateWhiteLabelInviteMutationVariables
  >({
    mutation: CREATE_WHITE_LABEL_INVITE_MUTATION,
    variables: {
      botId,
      login,
      permissions,
      max_bots_count,
    },
    refetchQueries: [
      {
        query: LOGINS_LIST_QUERY,
        variables: {
          botId,
        },
      },
    ],
  });

  const data = result.data?.createWhiteLabelInvite;
  const token = data?.invite?.token;
  const errorCode = data?.userError?.code;

  return { token, errorCode };
};

const addUserToBotWhiteLabel = async (
  variables: AddUserToBotWhiteLabelMutationVariables,
  limitReachedMessage: string,
) => {
  const { botId, login, permissions, max_bots_count } = variables;
  const rolesAndInvitesQueryParams = {
    query: ROLES_AND_INVITES_QUERY,
    variables: {
      botId,
    },
  };
  await client.mutate<
    AddUserToBotWhiteLabelMutation,
    AddUserToBotWhiteLabelMutationVariables
  >({
    mutation: ADD_USER_TO_BOT_WHITE_LABEL_MUTATION,
    variables: {
      botId,
      login,
      permissions,
      max_bots_count,
    },
    optimisticResponse: {
      addUserToBotWhiteLabel: {
        __typename: 'AddUserToBotWhiteLabelResult',
        admin: {
          __typename: 'Admin',
          id: nanoid(),
          name: login,
          picture: null,
          role: null as any, // role in this place don't need
          white_label_role: WhiteLabelRole.user,
        },
        userError: null,
      },
    },
    // eslint-disable-next-line consistent-return
    update: (store, { data }) => {
      if (!data) {
        return null;
      }

      const { admin, userError } = data.addUserToBotWhiteLabel;

      if (userError?.code === ErrorsCodes.WhiteLabelBotLimitReached) {
        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: limitReachedMessage,
          },
        });
        return null;
      }

      if (!admin) {
        return null;
      }

      const rolesAndInvitesData = store.readQuery<IRolesAndInvitesQuery>(
        rolesAndInvitesQueryParams,
      );
      if (rolesAndInvitesData) {
        rolesAndInvitesData.bot.admins.push();
        store.writeQuery({
          ...rolesAndInvitesQueryParams,
          data: rolesAndInvitesData,
        });
      }
    },
    refetchQueries: [rolesAndInvitesQueryParams],
  });
};

export const WhiteLabelInviteDialog: React.FC<ICustomRoleInviteDialogProps> = ({
  botId,
  onRequestClose,
}) => {
  const [permissions, setPermissions] = useState(
    DEFAULT_WHITE_LABEL_ROLE_PERMISSIONS,
  );
  const [loginState, setLoginState] = useState<ILoginState>({ login: '' });
  const [inviteToken, setInviteToken] = useState<string | undefined>();
  const [creatingInviteToken, setCreatingInviteToken] =
    useState<boolean>(false);
  const [isInviteCopied, setIsInviteCopied] = useState<boolean>(false);
  const { t } = useSafeTranslation();

  useEffect(() => {
    const inviteCopiedTimeout = setTimeout(
      () => setIsInviteCopied(false),
      1000,
    );
    return () => clearInterval(inviteCopiedTimeout);
  }, [isInviteCopied]);

  const limitReachedMessage = t(
    'components.admins.InviteAdminPopup.WhiteLabelInviteDialog.botLimitReached',
  );

  return (
    <Query<GetWhiteLabelAgencyDomainQuery>
      query={GET_WHITE_LABEL_AGENCY_DOMAIN_QUERY}
    >
      {({ data }) => {
        const domain = pathOr<string>('', ['whiteLabelAgency', 'domain'], data);

        return (
          <React.Fragment>
            <DialogContent>
              <DialogHeading>Access Settings</DialogHeading>
              <AccessSettings
                rolePermissions={permissions}
                onChange={setPermissions}
              />
              <div>
                <div className={css.inputTitle}>Username</div>
                <Flex alignItems="center">
                  <WLLoginsSuggest
                    className={css.input}
                    onChangeLogin={setLoginState}
                    disabled={!!inviteToken || creatingInviteToken}
                    botId={botId}
                  />
                  {inviteToken ? (
                    <Flex alignItems="center" className={css.birdIconBox}>
                      <BirdIcon className={css.birdIcon} />
                      <span>Username created</span>
                    </Flex>
                  ) : (
                    <Button
                      data-testid="admins__invite-button"
                      intent={ButtonIntent.primary}
                      className={css.inviteButton}
                      disabled={
                        !loginState.login ||
                        creatingInviteToken ||
                        !hasAnyAccess(permissions)
                      }
                      onClick={async () => {
                        setCreatingInviteToken(true);

                        const userData = {
                          botId,
                          login: loginState.login,
                          permissions: removeTypename(permissions),
                        };

                        const { max_bots_count } = await getAdminFeatures();
                        if (loginState.isNew) {
                          const { token, errorCode } =
                            await createWhiteLabelInvite({
                              ...userData,
                              max_bots_count,
                            });

                          const botsLimitIsReached =
                            errorCode === ErrorsCodes.WhiteLabelBotLimitReached;

                          if (botsLimitIsReached) {
                            toaster.show({
                              type: ServiceMessageType.error,
                              payload: {
                                message: limitReachedMessage,
                              },
                            });
                          }

                          if (token) {
                            setInviteToken(token);
                          }
                          setCreatingInviteToken(false);
                        } else {
                          addUserToBotWhiteLabel(
                            {
                              ...userData,
                              max_bots_count,
                            },
                            limitReachedMessage,
                          );
                          onRequestClose();
                        }
                      }}
                    >
                      {loginState.login === '' || loginState.isNew
                        ? 'Invite'
                        : 'Add'}
                    </Button>
                  )}
                </Flex>
              </div>
              {domain && inviteToken && (
                <div className={css.tokenLineBox}>
                  <div className={css.inputTitle}>
                    Copy and send this link to the user
                  </div>
                  <Flex alignItems="center">
                    <Input
                      className={css.input}
                      value={createInviteToken(domain, inviteToken)}
                      readOnly
                    />
                    <Button
                      intent={ButtonIntent.primary}
                      className={css.inviteButton}
                      onClick={() => {
                        copy(createInviteToken(domain, inviteToken));
                        setIsInviteCopied(true);
                      }}
                    >
                      {isInviteCopied ? (
                        <Flex alignItems="center" justifyContent="center">
                          <BirdIcon className={css.copiedIcon} />
                          Copied
                        </Flex>
                      ) : (
                        'Copy'
                      )}
                    </Button>
                  </Flex>
                </div>
              )}
            </DialogContent>
          </React.Fragment>
        );
      }}
    </Query>
  );
};
