import template from './sequences-subscribe-editor.html';
import controller from './controller';

export default {
  template: () => template,
  controllerAs: 'vm',
  controller,
  bindings: {
    affectedUsersCount: '<',
    sequences: '<',
    getUsers: '&',
    getSequences: '&',
    onSequenceSelected: '&',
    // to position dropdown;
    anchorOrigin: '<',
    popoverOrigin: '<',
  },
};
