import i18n from 'i18next';
import memoize from 'lodash-es/memoize';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { IS_DEBUG, IS_PRODUCTION_BUILD } from 'cf-common/src/environment';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getDashboardLanguage } from '@utils/Data/Admin/Locale';
import { EventEmitter } from '@utils/EventEmitter';
import { createSuspenseResource } from '@utils/createSuspenseResource';

// serviceVersion is from webpack DefinePlugin
const dictionaryVersion =
  // @ts-ignore
  typeof serviceVersion === 'undefined' ? 'default' : serviceVersion;

export enum I18nEvent {
  initialized = 'i18n:initialized',
}

export const i18nEmitter = new EventEmitter();

export const initI18n = async ({
  customLoadPath,
}: { customLoadPath?: string } = {}) => {
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    .use(Backend)
    .use(initReactI18next);

  const lng = await getDashboardLanguage();

  await i18n.init({
    lng,
    whitelist: ['en', 'es', 'pt', 'th', 'fil', 'vi'],
    fallbackLng: 'en',
    debug: !IS_PRODUCTION_BUILD || IS_DEBUG,
    detection: { checkForSimilarInWhitelist: true },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      queryStringParams: { v: dictionaryVersion },
      loadPath: customLoadPath || '/locales/{{lng}}/{{ns}}.json',
    },
  });

  if (!IS_PRODUCTION_BUILD) {
    const { applyClientHMR } = await import('i18next-hmr/client');
    applyClientHMR(i18n);
  }

  // special for Angular use
  window.i18next = i18n;
  i18nEmitter.emit(I18nEvent.initialized);
  return i18n;
};

export const loadUserLocale = async () => {
  const lng = await getDashboardLanguage();
  if (lng && i18n.language !== lng) {
    await i18n.changeLanguage(lng);
  }
};

export const initI18nSuspense = () =>
  createSuspenseResource<typeof i18n>(initI18n());

const languageDetector = new LanguageDetector(i18n.services, { caches: [] });

export const detectLanguage = memoize(() => languageDetector.detect() || 'en');
