import CardTextController from '../card-text-plugin/controller';

export default class CardTitleController extends CardTextController {
  init() {
    this.$scope.$on('gallery-sort-stop', () => {
      this.validate();
    });
    this.$scope.$on('$buttonUpdate', () => {
      this.validate();
    });
    this.type = 'gallery';
    this.field = 'title';
    this.$errors = { message: 'Title is Empty' };

    this.$timeout(() => {
      this.validate();
    });
  }

  save(event) {
    if (!this.validate()) {
      // return;
    }
    this.$scope.$emit('$validateListItem');
    this.CardController.save(this.item, this.index, event);
  }
}
