import ng from 'angular';

import './assets/less/style.less';

import { ngWarningText } from '../../../modern-ui/WarningText/WarningText.angular';
import Component from './component';

export default ng
  .module('app.common.plugins.cardFromSequencePlugin', [ngWarningText])
  .directive('pluginCardFromSequence', Component).name;
