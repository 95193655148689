export default class CardPluginController {
  constructor(
    $scope,
    $interpolate,
    PLUGINS,
    ModalService,
    BotService,
    $sce,
    $timeout,
    PluginCardService,
    StoreService,
    $rootScope,
  ) {
    'ngInject';

    this.StoreService = StoreService;
    this.ModalService = ModalService;
    this.BotService = BotService;
    this.PluginCardService = PluginCardService;
    this.$timeout = $timeout;
    this.$sce = $sce;
    this.$interpolate = $interpolate;
    this.$rootScope = $rootScope;
    this.$showSelect = false;
    this.API_URL = this.StoreService.getFullApiUrl();
    this.plugins = PLUGINS;
    this.botId = $rootScope.stateParams.botId;
    this.page = null;
    this.showFooter = false;
    this.pluginsList = this._initPlugins();
    this.hasValidationError = false;

    this.validation = {
      title: true,
      subtitle: true,
      button: true,
    };

    this.storiesNumbers = {
      1: '1 story',
      2: '2 stories',
      3: '3 stories',
      4: '4 stories',
      5: '5 stories',
      6: '6 stories',
      7: '7 stories',
      8: '8 stories',
      9: '9 stories',
    };

    this.listeners = [];

    this.listeners.push(
      $scope.$on('$angularClick', () => {
        $scope.$evalAsync(() => {
          this.$showSelect = false;
          this.$showHelp = false;
        });
      }),
    );

    this.listeners.push(
      $scope.$on('$pluginHasErrorValidation', (e, data) => {
        if (data.id === this.item.id) {
          this.hasValidationError = data.isError;
        }
      }),
    );

    this.setTokenUrl();
    this.initPreSelect();
  }

  $onInit() {
    this.$timeout(() => {
      if (!this.item.id) {
        this.save();
      }

      if (!this.item.validation_details) {
        this.item.validation_details = {};
      }

      if (this.item.is_valid === false) {
        this.data = this.item.validation_details || {};
        this.data.card_id = this.item.id;
        this.$rootScope.$broadcast('$pluginSaveResultError', {
          data: this.data,
        });
      } else {
        this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
      }
    });
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
  }

  initPreSelect() {
    if (this.item.initParam) {
      // this.BotService.cachedList()
      //   .then(bots => {
      //     this.bot = bots.find(bot => bot.id === this.botId);
      //     this.selectPlugin(this._initPlugin());
      //     delete this.item.initParam;
      //   });

      this.$timeout(() => {
        this.selectPlugin(this._initPlugin());
        delete this.item.initParam;
      });
    }
  }

  _initPlugin() {
    switch (this.item.initParam.type) {
      case 'integration': {
        const pluginTitle = this.plugins[this.item.initParam.type].title;
        const recipeTitle = this.plugins[this.item.initParam.type].recipes[
          this.item.initParam.param
        ].title;

        let title;
        if (this.item.initParam.param === 'zapier') {
          title = `${recipeTitle}`;
        } else {
          title = `${pluginTitle} - ${recipeTitle}`;
        }

        const value = { service: 'ifttt', recipe: this.item.initParam.param };
        const { type } = this.plugins[this.item.initParam.type];
        return { title, value, type };
      }
      case 'search': {
        const pluginTitle = this.plugins[this.item.initParam.type].title;
        const idTitle = this.plugins[this.item.initParam.type].ids[
          this.item.initParam.param
        ].title;
        const title = `${pluginTitle} - ${idTitle}`;
        const value = { id: this.item.initParam.param };
        const { type } = this.plugins[this.item.initParam.type];
        return { title, value, type };
      }
      default: {
        const { title } = this.plugins[this.item.initParam.type];
        const { type } = this.plugins[this.item.initParam.type];
        return { title, type };
      }
    }
  }

  _initPlugins() {
    const plugins = Object.keys(this.plugins);
    const result = [];
    plugins.forEach((plugin) => {
      switch (plugin) {
        case 'communication': {
          const { title } = this.plugins[plugin];
          const { type } = this.plugins[plugin];
          result.push({ title, type });
          break;
        }
        case 'integration': {
          const recipes = Object.keys(this.plugins[plugin].recipes);
          recipes.forEach((recipe) => {
            const pluginTitle = this.plugins[plugin].title;
            const recipeTitle = this.plugins[plugin].recipes[recipe].title;
            let title;
            if (recipe === 'zapier') {
              title = `${recipeTitle}`;
            } else {
              title = `${pluginTitle} - ${recipeTitle}`;
            }

            const value = { service: 'ifttt', recipe };
            const { type } = this.plugins[plugin];

            if (recipe !== 'instagram') {
              result.push({ title, value, type });
            }
          });
          break;
        }
        case 'search': {
          const ids = Object.keys(this.plugins[plugin].ids);
          ids.forEach((id) => {
            const pluginTitle = this.plugins[plugin].title;
            const idTitle = this.plugins[plugin].ids[id].title;
            const title = `${pluginTitle} - ${idTitle}`;
            const value = { id };
            const { type } = this.plugins[plugin];
            result.push({ title, value, type });
          });
          break;
        }
        default: {
          const { title } = this.plugins[plugin];
          const { type } = this.plugins[plugin];
          result.push({ title, type });
          break;
        }
      }
    });

    return result;
  }

  getHelpSubtitle() {
    const st = this.plugins[this.item.plugin_id].recipes[
      this.item.config.recipe
    ].title;
    return st === 'Zapier' ? 'Facebook' : st;
  }

  getSelectedTitle() {
    const pluginId = this.item.plugin_id;
    let title = '';
    const pluginTitle = this.plugins[pluginId].title;
    switch (pluginId) {
      case 'communication': {
        title = pluginTitle;
        break;
      }
      case 'integration': {
        const service = this.plugins[pluginId];
        const recipe = service.recipes[this.item.config.recipe];
        if (this.item.config.recipe === 'zapier') {
          title = `${recipe.title}`;
        } else {
          title = `${recipe.title}`;
        }

        break;
      }
      case 'plugin': {
        title = pluginTitle;
        break;
      }
      case 'search': {
        const { service } = this.item.config;
        if (service === 'google_plugin') {
          title = 'Google Site Search';
        } else if (service === 'bing_plugin') {
          title = 'Bing Search';
        } else if (service === 'swiftype_plugin') {
          title = 'Swiftype Search';
        }
        break;
      }
      default: {
        title = pluginTitle;
        break;
      }
    }

    return title;
  }

  selectPlugin(plugin) {
    const { type } = plugin;
    const { config } = this.plugins[type];

    switch (type) {
      case 'communication': {
        this.item.plugin_id = type;
        //  config.position = this.item.config.position;
        this.item.config = config;
        break;
      }
      case 'integration': {
        this.item.plugin_id = type;
        //   config.position = this.item.config.position;
        config.service = plugin.value.service;
        config.recipe = plugin.value.recipe;
        this.item.config = config;
        break;
      }
      case 'search': {
        this.item.plugin_id = type;
        //  config.position = this.item.config.position;
        config.service = plugin.value.id;

        this.item.config = config;

        this.setTokenUrl();

        break;
      }

      default: {
        break;
      }
    }
    this.$showSelect = false;
    //  this.save();
  }

  getServiceLink(pluginId) {
    if (this.plugins && this.plugins[pluginId]) {
      return this.plugins[pluginId].link;
    }
    return '';
  }

  saveCommunication() {
    this.save();
  }

  save() {
    if (this.item.plugin_id === 'communication') {
      if (
        this.item.config.idle_time_in_hours < 0 ||
        this.item.config.idle_time_in_hours === undefined
      ) {
        this.item.config.idle_time_in_hours = 1;
      }
    }
    this.PluginListController.savePlugin(this.item);
  }

  // remove($event) {
  //   $event.preventDefault();
  //   const context = this.$interpolate(this.ModalService.templates.plugin())();
  //   this.ModalService
  //     .confirm(context)
  //     .then(isConfirmed => isConfirmed && this.PluginListController.remove(this.item));
  // }

  // / /// Site search

  saveSearch() {
    this.PluginListController.savePlugin(this.item)
      .then((res) => {
        this.saveError = null;
      })
      .catch((e) => {
        if (
          e.data.message ===
            'Failed to check Google API with this key and cx: 400' ||
          e.data.message === 'Failed to check Bing API with this token: 401'
        ) {
          this.saveError = 'tokenError';
        }
      });
  }

  setTokenUrl() {
    if (this.item.config.service) {
      this.tokenUrl =
        this.item.config.service === 'google_plugin'
          ? 'https://console.developers.google.com/apis/api/customsearch/'
          : 'https://datamarket.azure.com/dataset/bing/search';
    }
  }

  setNumberPages(n) {
    this.item.config.number_of_pages = parseInt(n, 10);
    this.save();
  }
}
