import { ApolloProvider } from '@apollo/react-components';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Modal } from '@services/index';
import { globalHistory } from '@utils/Routing';
import ng from 'angular';
import cn from 'classnames';
import { compose, find, propEq, propOr } from 'ramda';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-apollo';
import { Router, useHistory } from 'react-router-dom';
import { react2angular } from 'react2angular';
import { AdminPermissionManagerDialog } from '@components/AdminPermissionManagerDialog';
import { Icon, IconSize } from '@ui/_deprecated/Icon';
import { HoverDisclosure } from '@ui/HoverDisclosure';
import { Loader } from '@ui/Loader';
import { sendEvent } from '@utils/Analytics';
import { isWhiteLabelAgencyUser } from '@utils/WhiteLabelUtils';
import { useAdminFeatures } from '@utils/Data/Admin';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  TextButton,
} from '../../../../modern-ui/_deprecated/Button';
import client from '../../../../common/services/ApolloService';
import {
  ROLES_AND_INVITES_QUERY as ROLES_AND_INVITES_QUERYTypes,
  ROLES_AND_INVITES_QUERY_bot_admins as AdminType,
  ROLES_AND_INVITES_QUERY_bot_invites as InviteType,
  ROLES_AND_INVITES_QUERYVariables,
} from '../@types/ROLES_AND_INVITES_QUERY';
import { ROLES_AND_INVITES_QUERY } from '../AdminsConst';
import { EditRolePopup } from '../EditRolePopup';
import { EditWhiteLabelRolePopup } from '../EditWhiteLabelRolePopup';
import { InviteAdminPopup } from '../InviteAdminPopup';
import { ADMINS_ROLES, IAdminRole } from '../RolesList/AdminsRoles';
import * as css from './AdminsList.css';
import { ReactComponent as InviteIcon } from './images/invite.svg';
import spinnerUrl from './images/spinner_b.svg';
import { useDeleteInvite } from '@utils/Data/Invite';
import { DateFormat } from '@utils/DateTime';
import { AdminListItem } from '@components/AdminList/AdminListItem';

interface AdminsListProps {
  botId?: string;
  currentUserId?: string;
  onRemoveAdmin?: (userId: string) => void;
}

interface AdminListLocationState {
  openInviteUserDialog?: boolean;
}

export const getRoleById: (id: string) => IAdminRole = (id) =>
  find(propEq('id', id), ADMINS_ROLES) || ({} as IAdminRole);
const getRoleTitleById = compose(propOr('', 'title'), getRoleById);
export const timeFormat = (timestampStr: string) =>
  DateFormat.DMMMHmm(Number(timestampStr)).toLowerCase();

enum EditRolePopupResolveType {
  editAdminPermissions = 'editAdminPermissions',
  removeAdmin = 'removeAdmin',
}
interface EditRolePopupResolveParams {
  type: EditRolePopupResolveType;
  userId?: string;
  roleId?: string;
}

export const AdminsList: React.FC<AdminsListProps> = ({
  botId,
  currentUserId,
  onRemoveAdmin,
}) => {
  const { t } = useSafeTranslation();
  const cardElementRef = useRef<HTMLDivElement | null>(null);

  const { adminFeatures, adminFeaturesLoading } = useAdminFeatures();

  const { data, loading, error } = useQuery<
    ROLES_AND_INVITES_QUERYTypes,
    ROLES_AND_INVITES_QUERYVariables
  >(ROLES_AND_INVITES_QUERY, { variables: { botId: botId || '' } });

  const [deleteInvite, { loading: inviteDeleting }] = useDeleteInvite({
    botId,
  });

  const admins: AdminType[] = useMemo(() => data?.bot.admins ?? [], [data]);
  const invites: InviteType[] = data?.bot.invites ?? [];
  const whiteLabelBotsLimit = adminFeatures?.max_bots_count ?? 0;

  const handleInviteClick = useCallback(
    (adminsLength: number) => {
      sendEvent({
        category: 'team',
        action: 'invite teammate click',
        value: adminsLength,
        propertyBag: {
          adminsLength,
        },
      });

      const whiteLabelBotsCount = data?.whiteLabelAgency?.bot_ids?.length ?? 0;
      const isBotLimitReached = !(whiteLabelBotsLimit > whiteLabelBotsCount);
      const isBotInAgencyBotsList = data?.whiteLabelAgency?.bot_ids?.includes(
        botId || '',
      );
      const isWhiteLabelAdmin = !!data?.me.white_label_role;

      Modal.show(({ close }) => (
        <InviteAdminPopup
          onRequestClose={close}
          botId={botId || ''}
          showWhiteLabelRole={isWhiteLabelAdmin}
          showBotLimitReachedPopup={isBotLimitReached && !isBotInAgencyBotsList}
        />
      ));
    },
    [botId, data, whiteLabelBotsLimit],
  );

  const {
    replace,
    location: { pathname, state: locationState },
  } = useHistory<AdminListLocationState>();

  useEffect(() => {
    if (cardElementRef.current && locationState?.openInviteUserDialog) {
      cardElementRef.current.scrollIntoView();
      handleInviteClick(admins.length);
      replace(pathname);
    }
  }, [locationState, pathname, admins, replace, handleInviteClick]);

  if (!botId) {
    return null;
  }

  const handleRemoveAdmin = (userId?: string) => {
    if (!userId) {
      return;
    }

    Modal.show(({ close }) => (
      <AdminPermissionManagerDialog
        botId={botId}
        userId={userId}
        onComplete={() => {
          if (onRemoveAdmin) {
            onRemoveAdmin(userId);
          }
          close();
        }}
        onDismiss={close}
      />
    ));
  };

  const handleEditAdminPermissions = (userId?: string, roleId?: string) => {
    if (!userId) {
      return;
    }

    Modal.show(({ close }) => (
      <AdminPermissionManagerDialog
        botId={botId}
        userId={userId}
        roleId={roleId}
        onComplete={close}
        onDismiss={close}
      />
    ));
  };

  const handleEditRoleClick = (admin: AdminType) => {
    if (admin.role) {
      const selectedRoleId = admin.role.id || undefined;

      if (isWhiteLabelAgencyUser(admin)) {
        Modal.show<string>(({ resolve, close }) => (
          <EditWhiteLabelRolePopup
            onDismiss={close}
            botId={botId}
            admin={admin}
            onRemoveAdminRequest={resolve}
          />
        ))?.then(handleRemoveAdmin);
      } else {
        Modal.show<EditRolePopupResolveParams>(({ resolve, close }) => (
          <EditRolePopup
            onDismiss={close}
            botId={botId}
            admin={admin}
            onRoleChangeWithReasons={(roleId) => {
              resolve({
                type: EditRolePopupResolveType.editAdminPermissions,
                userId: admin.id,
                roleId,
              });
            }}
            defaultSelectedRoleId={selectedRoleId}
            onRemoveAdminRequest={(userId) => {
              resolve({ type: EditRolePopupResolveType.removeAdmin, userId });
            }}
          />
        ))?.then((resolveData) => {
          if (!resolveData) {
            return;
          }
          const { type, userId, roleId } = resolveData;
          if (type === EditRolePopupResolveType.editAdminPermissions) {
            handleEditAdminPermissions(userId, roleId);
          } else {
            handleRemoveAdmin(userId);
          }
        });
      }
    }

    sendEvent({
      category: 'team',
      action: 'click change permissions',
      propertyBag: {
        editedUserId: admin.id,
        permissions: admin.role.id,
      },
    });
  };

  const spinnerIsShown = (loading || adminFeaturesLoading) && !error;

  return (
    <div className={cn('border-box', css.admins)} ref={cardElementRef}>
      <div className={css.header}>
        <div>{t('components.settings.adminList.teamHeader')}</div>
        <Button
          onClick={() => {
            handleInviteClick(admins.length);
          }}
          intent={ButtonIntent.primary}
          colorWay={ButtonColorWay.white}
          data-testid="admins__invite-teammate-button"
        >
          {t('components.settings.adminList.inviteButton')}
        </Button>
      </div>
      <div className={css.note}>{t('components.settings.adminList.note')}</div>
      {spinnerIsShown ? (
        <img src={spinnerUrl} className={css.spinner} alt="loading" />
      ) : (
        <div data-testid="admins__teammate-list" className={css.list}>
          {invites.map((invite) => (
            <HoverDisclosure
              key={invite.id}
              render={({ isVisible, bind }) => (
                <div
                  data-testid="admins__invite"
                  className={css.itemList}
                  {...bind}
                >
                  <div className={css.adminInfo}>
                    <div className={css.avaContainer}>
                      {inviteDeleting ? (
                        <Loader className={css.avaLoader} />
                      ) : (
                        <Icon size={IconSize.large} svg={InviteIcon} rounded />
                      )}
                    </div>
                    <div>
                      <div>
                        <span className={css.inviteName}>
                          {invite.login
                            ? t('components.settings.adminList.loginPending', {
                                login: invite.login,
                              })
                            : t('components.settings.adminList.newPending', {
                                role: getRoleTitleById(invite.roleId),
                              })}
                        </span>
                      </div>
                      {invite.created && (
                        <div className={css.role}>
                          {t('components.settings.adminList.addedTime', {
                            time: timeFormat(invite.created),
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {isVisible && (
                    <div>
                      <TextButton
                        data-testid="admins__revoke-access-button"
                        className={css.link}
                        onClick={() => {
                          deleteInvite({
                            variables: { inviteId: invite.id },
                          });
                          sendEvent({
                            category: 'team',
                            action: 'access revoked',
                          });
                        }}
                      >
                        {t('components.settings.adminList.revokeAccess')}
                      </TextButton>
                    </div>
                  )}
                </div>
              )}
            />
          ))}
          {admins.map((admin) => (
            <HoverDisclosure
              key={admin.id}
              render={({ isVisible, bind }) => (
                <div
                  data-testid="admins__admin"
                  className={css.itemList}
                  {...bind}
                >
                  <AdminListItem
                    admin={{
                      ...admin,
                      name: admin.name || '',
                      roleId: admin.role.id,
                    }}
                    currentUserId={currentUserId}
                    textWidth={330}
                  />
                  {isVisible && admin.id !== currentUserId && (
                    <div>
                      <TextButton
                        data-testid="admins__change-permissions-button"
                        className={css.link}
                        onClick={() => handleEditRoleClick(admin)}
                      >
                        {t('components.settings.adminList.changePermissions')}
                      </TextButton>
                    </div>
                  )}
                </div>
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const AdminListWrapper: React.FC<AdminsListProps> = (props) => {
  return (
    <ApolloProvider client={client}>
      <Router history={globalHistory}>
        <AdminsList {...props} />
      </Router>
    </ApolloProvider>
  );
};

export const ngAdminsList = ng
  .module('app.pages.configureTab.admins.list', [])
  .component(
    'adminsList',
    react2angular(
      AdminListWrapper,
      ['botId', 'currentUserId', 'onRemoveAdmin'],
      [],
    ),
  ).name;
