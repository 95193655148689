import moment from 'moment';
import { log } from 'cf-common/src/logger';
import { canEdit } from '../../../common/services/RoleService';
import { sendEvent } from '../../../utils/Analytics';

/* eslint-disable chatfuel/no-use-localStorage */
export default class HistoryController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $element,
    BotService,
    BlockService,
    BroadcastService,
    PluginCardService,
    ModalService,
    StoreService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$element = $element;
    this.BotService = BotService;
    this.BlockService = BlockService;
    this.BroadcastService = BroadcastService;
    this.PluginCardService = PluginCardService;
    this.ModalService = ModalService;
    this.StoreService = StoreService;
    this.RoleService = { canEdit };

    this.historyArray = [];
    this.historyEmpty = true;
    this.chunkHistoryAviable = true;
    this.getHistoryChunk();

    this.ttText =
      'When you send a broadcast, the bot attempts delivery to all users based on the User Filter you apply. You can see the corresponding number of addressees in the “Attempts” column.<br/><br/>Some users may have deleted or blocked the bot prior to the broadcast. Such instances only register when you try to send a broadcast. Those users are shown in the “Bounced” column.<br/><br/>Users who did not delete or block the bot will be shown in the “Sent” column. If they read and click a button in the broadcast message, they will show in the corresponding “Read” and “Clicked” columns.<br/><br/>Note:<br/>Data is refreshed every 5 minutes.';

    this.listener = $rootScope.$on('$bcClicked', () => {
      this.chunkHistoryAviable = true;
      this.historyArray.length = 0;
      this.serverHistoryEmpty = false;
      this.historyEmpty = false;
      this.getHistoryChunk();
    });
  }

  $onInit() {
    this.$timeout(() => this.initDom()); // init dom on angular component mount
  }

  $onDestroy() {
    this.container?.off('scroll');
    this.aWindow?.off('resize');
    this.aWindow?.off('scroll');
    this.listener();
  }

  initDom() {
    this.container = angular.element(document.querySelector('.rcol'));
    this.aWindow = angular.element(window);
    this.tHead = angular.element(this.$element[0].querySelector('.thead'));
    this.tBody = angular.element(this.$element[0].querySelector('.tbody'));

    this.container.on('scroll', () => {
      this.setTHeadPos();
      this.calcShowBlock();
    });

    this.aWindow.on('resize', () => {
      this.setTHeadPos();
    });

    this.aWindow.on('scroll', () => {
      this.setTHeadPos();
    });

    setTimeout(() => {
      this.setTHeadPos(true);
    }, 100);

    this.itemEndId = -1;
  }

  setTHeadPos(watch) {
    const lPos = this.getCoordsLeft(this.tBody[0]);
    if (lPos) {
      this.tHead.css('left', lPos + 1);
    } else if (watch) {
      setTimeout(() => {
        this.setTHeadPos(true);
      }, 50);
    }
  }

  getCoordsLeft(elem) {
    const box = elem.getBoundingClientRect();
    const { body } = document;
    const docEl = document.documentElement;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    return box.left ? box.left - clientLeft : false;
  }

  getHistoryChunk() {
    if (this.serverHistoryEmpty || this.reqProc) {
      return false;
    }
    this.reqProc = true;

    let dateFrom = '';
    if (this.historyArray.length) {
      dateFrom = this.historyArray[this.historyArray.length - 1].date;
    }

    this.BotService.getBroadcastStats(dateFrom).then((res) => {
      res.forEach((item) => this.historyArray.push(item));
      this.chunkHistoryAviable = res.length > 0;
      if (!this.eventSended) {
        this.eventSended = true;
        this.historyEmpty = !this.chunkHistoryAviable;
        this.$scope.$emit('$historyLoaded', this.chunkHistoryAviable);
      }

      this.serverHistoryEmpty = !this.chunkHistoryAviable;

      if (this.chunkHistoryAviable) {
        setTimeout(() => {
          this.calcShowBlock();
        }, 0);
      }
      this.reqProc = false;
    });
    return true;
  }

  calcShowBlock() {
    const itemHeight = 64;

    let itemStartId = Math.ceil(this.container[0].scrollTop / itemHeight) - 15;
    itemStartId = itemStartId < 0 ? 0 : itemStartId;
    let itemEndId = itemStartId + 40;
    if (itemEndId > this.historyArray.length - 1) {
      itemEndId = this.historyArray.length - 1;
      this.getHistoryChunk();
    }

    const lHistoryIdsArray = [];

    for (let i = itemStartId; i < itemEndId + 1; i++) {
      lHistoryIdsArray.push(i);
    }

    let paddingTop = 0;
    let paddingBottom = 0;

    if (itemStartId > 0) {
      paddingTop = itemStartId * itemHeight;
    }

    if (itemEndId < this.historyArray.length - 1) {
      paddingBottom = (this.historyArray.length - itemEndId) * itemHeight;
    }

    if (
      Math.abs(this.itemEndId - itemEndId) > 5 ||
      itemEndId === this.historyArray.length - 1 ||
      itemStartId === 0
    ) {
      if (this.itemStartId === itemStartId && this.itemEndId === itemEndId) {
        return;
      }

      this.itemStartId = itemStartId;
      this.itemEndId = itemEndId;

      this.$scope.$evalAsync(() => {
        this.historyIdsArray = lHistoryIdsArray;
        this.padding = `${paddingTop}px 0px ${paddingBottom}px`;
      });
    }
  }

  resend(broadcastId, type, $event) {
    $event.stopPropagation();

    sendEvent({
      category: 're-engage',
      action: 'duplicate this block',
    });

    const goto = (bl) => {
      this.BlockService.update(bl).then(() => {
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/broadcast/${bl.id}/0/now`,
        );
      });
    };

    this.BroadcastService.show(broadcastId).then((res) => {
      this.BlockService.show(res.block_id).then((targetBlock) => {
        this.BlockService.createBroadcastNowBlock().then((newBlock) => {
          if (newBlock.cards && newBlock.cards.length > 0) {
            newBlock.cards.forEach((card) =>
              this.PluginCardService.remove(card),
            );
          }

          newBlock.user_filter = targetBlock.user_filter;

          this.BlockService.clone(targetBlock.id).then(goto);
        });
      });
    });
  }

  goBlockByBC(event, broadcastId, type, userFilterValue) {
    event.preventDefault();
    if (event.ctrlKey || event.metaKey) {
      if (type === 'scheduled') {
        this.BroadcastService.showSchedule(broadcastId).then((res) => {
          const allowEdit =
            res.broadcast_start_timestamp >= moment.utc().startOf('day');
          if (!allowEdit) {
            const usersBlock = `users-${res.block_id}`;
            try {
              window.localStorage.setItem(usersBlock, userFilterValue);
            } catch (error) {
              log.warn({
                error,
                msg: `Error during setting ${usersBlock} item to local storage`,
              });
            }
          }

          window.open(
            `/bot/${this.$rootScope.stateParams.botId}/broadcast/${res.block_id}/${broadcastId}/schedule`,
            '_blank',
          );
        });
      } else if (type === 'recurrent' || type === 'monthly') {
        this.BroadcastService.showSchedule(broadcastId).then((res) => {
          window.open(
            `/bot/${this.$rootScope.stateParams.botId}/broadcast/${res.block_id}/${broadcastId}/schedule`,
            '_blank',
          );
        });
      } else if (type === 'sequence') {
        this.BroadcastService.showSequence(broadcastId).then((res) => {
          window.open(
            `/bot/${this.$rootScope.stateParams.botId}/broadcast/${res.block_id}/${broadcastId}/sequence`,
            '_blank',
          );
        });
      } else {
        this.BroadcastService.show(broadcastId).then((res) => {
          switch (type) {
            case 'send_now': {
              const usersBlock = `users-${res.block_id}`;
              const autoBlock = `auto-${res.block_id}`;
              try {
                window.localStorage.setItem(usersBlock, userFilterValue);
                window.localStorage.setItem(autoBlock, 1);
              } catch (error) {
                log.warn({
                  error,
                  msg: `Error during setting ${usersBlock} or ${autoBlock} item to local storage`,
                });
              }
              window.open(
                `/bot/${this.$rootScope.stateParams.botId}/broadcast/${res.block_id}/1/now`,
                '_blank',
              );
              break;
            }
            case 'autoposting':
              window.open(
                `/bot/${this.$rootScope.stateParams.botId}/broadcast/${res.block_id}/auto`,
                '_blank',
              );
              break;
            default:
              break;
          }
        });
      }
    } else if (type === 'recurrent' || type === 'monthly') {
      this.BroadcastService.showSchedule(broadcastId).then((res) => {
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/broadcast/${res.block_id}/${broadcastId}/schedule`,
        );
      });
    } else if (type === 'sequence') {
      this.BroadcastService.showSequence(broadcastId).then((res) => {
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/broadcast/${res.block_id}/${broadcastId}/sequence`,
        );
      });
    } else if (type === 'scheduled') {
      this.BroadcastService.showSchedule(broadcastId).then((res) => {
        const allowEdit =
          res.broadcast_start_timestamp >= moment.utc().startOf('day');
        if (!allowEdit) {
          const usersBlock = `users-${res.block_id}`;
          try {
            window.localStorage.setItem(usersBlock, userFilterValue);
          } catch (error) {
            log.warn({
              error,
              msg: `Error during setting ${usersBlock} item to local storage`,
            });
          }
        }
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/broadcast/${res.block_id}/${broadcastId}/schedule`,
        );
      });
    } else {
      this.BroadcastService.show(broadcastId).then((res) => {
        switch (type) {
          case 'send_now': {
            const usersBlock = `users-${res.block_id}`;
            const autoBlock = `auto-${res.block_id}`;
            try {
              window.localStorage.setItem(usersBlock, userFilterValue);
              window.localStorage.setItem(autoBlock, 1);
            } catch (error) {
              log.warn({
                error,
                msg: `Error during setting ${usersBlock} or ${autoBlock} item to local storage`,
              });
            }
            this.$rootScope.stateHistory.push(
              `/bot/${this.$rootScope.stateParams.botId}/broadcast/${res.block_id}/1/now`,
            );
            break;
          }
          case 'autoposting':
            this.BlockService.show(res.block_id).then(() => {
              this.$rootScope.stateHistory.push(
                `/bot/${this.$rootScope.stateParams.botId}/broadcast/${res.block_id}/auto`,
              );
            });
            break;
          default:
            break;
        }
      });
    }
  }

  calcProgress(itemId) {
    const item = (this.historyArray || [])[itemId];
    return item
      ? item.in_progress
        ? (item.success_users + item.error_users) / item.total_users
        : 1
      : 0;
  }

  calcProgressPercent = (itemId) => Math.floor(this.calcProgress(itemId) * 100);

  isError(itemId) {
    const MAX_ERROR_LEVEL = 0.1;
    const item = (this.historyArray || [])[itemId];
    return (
      item &&
      item.error_users / (item.success_users + item.error_users) >
        MAX_ERROR_LEVEL
    );
  }
}
