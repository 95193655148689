import template from './templates/template.html';
import controller from './controller';

export default () => ({
  template,
  controller,
  controllerAs: 'vmt',
  restrict: 'E',
  replace: true,
});
