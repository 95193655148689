import React, { useCallback, useEffect, useRef } from 'react';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Router, useHistory, useLocation } from 'react-router-dom';
import { globalHistory } from '@utils/Routing';
import { ApolloProvider } from '@apollo/react-common';
import client from '@common/services/ApolloService';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ConnectStripeForm } from '@components/Stripe/common/ConnectStripeForm';
import { Flex } from '@ui/Flex';
import logoSrc from '@components/Stripe/common/images/stripe_logo.png';
import { Icon } from '@ui/Icon';
import { Button } from '@ui/Button';
import { useStripeAccount } from '@utils/Stripe/hooks/useStripeAccount';
import { sendEvent } from '@utils/Analytics';

const TAB_RENDER_TIMEOUT = 1000;

export const PaymentsConfig: React.FC = () => {
  const { t } = useSafeTranslation();
  const { isStripeConnected, stripeDisconnect } = useStripeAccount();
  const { state: locationState } = useLocation();
  const history = useHistory();
  const boxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (locationState?.scrollToPayments) {
      setTimeout(() => {
        if (!boxRef.current) {
          return;
        }
        scrollIntoView(boxRef.current, {
          scrollMode: 'if-needed',
          behavior: 'auto',
          block: 'end',
        });
      }, TAB_RENDER_TIMEOUT); // wait all render
      history.push({
        ...history.location,
        state: {},
      });
    }
  }, [history, locationState]);

  const disconnectHandler = useCallback(() => {
    stripeDisconnect();
    sendEvent({
      category: 'payment config',
      action: 'disconnect button click',
    });
  }, [stripeDisconnect]);

  return (
    <div
      ref={boxRef}
      data-testid="configure-tab__payments-block"
      className="border-box"
    >
      <Type size="24px" weight="light">
        {t('components.settings.Payments.title')}
      </Type>
      <Spacer factor={7} />
      <Flex justifyContent="space-between" alignItems="center">
        {!isStripeConnected && (
          <img src={logoSrc} width={67} height={32} alt="Stripe" />
        )}
        {isStripeConnected && (
          <>
            <Flex alignItems="center">
              <img src={logoSrc} width={67} height={32} alt="Stripe" />
              <Icon icon="activeCircle2" color="accent2Secondary" size="16px" />
              <Spacer factor={1} horizontalFactor={1} />
              <Type size="12px" color="accent2Secondary">
                {t('components.settings.Payments.enabled')}
              </Type>
            </Flex>
            <Button
              data-testid="configure-tab__payments-block_disconnect-button"
              intent="secondary"
              onClick={disconnectHandler}
            >
              {t('components.settings.Payments.disconnect')}
            </Button>
          </>
        )}
      </Flex>
      <Spacer factor={7} />
      <ConnectStripeForm />
    </div>
  );
};

export const PaymentsConfigWrapper: React.FC = () => {
  return (
    <Router history={globalHistory}>
      <ApolloProvider client={client}>
        <PaymentsConfig />
      </ApolloProvider>
    </Router>
  );
};
