import ng from 'angular';

import Component from './component';

import Item from './item';

import './assets/less/styles.less';

export default ng
  .module('app.common.plugins.cardListPlugin', [Item])
  .directive('pluginCardList', Component).name;
