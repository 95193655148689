import CardTextController from '../card-text-plugin/controller';

export default class CardSubTitleController extends CardTextController {
  init() {
    this.type = 'gallery';
    this.field = 'subtitle';
    this.$errors = { message: 'Subtitle is Empty' };
  }

  save(event) {
    if (!this.validate()) {
      // return;
    }
    this.$scope.$emit('$validateListItem');
    this.CardController.save(this.item, this.index, event);
  }
}
