import ng from 'angular';

import template from './bots.spinner.template.html';
import controller from './controller';

export const yandexMetrikaConfirmPageNg = {
  template: () => template,
  controller,
  controllerAs: 'vm',
};

export default ng
  .module('app.components.yandexMetrikaConfirmPage', [])
  .component('yandexMetrikaConfirmPage', yandexMetrikaConfirmPageNg).name;
