import ng from 'angular';
import { react2angular } from 'react2angular';
import { ConvertToGalleryButton } from './ConvertToGalleryButton';

export const ngConvertToGalleryPlugin = ng
  .module('app.plugins.listPlugin', [])
  .component(
    'convertToGalleryButton',
    react2angular(ConvertToGalleryButton, ['onClick'], []),
  ).name;
