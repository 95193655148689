import client from '@common/services/ApolloService';
import { PERMISSIONS_FRAGMENT } from '@utils/Data/Permissions/fragments';
import gql from 'graphql-tag';
import {
  ROLES_AND_INVITES_QUERY as Query,
  ROLES_AND_INVITES_QUERYVariables as Variables,
} from './@types/ROLES_AND_INVITES_QUERY';

export const ADMINS_FRAGMENT = gql`
  fragment AdminsFragment on Bot {
    admins {
      id
      name
      picture
      role(botId: $botId) {
        id
        permissions {
          ...permissionsFragment
        }
      }
      white_label_role
    }
  }
  ${PERMISSIONS_FRAGMENT}
`;

export const ROLES_AND_INVITES_QUERY = gql`
  query ROLES_AND_INVITES_QUERY($botId: String!) {
    bot(id: $botId) {
      id
      ...AdminsFragment
      roles {
        id
        title
      }
      invites {
        id
        author
        bot
        created
        roleId
        login
      }
    }
    whiteLabelUserLogins {
      id
      login
      user_id
      user_bots {
        id
        title
      }
    }
    me {
      id
      white_label_role
    }
    whiteLabelAgency {
      id
      domain
      bot_ids
    }
  }
  ${ADMINS_FRAGMENT}
`;

export const getRolesAndInvites = (variables: Variables) =>
  client.query<Query, Variables>({ query: ROLES_AND_INVITES_QUERY, variables });
