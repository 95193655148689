import angular from 'angular';
import GrowService from './service';
import './assets/less/main.less';

/**
 * @ngdoc directive
 * @name grow
 * @restrict E
 */
export default angular
  .module('app.common.grow', [])
  .factory('GrowService', GrowService).name;
