import { find, propEq } from 'ramda';
import {
  TimezoneLabel,
  getTimezoneOptions,
} from '@components/FlowBuilder/EditorPanel/components/plugins/GoogleSheetPlugin/hooks/constants';
import * as GoogleAuth from 'cf-common/src/utils/googleAuth';
import { log } from 'cf-common/src/logger';
import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { getBlocksTitlesArchiveObservable } from '../../../modern-components/Aside/BlocksGroupsQueries';
import { toaster } from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2';
import { isWhiteLabelDomain } from '../../../utils/WhiteLabelUtils';

const _findById = (id, list) => find(propEq('id', id), list);

export default class CardRssController extends CardPluginBaseController {
  constructor(
    $scope,
    $rootScope,
    $interpolate,
    ModalService,
    BotService,
    BlockService,
    $sce,
    $timeout,
    $element,
    StoreService,
    PluginCardService,
    $q,
    $http,
  ) {
    'ngInject';

    super(
      $scope,
      $rootScope,
      $interpolate,
      ModalService,
      BotService,
      BlockService,
      $sce,
      $timeout,
      $element,
      StoreService,
      PluginCardService,
    );

    this.$q = $q;
    this.$http = $http;
    this.$rootScope = $rootScope;

    this.listeners = [];

    this.listeners.push(
      $scope.$on('$popupValueChange', (event, res) => {
        $timeout(() => {
          if (res.cardId === this.item.id) {
            this.save();
          }
        });
      }),
    );

    this.listeners.push(
      $scope.$on('$saveVariables', () => {
        this.save();
      }),
    );
    this.connectingToGoogle = false;
    this.googleApiReady = false;
    GoogleAuth.initGoogleApi();
  }

  get timestamp() {
    const timezone = getTimezoneOptions(this.timezoneOffset ?? 0).find(
      (v) => v.id === this.item.config.timezone,
    );

    return timezone?.title ?? TimezoneLabel.utc;
  }

  $onInit() {
    GoogleAuth.getGoogleAuthInstanceAsync()
      .then((v) => {
        this.googleApiReady = true;
      })
      .catch((error) => {
        log.error({
          error,
          msg: 'Error while authentication via Google',
          data: { label: 'automate_google_sheet' },
        });
      });

    if (!this.item.id) {
      this.save();
    }
    if (this.item.config.spreadsheet_id) {
      this.getSheetData(this.item.config.spreadsheet_id);
    }
    this.blockListSubscription = getBlocksTitlesArchiveObservable(
      this.$rootScope.stateParams.botId,
    ).subscribe((blocks) =>
      // eslint-disable-next-line no-return-assign
      this.$scope.$evalAsync(() => {
        this.blocks = blocks;
      }),
    );

    this.docLink =
      'https://docs.chatfuel.com/plugins/export-and-import/save-to-google-sheets';
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());

    if (this.blockListSubscription) {
      this.blockListSubscription.unsubscribe();
    }
  }

  async connectToGoogle() {
    try {
      const googleAuthInstance = await GoogleAuth.getGoogleAuthInstanceAsync();
      const { code: googleToken } = await googleAuthInstance.grantOfflineAccess(
        {
          scope: GoogleAuth.getGoogleScope(GoogleAuth.GoogleScope.googleSheet),
        },
      );
      this.connectingToGoogle = true;
      const result = await this.createSheet(googleToken);
      await this.$timeout(() => true, 1000);
      if (result) {
        const {
          spreadsheet,
          owner: { email },
        } = result;
        this.item.config.spreadsheet_id = spreadsheet.id;
        this.url = spreadsheet.url;
        this.email = email;
        this.save();
      } else {
        throw new Error('Create sheet error');
      }
    } catch (error) {
      this.connectingToGoogle = false;
      log.warn({ error, msg: 'Google sheet plugin error' });
    }
  }

  async createSheet(googleToken) {
    try {
      const block = _findById(this.$rootScope.stateParams.blockId, this.blocks);
      const sheetName = `Users from '${block && block.title}'`;

      const {
        data: { success, result },
      } = await this.$http({
        url: `${this.StoreService.getApiUrl()}/integrations/google_sheets/${
          this.$rootScope.stateParams.botId
        }/spreadsheets`,
        method: 'post',
        data: {
          code: googleToken,
          title: sheetName,
        },
      });
      return success && result;
    } catch (e) {
      return false;
    }
  }

  async getSheetData(id) {
    const {
      data: { success, result },
    } = await this.$http({
      url: `${this.StoreService.getApiUrl()}/integrations/google_sheets/${
        this.$rootScope.stateParams.botId
      }/spreadsheets/${id}`,
      method: 'get',
    });

    if (success) {
      const {
        spreadsheet: { url },
        owner: { email },
      } = result;
      this.url = url;
      this.email = email;
    }
  }

  setUseAllAttributes(value) {
    this.item.config.use_all_attributes = value;
    this.save();
  }
}
