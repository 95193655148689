import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';
import { Query } from '@apollo/react-components';
import { propEq, complement, allPass, T, pathOr } from 'ramda';
import gql from 'graphql-tag';
import { ADMINS_ROLES, IAdminRole, RoleIds } from './AdminsRoles';
import { Loader } from '../../../../modern-ui/Loader';
import { GetWhiteLabelAgencyDomainQuery } from './@types/GetWhiteLabelAgencyDomainQuery';
import * as css from './RolesList.css';

interface RolesListProps {
  onRoleClick?: (role: IAdminRole) => void;
  showTriangles?: boolean;
  selectedRoleId?: string;
  assigningTo?: string;
  showWhiteLabelRole?: boolean;
  showCustomRole?: boolean;
}

const propIdEq = propEq('id');

export const GET_WHITE_LABEL_AGENCY_DOMAIN_QUERY = gql`
  query GetWhiteLabelAgencyDomainQuery {
    whiteLabelAgency {
      id
      domain
    }
  }
`;

export function RolesList(props: RolesListProps) {
  const {
    showTriangles,
    selectedRoleId,
    assigningTo,
    onRoleClick,
    showWhiteLabelRole,
    showCustomRole,
  } = props;
  const { t } = useSafeTranslation();
  const filterCallback = allPass([
    showWhiteLabelRole ? T : complement(propIdEq(RoleIds.whiteLabel)),
    showCustomRole ? T : complement(propIdEq(RoleIds.custom)),
  ]);
  return (
    <div className={css.list} data-testid="admins__admin-role-list">
      {ADMINS_ROLES.filter(filterCallback).map((role) => {
        const {
          titleI18nKey,
          noteI18nKey,
          icon: RoleIcon,
          id,
          enabled,
          shouldShowDivider,
        } = role;
        return (
          <React.Fragment key={id}>
            {shouldShowDivider && <div className={css.divider} />}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              data-testid={`admins__${id}-role`}
              className={cn(css.listItem, {
                [css.roleDisabled]: !enabled,
                [css.selected]: selectedRoleId === id,
              })}
              onClick={() => {
                if (onRoleClick) {
                  onRoleClick(role);
                }
              }}
            >
              <div className={css.iconContainer}>
                {assigningTo === id ? (
                  <Loader className={css.roleLoader} />
                ) : (
                  <RoleIcon className={css.roleIcon} />
                )}
              </div>
              <div>
                <div className={css.itemTitle}>
                  <span>{t(titleI18nKey)}</span>
                  {showTriangles && <div className={css.triangle} />}
                </div>
                <div className={css.itemNote}>
                  <span>{t(noteI18nKey)}</span>
                  {id === 'WL' && (
                    <Query<GetWhiteLabelAgencyDomainQuery>
                      query={GET_WHITE_LABEL_AGENCY_DOMAIN_QUERY}
                      variables={{
                        domain: window.location.host,
                      }}
                    >
                      {({ data }) => {
                        const domain = pathOr<string>(
                          '',
                          ['whiteLabelAgency', 'domain'],
                          data,
                        );

                        if (!domain) {
                          return null;
                        }

                        return (
                          <React.Fragment>
                            <span>&nbsp;Invite will be sent to&nbsp;</span>
                            <a
                              href={`https://${domain}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={css.wlDomainLink}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              {domain}
                            </a>
                          </React.Fragment>
                        );
                      }}
                    </Query>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
