import { ATTRIBUTE_TYPE_CODES } from '../../consts';

export default class usersTableAttrPopupController {
  constructor($scope, $element) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.attributeTypeCodes = ATTRIBUTE_TYPE_CODES;
  }

  $onInit() {
    this.$scope.$on('$angularClick', () => {
      if (this.attributes) {
        this.$scope.$evalAsync(() => {
          this.attributes = null;
        });
      }
    });

    this.$element.on('click', (event) => {
      event.stopPropagation();
    });
  }

  $onDestroy() {
    this.$element.off('click');
  }

  calcAttributesCount() {
    return this.attributes ? this.attributes.length : 0;
    // if (this.attributes) {
    //   return this.attributes.filter(attr => !attr.isSystem).length;
    // } else {
    //   return 0;
    // }
  }
}
