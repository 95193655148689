/**
 * @description ClipboardService
 */
import { stripTags } from '../../utils/stripTags';

export default class ClipboardService {
  /**
   * @description constructor
   * @param {*} $document -
   */
  constructor($document) {
    'ngInject';

    this.$document = $document;
  }

  /**
   * @description Select all in not input element (div, span etc.)
   * @param {node} element -
   */
  selectAllInElement(element) {
    const range = document.createRange();
    range.selectNodeContents(element);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    element.focus();
    element.setSelectionRange(0, 999999);
  }

  /**
   * @description copy text to clipboard
   * @param {String} text -
   * @return {boolean} -
   */
  copyTextToClipboard(text) {
    const document = this.$document[0];
    const input = document.createElement('textarea');
    input.style.position = 'fixed';
    input.style.top = 0;
    input.style.left = 0;
    input.attributes.type = 'text';
    input.value = text;
    input.readOnly = true;
    input.contentEditable = true;
    document.body.appendChild(input);
    this.selectAllInElement(input);
    let success = false;
    try {
      success = document.execCommand('copy');
    } catch (err) {
      success = false;
      // not able to copy;
    } finally {
      document.body.removeChild(input);
    }
    return success;
  }

  /**
   * @description cut text by maxLength
   * @param {String} text -
   * @param {Number} maxLength -
   * @return {String} -
   */
  cutTextByMaxLength(text, maxLength) {
    if (!maxLength) {
      return text;
    }
    let availableTextLength = maxLength - this.$el[0].innerText.length;
    availableTextLength = availableTextLength < 0 ? 0 : availableTextLength;
    return text.substring(0, availableTextLength);
  }

  /**
   * @description remove tags and special chars, crop text by maxLength
   * @param {Event} pasteEvent -
   * @param {Number} maxLength -
   */
  safePaste(pasteEvent, maxLength) {
    pasteEvent.preventDefault();
    const pastedText = pasteEvent.originalEvent.clipboardData.getData(
      'text/plain',
    );
    const cleanText = stripTags(pastedText);
    const cutText = this.cutTextByMaxLength(cleanText, maxLength);
    document.execCommand('inserttext', false, cutText);
  }
}
