import React from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Router } from 'react-router-dom';
import { globalHistory, useCurrentBotId } from '@utils/Routing';
import { ApolloProvider } from '@apollo/react-common';
import client from '@common/services/ApolloService';
import { Button } from '@ui/Button';
import kommoLogo from './kommo-logo.png';
import { useKommoOauthUrl } from '@utils/Data/Kommo/useKommoOauthUrl';
import {
  IntegrationContainer,
  IntegrationContainerAnchor,
} from '../IntegrationContainer';
import { useKommoIntegrationStatus } from '@utils/Data/Kommo/useKommoIntegrationStatus';
import { KommoIntegrationStatus } from '@globals';
import { useDisconnectKommo } from '@utils/Data/Kommo/useDisconnectKommo';
import { Anchor } from '@ui/Links';
import { useBotFeatures } from '@utils/Data/Bot';
import { IntegrationContainerEnabledNote } from '../IntegrationContainerNote';

export const KommoIntegration: React.FC = () => {
  const botId = useCurrentBotId();
  const { botFeatures } = useBotFeatures(botId);
  const { t } = useSafeTranslation();
  const {
    data: kommoStatus,
    refetch,
    loading: statusLoading,
  } = useKommoIntegrationStatus();
  const { accountName, accountLink, status } =
    kommoStatus?.kommoIntegrationStatus || {};

  const [disconnectKommo, { loading: disconnectLoading }] = useDisconnectKommo({
    onCompleted() {
      refetch();
    },
  });

  const [getUrl, { data: kommoUrl }] = useKommoOauthUrl({
    onCompleted(result) {
      window.open(result.kommoOauthUrl);
    },
  });

  if (!status || !botFeatures?.kommo) return null;

  return (
    <IntegrationContainer
      data-testid="kommo-integration-anchor-button"
      defaultExtended={status === KommoIntegrationStatus.none}
      loading={statusLoading}
      anchor={(extended) => (
        <IntegrationContainerAnchor
          extended={extended}
          imageView={<img src={kommoLogo} width={70} height={24} alt="Kommo" />}
          isConnected={status === KommoIntegrationStatus.connected}
          noteView={<IntegrationContainerEnabledNote />}
          disconnectButtonView={({ getButtonProps }) => (
            <Button
              {...getButtonProps({ onClick: () => disconnectKommo() })}
              loading={disconnectLoading || statusLoading}
              data-testid="kommo-integration-disconnect-button"
            >
              {t('Integrations.disconnect')}
            </Button>
          )}
        />
      )}
    >
      {
        {
          [KommoIntegrationStatus.connected]: accountName && (
            <Type weight="medium">
              <Anchor
                href={accountLink ?? undefined}
                hideArrow
                noUnderline
                data-testid="kommo-integration-account-name"
              >
                {accountName}
              </Anchor>{' '}
              {t('Kommo-connect.isConnected')}
            </Type>
          ),
          [KommoIntegrationStatus.none]: (
            <Button
              fullWidth
              data-testid="kommo-integration-connect-button"
              onClick={() => {
                if (kommoUrl?.kommoOauthUrl)
                  window.open(kommoUrl.kommoOauthUrl);
                else getUrl();
              }}
            >
              {t('Integrations.connect')}
            </Button>
          ),
        }[status]
      }
    </IntegrationContainer>
  );
};

export const KommoIntegrationWrapper: React.FC = () => {
  return (
    <Router history={globalHistory}>
      <ApolloProvider client={client}>
        <KommoIntegration />
      </ApolloProvider>
    </Router>
  );
};
