const LINKS = {
  community: 'https://fb.com/groups/chatfuel',
};

export default class Constants {
  constructor($document) {
    'ngInject';

    this.$document = $document;
  }

  getLink(linkName) {
    return LINKS[linkName];
  }
}
