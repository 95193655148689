import {
  assoc,
  map,
  pipe,
  prop,
  defaultTo,
  __,
  when,
  complement as not,
  isNil,
  objOf,
  path,
  reject,
  compose,
  differenceWith,
  sortWith,
  descend,
  anyPass,
  propEq,
  any,
  equals,
  find,
  tap,
  lensPath,
  over,
} from 'ramda';
import memoize from 'lodash-es/memoize';
import { getBotFeatures } from '@utils/Data/Bot';
import { globalHistory } from '@utils/Routing';
import { attributeIdFromNameAndType } from '../../utils/AttributesUtils/AttributesUtils';
import { operationStringToCodeForParameterType } from '../../common/services/Segmentation';
import { normalizeSpaces } from '../../utils/String/normalizeSpaces';

const triggeredSequencePropName = 'triggered sequences';
let inlineStatsFeatureEnabled;

export class UsersFilterComponentController {
  constructor(
    $rootScope,
    $scope,
    Segmentation,
    BotUserVariable,
    CollectionUtils,
    SegmentService,
    TagsService,
    ApolloService,
    OMNIBOX_SEQUENCES_TITLES_MAP_QUERY,
  ) {
    'ngInject';

    const { segmentsToDatasource } = SegmentService;
    const { findById, findByProp } = CollectionUtils;
    const {
      stringsForParameterType,
      operationCodeToStringForParameterType,
      setParameterAtIndex,
      SEGMENTATION_PARAMETERS_CODE,
      setValuesAtIndex,
      setDataAtIndex,
      updateTypeAtIndex,
      operationForBotVariable,
      setOperationAtIndex,
      mapValueToOperationCodeForType,
      overValue,
      setFilterOperation,
      addNewParameter,
      removeParameterAtIndex,
      defaultParameters,
      isParamEmpty,
      cleanIfOneDefaultParam,
      isSingleValueOperator,
      updateValueForOperationAtIndex,
    } = Segmentation;

    this.TagsService = TagsService;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.tags = [];

    const {
      isDateVariable,
      attributeSuggestToDatasource,
      attributeValuesStringToValues,
      userAttributeSuggestToAutocompleteDatasourceForAttributeName,
    } = BotUserVariable;
    const { onChange } = this;

    const segmentationToOnChangeEvent = objOf('value');
    const fireOnChange = pipe(
      cleanIfOneDefaultParam,
      segmentationToOnChangeEvent,
      onChange,
    );

    const filterVariablesByNameAndType = (name, type) =>
      reject((variable) => variable.name === name && variable.type === type);

    const compareByValue = (datasourceValue, value) =>
      datasourceValue.value === value;

    this.isParamEmpty = isParamEmpty;

    const FILTER_TYPE = 'filter';
    this.parametersOperations = stringsForParameterType(FILTER_TYPE);
    this.filterOperationToString = memoize(
      operationCodeToStringForParameterType(FILTER_TYPE),
    );
    const setFilterOperationForEvent = (event) =>
      setFilterOperation(this.filter, event);
    this._onFilterParameterChange = pipe(
      objOf('newValue'),
      mapValueToOperationCodeForType(FILTER_TYPE),
      setFilterOperationForEvent,
      fireOnChange,
    );

    const featuresFilterWrapper = (type) => {
      if (type !== 'parameter' || inlineStatsFeatureEnabled) {
        return stringsForParameterType(type);
      }
      return stringsForParameterType(type).filter(
        (parameter) => parameter !== 'block',
      );
    };

    const generateDropDownForType = (type, value, onChange) => {
      const excludeParameters = $rootScope.isAutomateEnabled
        ? []
        : [
            SEGMENTATION_PARAMETERS_CODE.sequence,
            SEGMENTATION_PARAMETERS_CODE.block,
          ];
      const parameters = featuresFilterWrapper(type);
      const parametersOnlyIncludes =
        this.includeOnlyParameters &&
        ![
          SEGMENTATION_PARAMETERS_CODE.segment,
          SEGMENTATION_PARAMETERS_CODE.tag,
        ].includes(type)
          ? parameters.filter(
              (parameter) =>
                this.includeOnlyParameters.includes(parameter) &&
                !excludeParameters.includes(parameter),
            )
          : parameters.filter(
              (parameter) => !excludeParameters.includes(parameter),
            );

      return {
        datasource: parametersOnlyIncludes,
        value: operationCodeToStringForParameterType(type, value),
        type: 'dropdown',
        disabled: parametersOnlyIncludes.length < 2,
        onChange,
      };
    };

    this.$onChanges = ($e) => {
      this.filterParamToOmniboxValue.cache.clear();
    };

    this._removeParameterAtIndex = (filter, index) => {
      const newFilter = removeParameterAtIndex(filter, index);
      this.onFilterItemRemove();
      fireOnChange(newFilter);
    };

    this._addButtonClick = pipe(
      tap(() => this.onFilterItemAdd()),
      addNewParameter,
      fireOnChange,
    );

    this.isLastRow = (rowIndex) => {
      return (
        !path(['filter', 'parameters', 'length'], this) ||
        rowIndex === this.filter.parameters.length - 1
      );
    };

    this.filterParamToOmniboxValue = memoize((param, index) => {
      const { type: rawType, name, operation, values } = param;

      // param type normalization
      const type = Object.values(SEGMENTATION_PARAMETERS_CODE).includes(rawType)
        ? rawType
        : SEGMENTATION_PARAMETERS_CODE.custom;

      const {
        filter: initFilter,
        onChange,
        segmentsQueryData = { bot: {} },
        variableValueSuggestQueryData,
        onDataPreloadRequest,
        blocksQueryData = { bot: {} },
      } = this;
      const filter = isParamEmpty(initFilter)
        ? setParameterAtIndex(0, initFilter, {})
        : initFilter;
      const {
        bot: { segments, variableSuggest: botVariableSuggest },
      } = segmentsQueryData;
      const attributeIdFromName = attributeIdFromNameAndType(type);

      const {
        bot: { archiveBlocks = [] },
      } = blocksQueryData;

      const blocks = archiveBlocks.filter(
        (block) => !block.removed && !block.is_flow,
      );

      // -- Kostyl for show flow blocks name in omnibox
      const blockFromFlow = globalHistory.location?.state?.block;
      if (blockFromFlow) {
        blocks.push(blockFromFlow);
      }
      // --

      // TODO: @p2 refcktor to Service file;
      let variableValueSuggest =
        path(['bot', 'variableValueSuggest'], variableValueSuggestQueryData) ||
        [];

      variableValueSuggest = variableValueSuggest.reduce(
        (suggestByKey, suggest) => {
          suggestByKey[suggest.id] = suggest.suggests;
          return suggestByKey;
        },
        {},
      );

      // filter setters
      const setData = setDataAtIndex(index, filter, botVariableSuggest);
      const setOperation = setOperationAtIndex(index, filter);
      const updateValueForOperation = updateValueForOperationAtIndex(index);
      const setValue = setValuesAtIndex(index, filter);
      const resetValueForFilter = setValuesAtIndex(index, __, {
        newValue: { value: [] },
      });
      const suggestForAttributeId = pipe(
        prop(__, variableValueSuggest),
        defaultTo([]),
      );
      const datasourceForAttribute = pipe(
        suggestForAttributeId,
        map(userAttributeSuggestToAutocompleteDatasourceForAttributeName(name)),
      );

      const sequenceById = memoize(
        findById(
          __,
          suggestForAttributeId(attributeIdFromName(triggeredSequencePropName)),
        ),
      );
      const sequenceByName = memoize(
        findByProp(
          'value',
          __,
          suggestForAttributeId(attributeIdFromName(triggeredSequencePropName)),
        ),
      );
      const notNil = not(isNil);
      const valueById = (find, propName) =>
        when(pipe(find, notNil), pipe(find, prop(propName)));
      const setValueById = (find) =>
        overValue(map(when(pipe(find, notNil), pipe(find, prop('id')))));
      const sequenceValue = valueById(sequenceById, 'value');
      const findRemovedSequenceById = findById(
        __,
        this.sequencesTitlesMap || [],
      );

      const removedSequenceValue = when(
        findRemovedSequenceById,
        pipe(findRemovedSequenceById, prop('title')),
      );

      const setSequenceByName = setValueById(sequenceByName);

      const findSegmentById = (id) =>
        find(
          anyPass([
            propEq('id', id),
            pipe(prop('additional_ids'), defaultTo([]), any(equals(id))),
          ]),
        )(segments || []);
      const setSegmentByName = setValueById(findByProp('name', __, segments));
      const setBlockByName = setValueById(findByProp('title', __, blocks));
      const segmentValue = valueById(findSegmentById, 'name');
      const blockValue = valueById(findByProp, 'name');

      // set operations;

      const parameterType = 'parameter';
      // $DropdownChangeEvent => onChange;
      const onParameterChange = pipe(
        setParameterAtIndex(index, filter),
        segmentationToOnChangeEvent,
        onChange,
      );
      const segmentationParameterTypesSelectorConfig = generateDropDownForType(
        parameterType,
        type,
        onParameterChange,
      );
      const setIndexAndType = pipe(
        assoc('parameterIndex', index),
        assoc('type', 'onAttributeValueChange'),
      );
      const fireOnChangeWithType = pipe(
        segmentationToOnChangeEvent,
        setIndexAndType,
        onChange,
      );
      const onOperationChange = pipe(
        mapValueToOperationCodeForType(type),
        setOperation,
        updateValueForOperation,
        fireOnChange,
      );

      const onBlockIsOperationChange = (type) =>
        pipe(
          mapValueToOperationCodeForType(type),
          setOperation,
          updateValueForOperation,
          fireOnChange,
        );

      const cfgInputOnPopover = {
        isInputOnPopover: true,
        popoverWidth: 354,
        popoverYOffset: window.safari !== undefined ? -37 : -39,
      };
      /* eslint-disable no-case-declarations */
      switch (type) {
        case SEGMENTATION_PARAMETERS_CODE.system:
        case SEGMENTATION_PARAMETERS_CODE.custom:
          const operationValue = operationCodeToStringForParameterType(
            type,
            operation,
          );
          const operationValueStartWith = isSingleValueOperator(operationValue);
          const attributeValues = defaultTo([], values);

          const isDate = isDateVariable(param);
          const attributeValue =
            operationValueStartWith && !isDate
              ? attributeValues[0]
              : attributeValues;
          const onChange = pipe(
            overValue(attributeValuesStringToValues(name)),
            setValue,
            fireOnChangeWithType,
          );
          const onFocus = pipe(
            defaultTo({}),
            assoc('parameterIndex', index),
            objOf('parameterData'),
            onDataPreloadRequest,
          );
          const datasourceForAttr = datasourceForAttribute(
            attributeIdFromName(name),
          );
          const sortBySubheader = sortWith([descend(prop('subheader'))]);
          const datasource = compose(
            sortBySubheader,
            differenceWith(compareByValue, datasourceForAttr),
          )(attributeValues);

          return [
            segmentationParameterTypesSelectorConfig,
            {
              type: 'input',
              placeholder: 'lead status',
              allowFreeInput: false,
              value: name,
              datasource: pipe(
                defaultTo([]),
                filterVariablesByNameAndType(name, type),
                attributeSuggestToDatasource,
              )(botVariableSuggest),
              onChange: pipe(
                setData,
                resetValueForFilter,
                fireOnChangeWithType,
              ),
            },
            {
              type: 'dropdown',
              datasource: operationForBotVariable({ name }),
              value: operationValue,
              onChange: onOperationChange,
            },
            {
              type: isDate ? 'date' : 'input',
              allowFreeInput: true,
              placeholder: 'qualified',
              value: attributeValue,
              wide: true,
              datasource,
              onChange,
              onFocus,
              ...cfgInputOnPopover,
            },
          ];
        case SEGMENTATION_PARAMETERS_CODE.sequence:
          const sequenceInitialDatasource = datasourceForAttribute(
            attributeIdFromName(triggeredSequencePropName),
          );
          const sequenceValues = map(
            pipe(sequenceValue, removedSequenceValue),
            values || [],
          );
          const sequenceDatasource = differenceWith(
            compareByValue,
            sequenceInitialDatasource,
            sequenceValues,
          );

          return [
            segmentationParameterTypesSelectorConfig,
            generateDropDownForType(type, operation, onOperationChange),
            {
              type: 'input',
              allowFreeInput: true,
              placeholder: 'lead nurturing',
              value: sequenceValues,
              datasource: sequenceDatasource,
              onChange: pipe(setSequenceByName, setValue, fireOnChangeWithType),
              wide: true,
              ...cfgInputOnPopover,
            },
          ];
        case SEGMENTATION_PARAMETERS_CODE.segment:
          const segmentValues = map(segmentValue, values || []);
          const segmentInitialDatasource = segmentsToDatasource(segments);
          const segmentDatasource = differenceWith(
            compareByValue,
            segmentInitialDatasource,
            segmentValues,
          );

          return [
            segmentationParameterTypesSelectorConfig,
            generateDropDownForType(type, operation, onOperationChange),
            {
              type: 'input',
              allowFreeInput: true,
              placeholder: 'top leads',
              datasource: segmentDatasource,
              value: segmentValues,
              onChange: pipe(setSegmentByName, setValue, fireOnChangeWithType),
              wide: true,
              ...cfgInputOnPopover,
            },
          ];
        case SEGMENTATION_PARAMETERS_CODE.tag:
          const tagValue = valueById(
            (id) => find(propEq('id', id))(this.tags),
            'name',
          );
          const tagValues = map(tagValue, values || []);
          const tagInitialDatasource = this.tags.map((t) => ({
            value: t.name,
          }));
          const tagDatasource = differenceWith(
            (a, b) => a.value === b,
            tagInitialDatasource,
            tagValues,
          );

          return [
            segmentationParameterTypesSelectorConfig,
            generateDropDownForType(type, operation, onOperationChange),
            {
              type: 'input',
              allowFreeInput: true,
              placeholder: 'tags',
              datasource: tagDatasource,
              value: tagValues,
              onChange: pipe(
                setValueById(findByProp('name', __, this.tags)),
                setValue,
                fireOnChangeWithType,
              ),
              wide: true,
              ...cfgInputOnPopover,
            },
          ];
        case SEGMENTATION_PARAMETERS_CODE.block:
          const blockValues = (values || [])
            .filter((id) => id !== undefined)
            .map((value) => {
              const title = pipe(
                find(propEq('id', value)),
                prop('title'),
              )(blocks);

              return title || value;
            });

          const blocksInitialDatasource = blocks.map(({ title }) => ({
            value: title,
          }));

          const blocksDatasource = differenceWith(
            compareByValue,
            blocksInitialDatasource,
            blockValues,
          );

          return [
            segmentationParameterTypesSelectorConfig,
            {
              type: 'input',
              allowFreeInput: true,
              placeholder: 'choose block',
              datasource: blocksDatasource,
              value: blockValues,
              onChange: pipe(setBlockByName, setValue, fireOnChangeWithType),
              wide: true,
              ...cfgInputOnPopover,
            },
            {
              datasource: stringsForParameterType('blockIs'),
              value: operation.indexOf('not_') > -1 ? 'is not' : 'is',
              type: 'dropdown',
              onChange: (changes) => {
                const predicate = changes.newValue.value;
                const currentOperation =
                  operation.indexOf('not_') > -1
                    ? operation.substr(4)
                    : operation;
                const newOperation =
                  predicate === 'is'
                    ? currentOperation
                    : `not_${currentOperation}`;

                pipe(
                  setOperation,
                  updateValueForOperation,
                  fireOnChange,
                )({ ...changes, newValue: { value: newOperation } });
              },
            },
            {
              datasource: stringsForParameterType('block'),
              value: operationCodeToStringForParameterType('block')(
                operation.indexOf('not_') > -1
                  ? operation.substr(4)
                  : operation,
              ),
              type: 'dropdown',
              onChange: (changes) => {
                const newOperation = operationStringToCodeForParameterType(
                  'block',
                )(changes.newValue.value);
                const currentPredicate =
                  operation.indexOf('not_') > -1 ? 'not_' : '';
                const newValue = `${currentPredicate}${newOperation}`;

                pipe(
                  setOperation,
                  updateValueForOperation,
                  fireOnChange,
                )({ ...changes, newValue: { value: newValue } });
              },
            },
          ];

        default:
          throw new Error('Unknown param type');
      }
    });

    this.getParameters = () => {
      return isParamEmpty(this.filter)
        ? defaultParameters
        : this.filter.parameters;
    };

    (async () => {
      const botFeatures = await getBotFeatures($rootScope.stateParams.botId);
      inlineStatsFeatureEnabled = botFeatures.inline_stats_ro;
    })();

    (async () => {
      this.sequencesTitlesMap = path(
        ['data', 'bot', 'sequencesTitlesMap'],
        await ApolloService.query({
          query: OMNIBOX_SEQUENCES_TITLES_MAP_QUERY,
          variables: {
            botId: $rootScope.stateParams.botId,
          },
          fetchPolicy: 'network-only',
        }),
      );
      this.filterParamToOmniboxValue.cache.clear();
    })();
  }

  $onInit() {
    this.tagsSub = this.TagsService.getTags$(
      this.$rootScope.stateParams.botId,
    ).subscribe(({ data }) => {
      this.tags = data?.bot?.tags || [];
    });
  }
  $onDestroy() {
    this.tagsSub?.unsubscribe();
  }
}
