import template from './menu-tree-item.html';
import controller from './controller.js';

export const Component = {
  controller,
  template: () => template,
  controllerAs: '$menuTreeItem',
  bindings: {
    item: '=',
    index: '=',
    menu: '=',
    bot: '=',
    level: '@',
  },
};
