/**
 * OneElementQueue class
 */
class OneElementQueue {
  /**
   * @description constructor
   * @param {Function} factory -
   */
  constructor(factory) {
    // function (T) => Promise<V>  create promises;
    this._factory = factory;
    // used to track last request in a queue; basicaly id of the last request byt we use Symbol for that;
    this._lastRequestSymbolForSynk = null;
    // promise< void | any> holds link on currently executed request (chained request in order);
    this._currentRequest = null;
    this.inProgress = false;
  }

  /**
   * @param {*} args -
   * @return {Promise} -
   */
  async queue(...args) {
    const syncSymbol = Symbol('OneElementQueue sync symbol');
    this._lastRequestSymbolForSynk = syncSymbol;
    this.inProgress = true;
    const currentRequest = this._currentRequest || Promise.resolve();
    /**
     * @param {Error} e -
     * @return {Promise} -
     */
    const errorHandler = (e) => {
      const isThisLastRequest = this._lastRequestSymbolForSynk === syncSymbol;
      console.error('OneElementQueue request error', isThisLastRequest, e);
      // it we must resolve promise for memory concerns
      // but we should  fire request or report error only for last one in the queue;
      if (isThisLastRequest) {
        // clear rejected promise chaine or next promise will be rejected;
        this._currentRequest = null;
      }

      return isThisLastRequest ? Promise.reject(e) : undefined;
    };
    /**
     * @param {Object} res -
     * @return {*} -
     */
    const successHandler = (res) => {
      const isThisLastRequest = this._lastRequestSymbolForSynk === syncSymbol;
      // it we must resolve promise for memory concerns
      // but we should  fire request or report error only for last one in the queue;
      if (isThisLastRequest) {
        this.inProgress = false;
        return this._factory(...args);
      }
      return undefined;
    };

    this._currentRequest = currentRequest.then(successHandler, errorHandler);
    return this._currentRequest;
  }
}

/**
 * @description OneElementQueue service class
 */
export default class OneElementQueueService {
  /**
   * @description constructor
   */
  constructor() {
    this.queue = {};
  }

  /**
   * @param {String} id -
   * @param {Function} factory -
   * @return {*} -
   */
  getQueue(id, factory) {
    this.queue[id] = this.queue[id] || new OneElementQueue(factory);
    return this.queue[id];
  }

  removeQueue(id) {
    delete this.queue[id];
  }
}
