import { getPluginsDefaultData } from '../../../modern-components/Plugins/common/pluginConst';
import { PluginType } from '../../../modern-components/Plugins/common/PluginTypes';

const ANGULAR_LAYOUT_PLUGINS = [
  PluginType.typing,
  PluginType.audio,
  PluginType.video,
  PluginType.go_to_block_plugin,
  PluginType.email,
  PluginType.google_sheet,
  PluginType.integration_zapier_plugin,
  PluginType.event_trigger,
  PluginType.setup_variable_plugin,
];

const REACT_LAYOUT_PLUGINS = Object.keys(getPluginsDefaultData())
  .filter((key) => !ANGULAR_LAYOUT_PLUGINS.includes(key))
  .concat('quick_reply');

export default class CardController {
  constructor($scope, $rootScope, $element, $timeout, PluginCardService) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$element = $element;
    this.$timeout = $timeout;
    this.PluginCardService = PluginCardService;
    this.$error = {
      message: 'Set up at least one more item field: subtitle, button or image',
    };

    $scope.$on('gallery-sort-stop', () => {
      this.validate();
    });
    $scope.$on('$buttonUpdate', () => {
      this.validate();
    });
    $scope.$on('gallery-sort-start', (event, data) => {
      if (this.card && data.id === this.card.id) {
        $scope.$evalAsync(() => {
          this.$error.show = false;
        });
      }
    });
  }

  $onInit() {
    this.validate();
    // @todo: in better way
    // hide tooltip on focus any input
    this.$element.on('focus', 'input, textarea', () => {
      this.$error.show = false;
    });

    this.$scope.$on('$validateCard', () => {
      this.validate();
    });

    if (this.item.new) {
      delete this.item.new;
      setTimeout(() => {
        this.$element.find('.title textarea')[0].focus();
      }, 50);
    }
  }

  $onDestroy() {
    this.$element.off('focus');
  }

  validate() {
    this.$timeout(() => {
      if (this.$rootScope.buttonEditorOpen) {
        return;
      }

      this.$error.show = false;
      if (
        this.item.title &&
        !this.item.subtitle &&
        !this.item.buttons.length &&
        !this.item.image_url
      ) {
        if (this.item.plugin_id) {
          return;
        }
        this.$error.show = true;
      }
    }, 300);
  }

  save(item, index, event) {
    if (event && event.target === document.activeElement) {
      this.PluginController.save(this.item, this.index);
    } else {
      this.validate();
      this.PluginController.save(this.item, this.index);
    }
  }

  getCardBoxClass = () =>
    !REACT_LAYOUT_PLUGINS.includes(this.item.plugin_id) &&
    !this.PluginCardService.isCommentPlugin(this.item.plugin_id)
      ? 'card'
      : '';
}
