import { findIndex, propEq, path } from 'ramda';

export default class AdminsController {
  constructor(ModalService, BotService, $rootScope, $scope) {
    'ngInject';

    this.ModalService = ModalService;
    this.BotService = BotService;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
  }

  onRemoveAdmin = (userId) => {
    // for sync bot data from react to ng
    const { status } = this.bot;
    if (path(['page_info', 'owner', 'id'], status) === userId) {
      delete status.page_info;
      status.page = null;
      status.status = 'draft';
      this.$rootScope.$broadcast('$updateBotStatus', {
        id: this.bot.id,
        status: status.status,
        read_only: status.read_only,
        pageId: status.page,
        ownerId: null,
      });
      this.$rootScope.$broadcast('$fbClickEvent', true);
      this.$scope.$emit('$botUnBindEd');
    }
    const index = findIndex(propEq('id', userId), this.bot.admins);
    this.bot.admins.splice(index, 1);
  };
}
