import { getCurrencies } from '@utils/Data/Currency';
import { formatPrice } from '../../utils/Format';

const CURRENCY_LOCAL_STORAGE_KEY_PREFIX = '_cf_currency_code_for_bot';

export class CurrencyService {
  constructor($rootScope, $http, $filter, StoreService, LocalStorageService) {
    'ngInject';

    this.$http = $http;
    this.$rootScope = $rootScope;
    this.$filter = $filter;
    this.LocalStorageService = LocalStorageService;
    this.StoreService = StoreService;

    this.currenciesInfo = [];

    this._loadCurrenciesInfo();
  }

  async _loadCurrenciesInfo() {
    this.currenciesInfo = await getCurrencies();
  }

  _createLocalStorageKey() {
    return `${CURRENCY_LOCAL_STORAGE_KEY_PREFIX}_${this.$rootScope.stateParams.botId}`;
  }

  getCurrencies() {
    return this.currenciesInfo;
  }

  getCurrenciesCodes() {
    return this.currenciesInfo.map((item) => item.code);
  }

  setCurrencyForBot(currencyCode) {
    this.LocalStorageService.setItem(
      this._createLocalStorageKey(),
      currencyCode,
    );
  }

  getCurrencyForBot() {
    return (
      this.LocalStorageService.getItem(this._createLocalStorageKey()) || 'USD'
    );
  }

  formatPrice(amount, currency) {
    return formatPrice(amount * 0.01, {
      currency,
    });
  }

  isValidPayLimit(amount, currencyCode) {
    return currencyCode !== 'USD' || amount > 49;
  }
}
