import { sendEvent } from '../../../utils/Analytics';
import {
  toaster,
  Messages,
} from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2';
import { isFileSizeSameOrLess } from '../../../utils/fileUtils';

export default class CardButtonsTopController {
  constructor($interpolate, $timeout, Upload, ModalService, StoreService) {
    'ngInject';

    this.$interpolate = $interpolate;
    this.$timeout = $timeout;
    this.Upload = Upload;
    this.ModalService = ModalService;
    this.StoreService = StoreService;
  }

  upload(file, err) {
    if (err.length || (file && !isFileSizeSameOrLess(file, 10))) {
      return this.ModalService.imageSize();
    }
    if (file) {
      this.loading = true;
      file.upload = this.Upload.upload({
        url: `${this.StoreService.getApiUrl()}/imgupload`,
        data: { file },
      })
        .then((response) => {
          this.$timeout(() => {
            this.item.config.gallery_cards[
              this.index
            ].image_url = `${response.data.result}`;
            this.CardController.save();
            this.loading = false;
          });
        })
        .catch(() => {
          this.loading = false;
          toaster.show({
            type: ServiceMessageType.error,
            payload: {
              message: Messages.somethingWentWrong,
            },
          });
        });
    }

    return undefined;
  }

  remove($event) {
    $event.preventDefault();
    const context = this.$interpolate(this.ModalService.templates.card())();
    this.ModalService.confirm(context).then((isConfirmed) => {
      if (isConfirmed) {
        this.PluginController.remove(this.index);
        sendEvent({
          category: 'plugin gallery',
          action: 'item_remove',
          value: this.item.config.gallery_cards.length,
        });
      }
    });
  }
}
