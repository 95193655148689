import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Avatar } from '@ui/Avatar';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { TextEllipsis } from '@ui/TextEllipsis';
import * as navigationCSS from '@components/LeftNavigationPanel/components/NavItems/NavItem.css';
import { ReactComponent as NotificationIcon } from '@ui/_deprecated/Icon/icons/notification.svg';
import { UserNavigationItemCommonProps } from '../types';
import * as css from './UserNavigationItem.css';
import * as commonCSS from '../UserNavigationItemButton.css';

export interface UserNavigationItemProps extends UserNavigationItemCommonProps {
  name: Maybe<string>;
}

export const UserNavigationItem: React.FC<UserNavigationItemProps> = ({
  src,
  name,
  hasNotification,
  isPremium,
}) => (
  <Flex
    alignItems="center"
    flexDirection="row"
    justifyContent="space-between"
    className={navigationCSS.wrapper}
  >
    <Flex alignItems="center" flexDirection="row" className={css.content}>
      <Spacer horizontalFactor={1.5} />
      {hasNotification ? (
        <NotificationIcon className={cn(commonCSS.notification, css.icon)} />
      ) : (
        <Avatar src={src} customSize={14} className={css.icon} />
      )}
      <Spacer horizontalFactor={1.5} />
      <Type size="12px" color="grey" weight="medium" className={css.userName}>
        <TextEllipsis>{name || 'User'}</TextEllipsis>
      </Type>
      {isPremium && (
        <>
          <Spacer horizontalFactor={1.5} />
          <Icon icon="star" color="blue" size="16px" />
          <Spacer horizontalFactor={2} />
        </>
      )}
    </Flex>
    <Icon
      icon="arrow_right"
      color="grey"
      size="16px"
      className={css.iconRight}
    />
  </Flex>
);
