import ng from 'angular';
import './assets/less/persistent-menu.less';

import menusList from './menus-list';
import menuLocal from './menu-local';

import { Component } from './component';

export default ng
  .module('app.common.settings.persistentMenu', [menusList, menuLocal])
  .component('persistentMenu', Component).name;
