import CardPluginBaseController from '../card-plugin-base-controller/controller';

function normalizeUrl(url) {
  return url.replace('https://www.dropbox.com/', 'https://dl.dropbox.com/');
}

export default class CardVideoController extends CardPluginBaseController {
  $onInit() {
    this.loading = false;
    if (!this.item.id) {
      this.save();
    }
    if (this.item.is_valid === false) {
      this.data = this.item.validation_details || {};
      this.data.card_id = this.item.id;
      this.$rootScope.$broadcast('$pluginSaveResultError', { data: this.data });
    } else {
      this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
    }

    this.errorMessage = 'Please, try again. Something went wrong :(';

    this.docLink = 'https://docs.chatfuel.com/';
  }

  isInValid() {
    if (!this.item.config || !this.item.config.url) {
      // cannot be sure it is invalid;
      return false;
    }
    return (
      this.item.config.url.length &&
      (!this.item.is_valid || this.hasValidationError)
    );
  }

  handleInputBlur() {
    this.loading = true;
    this.item.config.url = normalizeUrl(this.item.config.url);
    this.saveMediaUpload({ cardId: this.item.id }).then((errorMessage) => {
      this.loading = false;
      this.errorMessage = errorMessage;
    });
  }
}
