import ng from 'angular';
import { react2angular } from 'react2angular';
import { OptionsButton } from './OptionsButton';

export const ngOptionsButton = ng
  .module('app.common.optionsButton', [])
  .component(
    'optionsButton',
    react2angular(OptionsButton, [
      'itemId',
      'blockId',
      'blockTitle',
      'botId',
      'onRemove',
      'pluginType',
      'onOpenChange',
      'onUpdate',
    ]),
  ).name;
