import React from 'react';

import PrioritySupportIcon from './images/priority_support.png';
import TeamAccessIcon from './images/team_access.png';
import FbAdsTargetingIcon from './images/dart_pie.png';
import RemoveBrandingIcon from './images/remove_branding.png';
import DataExportIcon from './images/data_export.png';
import SegmentAudienceIconUrl from './images/segment_audience.png';

export interface Advantage {
  title: string;
  description: React.ReactNode;
  icon: string;
}

export const proAdvantages: Advantage[] = [
  {
    title: 'Remove Branding',
    icon: RemoveBrandingIcon,
    description:
      "No Chatfuel branding on your bot. Focus the users' attention on the value you bring.",
  },
  {
    title: 'Priority Support',
    icon: PrioritySupportIcon,
    description:
      'Need help setting up your bot? Access prioritized email support to get everything working the way it should in no time!',
  },
  {
    title: 'Facebook Ads Targeting',
    icon: FbAdsTargetingIcon,
    description:
      'Looking for new users who are more likely to convert to paying customers? Export data to Facebook Ads Manager for ultimate targeting and reduced acquisiton costs.',
  },
  {
    title: 'Team Access',
    icon: TeamAccessIcon,
    description:
      'Build bots together with your teammates. Invite others and manage access permissions for effective team collaboration. For example, allow service reps to access only Live Chat functionality.',
  },
  {
    title: 'Data Export',
    icon: DataExportIcon,
    description: (
      <>
        Want to see all your users and their attributes in a&nbsp;spreadsheet?
        Export any user segment directly to a&nbsp;CSV file and analyze away!
      </>
    ),
  },
  {
    title: 'Segment Your Audience',
    icon: SegmentAudienceIconUrl,
    description: (
      <>
        People view your offer but do not progress to purchase? Create
        auto-updating user segments and <br />
        reengage them to increase conversions.
      </>
    ),
  },
];
