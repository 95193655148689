import angular from 'angular';
import 'angular-chart.js';

import './assets/less/statistics-global.less';
import './assets/less/statistics.less';

import StatisticsService from './service.js';
import { ngAnalyticsPlatformSelector } from './components/AnalyticsPlatformSelector';

export default angular
  .module('app.common.statistics', [ngAnalyticsPlatformSelector])
  .factory('StatisticsService', StatisticsService).name;
