import memoize from 'lodash-es/memoize';
import {
  cond,
  test,
  replace,
  T,
  identity,
  isNil,
  sortWith,
  prop,
  descend,
  ascend,
} from 'ramda';
import { Level, log } from 'cf-common/src/logger';
import locales from './locales.json';
import { ATTRIBUTE_TYPE_CODES } from './consts';
import { getAttributesDiff } from './getAttributesDiff';
import OneElementQueue from '../../common/services/OneElementQueueService.js';
import { Platform } from '../../../@types/globalTypes';

const SIGN_UP_DATE_FIELD_NAME = 'signed up';
const DEFAULT_USER_NAME = 'Unknown User';
const TIMESTAMP_OF_STATS_COUNTS_RELEASE = 1507672800000;
const IMAGE_SHACK_REG_EXP = new RegExp(
  '^(http(s)?:)?\\/\\/imagizer\\.imageshack\\.[a-z]{2,3}\\/',
  'i',
);
const CH_IMAGE_REF = '/raw/';
const TARGE_SIZING = '/250x250/';

const isImageShackUrl = test(IMAGE_SHACK_REG_EXP);
const isImageCFUrl = (str) => str.includes(CH_IMAGE_REF);
const replaceImageShackURLToTarget = replace(/\/\d+x\d+.*?\//, TARGE_SIZING);
const replaceImageCFURLToTarget = replace(CH_IMAGE_REF, TARGE_SIZING);
export const SKIP_REQUEST_ERROR = 'skip request';

/**
 * atrribute comporator, sort by type (custom first, then by count, then by string);
 * @param {{type: string, count:number, name:string}} attr - attribute
 * @returns {number} - type to count to be able to compare;
 */
const attrTypeToNumber = (attr) =>
  attr.type === ATTRIBUTE_TYPE_CODES.custom ? 1 : 0;
const customFirst = descend(attrTypeToNumber);
const moreCountFirst = descend(prop('count'));
const byName = ascend(prop('name'));
export const sortAttributes = sortWith([customFirst, moreCountFirst, byName]);
const oneElementQueue = new OneElementQueue();

/**
 * @description PeopleService
 */
export class PeopleService {
  /**
   * @description constructor
   * @param {*} $http -
   * @param $rootScope
   * @param {*} StoreService -
   * @param {*} BotService -
   * @param {*} $log -
   * @param {*} $timeout -
   * @param {*} OmniboxService -
   */
  constructor(
    $http,
    $rootScope,
    StoreService,
    BotService,
    $log,
    $timeout,
    OmniboxService,
  ) {
    'ngInject';

    this.$http = $http;
    this.$rootScope = $rootScope;
    this.StoreService = StoreService;
    this.BotService = BotService;
    this.$timeout = $timeout;
    this.OmniboxService = OmniboxService;

    this.getSuggestedNamesForUserCustomAttributes = memoize(
      this.getSuggestedNamesForUserCustomAttributes.bind(this),
    );

    this.usersRequestQueueOperator = oneElementQueue.getQueue('users', $http);
  }

  /**
   *  @description: navigate to people tab and open @filter
   *  @param {Segmentation} filter - filter to open
   *  @param {Object} stateParams - any additional state params.
   *  @return {void} -
   */
  navigatePeopleTabWithFilter(filter, stateParams = {}) {
    this.$rootScope.stateHistory.push(
      `/bot/${this.$rootScope.stateParams.botId}/users`,
      {
        ...this.$rootScope.stateLocation.state,
        filter,
      },
    );
  }

  /**
   * @description get and prepare user list
   * @param {Number} offset - request offser
   * @param {Boolean} desc - sort direction
   * @param {String} sort - field name for sort
   * @param {Object} filter - filter rules
   * @return {Promise.<*>} -
   */
  async list(offset = 0, desc, sort = '', filter = {}) {
    // eslint-disable-next-line global-require
    const { StoreService, $http } = this;
    const API_URL = StoreService.getApiUrl();
    const url = `${API_URL}/bots/${this.$rootScope.stateParams.botId}/users`;
    const requestResult = await this.usersRequestQueueOperator.queue({
      method: 'post',
      url,
      data: filter,
      params: {
        offset,
        sort,
        desc: !!desc,
      },
    });
    if (!requestResult) {
      throw new Error(SKIP_REQUEST_ERROR);
    }
    const {
      data: { result },
    } = requestResult;
    result.users = result.users.map((userArray) => {
      const initUser = {
        /**
         * -
         * @return {boolean} -
         */
        isUserSingedUpAfterSessionCountRelease() {
          const { variables } = this;
          const signedUpDateAttribute = variables.find(
            ({ name, type }) =>
              name === SIGN_UP_DATE_FIELD_NAME &&
              type === ATTRIBUTE_TYPE_CODES.system,
          );

          if (!signedUpDateAttribute) {
            log({
              msg: "User don't have 'signed up' attribute.",
              level: Level.warn,
              data: {
                currentUserVariables: variables,
              },
            });
            return false;
          }

          const signedUpDate = signedUpDateAttribute.values[0];
          return signedUpDate <= TIMESTAMP_OF_STATS_COUNTS_RELEASE;
        },
        /**
         * @description generate full name
         * @return {string} -
         */
        get fullName() {
          // to support camel case;
          const {
            last_name: lastName,
            first_name: firstName,
            instagram_name: instagramName,
            instagram_handle: instagramHandle,
            whatsapp_name: whatsappName,
            whatsapp_phone: whatsappPhone,
          } = this;
          const facebookName = firstName
            ? `${firstName} ${lastName}`.trim()
            : undefined;
          return (
            facebookName ??
            instagramName ??
            instagramHandle ??
            whatsappName ??
            whatsappPhone ??
            DEFAULT_USER_NAME
          );
        },

        /**
         * @description generate vars count
         * @return {Number} -
         */
        get vars_count() {
          if (!this.variables) {
            return 0;
          }
          return this.variables.reduce(
            (count, variable) =>
              variable.type === ATTRIBUTE_TYPE_CODES.custom ? ++count : count,
            0,
          );
        },
      };

      const user = userArray.reduce((user, variable) => {
        if (variable.type === ATTRIBUTE_TYPE_CODES.system) {
          // ???: to save current user type;
          const oldValueExists = user[variable.name];
          if (oldValueExists === undefined) {
            // eslint-disable-next-line prefer-destructuring
            user[variable.name.replace(/\s/gi, '_')] = variable.values[0];
          }
          if (variable.name === 'user id') {
            // eslint-disable-next-line prefer-destructuring
            user.id = variable.values[0];
          }
          // to move;
          if (user.locale) {
            user.locale_string =
              user.locale === 'ar_AR'
                ? 'ae' // use ae (Arabic Emirates) code (for show correct flag icon)
                : user.locale.split('_')[1].toLowerCase();
            user.locale_title = `${user.locale} ${this.getLanguageByLocale(
              user.locale,
            )}`;
          }
        }
        return user;
      }, initUser);

      user.tags = userArray
        .filter((variable) => variable.type === ATTRIBUTE_TYPE_CODES.tag)
        .map((variable) => ({ name: variable.name, id: variable.values[0] }));
      user.variables = userArray.map((attr) => ({ id: attr.name, ...attr }));
      user.profile_pic_url = this._replaceUserPicSize(user.profile_pic_url);

      return user;
    });
    return result;
  }

  /**
   * @description replace size if pis place on Imageshack
   * @param {String} picUrl -
   * @return {*} -
   * @private
   */
  _replaceUserPicSize(picUrl) {
    return cond([
      [isNil, identity],
      [isImageShackUrl, replaceImageShackURLToTarget],
      [isImageCFUrl, replaceImageCFURLToTarget],
      [T, identity],
    ])(picUrl);
  }

  /**
   * -
   * @return {number} -
   */
  getTimestampOfStatsCountRelease() {
    return TIMESTAMP_OF_STATS_COUNTS_RELEASE;
  }

  /**
   * @description  sort attributes: custom first
   * @param {any[]} attrs -
   * @return {[*]} -
   */
  sortAttributesCustomFirst(attrs) {
    const sortedAttr = [...attrs];

    sortedAttr.sort((a, b) => {
      const ia = a.type === ATTRIBUTE_TYPE_CODES.custom;
      const ib = b.type === ATTRIBUTE_TYPE_CODES.custom;
      if (ia === ib) {
        return a.name.localeCompare(b.name);
      }
      return ia > ib ? -1 : 1;
    });
    return sortedAttr;
  }

  /**
   * @description filter attributes by types
   * @param {any[]} attrs -
   * @param {string[]} types -
   * @return {[Object]} -
   */
  filterAttributesByTypes(attrs, types) {
    return attrs.filter((attr) => types.includes(attr.type));
  }

  /**
   * -
   * @param {Boolean} refresh -
   * @return {Promise <Array<string>>} -
   */
  async getListOfSuggestedCustomAttributesNames(refresh) {
    const { BotService } = this;
    const {
      getAttributesAsync,
      // eslint-disable-next-line global-require
    } = require('../../utils/AttributesUtils/AttributesUtils.ts'); // hide TS module for global unit test
    const allAttributesSuggestList = await getAttributesAsync(
      this.$rootScope.stateParams.botId,
      undefined,
      Platform.facebook,
    );
    return allAttributesSuggestList
      .filter((attribute) => attribute.type === ATTRIBUTE_TYPE_CODES.custom)
      .map((attribute) => attribute.name);
  }

  /**
   * -
   * @param {[Object]} attributes -
   * @param {[String]} currentSuggests -
   * @return {[String]} -
   */
  getSuggestedNamesForUserCustomAttributes(attributes = [], currentSuggests) {
    const attributesNamesThatUserAlreadyUsed = new Set();
    attributes.forEach((userAttribute) =>
      attributesNamesThatUserAlreadyUsed.add(userAttribute.name),
    );
    return currentSuggests.filter(
      (suggestedName) => !attributesNamesThatUserAlreadyUsed.has(suggestedName),
    );
  }

  getAttributesDiff(attributes, oldAttributes) {
    return getAttributesDiff(attributes, oldAttributes);
  }

  /**
   * -
   * @param {[Object]} attributes -
   * @param {Object} user -
   */
  async updateAttributesForUser(attributes, user) {
    await this._saveAttributesDiff(
      this.getAttributesDiff(attributes, user.variables),
      user.id,
    );
  }

  /**
   * -
   * @param {Object} diff -
   * @param {String} userId -
   * @return {Promise} -
   * @private
   */
  _saveAttributesDiff(diff, userId) {
    // POST /bots/:botId/user/:userId/attributes -d {update:[], delete:[]}
    const { $http, StoreService } = this;
    return $http({
      method: 'post',
      url: `${StoreService.getApiUrl()}/bots/${
        this.$rootScope.stateParams.botId
      }/user/${userId}/attributes`,
      data: diff,
    });
  }

  /**
   * -
   * @param {String} localeName -
   * @return {String} -
   */
  getLanguageByLocale(localeName) {
    if (!localeName) {
      return null;
    }
    const locale = locales[localeName.split('_')[0].toLowerCase()];
    return locale ? `(${locale.name})` : null;
  }

  /**
   * @description is show people tab
   * @param {String} botId -
   * @return {Promise} -
   */
  isShowPeopleTab(botId) {
    return this.$http({
      method: 'get',
      cacheOptions: {
        need: true,
      },
      url: `${this.StoreService.getApiUrl()}/bots/${
        botId || this.$rootScope.stateParams.botId
      }/people_tab`,
    }).then(
      (res) => !!res.data.result,
      () => false,
    );
  }

  /**
   * -
   * @param {[Object]} attributes -
   * @return {Object[]} -
   */
  filterAndSortCustomFirstAttributes(attributes) {
    const filteredAttributes = this.filterAttributesByTypes(attributes, [
      ATTRIBUTE_TYPE_CODES.system,
      ATTRIBUTE_TYPE_CODES.custom,
    ]);
    return this.sortAttributesCustomFirst(filteredAttributes);
  }

  /**
   * -
   * @return {String[]} -
   */
  getAttributeTypeCodes() {
    return ATTRIBUTE_TYPE_CODES;
  }
}
