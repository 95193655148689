import {
  getAttributesAsync,
  getAttributesQueryObservable,
} from '../../../../utils/AttributesUtils/AttributesUtils';
import { Platform } from '../../../../../@types/globalTypes';
import { VariableSuggestType } from '../../../../utils/AttributesUtils/AttributesUtilsTypes';

export default class SequenceUserFilterController {
  constructor(
    $scope,
    BotService,
    $timeout,
    $rootScope,
    $element,
    StoreService,
    PeopleService,
    UserFilterService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$scope = $scope;
    this.BotService = BotService;
    this.$element = $element;
    this.$rootScope = $rootScope;
    this.StoreService = StoreService;
    this.PeopleService = PeopleService;
    this.UserFilterService = UserFilterService;

    $scope.$on('$angularClick', () => {
      $scope.$evalAsync(() => {
        this.$showSelect = false;
        this.valuesPopUpOpen = null;
      });
    });
    $rootScope.$on('$suggestFocusedGlobal', () => {
      this.$showSelect = false;
      this.valuesPopUpOpen = null;
    });
    $rootScope.$on('$popupValueChange', (event, res) => {
      if (this.cardId === res.cardId) {
        if (
          ((res.previousValue === 'is' || res.previousValue === 'is_not') &&
            this.iCond.indexOf(res.value)) !== -1 ||
          (this.iCond.indexOf(res.value) !== -1 &&
            (res.value === 'is' || res.value === 'is_not'))
        ) {
          event.targetScope.$parent.filter.values = [];
        }
        this.$scope.$emit('$saveSequenceUserFilter');
      }
    });

    this.inputModels = [];

    if (this.index) {
      this.showValuesPopUp(this.index);
    }

    if (!this.userFilter.parameters || !this.userFilter.parameters.length) {
      this.add();
    }

    this.valueDropdown = {
      is: 'IS',
      is_not: 'IS NOT',
      starts_with: 'STARTS WITH',
      lt: '<',
      gt: '>',
    };
    this.iCond = ['starts_with', 'lt', 'gt'];

    this.conditionsOnly = {
      'rss and search subscriptions': ['is', 'is_not'],
      'within 24h window': ['is', 'is_not'],
    };

    this.attributes = [];
  }

  $onInit() {
    this.attributesSubscription = getAttributesQueryObservable(
      this.$rootScope.stateParams.botId,
      VariableSuggestType.template,
      Platform.facebook,
    ).subscribe((allBotAttributesForSuggest) => {
      this.attributes = this.PeopleService.filterAndSortCustomFirstAttributes(
        allBotAttributesForSuggest,
      );
    });

    if (this.userFilter.parameters) {
      this.userFilter.parameters.forEach((filter) => {
        this._loadValues(filter.name, true);
      });
    }
  }

  $onDestroy() {
    this.attributesSubscription.unsubscribe();
  }

  compileUserText(n) {
    return `${String(n).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')} ${
      Number(n) === 1 ? 'user' : 'users'
    }`;
  }

  add() {
    if (!this.userFilter.parameters) {
      this.userFilter.parameters = [];
    }

    this.userFilter.parameters.push({ name: '', values: [], operation: 'is' });

    this.$scope.$emit('$modalResize');
  }

  valuesClick($event, $index) {
    $event.stopPropagation();
    this.$showSelect = false;
    $event.currentTarget.querySelector('.input').focus();
    this.valuesPopUpOpen = $index;
    this.popupAct = -1;
  }

  showValuesPopUp($index) {
    this.$timeout(() => {
      this.valuesPopUpOpen = $index;
      const inp = this.$element[0].querySelectorAll('.filter-item .input')[
        $index
      ];
      if (inp) {
        inp.focus();
      }
    });
  }

  revmoveTT() {
    [].forEach.call(document.querySelectorAll('.system-tooltip'), (tt) => {
      tt.remove();
    });
  }

  onVariableClick(key, filter, filterIndex) {
    filter.name = key;
    filter.values = [];
    this.showValuesPopUp(filterIndex);
    this.revmoveTT();

    this._loadValues(key);
  }

  variablesFilter(key) {
    if (!this.userFilter || !this.userFilter.parameters) {
      return true;
    }

    return (
      typeof this.userFilter.parameters.find((item) => item.name === key) ===
      'undefined'
    );
  }

  attrFilter(attr) {
    const forbiddenAttrs = [
      'within 24h window',
      'rss and search subscriptions',
    ];
    if (forbiddenAttrs.includes(attr)) {
      return false;
    }
    return true;
  }

  clear(s) {
    return s
      .replace(/( |&nbsp;)+/g, ' ')
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/&amp;/gi, '&')
      .trim();
  }

  filterShow(filter, filterIndex) {
    const values = this._getValues(filter.name);
    return (
      values &&
      values.filter(
        (item) =>
          !this.inputModels[filterIndex] ||
          item.name.indexOf(this.inputModels[filterIndex]) !== -1,
      )
    );
  }

  onInputFocus(e, index) {
    if (!this.userFilter.parameters[index].name) {
      // this.$showSelect = index;
      this.valuesPopUpOpen = null;
      // e.target.blur();
    }
    this.popupAct = -1;
    window.angular.element(e.target).parent().parent().scrollLeft(0);

    this.focused = index;
  }

  onInputBlur(e, index) {
    window.angular.element(e.target).parent().parent().scrollLeft(0);

    if (e.target.innerText.trim().length > 0) {
      this.addVal(e.target.innerText.trim(), index);
      e.target.innerText = '';
      this.inputModels[index] = '';
    }
    this.$scope.$emit('$saveSequenceUserFilter');

    this.focused = false;
  }

  onKeyDown(e, filterIndex) {
    if (e.keyCode === 8) {
      if (e.target.innerText.trim().length === 0) {
        this.userFilter.parameters[filterIndex].values.pop();
        e.stopPropagation();
        e.preventDefault();
        this.$scope.$emit('$saveSequenceUserFilter');
      }
    } else if (e.keyCode === 13) {
      this.userFilter.parameters.push();
      e.stopPropagation();
      e.preventDefault();

      const filter = this.userFilter.parameters[filterIndex];
      const pars = this.filterShow(filter, filterIndex);

      if (pars[this.popupAct]) {
        this.addVal(pars[this.popupAct].name, filterIndex);
      } else if (e.target.innerText.trim().length) {
        this.addVal(e.target.innerText.trim(), filterIndex);
      }

      e.target.innerHTML = '';
      this.inputModels[filterIndex] = '';

      this.$scope.$emit('$saveSequenceUserFilter');

      this.popupAct = -1;
    } else if (e.keyCode === 38) {
      if (this.popupAct > 0) {
        this.popupAct--;
      }
      e.stopPropagation();
      e.preventDefault();
    } else if (e.keyCode === 40) {
      const filter = this.userFilter.parameters[filterIndex];
      if (this.popupAct < this.filterShow(filter, filterIndex).length - 1) {
        this.popupAct++;
      }
      e.stopPropagation();
      e.preventDefault();
    }
  }

  removeValueItem(event, index, filterIndex) {
    event.preventDefault();
    event.stopPropagation();
    this.userFilter.parameters[filterIndex].values.splice(index, 1);
    this.$scope.$emit('$saveSequenceUserFilter');
  }

  addVal(value, index) {
    value = value.trim();
    const ind = this.userFilter.parameters[index].values.indexOf(value);
    if (ind !== -1) {
      this.userFilter.parameters[index].values.splice(ind, 1);
    }

    this.userFilter.parameters[index].values.push(value);
  }

  onSimpleInputBlur(e, index) {
    if (e.target.value.trim().length > 0) {
      this.userFilter.parameters[index].values[0] = e.target.value.trim();
    } else {
      this.userFilter.parameters[index].values = [];
    }
    this.$scope.$emit('$saveSequenceUserFilter');
  }

  onSimpleInputKeyDown(e) {
    if (e.keyCode === 13) {
      e.stopPropagation();
      e.preventDefault();
      e.target.blur();
    }
  }

  async _loadValues(attributeName) {
    await getAttributesAsync(
      this.$rootScope.stateParams.botId,
      undefined,
      Platform.facebook,
    );
    const attr = this.attributes.find((item) => item.name === attributeName);
    if (attr) {
      const res = await this.BotService.getSuggestValuesAll(attr);
      if (window.angular.isArray(res)) {
        attr.values = res;
      }
    }
  }

  _getValues(attributeName) {
    if (this.attributes) {
      const attr = this.attributes.find((item) => item.name === attributeName);
      return attr ? attr.values : null;
    }
    return null;
  }
}
