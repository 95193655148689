import CardPluginBaseController from '../card-plugin-base-controller/controller';

export default class CardSendEmailController extends CardPluginBaseController {
  constructor(
    $scope,
    $rootScope,
    $interpolate,
    ModalService,
    BotService,
    BlockService,
    $sce,
    $timeout,
    $element,
    StoreService,
    PluginCardService,
  ) {
    'ngInject';

    super(
      $scope,
      $rootScope,
      $interpolate,
      ModalService,
      BotService,
      BlockService,
      $sce,
      $timeout,
      $element,
      StoreService,
      PluginCardService,
    );

    this.placeholderText =
      'To paste attributes, type {{name_of_the_attribute}}';
  }

  $onInit() {
    if (!this.item.id) {
      this.save();
    }
    if (!this.item.validation_details) {
      this.item.validation_details = {};
    }
    if (this.item.is_valid === false) {
      this.data = this.item.validation_details || {};
      this.data.card_id = this.item.id;
      this.$rootScope.$broadcast('$pluginSaveResultError', { data: this.data });
    } else {
      this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
    }
  }
}
