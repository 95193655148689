import ng from 'angular';

export default ng
  .module('app.common.titleSlicer', [])
  .directive('titleSlicer', ($timeout) => {
    'ngInject';

    return (scope, element, attrs) => {
      $timeout(() => {
        if (element[0].scrollWidth > 320) {
          element.addClass('long');
        }
      }, 500);
    };
  }).name;
