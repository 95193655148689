import { ServiceMessageType } from '@ui/ServiceMessage2';
import { toaster } from '@services/MessageService';
import { globalHistory } from '../../../utils/Routing';

/**
 * @description YandexMetrikaConfirmPage controller
 */
export default class YandexMetrikaConfirmPageController {
  /**
   * @description constructor -
   * @param {*} $rootScope -
   * @param {*} BotService -
   * @param {*} $http -
   * @param {*} StoreService -
   * @param {*} $timeout -
   */
  constructor($rootScope, BotService, $http, StoreService, $timeout) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.BotService = BotService;
    this.$http = $http;
    this.StoreService = StoreService;
    this.$timeout = $timeout;
    this.className = 'bot-spinner';
  }

  /**
   * @description init callback
   */
  $onInit() {
    this.getParams();
    if (this.params.has('code')) {
      this.getBotId()
        .success((res) => {
          this.$rootScope.stateHistory.push(`/bot/${res.result}/statistics`);
        })
        .error((res) => {
          if (res && res.bot_id) {
            this.$rootScope.stateHistory.push(`/bot/${res.bot_id}/statistics`);
            setTimeout(() => {
              toaster.show({
                type: ServiceMessageType.error,
                payload: {
                  message: res.message,
                },
              });
            }, 500);
          } else {
            this.$rootScope.stateHistory.push(`/bots`);
          }
        });
    } else {
      this.$rootScope.stateHistory.push(`/bots`);
    }
  }

  /**
   * @description parse url params
   */
  getParams() {
    this.params = new URLSearchParams(window.location.search);
  }

  /**
   * @description get confirmed bot id
   * @return {String} - bot id
   */
  getBotId() {
    return this.$http.get(
      `${this.StoreService.getApiUrl()}/yandex/confirm?code=${this.params.get(
        'code',
      )}&state=${this.params.get('state')}`,
    );
  }
}
