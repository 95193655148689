import { CURRENT_ADMIN_QUERY } from '../services/GQLqueries/User.queres';
import client from '../services/ApolloService';

export default ($http, $rootScope, StoreService) => {
  'ngInject';

  return {
    reshow() {
      this.show(true);
    },

    async show(refresh) {
      const {
        data: {
          me: { ...admin },
        },
      } = await client.query({
        query: CURRENT_ADMIN_QUERY,
        fetchPolicy: refresh ? 'network-only' : 'cache-first',
      });
      admin.date_added = parseInt(admin.date_added); // eslint-disable-line radix
      admin.terms_accepted = parseInt(admin.terms_accepted); // eslint-disable-line radix
      admin.ab_flags = JSON.parse(admin.abt_serialized);
      return admin;
    },

    setFlags(flagValue) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/user/add_flag?flag=${flagValue}`,
      }).then((res) => res.data.result);
    },

    removeFlags(flagValue) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/user/remove_flag?flag=${flagValue}`,
      }).then((res) => res.data.result);
    },

    acceptTerms(flagValue) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/accept_terms?subscribe=${flagValue}`,
      }).then((res) => res.data.result);
    },

    nativePaymentsForCurrentBot(value, obj) {
      const fn = `native_payments_in_bot_disabled_${$rootScope.stateParams.botId}`;
      if (value === false) {
        this.setFlags(fn);
      } else if (value === true) {
        this.removeFlags(fn);
      }

      if (obj) {
        const index = obj.flags.indexOf(fn);
        if (value !== true && value !== false) {
          value = index === -1;
        } else if (!value && index === -1) {
          obj.flags.push(fn);
        } else if (value && index !== -1) {
          obj.flags.splice(index, 1);
        }
      }

      return value;
    },
  };
};
