import ng from 'angular';
import { ngGoToMessengerPopup } from '../modern-components/FBPlugins/SendToMessengerPluginButton/ngGoToMessengerPopup';
import Logo from './logo';
import Structure from './structure';
import Statistics from './statistics';
import Support from './support';
import PluginContainer from './plugin-container';
import Grow from './grow';
import Users from './users';
import UserFilterComponent from './user-filter-component';
import UserFilterComponentDL from './user-filter-component-data-loader';
import FbErrorPopup from './fb-error-popup';
import BillingDetailsComponent from './billing-details-component';
import globalAttributes from './settings/global-attributes';
import Admins from './settings/admins';
import { ngAdminsList } from './settings/admins/AdminsList';
import { PeopleService } from './users/service';
import SequencesSubscribeEditor from './users/sequences-subsribe-editor';

import './broadcast';
import './settings';

export default ng
  .module('app.components', [
    Admins,
    SequencesSubscribeEditor,
    BillingDetailsComponent,
    Logo,
    Structure,
    Statistics,
    Support,
    PluginContainer,
    Grow,
    Users,
    UserFilterComponent,
    UserFilterComponentDL,
    FbErrorPopup,
    ngGoToMessengerPopup,
    ngAdminsList,
    globalAttributes,
  ])
  .service('PeopleService', PeopleService).name;
