import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import type ApolloClient from 'apollo-client';

import type { botTags, botTagsVariables } from './@types/botTags';

export const loadTagsQuery = gql`
  query botTags($botId: String!) {
    bot(id: $botId) {
      id
      tags {
        id
        name
      }
    }
  }
`;

export const useTags = (botId: string) => {
  const { data, loading, refetch, error } = useQuery<botTags, botTagsVariables>(
    loadTagsQuery,
    {
      variables: { botId },
    },
  );

  return {
    tags: data?.bot.tags || [],
    loading,
    refetch,
    error,
  };
};

export class TagsService {
  private ApolloService: ApolloClient<any>;
  constructor(ApolloService: ApolloClient<any>) {
    'ngInject';

    this.ApolloService = ApolloService;
  }

  getTags$(botId: string) {
    return this.ApolloService.watchQuery<botTags, botTagsVariables>({
      query: loadTagsQuery,
      variables: {
        botId,
      },
    });
  }
}
