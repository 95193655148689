import { clone, complement } from 'ramda';
import memoize from 'lodash-es/memoize';
import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { cleanFilter, isEqual } from '../../services/Segmentation';
import { sendEvent } from '../../../utils/Analytics';
import { isOptimisticBlockId } from '../../../modern-components/Aside/Mutations/BlockMutations';

export default class CardGoToBlockController extends CardPluginBaseController {
  constructor(
    $scope,
    $rootScope,
    $interpolate,
    ModalService,
    BotService,
    BlockService,
    $sce,
    $timeout,
    $element,
    StoreService,
    PluginCardService,
    UserFilterService,
  ) {
    'ngInject';

    super(
      $scope,
      $rootScope,
      $interpolate,
      ModalService,
      BotService,
      BlockService,
      $sce,
      $timeout,
      $element,
      StoreService,
      PluginCardService,
    );

    this.UserFilterService = UserFilterService;
    this.listeners = [];

    this.blocksTitles = [];
    this.excludeBlocks = [];

    this.listeners.push(
      $scope.$on('$buttonUpdate', () => {
        this.updateState();
      }),
    );
  }

  updateState() {
    const _userFilter = this.item.config.user_filter;
    this.userFilter = _userFilter ? clone(_userFilter) : { parameters: [] };
    this.blockItems = this.item.config.action.items;
    this.randomSwitchOn = this.item.config.action.random;
    this.showOmnibox = this.UserFilterService.isFilterTouched(this.userFilter);
  }

  $onInit() {
    this.updateState();
    this.docLink =
      'https://docs.chatfuel.com/plugins/redirect-users/redirect-to-block';
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
  }

  _onFilterChange(filter) {
    this.userFilter = filter;
    if (!isEqual(this.item.config.user_filter, filter)) {
      this.saveParameters();
    }
  }

  saveParameters() {
    this.$timeout(() => {
      this.item.config.user_filter = cleanFilter(this.userFilter);
      this.save().then(() => {
        this.userFilter.valid = this.item.config.user_filter.valid;
      });
    }, 100);
  }

  addBlockItems($event) {
    $event.preventDefault();
    this.blockItems.push({ item_type: 'block', blocks: [] });
    const index = this.blockItems.length - 1;
    this.$timeout(() => {
      this.$scope.$broadcast('$setFocusByIndex', index);
    });

    sendEvent({
      category: 'go to block plugin',
      action: 'add random block',
      value: this.blockItems.length,
      propertyBag: {
        length: this.blockItems.length,
      },
    });
  }

  removeBlockItems(index) {
    this.blockItems.splice(index, 1);
    this.save();

    sendEvent({
      category: 'go to block plugin',
      action: 'remove random block',
      value: this.blockItems.length,
      propertyBag: {
        length: this.blockItems.length,
      },
    });
  }

  changeRandom() {
    this.blockItemsCleaner();
    this.save();

    sendEvent({
      category: 'go to block plugin',
      action: 'switch',
      label: this.randomSwitchOn,
      propertyBag: {
        value: this.randomSwitchOn,
      },
    });
  }

  blockItemsCleaner() {
    let usedBlocks = [];
    const lIntent = {};

    lIntent.action = {
      random: this.randomSwitchOn,
      items: [],
    };

    this.blockItems.every((item) => {
      if (item.item_type === 'block') {
        lIntent.action.items.push({
          item_type: 'block',
          blocks: item.blocks ? item.blocks : [],
        });

        usedBlocks = usedBlocks.concat(item.blocks);
      }

      return this.randomSwitchOn;
    });

    this.item.config.action = lIntent.action;
  }

  save() {
    this.blockItemsCleaner();
    return super.save();
  }

  _onFilterItemRemove() {
    this.showOmnibox = this.UserFilterService.isFilterTouched(this.userFilter);
  }

  _onAddUserFilterClick() {
    if (
      this.showOmnibox &&
      !this.UserFilterService.isFilterNotEmpty(this.userFilter)
    ) {
      this.showOmnibox = false;
    } else if (!this.showOmnibox) {
      this.showOmnibox = true;
    }
  }

  handleBlocksSelectorChange = memoize((index) => ({ blockId }) => {
    this.$scope.$evalAsync(() => {
      this.blockItems[index].blocks = blockId;
      const realBlocksIds = blockId.filter(complement(isOptimisticBlockId));
      if (realBlocksIds.length) {
        sendEvent({
          category: 'go to block plugin',
          action: 'set blocks',
          propertyBag: {
            ids: realBlocksIds,
          },
        });
      }
      this.save();
    });
  });

  handleBlocksSelectorTitlesChange = memoize((index) => (titles) => {
    this.$scope.$evalAsync(() => {
      this.blocksTitles[index] = titles;
    });
  });
}
