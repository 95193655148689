import React from 'react';
import { UserNavigationItemSmall } from './UserNavigationItemSmall';
import {
  UserNavigationItem,
  UserNavigationItemProps,
} from './UserNavigationItem';

interface UserNavigationItemButtonProps extends UserNavigationItemProps {
  small: boolean;
}

export const UserNavigationItemButton: React.FC<UserNavigationItemButtonProps> =
  ({ small, ...props }) => (
    <>
      {small ? (
        <UserNavigationItemSmall {...props} />
      ) : (
        <UserNavigationItem {...props} />
      )}
    </>
  );
