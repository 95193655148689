import React, { useCallback } from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-common';
import { globalHistory, useCurrentBotId } from '@utils/Routing';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { goDisconnectShopifyAccount } from '@utils/Integrations/Shopify/utils/goDisconnectShopifyAccount';
import { Button } from '@ui/Button';
import client from '@common/services/ApolloService';
import shopifyLogo from './shopify-logo.png';
import {
  IntegrationContainer,
  IntegrationContainerAnchor,
} from '../IntegrationContainer';
import {
  IntegrationContainerMissingPaymentNote,
  IntegrationContainerEnabledNote,
} from '../IntegrationContainerNote';
import { ConnectedShopifyAccountView } from './ConnectedShopifyAccountView';
import { ShopifyPaymentView } from '@components/ShopifyPaymentView/ShopifyPaymentView';
import { ShopifyIntegrationContainer } from '@components/ShopifyIntegrationContainer';
import { ShopifySubscriptionStatus } from '@globals';

export const ShopifyIntegration: React.FC = () => {
  const botId = useCurrentBotId()!;
  const { t } = useSafeTranslation();
  const { account, loading } = useShopifyAccount();

  const onDisconnectClick = useCallback(() => {
    sendEvent({
      category: 'shopify integration',
      action: 'disconnect click',
    });
    goDisconnectShopifyAccount(botId);
  }, [botId]);

  const onConnectClick = useCallback(() => {
    sendEvent({
      category: 'shopify integration',
      action: 'connect click',
    });
    window.open('https://apps.shopify.com/chatfuel', '_blank');
  }, []);

  const noteView = !account?.subscription?.status
    ? null
    : {
        [ShopifySubscriptionStatus.active]: <IntegrationContainerEnabledNote />,
        [ShopifySubscriptionStatus.cap_reached]: (
          <IntegrationContainerEnabledNote />
        ),
        [ShopifySubscriptionStatus.cancelled]: null,
        [ShopifySubscriptionStatus.pending]: (
          <IntegrationContainerMissingPaymentNote />
        ),
      }[account.subscription.status];

  return (
    <IntegrationContainer
      defaultExtended
      loading={loading}
      anchor={(extended) => (
        <IntegrationContainerAnchor
          extended={extended}
          imageView={
            <img src={shopifyLogo} width={74} height={20} alt="Shopify" />
          }
          isConnected={Boolean(account)}
          noteView={noteView}
          disconnectButtonView={({ getButtonProps }) => (
            <Button
              {...getButtonProps({ onClick: onDisconnectClick })}
              data-testid="shopify-integration__disconnect"
            >
              {t('Integrations.disconnect')}
            </Button>
          )}
        />
      )}
    >
      <ShopifyPaymentView forceMobile />
      {account ? (
        <ConnectedShopifyAccountView account={account} />
      ) : (
        <Button
          fullWidth
          onClick={onConnectClick}
          data-testid="shopify-integration__connect"
        >
          {t('Shopify-connect.connectExistingStore')}
        </Button>
      )}
    </IntegrationContainer>
  );
};

export const ShopifyIntegrationWrapper: React.FC = () => {
  return (
    <Router history={globalHistory}>
      <ApolloProvider client={client}>
        <ShopifyIntegrationContainer>
          <ShopifyIntegration />
        </ShopifyIntegrationContainer>
      </ApolloProvider>
    </Router>
  );
};
