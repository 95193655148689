import React from 'react';
import { TFunction } from 'i18next';

export interface IFeature {
  title: string;
  tooltip?: string | React.ReactNode;
  isNew?: boolean;
}

export enum FeatureType {
  free = 'free',
  pro = 'pro',
  premium = 'premium',
}

const formater = new Intl.NumberFormat('en');

export const getFeaturesSet = (
  maxUsers: number,
  t: TFunction,
): { [key: string]: IFeature } => {
  return {
    basic: { title: t('constants.premiumData.basicTitle') },
    freeSubscribers: {
      title: t('constants.premiumData.freeSubscribers', {
        maxUsers: formater.format(maxUsers),
      }),
      tooltip: '',
    },
    cfBranding: {
      title: t('constants.premiumData.cfBrandingTitle'),
      tooltip: t('constants.premiumData.cfBrandingTooltip'),
    },
    fullFeatured: {
      title: t('constants.premiumData.fullFeaturedTitle'),
      tooltip: t('constants.premiumData.fullFeaturedTooltip'),
    },
    noLimit: {
      title: t('constants.premiumData.noLimitTitle'),
      tooltip: t('constants.premiumData.noLimitTooltip'),
    },
    noBranding: {
      title: t('constants.premiumData.noBrandingTitle'),
      tooltip: t('constants.premiumData.noBrandingTooltip'),
    },
    targeting: {
      title: t('constants.premiumData.targetingTitle'),
      tooltip: t('constants.premiumData.targetingTooltip'),
    },
    teammates: {
      title: t('constants.premiumData.teammatesTitle'),
      tooltip: t('constants.premiumData.teammatesTooltip'),
    },
    prioritySupport: {
      title: t('constants.premiumData.prioritySupportTitle'),
      tooltip: t('constants.premiumData.prioritySupportTooltip'),
    },
    vipSupport: {
      title: t('constants.premiumData.vipSupportTitle'),
      tooltip: t('constants.premiumData.vipSupportTooltip'),
    },
    accountManager: {
      title: t('constants.premiumData.accountManagerTitle'),
      tooltip: t('constants.premiumData.accountManagerTooltip'),
    },
    buildingHelp: {
      title: t('constants.premiumData.buildingHelpTitle'),
      tooltip: t('constants.premiumData.buildingHelpTooltip'),
    },
    adsSetup: {
      title: t('constants.premiumData.adsSetupTitle'),
      tooltip: t('constants.premiumData.adsSetupTooltip'),
    },
  };
};

export const getFeatures = (
  type: FeatureType,
  usersLimit: number,
  t: TFunction,
): IFeature[] => {
  const featuresSet = getFeaturesSet(usersLimit, t);

  switch (type) {
    case FeatureType.free:
      return [featuresSet.fullFeatured, featuresSet.freeSubscribers];

    case FeatureType.pro:
      return [
        featuresSet.fullFeatured,
        featuresSet.noLimit,
        featuresSet.targeting,
        featuresSet.teammates,
        featuresSet.prioritySupport,
      ];

    case FeatureType.premium:
      return [
        featuresSet.fullFeatured,
        featuresSet.noLimit,
        featuresSet.targeting,
        featuresSet.teammates,
        featuresSet.vipSupport,
        featuresSet.accountManager,
        featuresSet.buildingHelp,
        featuresSet.adsSetup,
      ];

    default:
      return [];
  }
};
