import ng from 'angular';
import { react2angular } from 'react2angular';
import './assets/less/users.less';
import { ExportUsersWrapper } from './ExportUsers';
import { Button } from '../../modern-ui/_deprecated/Button';

export default ng
  .module('app.components.Users', [])
  .component(
    'uiKitButton',
    react2angular(Button, [
      'intent',
      'colorWay',
      'textColorWay',
      'size',
      'tall',
      'wide',
      'children',
      'innerRef',
      'renderIcon',
      'renderIconRight',
      'disabled',
      'onClick',
      'className',
    ]),
  )
  .component(
    'exportUsers',
    react2angular(ExportUsersWrapper, [
      'botId',
      'segment',
      'affectedUsersCount',
      'getCurrentSortAndFilterParams',
    ]),
  ).name;
