export default class ModalController {
  constructor($uibModalInstance, params) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.params = params;

    this.searchTerm = '';
    this.currentSequence = null;
  }

  $onInit() {}

  _onSaveButtonClick() {
    this.$modal.close(this.currentSequence);
  }

  _onCloseButtonClick() {
    this.$modal.close(null);
  }

  _onRequestSelect(value, index) {
    if (index === -1) {
      this.searchTerm = '';
      this.currentSequence = null;
    } else {
      this.searchTerm = value;
      this.currentSequence = this.params.sequences[index];
    }
  }
}
