import React from 'react';
import { ShopifyConnectedAccountStateQuery_bot_connectedShop } from '@utils/Integrations/Shopify/@types/ShopifyConnectedAccountStateQuery';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import * as css from './ConnectedShopifyAccountView.css';

export interface ConnectedShopifyAccountViewProps {
  account: ShopifyConnectedAccountStateQuery_bot_connectedShop;
  showIcon?: boolean;
}

export const ConnectedShopifyAccountView: React.FC<ConnectedShopifyAccountViewProps> =
  ({ account, showIcon, children }) => {
    const { t } = useSafeTranslation();

    return (
      <Flex flexWrap inline justifyContent="space-between" alignItems="center">
        <Flex inline alignItems="center">
          {showIcon && <Icon icon="shopifyBagColorful" />}
          <Spacer factor={0} horizontalFactor={1} />
          <Anchor
            intent="external"
            className={css.anchor}
            href={`https://${account.myshopify_domain}`}
            target="_blank"
            hideArrow
            noUnderline
          >
            <TextEllipsis title={account.myshopify_domain}>
              <Type color="blue" weight="medium">
                {account.myshopify_domain}
              </Type>
            </TextEllipsis>
          </Anchor>
          &nbsp;
          <Type weight="medium" className={css.text}>
            {t('Shopify-connect.connected')}
          </Type>
        </Flex>

        {children}
      </Flex>
    );
  };
