import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Icon, IconNames } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { ColorKey } from '@ui/_common/colors';

export interface IntegrationContainerNoteProps {
  color: ColorKey;
  icon: IconNames;
}

export const IntegrationContainerNote: React.FC<IntegrationContainerNoteProps> =
  ({ children, icon, color }) => (
    <>
      <Icon icon={icon} color={color} size="16px" />
      <Spacer factor={0} horizontalFactor={1} />
      <Type size="12px" color={color}>
        {children}
      </Type>
    </>
  );

export const IntegrationContainerEnabledNote = () => {
  const { t } = useSafeTranslation();

  return (
    <IntegrationContainerNote icon="check_in_circle" color="accent2Dark">
      {t('Integrations.enabled')}
    </IntegrationContainerNote>
  );
};

export const IntegrationContainerMissingPaymentNote = () => {
  const { t } = useSafeTranslation();

  return (
    <IntegrationContainerNote icon="circleWarning" color="accent3Dark">
      {t('Integrations.missingPayment')}
    </IntegrationContainerNote>
  );
};
