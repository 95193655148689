import { clone } from 'ramda';
import { getAdminFeatures } from '@utils/Data/Admin';
import { createSetUpPersistentMenuButtonInFlows } from './SetUpPersistentMenuButtonInFlows/SetUpPersistentMenuButtonInFlows';

export default class PersistentMenuController {
  constructor($scope, $rootScope, $element, BotService) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.$rootScope = $rootScope;
    this.BotService = BotService;

    this.hidePersistentMenu = true;
    this.createPersistentMenuButton = createSetUpPersistentMenuButtonInFlows;

    this.ttText =
      'By default, users are allowed to input text messages into the bot. You can disable this functionality by triggering this switch. Users will only be able to interact with the bot via buttons in Gallery and Text Cards, Quick Reply buttons, and the Persistent menu.<br/>Note that disabling User Input removes this functionality everywhere in your bot flow';
  }

  $onInit() {
    this.loadMenu();

    this.$scope.$on('$saveMenu', (event) => {
      this.saveMenu();
      event.stopPropagation();
    });

    this.$scope.$on('$selLanguage', (event, lang) => {
      if (
        !this.menuItems.localized_items.find((item) => item.language === lang)
      ) {
        const newLangItem = clone(this.menuItems.localized_items[0]);
        newLangItem.language = lang;
        this.menuItems.localized_items.push(newLangItem);
      }
      this.selectLanguage(lang);
      this.saveMenu();
      event.stopPropagation();
    });

    this.$scope.$on('$removeLanguage', (event, lang) => {
      const ind = this.menuItems.localized_items.findIndex(
        (item) => item.language === lang,
      );
      if (ind === this.currentLocaleMenuIndex) {
        this.selectLanguage();
      }

      this.menuItems.localized_items.splice(ind, 1);

      if (ind !== this.currentLocaleMenuIndex) {
        this.currentLocaleMenuIndex = this.menuItems.localized_items.findIndex(
          (item) => item.language === this.currentLocaleMenu.language,
        );
      }

      this.saveMenu();
    });

    this.listener = this.$rootScope.$on('$updateBot', (e, bot) => {
      this.bot = bot;
    });
  }

  $onDestroy() {
    this.listener();
  }

  async loadMenu() {
    const [res, adminFeatures] = await Promise.all([
      this.BotService.getMenu(),
      getAdminFeatures(),
    ]);
    if (res.displayInConfigure === false && adminFeatures.entry_points) {
      this.hidePersistentMenu = true; // PersistentMenu in flow builder
      return;
    }
    this.hidePersistentMenu = false;
    this.menuItems = res;
    if (
      !this.menuItems.localized_items ||
      this.menuItems.localized_items.length === 0
    ) {
      this.menuItems.localized_items = [
        {
          language: 'default',
          user_input_disabled: false,
          items: [],
        },
      ];
    }

    this.selectLanguage();
    this.navigateToHash();
  }

  isNestedItemsExists() {
    return this.currentLocaleMenu.items?.some((item) => item.items?.length);
  }

  selectLanguage(lang) {
    if (!lang) {
      lang = 'default';
    }
    if (this.currentLocaleMenu && lang === this.currentLocaleMenu.language) {
      return;
    }

    this.currentLocaleMenuIndex = this.menuItems.localized_items.findIndex(
      (item) => item.language === lang,
    );
    if (this.currentLocaleMenuIndex !== -1) {
      this.currentLocaleMenu = clone(
        this.menuItems.localized_items[this.currentLocaleMenuIndex],
      );
      this.currentLocaleMenu.user_input =
        !this.currentLocaleMenu.user_input_disabled;
    }
  }

  saveMenu() {
    clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      const loc = clone(this.currentLocaleMenu);

      loc.user_input_disabled = !loc.user_input;
      delete loc.user_input;
      delete loc.thothSubmenu;

      this.menuItems.localized_items[this.currentLocaleMenuIndex] = loc;
      this.BotService.setMenu(this.menuItems);
    }, 200);
  }

  isToggleDisabled() {
    return this?.currentLocaleMenu?.items?.length === 0;
  }

  navigateToHash() {
    const { hash } = window.location;
    if (hash) {
      window.location.hash = '';
      window.location.hash = hash;
    }
  }
}
