import template from './public-vars-suggest.html';
import controller from './controller';

export default () => ({
  template,
  controllerAs: '$publicVarsSuggest',
  controller,
  bindToController: true,
  restrict: 'E',
  replace: true,
  scope: {
    ngModel: '=',
    placeholder: '@',
  },
});
