import { sendEvent } from '../../../utils/Analytics';

export default class CardButtonsListController {
  constructor($scope, $rootScope, $timeout) {
    'ngInject';

    this.$scope = $scope;
    this.activeButton = {};

    $scope.$on('$moveToFirst', (event, index, newItem) => {
      newItem.new = true;

      this.items.splice(index, 1);
      this.items.unshift(newItem);

      $timeout(() => {
        $rootScope.$emit('buttons-update');
        this.$scope.$broadcast('$openButtonPopUp', 0);
      });
    });
  }

  save() {
    this.$scope.$emit('$buttonUpdate');
    this.PluginController.save(this.item, this.index);
  }

  onOver(flag) {
    this.$scope.$emit('$onActionListOver', { id: this.item.id, flag });
  }

  remove(index) {
    const type = this.item.plugin_id;
    if (type === 'list') {
      this.item.config.buttons.splice(0, 1);
      this.save();
    } else {
      this.items.splice(index, 1);
      this.save();
    }
    sendEvent({
      category: 'plugin',
      action: 'button remove',
      value: this.item.config?.buttons.length,
      label: this.item.plugin_id,
      propertyBag: {
        plugin: this.item.plugin_id,
      },
    });
  }
}
