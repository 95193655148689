/**
 * @description: this is all about BotUserVariable;
 */

import {
  filter,
  isNil,
  map,
  pipe,
  defaultTo,
  prop,
  __,
  complement as not,
  curry,
} from 'ramda';
import { sortAttributes } from '../../components/users/service';
import {
  attributeValueToString,
  attributeFromStringToValue,
} from './OmniboxService.js';

const DATE_ATTRIBUTES_MAP = {
  'signed up': true,
  'last seen': true,
  'blocked date': true,
};

// getter
const getName = prop('name');

export const attributeValuesStringToValues = curry((name, values) => {
  values = Array.isArray(values) ? values : [values];
  return values.map((value) => {
    return attributeFromStringToValue({
      values: [value],
      name,
    });
  });
});

export const attributeValuesToString = curry((name, values) => {
  return values.map((value) => {
    return attributeValueToString({
      values: [value],
      name,
    });
  });
});

export const userAttributeSuggestToAutocompleteDatasourceForAttributeName = curry(
  (name, { value, count }) => {
    const setValue = attributeValuesToString(name, [value]);
    return {
      value: setValue[0],
      subheader: isNil(count) ? '' : count,
    };
  },
);

export const userAttributeSuggestToAutocompleteDatasource = (attr) => {
  const { count } = attr;
  return {
    value: attr.name || attr.value,
    subheader: isNil(count) ? '' : count,
  };
};
const filterOnlySystemAndCustomType = filter(
  ({ type }) => type === 'system' || type === 'custom',
);

/**
 * @param: BotUserVariable
 * @return: boolean
 */
export const isDateVariable = pipe(
  getName,
  prop(__, DATE_ATTRIBUTES_MAP),
  not(isNil),
);

/**
 * @param: {BotUserVariable[]} - list of BotVariables suggest;
 * @return: {{value: string, subheader: string}[]} - datasource for autocompete;
 */
export const attributeSuggestToDatasource = pipe(
  defaultTo([]),
  filterOnlySystemAndCustomType,
  sortAttributes,
  map(userAttributeSuggestToAutocompleteDatasource),
);
