export enum EntryPointTutorialYoutube {
  commentsAutoreply = 'j_s1FoC6HTM',
  botLink = '99FWocWnmCo',
  customerChat = 'yb2yCJshWV8',
  audienceSync = 'O5HmJtC1A4M',
  clickToMessenger = 'v2F1uXt2AlQ',
  sponsoredMessages = 'WuAhn8-Xq9M',
  facebookShops = 'BVNrB1-A0Rc',
  facebookPage = 'PF_uI1_HCvE',
  persistentMenu = 'DWovaAjFOOk',
  sendToMessenger = 'H4bUphccvnU',
  zapier = '', // TODO add video
  shopifyPopUp = '', // todo 4946 add link
  cartReminders = '', // todo 4946 add link
  instagramDirect = '', // TODO add video
  instagramStoryMention = 'udMWLBGYS_o',
  instagramStoryReply = '5edXfPkC0FA',
  instagramCommentsAutoreply = 'WV-rJLCHB2M',
  instagramAds = '', // TODO add video
  clickToMessengerJsonAds = '', // TODO add video
}

export enum PluginTutorialYoutube {
  oneTimeNotification = 'vTwdLHar6pI',
  datePicker = '9jGuWa1sgAw',
  messageTag = 'f0XmcMfArfI',
  shareLink = 'QcNZXxdNMR8?t=204',
  googleSheet = 'ytfznWs3Bh0',
}
