/**
 * @description Unbind bot from page Popup window controller
 */
export default class UnbindPopupController {
  /**
   *
   * @param {*} $rootScope -
   * @param {*} PageService -
   */
  constructor($rootScope, PageService) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.PageService = PageService;
  }

  /**
   *
   * @param {HTMLClickEvent} e -
   * @description RequestClose handler from modal component, translate event to parent
   * @private
   * @return {void}
   */
  _onRequestClose(e) {
    this.onRequestClose({ $event: e });
  }

  /**
   * @description Disconnect button click event handler, unbinds bot from page and calls parent's callback
   * @private
   * @return {void}
   */
  async _onDisconnectButtonClick() {
    await this.PageService.unbind(this.page.id);
    this.onDisconnectButtonClick();
  }
}
