import {
  values,
  invertObj,
  uniq,
  curry,
  assocPath,
  path,
  omit,
  ifElse,
  always,
  lensPath,
  over,
  when,
  is,
  complement as not,
  append,
  remove,
  identity,
  reject,
  equals,
  map,
  both,
  pipe,
  isEmpty as isArrayEmpty,
  all,
  prop,
  cond,
  compose,
  lensProp,
  defaultTo,
  propOr,
  __,
  flip,
  take,
  clone,
  pathOr,
  includes,
  unless,
  o,
  view,
  isNil,
} from 'ramda';
import { isDateVariable } from './BotUserVariable';
import { findById } from './CollectionUtils';
import { normalizeSpaces } from '../../utils/String/normalizeSpaces';

export const SEGMENTATION_PARAMETERS_CODE = {
  sequence: 'sequence',
  system: 'system',
  custom: 'custom',
  segment: 'segment',
  block: 'block',
  tag: 'tag',
};

export const SEGMENTATION_PARAMETERS_STRING = {
  sequence: 'sequence',
  system: 'attribute',
  custom: 'attribute',
  segment: 'segment',
  block: 'block',
  tag: 'tag',
};

export const FILTER_PARAMETERS_STRING_TO_CODE = {
  and: 'and',
  or: 'or',
};

export const SEGMENTATION_SEGMENT_OPERATIONS_STRING_TO_CODE = {
  is_not: 'is not',
  is: 'is',
};

export const SEGMENTATION_TAG_OPERATIONS_STRING_TO_CODE = {
  is_not: 'is not',
  is: 'is',
};

export const SEGMENTATION_ATTRIBUTES_OPERATIONS_STRING_TO_CODE = {
  is_not: 'is not',
  is: 'is',
  starts_with: 'starts with',
  contains: 'contains',
  gt: 'greater than',
  lt: 'less than',
};

export const SEGMENTATION_SEQUENCE_OPERATIONS_CODE_TO_STRING = {
  is_not: 'is not',
  is: 'is',
  was: 'was',
  was_not: 'was not',
};

export const SEGMENTATION_BLOCK_OPERATIONS_CODE_TO_STRING = {
  attempted: 'sent',
  sent: 'delivered',
  seen: 'seen',
  clicked: 'clicked',
  failed: 'failed',
  blocked: 'blocked',
};

export const SEGMENTATION_BLOCK_IS_OPERATIONS_CODE_TO_STRING = {
  is: 'is',
  is_not: 'is not',
};

export const STRING_OPERATIONS_CODES = ['starts_with', 'contains'];

export const parameterToOperation = {
  parameter: SEGMENTATION_PARAMETERS_STRING,
  sequence: SEGMENTATION_SEQUENCE_OPERATIONS_CODE_TO_STRING,
  system: SEGMENTATION_ATTRIBUTES_OPERATIONS_STRING_TO_CODE,
  custom: SEGMENTATION_ATTRIBUTES_OPERATIONS_STRING_TO_CODE,
  segment: SEGMENTATION_SEGMENT_OPERATIONS_STRING_TO_CODE,
  filter: FILTER_PARAMETERS_STRING_TO_CODE,
  block: SEGMENTATION_BLOCK_OPERATIONS_CODE_TO_STRING,
  blockIs: SEGMENTATION_BLOCK_IS_OPERATIONS_CODE_TO_STRING,
  tag: SEGMENTATION_TAG_OPERATIONS_STRING_TO_CODE,
  compound: {},
};

const attribute = (item) =>
  item.type === SEGMENTATION_PARAMETERS_CODE.system ||
  item.type === SEGMENTATION_PARAMETERS_CODE.custom;
const segmentType = (item) =>
  item.type === SEGMENTATION_PARAMETERS_CODE.segment;
const sequenceType = (item) =>
  item.type === SEGMENTATION_PARAMETERS_CODE.sequence;
const noName = (item) => !item.name;
const noValue = (item) =>
  !item.values || isArrayEmpty(item.values) || all(isArrayEmpty, item.values);
const bothNoValueNoName = cond([
  [attribute, both(noName, noValue)],
  [segmentType, noValue],
  [sequenceType, noValue],
]);

const attributeWithNoName = both(noName, attribute);

/**
 * @description: transformation get list of codes and code -> string and string -> code
 * @param type
 * @return {*}
 */

export const stringsForParameterType = (type) =>
  uniq(values(parameterToOperation[type]));
export const operationCodeToStringForParameterType = curry(
  (type, code) => parameterToOperation[type][code],
);
export const operationStringToCodeForParameterType = curry(
  (type, string) => invertObj(parameterToOperation[type])[string],
);

export const emptyParameterForType = (
  type = SEGMENTATION_PARAMETERS_STRING.system,
) => {
  const operation = SEGMENTATION_ATTRIBUTES_OPERATIONS_STRING_TO_CODE.is;
  switch (type) {
    case SEGMENTATION_PARAMETERS_STRING.system:
    case SEGMENTATION_PARAMETERS_STRING.custom:
      return {
        name: '',
        values: [],
        operation,
        type: 'system', // fixme @p2 not sure what type should be here;
      };
    case SEGMENTATION_PARAMETERS_STRING.segment:
      return {
        name: null,
        values: [],
        operation,
        type: SEGMENTATION_PARAMETERS_STRING.segment,
      };
    case SEGMENTATION_PARAMETERS_STRING.sequence:
      return {
        name: 'triggered sequences', // fixme @p2 wtf triggered sequences ??
        values: [],
        operation,
        type: 'sequence',
      };
    case SEGMENTATION_PARAMETERS_STRING.block:
      return {
        name: '',
        values: [],
        operation: 'seen',
        type: 'block',
      };
    case SEGMENTATION_PARAMETERS_STRING.tag:
      return {
        name: '',
        values: [],
        operation,
        type: 'tag',
      };
    default:
      throw new Error(
        `trying to create empty parameter for filter for unknown filter type: ${type}`,
      );
  }
};

export const defaultParameter = () =>
  emptyParameterForType(SEGMENTATION_PARAMETERS_STRING.system);

export const safeGetValue = path(['newValue', 'value']);

// setters
export const valueLens = lensPath(['newValue', 'value']);
const parameterPropName = 'parameters';
export const overParameter = over(lensPath([parameterPropName]));
export const overValue = over(valueLens);
export const setValueToArrayInEvent = overValue(when(not(is(Array)), Array.of));
export const mapValueToOperationCodeForType = (type) =>
  overValue(operationStringToCodeForParameterType(type));

export const setFilterOperation = curry((filter, value) =>
  assocPath(['operation'], safeGetValue(value), filter),
);

export const setParameterAtIndex = curry((parameterIndex, filter, value) =>
  assocPath(
    [parameterPropName, parameterIndex],
    emptyParameterForType(safeGetValue(value)),
    filter,
  ),
);

const getPathToNameAtIndex = (index) => [parameterPropName, index, 'name'];
const getPathToTypeAtIndex = (index) => [parameterPropName, index, 'type'];

export const setDataAtIndex = curry(
  (parameterIndex, filter, botVariableSuggest, value) => {
    return pipe(
      assocPath(getPathToNameAtIndex(parameterIndex), safeGetValue(value)),
      assocPath(
        getPathToTypeAtIndex(parameterIndex),
        pipe(
          pathOr(SEGMENTATION_PARAMETERS_CODE.custom, [
            value.newValueIndex,
            'type',
          ]),
          unless(
            // fix attribute type if need
            includes(__, [
              SEGMENTATION_PARAMETERS_CODE.custom,
              SEGMENTATION_PARAMETERS_CODE.system,
            ]),
            always(SEGMENTATION_PARAMETERS_CODE.custom),
          ),
        )(botVariableSuggest),
      ),
    )(filter);
  },
);

export const updateTypeAtIndex = curry(
  (parameterIndex, botVariableSuggest, filter) =>
    pipe(
      findById(path(getPathToNameAtIndex(parameterIndex), filter)),
      defaultTo({}),
      propOr(SEGMENTATION_PARAMETERS_CODE.custom, 'type'),
      assocPath([parameterPropName, parameterIndex, 'type'], __, filter),
    )(botVariableSuggest),
);

export const setValuesAtIndex = curry((parameterIndex, filter, value) =>
  assocPath(
    [parameterPropName, parameterIndex, 'values'],
    safeGetValue(setValueToArrayInEvent(value)),
    filter,
  ),
);

export const setOperationAtIndex = curry((parameterIndex, filter, value) =>
  assocPath(
    [parameterPropName, parameterIndex, 'operation'],
    safeGetValue(value),
    filter,
  ),
);

// BotUserVariable => Strings[]
export const operationForBotVariable = ifElse(
  isDateVariable,
  always(
    values(
      omit(
        STRING_OPERATIONS_CODES,
        SEGMENTATION_ATTRIBUTES_OPERATIONS_STRING_TO_CODE,
      ),
    ),
  ),
  always(stringsForParameterType('system')),
);

/**
 * @description: add new row to filter to add more statements;
 * @param {Segmentation} filter - get Segmentation object
 * @return {Segmentation} - return new Segmentation object with added row;
 */
export const addNewParameter = (filter) => {
  return overParameter(append(defaultParameter()), filter);
};

/**
 * @description remove row from filter at index and if after removal filter is empty it return default filter;
 *              so segmentation cannot be really empty;
 * @param {Segmentation} filter  -
 * @param {number} index if row to delete - do not check of overflows;
 * @return {Segmentation} returns new filter;
 */
export const removeParameterAtIndex = (filter, index) => {
  const newFilter = overParameter(remove(index, 1), filter);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return ifElse(isEmpty, empty, identity)(newFilter);
};
const isParameterEmpty = pipe(
  prop(parameterPropName),
  map(bothNoValueNoName),
  all(identity),
);

export const isParamEmpty = pipe(
  path([parameterPropName, 'length']),
  (x) => !x,
);

/**
 * @description: check if the Segmentation (filter) is empty;
 * @param {Segmentation} filter -
 * @return {boolean} -
 */
export const isEmpty = (filter) =>
  isParamEmpty(filter) || isParameterEmpty(filter);
/**
 * @description create empty filter;
 * @return {Segmentation} -
 */
export const empty = () => {
  return {
    operation: FILTER_PARAMETERS_STRING_TO_CODE.and,
    [parameterPropName]: [defaultParameter()],
  };
};

const filterEmptyString = reject((s) => s === '');
const removeEmptyStringForEachParameter = map(
  over(lensPath(['values']), filterEmptyString),
);
export const removeEmptyFromValues = pipe(
  defaultTo(empty()),
  overParameter(removeEmptyStringForEachParameter),
);

export const isEqual = (filter1, filter2) => {
  const newFitler1 = removeEmptyFromValues(filter1);
  const newFitler2 = removeEmptyFromValues(filter2);
  const result = equals(newFitler1, newFitler2);
  return result;
};

export function removeParametersWithEmptyName(filter) {
  const parameters = reject(attributeWithNoName, filter.parameters);

  return {
    ...filter,
    parameters,
  };
}

export const cleanFilter = compose(
  clone,
  removeParametersWithEmptyName,
  removeEmptyFromValues,
);

export const defaultParameters = [defaultParameter()];
export const cleanIfOneDefaultParam = over(
  lensProp(parameterPropName),
  when(equals(defaultParameters), always([])),
);

const SINGLE_VALUE_OPERATIONS = ['starts with', 'less than', 'greater than'];

export const isSingleValueOperator = (operator) =>
  SINGLE_VALUE_OPERATIONS.includes(operator);

export const updateValueForOperationAtIndex = (index) =>
  over(
    lensPath(['parameters', index]),
    ifElse(
      pipe(
        prop('operation'),
        flip(prop)(SEGMENTATION_ATTRIBUTES_OPERATIONS_STRING_TO_CODE),
        isSingleValueOperator,
      ),
      over(lensProp('values'), take(1)),
      identity,
    ),
  );

const nameLens = lensProp('name');

export const normalizeSpacesInNamesOnFilter = pipe(
  clone,
  overParameter(
    map(unless(o(isNil, view(nameLens)), over(nameLens, normalizeSpaces))),
  ),
);
