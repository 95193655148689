import controller from './controller';
import template from './broadcast.sequence.template.html';

export default () => ({
  scope: true,
  template,
  controller,
  controllerAs: 'vm',
  restrict: 'E',
  replace: true,
});
