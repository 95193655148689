import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { addAttributeToGQLCaches } from '../../../utils/AttributesUtils/AttributesUtils';
import { Platform } from '../../../../@types/globalTypes';

const MAX_COLLECT_MESSAGE_LENGTH = 640;

/**
 * @description: collect email phone
 */
export default class CollectEmailPhonePlugin extends CardPluginBaseController {
  /**
   * @description: define constance on init;
   * @return {void} -
   */
  $onInit() {
    /**
     *
     * @typedef {Object} Info
     * @description: the template is same for both collect email and collect phone plugin but text and icon is different
     * @property {string} title - show text in header
     * @property {string} icon - class name that add icon to header
     * @property {string} description - text to add to description
     *
     */
    this[this.PluginCardService.PLUGIN_TYPES.collectEmail] = {
      title: 'Save User Email',
      description:
        'Collect your bot users’ emails. The plugin will send a Quick Reply button, automatically pre-filled with the email address from the user’s Facebook profile. If the profile does not have an email address, the Quick Reply will not be shown, but the user will still be able to type in their email.',
      icon: 'email-icon',
    };

    this[this.PluginCardService.PLUGIN_TYPES.collectPhone] = {
      title: 'Save User Phone Number',
      description:
        'Collect your bot users’ phone numbers. The plugin will send a Quick Reply button, automatically pre-filled with the phone number from the user’s Facebook profile. If the profile does not have a phone number, the Quick Reply will not be shown, but the user will still be able to type in their number.',
      icon: 'phone-icon',
    };

    this.$scope.$evalAsync(() => {
      this.textarea = this.$element[0].querySelector('textarea');
      this.updateDiscount();
    });

    this.MAX_COLLECT_MESSAGE_LENGTH = MAX_COLLECT_MESSAGE_LENGTH;
  }

  updateDiscount() {
    this.leftSymbols = MAX_COLLECT_MESSAGE_LENGTH;
    if (this.textarea) {
      this.leftSymbols =
        MAX_COLLECT_MESSAGE_LENGTH - this.textarea.value.length;
    }
  }

  addAttributeToGQLCaches() {
    addAttributeToGQLCaches(
      this.$rootScope.stateParams.botId,
      this.item.config.variable_name,
      Platform.facebook,
    );
  }
}
