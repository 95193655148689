import jQuery from 'jquery';

export default class PluginRemoveController {
  constructor($scope, $interpolate, ModalService) {
    'ngInject';

    this.$interpolate = $interpolate;
    this.ModalService = ModalService;
    this.remove = this.remove.bind(this);
    this.onUpdate = () => {
      $scope.$emit('currentBlock:update');
    };
    this.toggleDisclosure = () => {
      $scope.$evalAsync(() => this.onToggleDisclosure());
    };
  }

  remove() {
    return this.PluginController.remove();
  }

  moveMouseDown() {
    jQuery('.cards-list').height(jQuery('.cards-list').height());
  }
}
