import { clone } from 'ramda';
import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { cleanFilter, isEqual } from '../../services/Segmentation';
import { getBlocksGroupsObservable } from '../../../modern-components/Aside/BlocksGroupsQueries';

export default class CardToSequenceController extends CardPluginBaseController {
  constructor(
    $scope,
    $rootScope,
    $interpolate,
    ModalService,
    BotService,
    BlockService,
    $sce,
    $timeout,
    $element,
    StoreService,
    PluginCardService,
    GroupService,
    UserFilterService,
  ) {
    'ngInject';

    super(
      $scope,
      $rootScope,
      $interpolate,
      ModalService,
      BotService,
      BlockService,
      $sce,
      $timeout,
      $element,
      StoreService,
      PluginCardService,
    );
    this.StoreService = StoreService;
    this.GroupService = GroupService;
    this.UserFilterService = UserFilterService;
    this.$rootScope = $rootScope;
    this.listeners = [];
    this.groups = [];
  }

  $onInit() {
    this.userFilter = clone(this.item.config.user_filter);
    this.blockItems = this.item.config.action.items;
    this.randomSwitchOn = this.item.config.action.random;
    this.showOmnibox = this.UserFilterService.isFilterTouched(this.userFilter);

    this.listeners.push(
      this.$scope.$on('$buttonUpdate', () => {
        this.userFilter = clone(this.item.config.user_filter);
        this.blockItems = this.item.config.action.items;
        this.randomSwitchOn = this.item.config.action.random;
        this.showOmnibox = this.UserFilterService.isFilterTouched(
          this.userFilter,
        );
      }),
    );

    this.listeners.push(
      this.$scope.$on('$updateBlocksInSuggest', (event) => {
        event.stopPropagation();
        this.save();
      }),
    );

    this.blocksGroupsSubscription = getBlocksGroupsObservable(
      this.$rootScope.stateParams.botId,
    ).subscribe((groups) => {
      this.groups = groups.filter((group) => group.sequence);
    });

    this.docLink =
      'https://docs.chatfuel.com/broadcasting/broadcasting-documentation/sequences';
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
    if (this.blocksGroupsSubscription) {
      this.blocksGroupsSubscription.unsubscribe();
    }
  }

  _onFilterChange(filter) {
    this.userFilter = filter;
    if (!isEqual(this.item.config.user_filter, filter)) {
      this.saveParameters();
    }
  }

  saveParameters() {
    this.$timeout(() => {
      this.item.config.user_filter = cleanFilter(this.userFilter);
      this.save().then(() => {
        this.userFilter.valid = this.item.config.user_filter.valid;
      });
    }, 100);
  }

  addBlockItems($event) {
    $event.preventDefault();
    this.blockItems.push({ item_type: 'group', groups: [] });
    const index = this.blockItems.length - 1;
    this.$timeout(() => {
      this.$scope.$broadcast('$setFocusByIndex', index);
    });
  }

  removeBlockItems(index) {
    this.blockItems.splice(index, 1);
    this.save();
    this.$scope.$broadcast('$removedLine', index);
  }

  changeRandom() {
    this.blockItemsCleaner();
    this.save();
  }

  blockItemsCleaner() {
    let usedBlocks = [];
    const lIntent = {};

    lIntent.action = {
      random: this.randomSwitchOn,
      items: [],
    };

    // TODO: а что это ?
    this.blockItems.every((item) => {
      // if (item.item_type === 'group') {
      lIntent.action.items.push({
        item_type: 'group',
        groups: item.groups || [],
      });

      usedBlocks = usedBlocks.concat(item.groups);
      // }

      return this.randomSwitchOn;
    });

    this.item.config.action = lIntent.action;
  }

  save() {
    this.blockItemsCleaner();
    return super.save();
  }

  _onFilterItemRemove() {
    this.showOmnibox = this.UserFilterService.isFilterTouched(this.userFilter);
  }

  _onAddUserFilterClick() {
    this.showOmnibox = true;
  }
}
