import { isValidUrl } from '../../utils/UrlUtils';

export default class DomainValidationService {
  getUrlHostname(url) {
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
      url = `http://${url}`;
    }
    if (!this.isUrlValid(url)) {
      return '';
    }
    const link = document.createElement('a');
    link.href = url;
    return link.hostname;
  }

  /**
   * @description validate url;
   * @param url string
   * return boolean
   */
  isUrlValid(url = '') {
    return isValidUrl(url);
  }
}
