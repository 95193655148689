import i18next from 'i18next';
import { sendEvent } from '../../../utils/Analytics';

export default class BcApiController {
  constructor($scope, $interpolate, $timeout, ModalService, BotService) {
    'ngInject';

    this.$interpolate = $interpolate;
    this.$scope = $scope;
    this.$timeout = $timeout;

    this.ModalService = ModalService;
    this.BotService = BotService;
  }

  showModalToken() {
    sendEvent({
      category: 'broadcasting api token',
      action: 'reset',
    });

    this.ModalService.confirmAdv(
      i18next.t('components.settings.bcApi.modal.content'),
      {
        close: i18next.t('components.settings.bcApi.modal.close'),
        ok: i18next.t('components.settings.bcApi.modal.ok'),
      },
    ).then((res) => {
      if (res) {
        sendEvent({
          category: 'broadcasting api token',
          action: 'reset confirmed',
        });

        this.BotService.resetApiToken().then((token) => {
          this.bot.external_api_token = token;
        });
      }
    });
  }

  copyLink(event) {
    sendEvent({
      category: 'broadcasting api token',
      action: 'copy',
    });

    event.preventDefault();
    event.currentTarget.select();
    try {
      document.execCommand('copy');
      this.copied = true;
      this.$timeout(() => {
        this.copied = false;
      }, 1000);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }
}
