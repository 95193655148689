import React from 'react';
import { Button, ButtonIntent } from '../../../../modern-ui/_deprecated/Button';

interface ConvertToGalleryButtonProps {
  onClick: () => void;
}

export const ConvertToGalleryButton: React.FC<ConvertToGalleryButtonProps> = (
  props,
) => {
  return (
    <Button intent={ButtonIntent.primary} onClick={props.onClick}>
      Convert to Gallery
    </Button>
  );
};
