import React, { useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { pathOr } from 'ramda';
import gql from 'graphql-tag';
import { Mutation, Query } from '@apollo/react-components';

import { removeTypename } from '@utils/GQL/utils';
import * as css from './EditWhiteLabelRolePopup.css';
import { Modal } from '../../../../modern-ui/Modal';
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeading,
} from '../../../../modern-ui/Dialog';
import {
  ROLES_AND_INVITES_QUERY_bot_admins as AdminType,
  ROLES_AND_INVITES_QUERY_bot_admins_role_permissions as IPermissions,
} from '../@types/ROLES_AND_INVITES_QUERY';
import { Button, ButtonIntent } from '../../../../modern-ui/_deprecated/Button';
import {
  ADMINS_ROLES_MAP,
  IAdminRole,
  RoleIds,
} from '../RolesList/AdminsRoles';
import { GET_WHITE_LABEL_AGENCY_DOMAIN_QUERY } from '../RolesList';
import { Flex } from '../../../../modern-ui/Flex';
import { DEFAULT_WHITE_LABEL_ROLE_PERMISSIONS } from '../InviteAdminPopup/WhiteLabelInviteDialog/WhiteLabelInviteDialog';
import { ROLE_QUERY_bot_currentRole_permissions as IWhiteLabelPermissions } from '../AccessSettings/@types/ROLE_QUERY';
import { AccessSettings } from '../AccessSettings';
import {
  EditWhiteLabelRoleMutation,
  EditWhiteLabelRoleMutationVariables,
} from './@types/EditWhiteLabelRoleMutation';
import { ReactComponent as WhiteLabelUserIcon } from '@components/AdminList/images/white_label_user_pic.svg';
import { hasAnyAccess } from '../../../../common/services/RoleService';
import { GetWhiteLabelAgencyDomainQuery } from '../RolesList/@types/GetWhiteLabelAgencyDomainQuery';
import { PERMISSIONS_FRAGMENT } from '@utils/Data/Permissions/fragments';

interface IEditRolePopupProps {
  onDismiss: () => void;
  admin: AdminType;
  onRemoveAdminRequest: (userId: string) => void;
  botId: string;
}

const WHITE_LABEL_ROLE = ADMINS_ROLES_MAP.get(RoleIds.whiteLabel) as IAdminRole;

const filterWhiteLabelPermissions = (
  permissions: IPermissions,
): IWhiteLabelPermissions => {
  const clone = { ...permissions };

  Object.keys(permissions).forEach((permissionId) => {
    if (
      !DEFAULT_WHITE_LABEL_ROLE_PERMISSIONS[
        permissionId as keyof IWhiteLabelPermissions
      ]
    ) {
      delete clone[permissionId as keyof IWhiteLabelPermissions];
    }
  });

  return clone;
};

const EDIT_WHITE_LABEL_ROLE_MUTATION = gql`
  mutation EditWhiteLabelRoleMutation(
    $roleId: String!
    $permissions: WhiteLabelPermissionsInput!
  ) {
    editWhiteLabelRole(roleId: $roleId, permissions: $permissions) {
      id
      permissions {
        ...permissionsFragment
      }
    }
  }
  ${PERMISSIONS_FRAGMENT}
`;

export const EditWhiteLabelRolePopup: React.FC<IEditRolePopupProps> = ({
  admin,
  onDismiss,
  onRemoveAdminRequest,
}) => {
  const { t } = useSafeTranslation();
  const [permissions, setPermissions] = useState<IWhiteLabelPermissions>(
    filterWhiteLabelPermissions(admin.role.permissions),
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className={css.fixedVirtualHeight}>
        <Dialog className={css.dialog}>
          <DialogContent>
            <DialogHeading>Change permissions</DialogHeading>
            <div className={css.subHead}>
              <div className={css.user}>
                <WhiteLabelUserIcon className={css.adminPicture} />
                {admin.name}
              </div>
              <Button
                intent={ButtonIntent.secondary}
                onClick={() => onRemoveAdminRequest(admin.id)}
                tabIndex={-1}
              >
                Remove Teammate
              </Button>
            </div>
            <Flex alignItems="center" className={css.info}>
              <div className={css.iconContainer}>
                {WHITE_LABEL_ROLE.icon({})}
              </div>
              <div>
                <div className={css.itemTitle}>
                  <span>{t(WHITE_LABEL_ROLE.titleI18nKey)}</span>
                </div>
                <div className={css.itemNote}>
                  <span>{t(WHITE_LABEL_ROLE.noteI18nKey)}</span>
                  <Query<GetWhiteLabelAgencyDomainQuery>
                    query={GET_WHITE_LABEL_AGENCY_DOMAIN_QUERY}
                  >
                    {({ data }) => {
                      const domain = pathOr<string>(
                        '',
                        ['whiteLabelAgency', 'domain'],
                        data,
                      );

                      if (!domain) {
                        return null;
                      }

                      return (
                        <React.Fragment>
                          <span>&nbsp;Invite will be sent to&nbsp;</span>
                          <a
                            href={`https://${domain}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={css.wlDomainLink}
                          >
                            {domain}
                          </a>
                        </React.Fragment>
                      );
                    }}
                  </Query>
                </div>
              </div>
            </Flex>
            <div className={css.rolesErrorBox}>
              {hasAnyAccess(permissions) ? null : (
                <div className={css.rolesError}>
                  You can’t hide all tabs. Keep at least one tab with view or
                  edit permissions.
                </div>
              )}
            </div>
            <Mutation<
              EditWhiteLabelRoleMutation,
              EditWhiteLabelRoleMutationVariables
            >
              mutation={EDIT_WHITE_LABEL_ROLE_MUTATION}
              ignoreResults
            >
              {(savePermissions) => (
                <AccessSettings
                  rolePermissions={permissions}
                  onChange={(updatedPermissions) => {
                    setPermissions(updatedPermissions);
                    if (hasAnyAccess(updatedPermissions)) {
                      savePermissions({
                        variables: {
                          permissions: removeTypename(updatedPermissions),
                          roleId: admin.role.id,
                        },
                      });
                    }
                  }}
                />
              )}
            </Mutation>
          </DialogContent>
          <DialogCloseButton onClick={onDismiss} />
        </Dialog>
      </div>
    </Modal>
  );
};
