import controller from '../card-plugin-base-controller/controller';
import template from './template.html';

export default () => ({
  require: {
    PluginListController: '^^pluginList',
  },
  template,
  controller,
  controllerAs: 'vm',
  bindToController: true,
  restrict: 'E',
  replace: true,
  scope: {
    item: '=',
  },
});
