import 'ng-tags-input/build/ng-tags-input.css';
import './assets/less/cards-list.less';
import './assets/less/ng-tags-input.less';
import './assets/less/plugin-modules.less';
import './assets/less/aciton-item-v2.less';

import ng from 'angular';
import ngFileUpload from 'ng-file-upload';
import 'ng-tags-input';

import { PluginCardService } from './services';
import * as components from './components';

import CardTextPlugin from './card-text-plugin';
import CardSubscriptionPublisherPlugin from './card-subscriptionspublisher-plugin';
import CardSubscriptionManagerPlugin from './card-subscriptionsmanager-plugin';
import CardSubscriberPlugin from './card-subscriber-plugin';
import CardSearchGooglePlugin from './card-search-google-plugin';
import CardSearchBingPlugin from './card-search-bing-plugin';
import CardSearchSwiftypePlugin from './card-search-swiftype-plugin';
import CardRssPlugin from './card-rss-plugin';
import CardRssPluginAuto from './card-rss-plugin-auto';
import CardPicturePlugin from './card-picture-plugin';
import CardIntegrationZapierPlugin from './card-integration-zapier-plugin';
import CardIntegrationZapierPluginAuto from './card-integration-zapier-plugin-auto';
import CardFormPlugin from './card-form-plugin';
import CardCommunicationPlugin from './card-communication-plugin';
import TypingPlugin from './typing-plugin';
import BuyButton from './buy-button';
import CardSetupVariablePlugin from './card-setup-variable-plugin';
import CardGoToBlockPlugin from './card-go-to-block-plugin';
import CardAudioPlugin from './card-audio-plugin';
import CardQuickRepliesPlugin from './card-quick-replies-plugin';
import CardVideoPlugin from './card-video-plugin';
import CardChatroomPlugin from './card-chatroom-plugin';
import CardLocationPlugin from './card-location-plugin';
import CardSendEmailPlugin from './card-send-email-plugin';
import CardListPlugin from './card-list-plugin';
import CardToSequencePlugin from './card-to-sequence-plugin';
import CardFromSequencePlugin from './card-from-sequence-plugin';
import CommentPlugin from './comment-plugin';
import CollectPhoneEmailPlugin from './collect-email-phone-plugin';
import GoogleSheetPlugin from './google-sheet';
import EventTriggerPlugin from './event-trigger';
import { ngOptionsButton } from './OptionsButton/OptionsButton.angular';
import { ngDragButton } from './DragButton/DragButton.angular';

export default ng
  .module('app.common.plugins', [
    'ngTagsInput',
    ngFileUpload,
    CardTextPlugin,
    CardSubscriptionPublisherPlugin,
    CardSubscriptionManagerPlugin,
    CardSubscriberPlugin,
    CardSearchGooglePlugin,
    CardSearchBingPlugin,
    CardSearchSwiftypePlugin,
    CardRssPlugin,
    CardPicturePlugin,
    CardIntegrationZapierPlugin,
    CardFormPlugin,
    CardCommunicationPlugin,
    CardRssPluginAuto,
    CardIntegrationZapierPluginAuto,
    TypingPlugin,
    BuyButton,
    CardSetupVariablePlugin,
    CardGoToBlockPlugin,
    CardQuickRepliesPlugin,
    CardAudioPlugin,
    CardVideoPlugin,
    CardChatroomPlugin,
    CardLocationPlugin,
    CardSendEmailPlugin,
    CardListPlugin,
    CardToSequencePlugin,
    CardFromSequencePlugin,
    CommentPlugin,
    CollectPhoneEmailPlugin,
    GoogleSheetPlugin,
    EventTriggerPlugin,
    ngOptionsButton,
    ngDragButton,
  ])
  .service('PluginCardService', PluginCardService)
  .directive('pluginList', components.PluginListComponent)
  .directive('plugin', components.PluginComponent)
  .directive('pluginRemove', components.PluginRemoveComponent)
  .directive('pluginCard', components.CardComponent)
  .directive('pluginCardAdd', components.CardAddComponent)
  .directive('pluginCardPlugin', components.CardPluginComponent)
  .directive('pluginCardTitle', components.CardTitleComponent)
  .directive('pluginCardSubtitle', components.CardSubTitleComponent)
  .directive('pluginCardImage', components.CardImageComponent)
  .directive('pluginCardUrl', components.CardUrlComponent)
  .directive('pluginCardButtonsTop', components.CardButtonsTopComponent)
  .directive('pluginCardButtonsList', components.CardButtonsListComponent)
  .directive('pluginCardButtonsItem', components.CardButtonsItemComponent)
  .directive('pluginCardButtonsAdd', components.CardButtonsAddComponent)
  .constant('PLUGINS', {
    plugin: {
      title: 'Choose a plugin',
      type: 'plugin',
    },
    integration: {
      title: 'IFTTT',
      type: 'integration',
      link: 'https://ifttt.com/',
      config: {
        position: '',
        service: '',
        recipe: '',
        view: 'all',
      },
      recipes: {
        twitter: {
          title: 'Twitter',
          link: 'https://ifttt.com/recipes/407968-twitter-to-chatfuel',
        },
        youtube: {
          title: 'YouTube',
          link: 'https://ifttt.com/recipes/407969-youtube-to-chatfuel',
        },
        instagram: {
          title: 'Instagram',
          link: 'https://ifttt.com/recipes/407976-instagram-to-chatfuel',
        },
        instagramNew: {
          title: 'Instagram',
          link: 'https://ifttt.com/recipes/427101-your-instagram-to-chatfuel',
        },
        rss: {
          title: 'RSS',
          link: 'https://ifttt.com/recipes/414621-rss-to-chatfuel',
        },
        zapier: {
          title: 'Zapier',
          link:
            'https://zapier.com/developer/invite/41346/322590eb123637445beca8283698f5d3',
        },
      },
    },
  })
  .filter('filterPhone', () => (text) =>
    (text ? (text.indexOf('+') === -1 && text.length > 10 ? '+' : '') : '') +
    text,
  )
  .filter('currency', () => (val) =>
    typeof val === 'number' && val > 0
      ? `${(val < 100 ? '0' : '') +
          String(val)
            .substring(0, String(val).length - 2)
            .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')}.${
          val < 10 ? '0' : ''
        }${String(val).substring(String(val).length - 2)}`
      : '0.00',
  )
  .config([
    'tagsInputConfigProvider',
    (tagsInputConfigProvider) => {
      tagsInputConfigProvider.setActiveInterpolation('tagsInput', {
        placeholder: true,
      });
    },
  ]).name;
