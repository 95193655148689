import gql from 'graphql-tag';

export const CURRENT_ADMIN_QUERY = gql`
  query CURRENT_ADMIN_QUERY {
    me {
      id
      constructor_user_id
      name
      email
      terms_accepted
      roles {
        bot_id
        role_id
      }
      date_added
      picture
      flags
      has_connected_bots
      pages_access
      subscriptions_access
      payments_access
      comments_access
      number_of_bots
      abt_serialized
      last_invited_bot
      white_label_role
    }
  }
`;
