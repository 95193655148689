import { getBotFeatures } from '@utils/Data/Bot';
import { getBillingPageUrl } from '@utils/Routing';

export default ($http, StoreService, $rootScope) => {
  'ngInject';

  return {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    get,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    update,
    gotoSettingsAndScrollToPro: async () => {
      const { botId } = $rootScope.stateParams;
      const { dialogs_pricing_enabled } = await getBotFeatures(botId);
      $rootScope.stateHistory.push(
        getBillingPageUrl(botId, dialogs_pricing_enabled),
      );
    },
  };

  /**
   * get
   * @param {Number} id -
   * @return {Object} -
   */
  function get(id) {
    return $http({
      url: `${StoreService.getApiUrl()}/bots/${id}`,
      method: 'get',
    }).then((res) => res.data.result);
  }

  /**
   * update
   * @param {Number} id -
   * @param {Object} data -
   * @return {Object} -
   */
  function update(id, data) {
    return $http({
      url: `${StoreService.getApiUrl()}/bots/${id}`,
      method: 'post',
      data,
    }).then((res) => res.data.result);
  }
};
