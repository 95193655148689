import { AspectRatioValue } from '../../../modern-components/Plugins/ImagePlugin/ratio';
import { PluginType } from '../../../modern-components/Plugins/common/PluginTypes';
import {
  getAutoResizedSquareImage,
  getAutoResizedHorizontalGalleryImage,
} from '../../../modern-components/Plugins/ImagePlugin/components/ImageResize/utils/cropper';
import {
  toaster,
  Messages,
} from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2';
import { isFileSizeSameOrLess } from '../../../utils/fileUtils';

export default class CardImageController {
  constructor($scope, $timeout, ModalService, Upload, StoreService, $element) {
    'ngInject';

    this.$scope = $scope;
    this.ModalService = ModalService;
    this.StoreService = StoreService;
    this.Upload = Upload;
    this.$timeout = $timeout;
    this.$element = $element;
  }

  $onInit() {
    this.$scope.$on('$removeForstItem', (res) => {
      this.remove();
    });
  }

  edit() {
    this.pluginType =
      this.PluginController.item.plugin_id || this.plugin.plugin_id;

    const ratio = this.getResizeRatio(this.plugin.config.square_ratio);

    this.ModalService.imageResize(this.item.image_url, ratio).then((result) => {
      if (result) {
        this.item.image_url = `${result}`;
        this.save();
      }
    });
  }

  save() {
    this.$scope.$emit('$validateListItem');
    this.PluginController.save(this.item, this.index);
  }

  remove() {
    this.item.image_url = '';
    this.save();
  }

  upload(file, err) {
    if (err.length || (file && !isFileSizeSameOrLess(file, 10))) {
      return this.ModalService.imageSize();
    }

    if (!file) {
      return undefined;
    }

    this.loading = true;

    this.pluginType =
      this.PluginController.item.plugin_id || this.plugin.plugin_id;

    const isImagesPresent =
      this.pluginType === PluginType.list ||
      (this.plugin.config.gallery_cards &&
        this.plugin.config.gallery_cards.some((elem) => !!elem.image_url));

    if (isImagesPresent) {
      this.uploadPresentImage(file);
    } else {
      this.uploadAbsentImage(file);
    }

    return undefined;
  }

  getResizeRatio(isSquare) {
    return isSquare
      ? AspectRatioValue.square
      : this.pluginType === PluginType.gallery
      ? AspectRatioValue.horizontalFacebookGallery
      : AspectRatioValue.horizontal;
  }

  alert() {
    this.loading = false;
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: Messages.somethingWentWrong,
      },
    });
  }

  async uploadPresentImage(fileBlob) {
    let file = fileBlob;
    try {
      if (this.pluginType === PluginType.gallery) {
        if (this.plugin.config.square_ratio) {
          file = await getAutoResizedSquareImage(fileBlob);
        } else {
          file = await getAutoResizedHorizontalGalleryImage(fileBlob);
        }
      }
    } catch {
      this.alert();
      return;
    }

    const ratio = this.getResizeRatio(this.plugin.config.square_ratio);

    this.ModalService.imageResize(fileBlob, ratio)
      .then((result) => {
        if (result) {
          if (this.pluginType === PluginType.list) {
            this.$scope.$emit('$validateListItem');
          }

          this.item.image_url = result;
          this.CardController.save();
        }
        this.loading = false;
      })
      .catch(() => {
        this.alert();
      });
  }

  uploadAbsentImage(fileBlob) {
    this.ModalService.selectCropType(fileBlob).then((resCropType) => {
      if (resCropType && resCropType.success) {
        this.plugin.config.square_ratio = resCropType.isSquare;

        let promise;
        if (this.pluginType === PluginType.gallery) {
          if (this.plugin.config.square_ratio) {
            promise = getAutoResizedSquareImage(fileBlob);
          } else {
            promise = getAutoResizedHorizontalGalleryImage(fileBlob);
          }
        }

        const ratio = this.getResizeRatio(this.plugin.config.square_ratio);

        promise
          .then((file) => this.ModalService.imageResize(fileBlob, ratio))
          .then((result) => {
            if (result) {
              this.item.image_url = result;
              this.CardController.save();
              this.$scope.$emit('$buttonUpdate');
            }
            this.loading = false;
          })
          .catch(() => {
            this.alert();
          });
      } else {
        this.loading = false;
      }
    });
  }
}
