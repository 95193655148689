import { ATTRIBUTE_TYPE_CODES } from '../../consts';

/**
 * @description UserAttributeEdit controller
 */
export default class UserAttributeEditController {
  /**
   * @description constructor
   * @param {*} $element -
   * @param {*} $scope -
   * @param {*} $window -
   */
  constructor($element, $scope, $window) {
    'ngInject';

    this.attributeTypeCodes = ATTRIBUTE_TYPE_CODES;

    if (this.usersToPreview === undefined || this.usersToPreview.length === 0) {
      throw new Error('users-to-preview should have at least 1 users');
    }

    this.modal = $element.find('modal');
    this.$scope = $scope;
    this.$element = $element;
    this.$window = $window;

    // this will be passed to modal to get modals methods; // see modal descriptions
    this.modalContext = {};
    this.userAttributeEditorContext = {};
  }

  /**
   * @description on add attribute handler call method form userAttributeEditor component
   * @private
   */
  _onAddAttr() {
    this.userAttributeEditorContext.addAttribute();
  }

  /**
   * @description get scroll rect (call method form Modal component)
   * @return {ClientRect} -
   * @private
   */
  _getScrollRect() {
    return this.modalContext.getContentViewRect();
  }
}
