import gql from 'graphql-tag';
import { Level, log, processRestLogs } from 'cf-common/src/logger';
import { processRestMetrics } from 'cf-common/src/metrics';
import { processRestEvents } from 'cf-common/src/analytics';
import client from './ApolloService';
import { SchemaHash } from './@types/SchemaHash';

const MAJOR_VERSION_QUERY = gql`
  query SchemaHash {
    schemaHash
  }
`;

const POLL_INTERVAL = 60 * 1000;
const JITTER_RANGE = 5 * 60 * 1000;

// https://chatfuel.atlassian.net/browse/CHAT-8367
let currentSchemaHash: String;

const intervalFn = async () => {
  try {
    const queryResult = await client.query({
      query: MAJOR_VERSION_QUERY,
      fetchPolicy: 'no-cache',
    });
    const { schemaHash } = queryResult.data as SchemaHash;

    if (typeof currentSchemaHash !== 'string') {
      currentSchemaHash = schemaHash;
    } else if (schemaHash !== currentSchemaHash) {
      // to avoid all clients reloadind at the same time
      const jitter = Math.random() * JITTER_RANGE;

      log({
        msg: 'Reload dashboard because of breaking changes',
        level: Level.info,
        data: {
          previousSchemaHash: currentSchemaHash,
          newSchemaHash: schemaHash,
        },
      });

      processRestLogs();
      processRestMetrics();
      processRestEvents();

      setTimeout(() => {
        // @ts-ignore
        window.location.reload(true);
      }, jitter);
    }
  } catch (error) {
    log.warn({ error, msg: 'Error on fetch schema version' });
  }
};

export const watchForSchemaHashChanges = () => {
  setInterval(() => {
    intervalFn();
  }, POLL_INTERVAL);
};
