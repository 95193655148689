import i18next from 'i18next';
import moment from 'moment';
import { cond, always, equals, T } from 'ramda';
import { sendEvent } from 'cf-common/src/analytics';
import { unescape } from '@utils/escape';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { DatePickerShortcut } from '@ui/DatePicker/types';
import { getBotFeatures } from '@utils/Data/Bot';
import { Platform } from '@globals';
import { ChartType } from './ChartType';
import { testAllowedPlatforms } from '../../utils/Platform/testAllowedPlatforms';
import { getWhatsappSettingsObservable } from '../../utils/Data/Whatsapp/getWhatsappSettingsObservable';
import { removeNonDigits } from '../../utils/String/removeNonDigits';

const calcCustomTooltipPosition = (originalTooltip, parentSelector) => {
  const { xAlign, x } = originalTooltip;
  const leftMargin = 10;
  const offsetForRightPosition = 39;
  const offsetForCenterPosition = 17;
  const offsetForLeftPosition = 7;
  const { top } = window.angular
    .element(document.querySelector(parentSelector))
    .offset();
  const left =
    x +
    leftMargin +
    cond([
      [equals('right'), always(offsetForRightPosition)],
      [equals('center'), always(offsetForCenterPosition)],
      [T, always(offsetForLeftPosition)],
    ])(xAlign);
  return { left, top };
};

const DEFAULT_LINK_NAME = 'Default link';

export default class StatisticsController {
  constructor(
    $scope,
    $timeout,
    $rootScope,
    StatisticsService,
    PageService,
    BlockService,
    BotService,
    StoreService,
    GrowService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.PageService = PageService;
    this.BlockService = BlockService;
    this.BotService = BotService;
    this.StoreService = StoreService;
    this.GrowService = GrowService;
    this.connected = false;
    this.totalUsersLoading = true;
    this.dailyUsersLoading = true;
    this.userActivityLoading = true;
    this.popularBlocksLoading = true;
    this.popularButtonsLoading = true;
    this.popularInputsLoading = true;
    this.referralsLoading = true;
    this.aiInboxLoading = true;
    this.popularUrlsLoading = true;
    this.loading = true;
    this.className = 'statistics-container';
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.analyticsUnrecognizedPhrasesAreVisible = false;
    this.showThirdPartyAnalytics = false;
    this.isWhatsappEnabled = undefined;

    this.initialRangePickerDates = [
      moment().startOf('week'),
      moment().endOf('day'),
    ];

    this.startDate = this.initialRangePickerDates[0].toDate();
    this.endDate = this.initialRangePickerDates[1].toDate();

    this.StatisticsService = StatisticsService;

    $scope.$watchGroup(['vm.startDate', 'vm.endDate'], this.generateCharts);

    this.unescape = unescape;
    $scope.$on('chart-create', (e, chart) => {
      this.setPointColor(chart);
    });

    this.refTypes = {
      general: 'Other',
      m_me_link: 'm.me link',
      m_me_link_ref: 'Direct m.me + other',
      messenger_code: 'Messenger Code',
      discover_tab: 'Discover Tab',
      json_ad: 'JSON Ad',
      comment_reply: 'Acquisition from comments',
      landing: 'Chatfuel landing page',
      send_to_messenger: 'Send to Messenger plugin',
      checkbox: 'Checkbox plugin',
      test_your_bot: 'Test your bot',
      prior_untracked_source: 'Prior untracked source',
    };

    $scope.RoleService = $rootScope.RoleService;

    getBotFeatures(this.$rootScope.stateParams.botId).then(
      ({
        hide_analytics_unrecognized_phrases,
        show_third_party_analytics,
        enable_whatsapp,
      }) => {
        this.analyticsUnrecognizedPhrasesAreVisible =
          !hide_analytics_unrecognized_phrases;
        this.showThirdPartyAnalytics = show_third_party_analytics;
        this.isWhatsappEnabled = enable_whatsapp;
      },
    );
  }

  generateCharts = () => {
    if (this.has_botan_app) {
      this.totalUsersLoading = true;
      this.dailyUsersLoading = true;
      this.userActivityLoading = true;
      this.popularBlocksLoading = true;
      this.popularButtonsLoading = true;
      this.popularInputsLoading = true;
      this.referralsLoading = true;
      this.aiInboxLoading = true;
      this.popularUrlsLoading = true;

      if (!this.dailyUsersCsvExportDisabled) {
        this.getTotalUsers(ChartType.daily, this.dailyUsersPlatform);
      } else {
        this.dailyUsersLoading = false;
      }

      if (!this.totalUsersCsvExportDisabled) {
        this.getTotalUsers(ChartType.total, this.totalUsersPlatform);
      } else {
        this.totalUsersLoading = false;
      }

      this.getEventUsers();
      this.getUsage();
      this.getReferrals();
      this.getAiInbox();
      this.getPopularUrls();
    }
  };

  setPointColor(chart) {
    for (let j = 0; j < chart.config.data.datasets.length; j++) {
      for (let i = 0; i < chart.config.data.labels.length; i++) {
        chart.config.data.datasets[j].pointBorderColor.push(
          'rgba(68, 138, 241, 0)',
        );
        chart.config.data.datasets[j].pointBackgroundColor.push(
          'rgba(68, 138, 241, 0)',
        );
      }
    }
    chart.update();
  }

  $onInit() {
    this.grafToolTip = angular.element(
      document.querySelector('.custom-graf-tooltip'),
    );
    this.genPreview();

    this.rulesForComments = {};

    this.GrowService.rulesList(this.$rootScope.stateParams.botId).then(
      (response) => {
        response.subscriptions.forEach((item) => {
          this.rulesForComments[item.id] = item.title;
        });
      },
    );

    this.userData = [
      { activity: 'Menu', rate: '16,657,757' },
      { activity: 'Contacts', rate: '36,747' },
      { activity: 'Hot deal of the day', rate: '647' },
      { activity: 'Default', rate: '563' },
      { activity: 'Message us', rate: '71' },
      { activity: 'Francisco amonio shuppa-ruppa!', rate: '36' },
      { activity: 'The most interesting fact about George Bush', rate: '2' },
      { activity: 'Truth about Kraken', rate: '1' },
    ];
    this.data = {
      labels: {
        user: [
          'OCT 20',
          'OCT 21',
          'OCT 22',
          'OCT 23',
          'OCT 24',
          'OCT 25',
          'OCT 26',
          'OCT 27',
          'OCT 28',
          'OCT 29',
          'OCT 30',
          'OCT 31',
        ],
        popular: [
          'OCT 20',
          'OCT 21',
          'OCT 22',
          'OCT 23',
          'OCT 24',
          'OCT 25',
          'OCT 26',
          'OCT 27',
        ],
      },
      points: {
        user: [
          [
            1250, 2500, 3750, 5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500,
            9000,
          ],
        ],
        popular: [
          [30, 60, 40, 70, 45, 80, 50, 77],
          [15, 59, 45, 91, 106, 70, 30, 54],
          [65, 59, 80, 98, 56, 79, 40, 61],
        ],
      },
      datasetOverride: {
        user: [
          {
            yAxisID: 'y-axis-1',
            lineTension: 0,
            label: i18next.t('components.settings.statistics.totalUsers'),
            borderWidth: 1,
            borderColor: 'rgba(0,0,0,0)',
            pointRadius: 3,
            pointBackgroundColor: 'rgba(68, 138, 241, 0)',
            pointBorderColor: 'rgba(68, 138, 241, 0)',
            pointHoverRadius: 2,
            hitRadius: 10,
            backgroundColor: 'rgba(68, 138, 241, 1)',
          },
          {
            yAxisID: 'y-axis-1',
            lineTension: 0,
            label: i18next.t('components.settings.statistics.blocked'),
            borderWidth: 1,
            borderColor: 'rgba(0,0,0,0)',
            pointRadius: 3,
            pointBackgroundColor: 'rgba(68, 138, 241, 0)',
            pointBorderColor: 'rgba(68, 138, 241, 0)',
            pointHoverRadius: 2,
            hitRadius: 100,
            backgroundColor: 'rgba(233, 96, 74, 1)',
          },
        ],
      },
      options: {
        popular: {
          legend: {
            display: true,
            position: 'bottom',
            labels: { boxWidth: 10 },
          },
          tooltips: {
            mode: 'x-axis',
          },
          hover: {
            mode: 'x-axis',
          },
          scales: {
            yAxes: [
              {
                id: 'y-axis-1',
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                  fontColor: '#767676',
                  fontSize: 13,
                  maxTicksLimit: 5,
                },
                gridLines: {
                  drawBorder: false,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontColor: '#767676',
                  fontSize: 13,
                  maxRotation: 0,
                },
              },
            ],
          },
        },
      },
    };
    this.tooltipMessage = {
      users: i18next.t('components.settings.statistics.tooltipMessages.users'),
      activity: i18next.t(
        'components.settings.statistics.tooltipMessages.activity',
      ),
      blocks: i18next.t(
        'components.settings.statistics.tooltipMessages.blocks',
        { context: this.$rootScope.isAutomateEnabled ? undefined : 'flows' },
      ),
      buttons: i18next.t(
        'components.settings.statistics.tooltipMessages.buttons',
      ),
      inputs: i18next.t(
        'components.settings.statistics.tooltipMessages.inputs',
      ),
      inputs_not_recognized: i18next.t(
        'components.settings.statistics.tooltipMessages.inputs_not_recognized',
      ),
      daily_users: i18next.t(
        'components.settings.statistics.tooltipMessages.daily_users',
      ),
      referrals: i18next.t(
        'components.settings.statistics.tooltipMessages.referrals',
      ),
    };

    this.labelTooltipTexts = {
      totalUser: {
        total: i18next.t(
          'components.settings.statistics.labelTooltipTexts.totalUser.total',
        ),
        subscribed: i18next.t(
          'components.settings.statistics.labelTooltipTexts.totalUser.subscribed',
        ),
        unsubscribed: i18next.t(
          'components.settings.statistics.labelTooltipTexts.totalUser.unsubscribed',
        ),
      },
      daily: {
        subscribed: i18next.t(
          'components.settings.statistics.labelTooltipTexts.daily.subscribed',
        ),
        unsubscribed: i18next.t(
          'components.settings.statistics.labelTooltipTexts.daily.unsubscribed',
        ),
      },
      activity: {
        active: i18next.t(
          'components.settings.statistics.labelTooltipTexts.activity.active',
        ),
        read: i18next.t(
          'components.settings.statistics.labelTooltipTexts.activity.read',
        ),
        received: i18next.t(
          'components.settings.statistics.labelTooltipTexts.activity.received',
        ),
      },
    };

    this.BotService.show().then((res) => {
      this.bot = res;
      if (res.id) {
        this.yandex_app = res.yandex_app;
        this.has_botan_app = res.has_botan_app;
        this.loading = false;

        this.whatsappSettingsSubscription = getWhatsappSettingsObservable(
          this.bot.id,
        ).subscribe(({ whatsappSettings }) => {
          this.whatsappSettings = whatsappSettings;
        });
      }
      this.generateCharts();
    });

    this.totalUserParams = {
      datasetOverride: [
        {
          yAxisID: 'y-axis-1',
          lineTension: 0,
          label: i18next.t('components.settings.statistics.reachable'),
          borderWidth: 3,
          borderColor: '#448af1',
          pointRadius: 3,
          pointBackgroundColor: [],
          pointBorderColor: [],
          pointHoverRadius: 3,
          hitRadius: 10,
          backgroundColor: 'rgba(68, 138, 241, 0.15)',
          pointHoverBackgroundColor: '#448af1',
          pointHoverBorderColor: '#ffffff',
          pointHoverBorderWidth: 1,
        },
        {
          yAxisID: 'y-axis-1',
          lineTension: 0,
          label: i18next.t('components.settings.statistics.blocked'),
          borderWidth: 3,
          borderColor: '#ffc98c',
          pointRadius: 3,
          pointBackgroundColor: [],
          pointBorderColor: [],
          pointHoverRadius: 3,
          hitRadius: 100,
          backgroundColor: 'rgba(255, 201, 140, 0.15)',
          pointHoverBackgroundColor: '#ffc98c',
          pointHoverBorderColor: '#ffffff',
          pointHoverBorderWidth: 1,
        },
      ],
      options: {
        responsive: false,
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'x-axis',
          enabled: false,
          position: 'average',
          callbacks: {
            label: () => null,
            title: (tooltipItem) => String(tooltipItem[0].index),
            footer: () => '-----',
          },
          custom: (tooltip) => {
            if (!tooltip || !tooltip.x || !tooltip.title) {
              this.grafToolTip.css({ opacity: 0 });
              return;
            }

            const { left, top } = calcCustomTooltipPosition(
              tooltip,
              '.graf-total-users',
            );
            const index = Number(tooltip.title[0]);
            const unsubscribed = this.totalUsersData.points[1][index];
            const subscribed = this.totalUsersData.points[0][index];
            const out = `
                <div class="date"><b>${moment(
                  this.totalUsersData.datesObj[index],
                ).format('D MMM Y')}</b></div>
                <div class="subscribed">${this.numberFormater(
                  subscribed,
                )} ${i18next.t(
              'components.settings.statistics.reachable',
            )}</div>
                <div class="unsubscribed">${this.numberFormater(
                  unsubscribed,
                )} ${i18next.t('components.settings.statistics.blocked')}</div>
              `;

            // NOTE: for some reason, element .custom-graf-tooltip is not initialized here
            if (!this.grafToolTip[0]) {
              this.grafToolTip = angular.element(
                document.querySelector('.custom-graf-tooltip'),
              );
            }
            this.grafToolTip.html(out);
            this.grafToolTip.css({
              opacity: 1,
              left: `${left}px`,
              top: `${top}px`,
            });
          },
        },
        hover: {
          mode: 'x-axis',
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              type: 'linear',
              display: true,
              position: 'left',
              ticks: {
                fontColor: '#767676',
                fontSize: 13,
                maxTicksLimit: 5,
                beginAtZero: true,
                padding: 17,
                callback: (label, index, labels) => {
                  const isInt = (n) => n % 1 === 0;
                  const num = isInt(Number(label))
                    ? Number(label)
                    : Number(label).toFixed(1);
                  return (
                    String(num > 999 ? num / 1000 : num).replace(
                      /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                      '$1,',
                    ) + (num > 999 ? 'k' : '')
                  );
                },
              },
              gridLines: {
                drawBorder: false,
                tickMarkLength: 0,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
                tickMarkLength: 14,
              },
              ticks: {
                fontColor: '#767676',
                fontSize: 12,
                maxRotation: 0,
              },
            },
          ],
        },
      },
      colors: ['#448af1', '#ffc98c'],
    };

    this.dailyUserParams = {
      datasetOverride: [
        {
          yAxisID: 'y-axis-1',
          lineTension: 0,
          label: '',
          borderWidth: 3,
          borderColor: '#448af1',
          pointRadius: 3,
          pointBackgroundColor: [],
          pointBorderColor: [],
          pointHoverRadius: 3,
          hitRadius: 10,
          backgroundColor: 'rgba(143, 185, 247, 1)',
          pointHoverBackgroundColor: '#448af1',
          pointHoverBorderColor: '#ffffff',
          pointHoverBorderWidth: 1,
        },
        {
          yAxisID: 'y-axis-1',
          lineTension: 0,
          label: '',
          borderWidth: 3,
          borderColor: '#ffc98c',
          pointRadius: 3,
          pointBackgroundColor: [],
          pointBorderColor: [],
          pointHoverRadius: 3,
          hitRadius: 100,
          backgroundColor: 'rgba(255, 214, 167, 1)',
          pointHoverBackgroundColor: '#ffc98c',
          pointHoverBorderColor: '#ffffff',
          pointHoverBorderWidth: 1,
        },
      ],
      options: {
        responsive: false,
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'x-axis',
          enabled: false,
          position: 'average',
          callbacks: {
            label: () => null,
            title: (tooltipItem) => String(tooltipItem[0].index),
            footer: () => '-----',
          },
          custom: (tooltip) => {
            if (!tooltip || !tooltip.x || !tooltip.title) {
              this.grafToolTip.css({ opacity: 0 });
              return;
            }

            const { left, top } = calcCustomTooltipPosition(
              tooltip,
              '.graf-daily-users',
            );
            const index = Number(tooltip.title[0]);
            const subscribed = this.dailyUsersData.points[0][index];
            const unsubscribed =
              this.dailyUsersData.points[1][index] + this.pushBlockedUsersLine;

            const out = `
              <div class="date"><b>${moment(
                this.dailyUsersData.datesObj[index],
              ).format('D MMM Y')}</b></div>
              <div class="subscribed">${this.numberFormater(
                subscribed,
              )} ${i18next.t('components.settings.statistics.dailyNew')}</div>
              <div class="unsubscribed">${this.numberFormater(
                unsubscribed,
              )} ${i18next.t(
              'components.settings.statistics.dailyBlocked',
            )}</div>
            `;

            this.grafToolTip.html(out);

            this.grafToolTip.css({
              opacity: 1,
              left: `${left}px`,
              top: `${top}px`,
            });
          },
        },
        hover: {
          mode: 'x-axis',
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              type: 'linear',
              display: true,
              position: 'left',
              scaleStepWidth: 1,
              ticks: {
                fontColor: '#767676',
                fontSize: 13,
                stepValue: 6,
                minTicksLimit: 1,
                maxTicksLimit: 5,
                beginAtZero: true,
                padding: 17,
                callback: (label, index, labels) => {
                  const num = Math.abs(Number(label));
                  if (num > 0 && num < 1) {
                    return undefined;
                  }
                  return (
                    String(num > 999 ? num / 1000 : num).replace(
                      /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                      '$1,',
                    ) + (num > 999 ? 'k' : '')
                  );
                },
              },
              gridLines: {
                drawBorder: false,
                tickMarkLength: 0,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
                tickMarkLength: 14,
              },
              ticks: {
                fontColor: '#767676',
                fontSize: 12,
                maxRotation: 0,
              },
            },
          ],
        },
      },
      colors: ['#448af1', '#ffc98c'],
    };

    this.eventUserParams = {
      datasetOverride: [
        {
          yAxisID: 'y-axis-1',
          lineTension: 0,
          label: '',
          borderWidth: 3,
          borderColor: '#448af1',
          pointRadius: 3,
          pointBackgroundColor: [],
          pointBorderColor: [],
          pointHoverRadius: 3,
          hitRadius: 10,
          backgroundColor: 'rgba(143, 185, 247, 0)',
          pointHoverBackgroundColor: '#448af1',
          pointHoverBorderColor: '#ffffff',
          pointHoverBorderWidth: 1,
        },
        {
          yAxisID: 'y-axis-1',
          lineTension: 0,
          label: '',
          borderWidth: 3,
          borderColor: '#ffc98c',
          pointRadius: 3,
          pointBackgroundColor: [],
          pointBorderColor: [],
          pointHoverRadius: 3,
          hitRadius: 100,
          backgroundColor: 'rgba(143, 185, 247, 0)',
          pointHoverBackgroundColor: '#ffc98c',
          pointHoverBorderColor: '#ffffff',
          pointHoverBorderWidth: 1,
        },
        {
          yAxisID: 'y-axis-1',
          lineTension: 0,
          label: '',
          borderWidth: 3,
          borderColor: '#5bd8ac',
          pointRadius: 3,
          pointBackgroundColor: [],
          pointBorderColor: [],
          pointHoverRadius: 3,
          hitRadius: 100,
          backgroundColor: 'rgba(143, 185, 247, 0)',
          pointHoverBackgroundColor: '#5bd8ac',
          pointHoverBorderColor: '#ffffff',
          pointHoverBorderWidth: 1,
        },
      ],
      options: {
        bezierCurve: false,
        responsive: false,
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'x-axis',
          enabled: false,
          position: 'average',
          callbacks: {
            label: () => null,
            title: (tooltipItem) => String(tooltipItem[0].index),
            footer: () => '-----',
          },
          custom: (tooltip) => {
            if (!tooltip || !tooltip.x || !tooltip.title) {
              this.grafToolTip.css({ opacity: 0 });
              return;
            }

            const { left, top } = calcCustomTooltipPosition(
              tooltip,
              '.graf-event-users',
            );
            const index = Number(tooltip.title[0]);

            const out = `
              <div class="date"><b>${moment(
                this.userEventData.datesObj[index],
              ).format('D MMM Y')}</b></div>
              <div class="active-users">${this.numberFormater(
                this.userEventData.points[0][index],
              )} ${i18next.t(
              'components.settings.statistics.activeUsers',
            )}</div>
              <div class="user-message-read">${this.numberFormater(
                this.userEventData.points[1][index],
              )} ${i18next.t(
              'components.settings.statistics.reengageRead',
            )}</div>
              <div class="user-input-received">${this.numberFormater(
                this.userEventData.points[2][index],
              )} ${i18next.t(
              'components.settings.statistics.inputReceived',
            )}</div>
            `;

            this.grafToolTip.html(out);

            this.grafToolTip.css({
              opacity: 1,
              left: `${left}px`,
              top: `${top}px`,
            });
          },
        },
        hover: {
          mode: 'x-axis',
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              type: 'linear',
              display: true,
              position: 'left',
              scaleStepWidth: 1,
              ticks: {
                fontColor: '#767676',
                fontSize: 13,
                stepValue: 6,
                minTicksLimit: 1,
                maxTicksLimit: 5,
                beginAtZero: true,
                padding: 17,
                callback: (label, index, labels) => {
                  const num = Math.abs(Number(label));
                  if (num > 0 && num < 1) {
                    return undefined;
                  }
                  return (
                    String(num > 999 ? num / 1000 : num).replace(
                      /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                      '$1,',
                    ) + (num > 999 ? 'k' : '')
                  );
                },
              },
              gridLines: {
                drawBorder: false,
                tickMarkLength: 0,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
                tickMarkLength: 14,
              },
              ticks: {
                fontColor: '#767676',
                fontSize: 12,
                maxRotation: 0,
              },
            },
          ],
        },
      },
      colors: ['#448af1', '#ffc98c', '#5bd8ac'],
    };
  }

  $onDestroy() {
    this.whatsappSettingsSubscription?.unsubscribe();
  }

  mapingGrafOut(inputDate) {
    const out = {
      points: [],
      dates: [],
      datesObj: [],
    };

    inputDate.dates.forEach((date, i) => {
      if (
        date.valueOf() >= this.startDate.valueOf() - 3600000 * 24 &&
        date.valueOf() <= this.endDate.valueOf() + 1000
      ) {
        out.dates.push(moment(date).format('MMM D').toUpperCase());
        out.datesObj.push(date);

        inputDate.points.forEach((line, li) => {
          if (!out.points[li]) {
            out.points[li] = [];
          }
          out.points[li].push(inputDate.points[li][i]);
        });
      }
    });
    return out;
  }

  getTotalUsers(chartType = ChartType.all, platform) {
    this.StatisticsService.totalUsers.get(this, chartType, platform);
  }

  getEventUsers() {
    this.StatisticsService.eventUsers.get(this);
  }

  getUsage() {
    this.StatisticsService.userUsage.get(this);
  }

  getReferrals() {
    this.StatisticsService.userNewReferrals.get(this);
  }

  getAiInbox() {
    this.StatisticsService.usersAiInbox.get(this);
  }

  getPopularUrls() {
    this.StatisticsService.popularUrls.get(this);
  }

  getRetutningUsers(total, newUsers) {
    return Math.max(total - newUsers, 0);
  }

  customOrder(item) {
    return parseInt(item.rate.replace(/,/g, ''), 10);
  }

  setBg(value) {
    if (value) {
      const val = parseInt(value, 10);
      if (!val) {
        return 0;
      }
      return val / 100;
    }
    return undefined;
  }

  onRefresh(value) {
    if (value === 'dailyUsersLoadingError') {
      this.dailyUsersLoadingError = false;
      this.dailyUsersLoading = true;
      this.getTotalUsers(ChartType.daily, this.dailyUsersPlatform);
    }
    if (value === 'totalUsersLoadingError') {
      this.totalUsersLoadingError = false;
      this.totalUsersLoading = true;
      this.getTotalUsers(ChartType.total, this.totalUsersPlatform);
    }
    if (value === 'userActivityLoadingError') {
      this.userActivityLoadingError = false;
      this.userActivityLoading = true;
      this.getEventUsers();
    }
    if (value === 'popularBlocksLoadingError') {
      this.popularBlocksLoadingError = false;
      this.popularBlocksLoading = true;
      this.popularButtonsLoading = true;
      this.popularInputsLoading = true;
      this.getUsage();
    }
    if (value === 'referralsLoadingError') {
      this.referralsLoading = true;
      this.referralsLoadingError = false;
      this.getReferrals();
    }
    if (value === 'aiInboxLoadingError') {
      this.aiInboxLoading = true;
      this.aiInboxLoadingError = false;
      this.getAiInbox();
    }
    if (value === 'popularUrlsLoadingError') {
      this.popularUrlsLoading = true;
      this.popularUrlsLoadingError = false;
      this.getPopularUrls();
    }
  }

  onTotalUsersPlatformChange = (platforms) => {
    this.totalUsersData = {
      points: [[], []],
      dates: [],
    };

    this.totalUsersLoading = true;
    this.totalUsersLoadingError = false;
    this.totalUsersCsvExportDisabled = false;

    if (platforms.has(Platform.instagram) && platforms.has(Platform.facebook)) {
      this.totalUsersPlatform = undefined;
      this.getTotalUsers(ChartType.total);
    } else if (platforms.has(Platform.instagram)) {
      this.totalUsersPlatform = Platform.instagram;
      this.getTotalUsers(ChartType.total, Platform.instagram);
    } else if (platforms.has(Platform.facebook)) {
      this.totalUsersPlatform = Platform.facebook;
      this.getTotalUsers(ChartType.total, Platform.facebook);
    } else {
      this.totalUsersLoading = false;
      this.totalUsersCsvExportDisabled = true;
    }
    this.$scope.$apply();
  };

  onDailyUsersPlatformChange = (platforms) => {
    this.dailyUsersData = {
      points: [[], []],
      dates: [],
    };

    this.dailyUsersLoading = true;
    this.dailyUsersLoadingError = false;
    this.dailyUsersCsvExportDisabled = false;

    this.pushBlockedUsersLine = 0;

    if (platforms.has(Platform.instagram) && platforms.has(Platform.facebook)) {
      this.dailyUsersPlatform = undefined;
      this.getTotalUsers(ChartType.daily);
    } else if (platforms.has(Platform.instagram)) {
      this.dailyUsersPlatform = Platform.instagram;
      this.getTotalUsers(ChartType.daily, Platform.instagram);
    } else if (platforms.has(Platform.facebook)) {
      this.dailyUsersPlatform = Platform.facebook;
      this.getTotalUsers(ChartType.daily, Platform.facebook);
    } else {
      this.dailyUsersLoading = false;
      this.dailyUsersCsvExportDisabled = true;
    }
    this.$scope.$apply();
  };

  numberFormater(num, ksh) {
    num = Math.abs(num);
    return (
      (num > 1000 && ksh ? Math.round(num / 1000) : num)
        .toFixed(0)
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,') +
      (num > 1000 && ksh ? 'k' : '')
    );
  }

  genPreview() {
    this.totalUsersData = {
      points: [[], []],
      dates: [],
    };

    this.dailyUsersData = {
      points: [[], []],
      dates: [],
    };

    let total = Math.round(Math.random() * 1000) + 1000;
    let ublock = Math.round(Math.random() * 400) + 100;

    for (let i = 0; i < 8; i++) {
      this.totalUsersData.dates.push(
        moment().endOf('day').subtract(i, 'days').toDate(),
      );

      total += Math.round(Math.random() * 1000) + 1000;
      ublock += Math.round(Math.random() * 400) + 100;

      this.totalUsersData.points[0].push(total);
      this.totalUsersData.points[1].push(ublock);
    }

    this.totalUsersData.dates.forEach((date, i) => {
      if (i > 0) {
        const dailyUsersUns =
          this.totalUsersData.points[1][i] -
          this.totalUsersData.points[1][i - 1];
        const dailyUsersTot =
          this.totalUsersData.points[0][i] -
          this.totalUsersData.points[0][i - 1];

        this.dailyUsersData.dates.push(this.totalUsersData.dates[i]);
        this.dailyUsersData.points[0].push(dailyUsersTot);
        this.dailyUsersData.points[1].push(-dailyUsersUns);
      }
    });
  }

  encodeLink(referralParameter) {
    return encodeURIComponent(referralParameter);
  }

  getDateRange(startDate, endDate) {
    const startDateFormatted = moment(startDate).format('MMM D');
    const endDateFormatted = moment(endDate).format('MMM D');
    if (startDateFormatted === endDateFormatted) {
      return startDateFormatted;
    }
    return `${startDateFormatted} — ${endDateFormatted}`;
  }

  isWhiteLabelDomain = () => isWhiteLabelDomain();

  onRangeChange = ([startDate, endDate], range) => {
    this.$scope.$evalAsync(() => {
      this.totalUsersLoadingError = false;
      this.userActivityLoadingError = false;
      this.popularBlocksLoadingError = false;
      this.referralsLoadingError = false;
      this.aiInboxLoadingError = false;
      this.popularUrlsLoadingError = false;

      this.startDate = startDate.toDate();
      this.endDate = endDate.toDate();

      sendEvent({
        category: 'analytics',
        action: 'choose date range',
        propertyBag: {
          range,
          botId: this.$rootScope.stateParams.botId,
          startDate: this.startDate,
          endDate: this.endDate,
        },
      });
    });
  };

  onRangeChangeCancel = () => {
    sendEvent({
      category: 'analytics',
      action: 'cancel range choosing',
      propertyBag: {
        range: DatePickerShortcut.custom,
        botId: this.$rootScope.stateParams.botId,
      },
    });
  };

  onShowRangePickerMenu = () => {
    sendEvent({
      category: 'analytics',
      action: 'date range picker click',
      propertyBag: {
        botId: this.$rootScope.stateParams.botId,
      },
    });
  };

  getRefLink(ref) {
    if (testAllowedPlatforms([Platform.whatsapp], this.bot.allowedPlatforms)) {
      return `https://wa.me/${removeNonDigits(
        this.whatsappSettings.phone?.display_phone_number,
      )}${
        ref.ref?.name === DEFAULT_LINK_NAME
          ? ''
          : `?ref=${encodeURIComponent(ref.ref?.name || '')}`
      }`;
    }
    return `https://m.me/${
      this.bot.status.page_info.username || this.bot.status.page
    }${
      ref.ref?.name === DEFAULT_LINK_NAME
        ? ''
        : `?ref=${encodeURIComponent(ref.ref?.name || '')}`
    }`;
  }
}
