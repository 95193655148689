import { clone } from 'ramda';
import { getPluginsDefaultData } from '../../modern-components/Plugins/common/pluginConst';
import { PluginType } from '../../modern-components/Plugins/common/PluginTypes';
import { sendEvent } from '../../utils/Analytics';
import { refetchAttributesQueryObservables } from '../../utils/AttributesUtils/AttributesUtils';
import { removeTypename } from '../../utils/GQL/utils';
import { Platform } from '../../../@types/globalTypes';

const PLUGIN_TYPES = {
  comment: 'comment',
  collectEmail: 'collect_email',
  collectPhone: 'collect_phone',
  text: 'text',
  goto: 'goto',
  gallery: 'gallery',
  googleSheet: 'google_sheet',
  todo: 'todo',
  eventTrigger: 'event_trigger',
  handoverProtocol: 'handover',
};

/**
 * @description: used for save\remove plugin and other generic operations;
 */
export class PluginCardService {
  /**
   *
   * @param {*} $http -
   * @param {*} $rootScope -
   * @param {*} StoreService -
   */
  constructor($http, $rootScope, StoreService) {
    'ngInject';

    this.$http = $http;
    this.$rootScope = $rootScope;
    this.StoreService = StoreService;
    this.PLUGIN_TYPES = PLUGIN_TYPES;
  }

  /**
   * @description: save plugin
   * @param {*} data -
   * @param {*} async -
   * @return {PromiseLike<T> | Promise<T>} -
   */
  save(data, async) {
    const ldata = removeTypename(data);

    delete ldata.validation_details;

    // todo Localization to Array in backend
    if (ldata && Array.isArray(ldata.localization)) {
      ldata.localization = ldata.localization.reduce((acc, phrase) => {
        acc[phrase.key] = phrase.value;
        return acc;
      }, {});
    }

    if (ldata.plugin_id === 'search') {
      ldata.config.number_of_pages = Number(ldata.config.number_of_pages);
    }

    delete ldata.initParam;
    /**
     * @description: Recursive remove empty fields in object
     * @param {*} obj -
     * @return {void} -
     */
    const cleaner = (obj) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if (
          obj[key] === '' &&
          !['postback_email', 'attribute_update_list'].includes(key)
        ) {
          delete obj[key];
        } else if (typeof obj[key] === 'object') {
          cleaner(obj[key]);
        }
      }
    };

    /**
     * @description: Recursive remove null fields in object
     * @param {*} obj -
     * @return {void} -
     */
    const cleanerNull = (obj) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if (obj[key] === null) {
          delete obj[key];
        } else if (typeof obj[key] === 'object') {
          cleanerNull(obj[key]);
        }
      }
    };

    if (
      ldata.plugin_id !== 'setup_variable_plugin' &&
      !Object.keys(getPluginsDefaultData()).includes(ldata.plugin_id) // no clean config of new plugins
    ) {
      cleaner(ldata);
    }
    if (ldata.plugin_id === 'setup_variable_plugin') {
      cleanerNull(ldata);
    }

    return this.$http({
      url: `${this.StoreService.getApiUrl()}/blocks/${ldata.blockId}/cards`,
      method: 'post',
      cacheOptions: {
        skipPreCache: true,
      },
      data: ldata,
    }).then((res) => {
      this._updateAllAttributesDataIfNeedByPluginId(ldata.plugin_id);
      return res.data;
    });
  }

  /**
   * @description: Update attributes suggest for some plugins
   * @param {string} pluginId -
   * @return {void} -
   *
   *  TODO Implement similar functionality for plugins on React (when need)
   */
  _updateAllAttributesDataIfNeedByPluginId(pluginId) {
    if (
      [
        PluginType.quick_reply,
        PluginType.form_v2,
        PluginType.setup_variable_plugin,
        PluginType.collect_phone,
        PluginType.collect_email,
      ].includes(pluginId)
    ) {
      refetchAttributesQueryObservables(
        this.$rootScope.stateParams.botId,
        Platform.facebook,
      );
    }
  }

  /**
   * @description: delete plugin?
   * @param {PluginItem} data -
   * @return {PromiseLike<T> | Promise<T>} -
   */
  remove({ id, plugin_id }) {
    if (plugin_id) {
      sendEvent({
        category: 'plugin',
        action: 'delete',
        label: plugin_id,
        propertyBag: {
          plugin: plugin_id,
        },
      });
    }

    return this.$http({
      url: `${this.StoreService.getApiUrl()}/cards/${id}/delete`,
      method: 'post',
    }).then((res) => res.data);
  }

  /**
   * @description Quick reply cannot be shown in any index for example it cannot be first in block
   * @param {number} index - current qr index
   * @param {Array<PluginItem>} plugins - array of all plugins
   * @return {boolean} - is it possible to put QR plugin here;
   */
  isQuickReplyAvailableInThisIndex(index, plugins) {
    return (
      index > 0 &&
      plugins &&
      plugins.length > 0 &&
      ['text', 'gallery', 'image', 'list'].includes(
        (
          plugins
            .slice(0, index)
            .filter((plugin) => !this.isCommentPlugin(plugin.plugin_id))
            .pop() || {}
        ).plugin_id,
      )
    );
  }

  /**
   * @description: is it comment plugin or not;
   * @param {string} pluginId -
   * @return {boolean} -
   */
  isCommentPlugin(pluginId) {
    return pluginId === PLUGIN_TYPES.comment;
  }

  /**
   * @description: -;
   * @param {string} pluginId -
   * @return {boolean} -
   */
  isCollectPhonePlugin(pluginId) {
    return pluginId === PLUGIN_TYPES.collectPhone;
  }

  /**
   * @description: -;
   * @param {string} pluginId -
   * @return {boolean} -
   */
  isGoogleSheetPlugin(pluginId) {
    return pluginId === PLUGIN_TYPES.googleSheet;
  }

  isHandoverProtocolPlugin(pluginId) {
    return pluginId === PLUGIN_TYPES.handoverProtocol;
  }

  isTodoPlugin(pluginId) {
    return pluginId === PLUGIN_TYPES.todo;
  }

  isEventTriggerPlugin(pluginId) {
    return pluginId === PLUGIN_TYPES.eventTrigger;
  }

  isAutopostingPlugin(pluginId) {
    return pluginId.includes('autoposting/');
  }

  isIntegrationPlugin(pluginId) {
    return pluginId.includes('integration/');
  }

  /**
   * @description: -;
   * @param {string} pluginId -
   * @return {boolean} -
   */
  isCollectPhoneOrCollectEmailPlugin(pluginId) {
    return (
      pluginId === PLUGIN_TYPES.collectEmail ||
      this.isCollectPhonePlugin(pluginId)
    );
  }

  /**
   * @description: sometime we should put delete button id diffrent place so we generate class names here;
   * @param {*} plugin - ?
   * @param {*} pluginIdForHideButtons - ?
   * @return {Array<string>} - array of classes
   */
  pluginButtonsClassGenerator(plugin, pluginIdForHideButtons) {
    const clases = [];

    if (this.isCommentPlugin(plugin.plugin_id)) {
      clases.push('position-top');
    }

    if (pluginIdForHideButtons.includes(plugin.id)) {
      clases.push('r-hide');
    }

    return clases;
  }
}
