import ng from 'angular';
import './assets/less/style.less';

import angularInputMasks from 'angular-input-masks';

import Component from './component';

export default ng
  .module('app.common.plugins.buyButton', [angularInputMasks])
  .directive('buyButton', Component).name;
