import throttle from 'lodash-es/throttle';
import { sendEvent } from '../../../utils/Analytics';

export default class MessengerExtensionsController {
  constructor($scope, $timeout, BotService) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.BotService = BotService;

    this.botWatcher = this.$scope.$watch('vmme.bot', (bot) => {
      if (bot && !bot.button_whitelist) {
        this.bot.button_whitelist = [];
      }
    });
  }

  $onInit() {
    this.throttleHandler = throttle((messengerExtensions, url) => {
      this.BotService.messengerExtensions(messengerExtensions).then(
        (res) => {
          this.invalidUrls = [];
          this.urlInd = this.validatingField;
          this.validatingField = null;
        },
        () => {
          this.validatingField = null;
        },
      );
    }, 500);
  }

  $onDestroy() {
    this.botWatcher();
  }

  addUrl() {
    sendEvent({
      category: 'messenger extensions',
      action: 'add url',
    });

    this.bot.button_whitelist.push('');

    this.$timeout(() => {
      const newUrlFields = angular.element.find('.messenger-extensions-input');
      newUrlFields[newUrlFields.length - 1].focus();
    });
  }

  remove(index) {
    this.bot.button_whitelist.splice(index, 1);
    this.urlInd = null;
    this.save();
  }

  save(index, url) {
    this.validatingField = index;
    this.throttleHandler(
      this.bot.button_whitelist.filter((element) => {
        return element.length;
      }),
      url,
    );
  }

  nonValidUrl(url) {
    return !url;
  }
}
