import { log } from 'cf-common/src/logger';

export class LocalStorageService {
  setItem(key, value) {
    try {
      // eslint-disable-next-line chatfuel/no-use-localStorage
      window.localStorage.setItem(key, value);
    } catch (error) {
      log.warn({ error });
    }
  }

  getItem(key) {
    try {
      // eslint-disable-next-line chatfuel/no-use-localStorage
      return window.localStorage.getItem(key);
    } catch (error) {
      log.warn({ error });
      return undefined;
    }
  }
}
