import React, { PropsWithChildren } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Icon } from '@ui/Icon';
import { ButtonProps, ButtonUnstyled } from '@ui/Button';
import { CollapsibleSection } from '@ui/CollapsibleSection';
import cn from 'classnames';
import css from './Container.css';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';

export interface IntegrationContainerProps {
  anchor(extended: boolean): React.ReactNode;
  defaultExtended?: boolean;
  loading: boolean;
  'data-testid'?: string;
}

export const IntegrationContainer: React.FC<
  PropsWithChildren<IntegrationContainerProps>
> = ({ anchor, children, defaultExtended, loading, ...props }) => (
  <div className={cn('border-box', css.container)}>
    {loading ? (
      <LoadingPlaceholder height={30} />
    ) : (
      <CollapsibleSection
        defaultExtended={defaultExtended}
        anchor={({ changeExtended, extended }) => (
          <ButtonUnstyled
            className={css.sectionTitle}
            onClick={changeExtended}
            data-testid={props['data-testid']}
          >
            <Flex alignItems="center" className={css.anchor}>
              {anchor(extended)}
            </Flex>
            <Icon
              className={cn(css.icon, extended && css.extended)}
              icon="arrow_right"
              size="20px"
            />
          </ButtonUnstyled>
        )}
      >
        {({ bind }) => (
          <div {...bind}>
            <Spacer factor={4} />
            <Flex flexDirection="column" gapFactor={7}>
              {children}
            </Flex>
          </div>
        )}
      </CollapsibleSection>
    )}
  </div>
);

export interface DisonnectButtonViewProps {
  getButtonProps: (props: Partial<ButtonProps>) => Partial<ButtonProps>;
}

export interface IntegrationContainerAnchorProps {
  extended: boolean;
  imageView: React.ReactNode;
  isConnected: boolean;
  disconnectButtonView: (props: DisonnectButtonViewProps) => React.ReactNode;
  noteView: React.ReactNode;
}

export const IntegrationContainerAnchor: React.FC<IntegrationContainerAnchorProps> =
  ({ extended, imageView, isConnected, disconnectButtonView, noteView }) => {
    return (
      <>
        {imageView}
        <Spacer factor={0} horizontalFactor={2} />
        {isConnected && (
          <>
            {noteView}

            <CSSTransition
              in={extended}
              timeout={300}
              classNames={{
                enter: css.disconnectActionEnter,
                enterActive: css.disconnectActionEnterActive,
                exit: css.disconnectActionExit,
                exitActive: css.disconnectActionExitActive,
              }}
              unmountOnExit
            >
              <div className={css.disconnectAction}>
                {disconnectButtonView({
                  getButtonProps: (buttonProps: Partial<ButtonProps>) => ({
                    ...buttonProps,
                    intent: 'secondary',
                    onClick: (e) => {
                      e.stopPropagation();
                      buttonProps.onClick?.(e);
                    },
                  }),
                })}
              </div>
            </CSSTransition>
          </>
        )}
      </>
    );
  };
