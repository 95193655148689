import { getFbLoginStatus } from '@components/FBPlugins/FbLoginStatus';
import { clearUserId } from '@utils/UserId';
import { LSKey, LS } from 'cf-common/src/localStorage';
import merge from 'lodash-es/merge';
import { livechatResolvers } from '@pages/LiveChat/components/Messages/resolvers';

interface TokenSetMutation {
  token: string;
}

const ClipboardState: { data: string | null } = {
  data: null,
};

const commonResolvers = {
  Query: {
    token: () => {
      return LS.getRaw(LSKey.token);
    },
    successAuth: () => LS.getRaw(LSKey.authed),
    fbLoginStatus: async () => {
      const { status, authResponse } = await getFbLoginStatus(true);

      return {
        status,
        __typename: 'FbSdkLoginStatus',
        authResponse: {
          __typename: 'FbSdkLoginAuthResponse',
          accessToken: (authResponse && authResponse.accessToken) || '',
        },
      };
    },
    clipboardData: () => {
      return ClipboardState.data;
    },
  },
  Mutation: {
    tokenSet: (_: any, { token }: TokenSetMutation) => {
      LS.setRaw(LSKey.token, token);
      return token;
    },
    tokenRemove: () => {
      LS.remove(LSKey.token);
      return true;
    },
    userIdClear: () => {
      clearUserId();
      return true;
    },
    successAuthSet: () => {
      LS.setRaw(LSKey.authed, 'true');
      return true;
    },
    successAuthRemove: () => {
      LS.remove(LSKey.authed);
      return true;
    },
    clipboardDataSet: (_: any, { data }: { data: string }) => {
      ClipboardState.data = data;
      return true;
    },
  },
};

export const resolvers = merge(commonResolvers, livechatResolvers);
