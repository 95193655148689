import gql from 'graphql-tag';

export const TEMPLATES_CATEGORIES_AND_CREATORS = gql`
  query TEMPLATES_CATEGORIES_AND_CREATORS {
    templatesCategories {
      id
      name
      order
    }
    templatesCreators {
      id
      name
      image
      description
      url
      urlName
      fbPageId
      fbPageName
    }
  }
`;

export const TEMPLATES_LIST = gql`
  query TEMPLATES_LIST {
    templates {
      id
      name
      description
      image
      bgColor
      fontColor
      price
      botId
      creator {
        id
        name
      }
      version
      purchased
      pageId
      firstBlock
      order
      categories {
        id
        name
      }
    }
  }
`;
