import ng from 'angular';

import './assets/less/card-setup-variable-plugin.less';
import attributeValuesAdv from './attribute-values-adv';

import Component from './component';

export default ng
  .module('app.common.plugins.cardSetupVariablePlugin', [attributeValuesAdv])
  .directive('pluginCardSetupVariable', Component).name;
