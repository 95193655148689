import controller from './controller';
import templateUrl from './content.tpl';
import windowTemplateUrl from './window.tpl';

import './assets/less/style.less';

export default (params) => ({
  controllerAs: 'vm',
  bindToController: true,
  backdrop: 'static',
  resolve: {
    params,
  },
  controller,
  templateUrl,
  windowTemplateUrl,
});
