import { isNil } from 'ramda';
import { getPluginName } from '../../../common/pluginNames';

export function titleCleaner() {
  return (val) => val.split('|')[0];
}

export function percent() {
  return (val, base) =>
    `${val && base ? Math.floor((val / base) * 100) : '0'}%`;
}

export function generatePreview() {
  return (obj) => {
    if (!obj) {
      return '';
    }
    const aCount = new Map(
      [...new Set(obj.plugins)].map((x) => [
        x,
        obj.plugins.filter((y) => y === x).length,
      ]),
    );
    let out = '';
    aCount.forEach((cou, pId) => {
      out += `${cou} ${getPluginName(pId)} plugin${cou > 1 ? 's' : ''} &nbsp; `;
    });
    return out;
  };
}

export function numberFormat() {
  return (n) => (isNil(n) ? '' : n.toLocaleString('en-EN'));
}
