import ng from 'angular';

export default ng
  .module('app.common.textareaVExpand', [])
  .directive('textareaVExpand', ($timeout) => {
    'ngInject';

    return (scope, element) => {
      $timeout(() => {
        const listeners = [];

        const updater = () => {
          $timeout(() => {
            const mask = element.parent().find('.wrap-mask');
            const maskHeight = mask && mask.height();
            if (maskHeight) {
              element.css({ height: maskHeight + 12 });
            }
          });
        };

        listeners.push(
          scope.$on('update-mask', () => {
            updater();
          }),
        );

        listeners.push(
          scope.$on('$destroy', () => {
            listeners.forEach((fn) => fn.call());
          }),
        );

        updater();
      });
    };
  }).name;
