/**
 * NOTE: Extracted from PeopleService class
 */

export function diffArrayByKeys(arrayA, arrayB, keys) {
  const [firstKey, ...otherKeys] = keys;
  const indexArrayB = {};
  // index by first key;
  arrayB.forEach((obj) => {
    const indexObjectByValueOfTheFistKey = obj[firstKey];
    indexArrayB[indexObjectByValueOfTheFistKey] = obj;
  });
  return arrayA.filter((obj) => {
    const objectFromB = indexArrayB[obj[firstKey]];
    return (
      !objectFromB ||
      otherKeys.find(
        (otherKey) => objectFromB[otherKey][0] !== obj[otherKey][0],
      )
    );
  });
}

const EXCLUDE_ATTRIBUTES_NAMES_FROM_DIFF = [
  'subscribed sequences',
  'triggered sequences',
];
const isNotExcludedAttribute = (name) =>
  !EXCLUDE_ATTRIBUTES_NAMES_FROM_DIFF.includes(name);

/**
 * case A edit value: attributes && oldAttributes has value but value is different;
 * case B add attr or rename: attributes && oldAttributes do not have attributes;
 * case C deleted or rename: oldAttributes did not have attribute but attributes has one;
 * @param {BotUserVariables[]} attributes -
 * @param  {BotUserVariables[]} oldAttributes -
 * @return {{update: {name, value: *}[], delete: any[]}} -
 */
export function getAttributesDiff(attributes, oldAttributes) {
  const update = diffArrayByKeys(attributes, oldAttributes, ['name', 'values']);
  const deleted = diffArrayByKeys(oldAttributes, attributes, ['name']);
  return {
    update: update
      .map(({ name, values }) => ({
        name,
        value: values[0],
      }))
      .filter(isNotExcludedAttribute),
    delete: deleted.map((attr) => attr.name).filter(isNotExcludedAttribute),
  };
}
