import template from './attribute-values-adv.html';
import controller from './controller';

export default () => ({
  template,
  controllerAs: '$val',
  controller,
  bindToController: true,
  restrict: 'E',
  replace: true,
  scope: {
    value: '=',
    values: '=',
    vars: '=',
    isNew: '@',
    varName: '@',
    save: '&',
    loadValues: '&',
  },
});
