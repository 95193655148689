import ng from 'angular';
import template from './template.html';
import { UsersFilterComponentDataLoaderConstructorController } from './controller';
import './user-filter-component-data-loader.less';

export default ng
  .module('app.components.users.userFilterComponentDataLoader', [])
  .component('userFilterComponentDataLoader', {
    template: () => template,
    controllerAs: 'vm',
    controller: UsersFilterComponentDataLoaderConstructorController,
    bindings: {
      filter: '<',
      showGlobalPreloader: '<',
      onChange: '&',
      onReady: '&',
      onFilterItemRemove: '&',
      type: '@',
      includeOnlyParameters: '<',
    },
  }).name;
