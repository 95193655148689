/* eslint-disable new-cap */
import moment from 'moment';
import { ChartType } from './ChartType';

/**
 * Class totalUsers
 */
class totalUsers {
  constructor($http, StoreService) {
    this.$http = $http;
    this.StoreService = StoreService;

    return this;
  }

  get(controllerContext, chartType = ChartType.all, platform = undefined) {
    const startDate = moment(controllerContext.startDate);
    const endDate = moment(controllerContext.endDate);
    if (startDate.isSame(endDate, 'day')) {
      startDate.subtract(1, 'day');
    }
    return this.$http({
      url: `${this.StoreService.getApiUrl()}/bots/${
        controllerContext.$rootScope.stateParams.botId
      }/yandex/stats/total_users`,
      params: {
        start_date: startDate.subtract(1, 'day').format('Y-MM-DD'),
        end_date: endDate.format('Y-MM-DD'),
        ...(platform ? { platform } : {}),
      },
      method: 'get',
    })
      .success(this.success.call(controllerContext, chartType))
      .error(this.error.call(controllerContext, chartType));
  }

  success(chartType) {
    return (res) => {
      if (chartType !== ChartType.daily) {
        this.totalUsersData = {
          points: [[], []],
          dates: [],
          datesObj: [],
        };
      }

      if (chartType !== ChartType.total) {
        this.dailyUsersData = {
          points: [[], []],
          dates: [],
          datesObj: [],
        };
      }

      res.result.x.forEach((date, i) => {
        if (date.indexOf('201') === -1) {
          date += ', 2016';
        }
        if (i > 0 && chartType !== ChartType.daily) {
          this.totalUsersData.dates.push(
            moment(date).format('MMM D').toUpperCase(),
          );
          this.totalUsersData.datesObj.push(moment(date).toDate());
          this.totalUsersData.points[0].push(
            res.result.y['Total users'][i] - res.result.y['Blocked users'][i],
          );
          this.totalUsersData.points[1].push(res.result.y['Blocked users'][i]);
        }

        if (i > 0 && chartType !== ChartType.total) {
          const dailyUsersUns = res.result.y['New Blocked users']
            ? res.result.y['New Blocked users'][i]
            : 0;
          const dailyUsersTot = res.result.y['Total New users']
            ? res.result.y['Total New users'][i]
            : 0;

          this.dailyUsersData.dates.push(
            moment(date).format('MMM D').toUpperCase(),
          );
          this.dailyUsersData.datesObj.push(moment(date).toDate());
          this.dailyUsersData.points[0].push(dailyUsersTot);
          this.dailyUsersData.points[1].push(-dailyUsersUns);
        }
      });

      if (chartType !== ChartType.total) {
        this.pushBlockedUsersLine =
          Math.max(...this.dailyUsersData.points[0]) * 0.015;
        this.dailyUsersData.points[1].forEach((point, i) => {
          this.dailyUsersData.points[1][i] -= this.pushBlockedUsersLine;
        });
      }

      this.minDate = new Date(res.result.x[0]);

      if (chartType !== ChartType.total) {
        this.dailyUsersLoading = false;
      }

      if (chartType !== ChartType.daily) {
        this.totalUsersLoading = false;
      }
    };
  }

  error(chartType) {
    return () => {
      if (chartType !== ChartType.total) {
        this.dailyUsersLoading = false;
        this.dailyUsersLoadingError = true;
      }
      if (chartType !== ChartType.daily) {
        this.totalUsersLoading = false;
        this.totalUsersLoadingError = true;
      }
    };
  }
}

/**
 * Class eventUsers
 */
class eventUsers {
  constructor($http, StoreService) {
    this.$http = $http;
    this.StoreService = StoreService;

    return this;
  }

  get(controllerContext) {
    return this.$http({
      url: `${this.StoreService.getApiUrl()}/bots/${
        controllerContext.$rootScope.stateParams.botId
      }/yandex/stats/event_users`,
      params: {
        start_date: moment(controllerContext.startDate).format('Y-MM-DD'),
        end_date: moment(controllerContext.endDate).format('Y-MM-DD'),
      },
      method: 'get',
    })
      .success(this.success.call(controllerContext))
      .error(this.error.call(controllerContext));
  }

  success() {
    return (res) => {
      this.data.datasetOverride.popular = [];

      this.userEventData = {
        points: [],
        dates: [],
        datesObj: [],
      };
      res.result.x.forEach((date) => {
        if (date.indexOf('201') === -1) {
          date += ', 2016';
        }

        this.userEventData.dates.push(
          moment(date).format('MMM D').toUpperCase(),
        );
        this.userEventData.datesObj.push(moment(date).toDate());
      });

      if (res.result.y['ym:ce:users']) {
        this.userEventData.points.push(res.result.y['ym:ce:users']);
      } else {
        this.userEventData.points.push(this.userEventData.dates.map(() => 0));
      }

      if (res.result.y['broadcast read']) {
        this.userEventData.points.push(res.result.y['broadcast read']);
      } else {
        this.userEventData.points.push(this.userEventData.dates.map(() => 0));
      }

      if (res.result.y['input from user received']) {
        this.userEventData.points.push(
          res.result.y['input from user received'],
        );
      } else {
        this.userEventData.points.push(this.userEventData.dates.map(() => 0));
      }

      this.userActivityLoading = false;
    };
  }

  error() {
    return () => {
      this.userActivityLoading = false;
      this.userActivityLoadingError = true;
    };
  }
}

/**
 * Class userUsage
 */
class userUsage {
  constructor($http, StoreService) {
    this.$http = $http;
    this.StoreService = StoreService;

    return this;
  }

  get(controllerContext) {
    return this.$http({
      url: `${this.StoreService.getApiUrl()}/bots/${
        controllerContext.$rootScope.stateParams.botId
      }/yandex/stats/usage`,
      params: {
        start_date: moment(controllerContext.startDate).format('Y-MM-DD'),
        end_date: moment(controllerContext.endDate).format('Y-MM-DD'),
      },
      method: 'get',
    })
      .success(this.success.call(controllerContext))
      .error(this.error.call(controllerContext));
  }

  success() {
    return (res) => {
      this.userPopularBlocks = res.result.blocks;
      this.userPopularButtons = res.result['button pressed'];
      this.popularBlocksLoading = false;
      this.popularButtonsLoading = false;
      this.popularInputsLoading = false;
    };
  }

  error() {
    return () => {
      this.popularBlocksLoading = false;
      this.popularButtonsLoading = false;
      this.popularInputsLoading = false;
      this.popularBlocksLoadingError = true;
    };
  }
}

/**
 * class userReferrals
 */
class userReferrals {
  constructor($http, StoreService) {
    this.$http = $http;
    this.StoreService = StoreService;

    return this;
  }

  dateReqSting(startDate, endDate) {
    return `?start_date=${moment(startDate).format(
      'Y-MM-DD',
    )}&end_date=${moment(endDate).format('Y-MM-DD')}`;
  }
  get(controllerContext) {
    return this.$http({
      url: `${this.StoreService.getApiUrl()}/bots/${
        controllerContext.$rootScope.stateParams.botId
      }/yandex/stats/referrals`,
      params: {
        start_date: moment(controllerContext.startDate).format('Y-MM-DD'),
        end_date: moment(controllerContext.endDate).format('Y-MM-DD'),
      },
      method: 'get',
    })
      .success(this.success.call(controllerContext))
      .error(this.error.call(controllerContext));
  }

  success() {
    return (res) => {
      this.referralsData = res.result;
      this.referralsLoading = false;
      this.referralsLoadingError = false;
    };
  }

  error() {
    return () => {
      this.referralsLoading = false;
      this.referralsLoadingError = true;
    };
  }
}

/**
 * class usersAiInbox
 */
class usersAiInbox {
  constructor($http, StoreService) {
    this.$http = $http;
    this.StoreService = StoreService;

    return this;
  }

  get(controllerContext) {
    return this.$http({
      url: `${this.StoreService.getApiUrl()}/bots/${
        controllerContext.$rootScope.stateParams.botId
      }/ai_inbox`,
      method: 'get',
    })
      .success(this.success.call(controllerContext))
      .error(this.error.call(controllerContext));
  }

  success() {
    return (res) => {
      let duplicatedPhrase;
      this.aiInboxData = [];
      const startDateTemp = new Date(this.startDate);
      const endDateTemp = new Date(this.endDate);
      startDateTemp.setHours(0, 0, 0, 0);
      endDateTemp.setHours(23, 59, 59, 0);

      this.aiInboxDateFiltered = res.result.filter((message) => {
        return (
          message.date >= startDateTemp.getTime() &&
          message.date <= endDateTemp.getTime()
        );
      });

      if (
        this.aiInboxDateFiltered.length &&
        this.aiInboxDateFiltered.length < 2
      ) {
        this.aiInboxData.push({
          phrase: this.aiInboxDateFiltered[0].phrase,
          number: 1,
        });
      } else {
        this.aiInboxDateFiltered.forEach((message, index) => {
          duplicatedPhrase = this.aiInboxDateFiltered
            .slice(0, index)
            .find((element) => {
              return element.phrase === message.phrase;
            });

          if (!duplicatedPhrase) {
            this.aiInboxData.push({ phrase: message.phrase, number: 1 });
          } else {
            ++this.aiInboxData.find((element) => {
              return element.phrase === message.phrase;
            }).number;
          }
        });
      }

      this.aiInboxLoading = false;
      this.aiInboxLoadingError = false;
    };
  }

  error() {
    return () => {
      this.aiInboxLoading = false;
      this.aiInboxLoadingError = true;
    };
  }
}

/**
 * class userNewReferrals
 */
class userNewReferrals {
  constructor($http, StoreService, $q) {
    this.$q = $q;
    this.$http = $http;
    this.StoreService = StoreService;

    return this;
  }

  get(controllerContext) {
    const chDate = new Date(2017, 6, 20, 0, 0, 0, 0);
    const intervals = {
      before: {
        startDate: null,
        endDate: null,
      },
      after: {
        startDate: null,
        endDate: null,
      },
    };

    const requestes = [];

    if (controllerContext.startDate < chDate) {
      intervals.before.startDate = controllerContext.startDate;
      intervals.before.endDate =
        controllerContext.endDate < chDate ? controllerContext.endDate : chDate;
      requestes.push(
        this.$http({
          url: `${this.StoreService.getApiUrl()}/bots/${
            controllerContext.$rootScope.stateParams.botId
          }/yandex/stats/referrals`,
          params: {
            start_date: moment(intervals.before.startDate).format('Y-MM-DD'),
            end_date: moment(intervals.before.endDate).format('Y-MM-DD'),
          },
          method: 'get',
        }),
      );
    }

    if (controllerContext.endDate >= chDate) {
      intervals.after.startDate =
        controllerContext.startDate >= chDate
          ? controllerContext.startDate
          : chDate;
      intervals.after.endDate = controllerContext.endDate;
      requestes.push(
        this.$http({
          url: `${this.StoreService.getApiUrl()}/bots/${
            controllerContext.$rootScope.stateParams.botId
          }/ref_stats`,
          params: {
            start_date: moment(intervals.after.startDate).format('Y-MM-DD'),
            end_date: moment(intervals.after.endDate).format('Y-MM-DD'),
          },
          method: 'get',
        }),
      );
    }

    return this.$q
      .all(requestes)
      .then(
        this.success.call(controllerContext),
        this.error.call(controllerContext),
      );
  }

  success() {
    return (results) => {
      let refArr = [];

      results.forEach((req) => {
        const res = req.data.result;
        if (res.length && res[0].ref) {
          refArr = refArr.concat(res);
        } else if (res.length && res[0].link) {
          const refs = {};
          res.forEach((ref) => {
            if (!refs[ref.link]) {
              refs[ref.link] = 0;
            }
            refs[ref.link] += ref.new;
          });

          // eslint-disable-next-line no-restricted-syntax
          for (const link in refs) {
            if (Object.prototype.hasOwnProperty.call(refs, link)) {
              refArr.push({
                all_interactions: null,
                first_interactions: 0,
                new_interactions: refs[link],
                ref: {
                  type: 'prior_untracked_source',
                  prior_untracked_source_ref:
                    link === 'Default link' ? '-' : link,
                },
              });
            }
          }
        }
      });

      refArr.sort(
        (a, b) =>
          (b.new_interactions > a.new_interactions) -
            (a.new_interactions > b.new_interactions) ||
          (b.all_interactions > a.all_interactions) -
            (a.all_interactions > b.all_interactions),
      );

      this.referralsData = refArr;
      this.referralsLoading = false;
      this.referralsLoadingError = false;
    };
  }

  error() {
    return () => {
      this.compileDateProc = false;
      this.referralsLoading = false;
      this.referralsLoadingError = true;
    };
  }
}

/**
 * class userReferrals
 */
class popularUrls {
  constructor($http, StoreService) {
    this.$http = $http;
    this.StoreService = StoreService;

    return this;
  }

  get(controllerContext) {
    return this.$http({
      url: `${this.StoreService.getApiUrl()}/bots/${
        controllerContext.$rootScope.stateParams.botId
      }/url_stats`,
      params: {
        start_date: moment(controllerContext.startDate).format('Y-MM-DD'),
        end_date: moment(controllerContext.endDate).format('Y-MM-DD'),
      },
      method: 'get',
    })
      .success(this.success.call(controllerContext))
      .error(this.error.call(controllerContext));
  }

  success() {
    return (res) => {
      this.popularUrlsData = res.result;
      this.popularUrlsLoading = false;
      this.popularUrlsLoadingError = false;
    };
  }

  error() {
    return () => {
      this.popularUrlsLoading = false;
      this.popularUrlsLoadingError = true;
    };
  }
}

/**
 * EXPORT function
 */
export default ($http, StoreService, $q) => {
  'ngInject';

  return {
    totalUsers: new totalUsers($http, StoreService),
    eventUsers: new eventUsers($http, StoreService),
    userUsage: new userUsage($http, StoreService),
    userReferrals: new userReferrals($http, StoreService),
    usersAiInbox: new usersAiInbox($http, StoreService),
    userNewReferrals: new userNewReferrals($http, StoreService, $q),
    popularUrls: new popularUrls($http, StoreService),
  };
};
