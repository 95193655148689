import ng from 'angular';
import { react2angular } from 'react2angular';
import component from './component';
import 'angular-progress-arc';
import './assets/less/history.less';
import { ngExportBroadcast } from '../ExportBroadcast/ExportBroadcast.angular';
import { StatDetails } from './StatDetails';

import {
  percent,
  generatePreview,
  titleCleaner,
  numberFormat,
} from './filters';

export default ng
  .module('app.broadcast.history', ['angular-progress-arc', ngExportBroadcast])
  .component('statDetails', react2angular(StatDetails, ['errors']))
  .directive('history', component)
  .filter('percent', percent)
  .filter('generatePreview', generatePreview)
  .filter('titleCleaner', titleCleaner)
  .filter('numberFormat', numberFormat).name;
