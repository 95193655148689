import moment from 'moment';
import { sortWith, prop, ascend, propEq } from 'ramda';
import { canEdit } from '../../../common/services/RoleService';
import { addRandomPostfix } from '../content.controller';
import { sendEvent } from '../../../utils/Analytics';

export default class BroadcastScheduleController {
  constructor(
    $rootScope,
    $scope,
    $filter,
    BotService,
    BlockService,
    BroadcastService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.BroadcastService = BroadcastService;
    this.BlockService = BlockService;
    this.BotService = BotService;
    this.RoleService = { canEdit };
    this.creating = false;

    this.date = new Date();

    this.listeners = [];
    this.schedules = [];

    this.listeners.push(
      $rootScope.$on('broadcast:remove', (event, id) => {
        this.deleteSchedule(id);
      }),
    );
    this.listeners.push(
      $rootScope.$on('broadcast:copy', (event, data) => {
        this.copySchedule(data);
      }),
    );

    this.listeners.push(
      $scope.$on('$pluginHasErrorValidation', (e, data) => {
        // this.updateValidateStatus(data.blockId);
      }),
    );

    this.listeners.push(
      $scope.$on('$pluginUpdated', (e, data, cards) => {
        this.updateValidateStatus(data.blockId, cards);
      }),
    );

    this.listeners.push(
      $rootScope.$on('broadcast:update', (event, id, updated) => {
        const broadcast = this.schedules.find((item) => item.id === id);

        if (broadcast) {
          broadcast.block_title = updated.block_title;
          broadcast.enabled = updated.enabled;
          broadcast.type = updated.type;
          broadcast.broadcast_start_timestamp =
            updated.broadcast_start_timestamp;
          if (broadcast.type === 'weekly_repeat') {
            broadcast.weekly_day_time = updated.weekly_day_time;
            broadcast.weekly_schedule = updated.weekly_schedule;
          }

          $scope.$apply();
        }
      }),
    );

    this.loadSchedules();
    this.loadBot();
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
  }

  updateValidateStatus(blockId, cards) {
    if (!blockId) {
      // eslint-disable-next-line prefer-destructuring
      blockId = this.$rootScope.stateParams.blockId;
    }
    const bc = this.schedules.find((item) => item.block_id === blockId);
    if (bc) {
      const isValid = cards.every((item) => {
        return item.is_valid === true;
      });
      bc.is_valid = isValid;
    }
  }

  loadBot() {
    return this.BotService.show().then((bots) => {
      this.bot = bots;
      return this.bot;
    });
  }

  loadSchedules() {
    return this.BroadcastService.schedules().then((rows) => {
      rows.forEach((row) => {
        if (this.schedules.some((item) => item.id === row.id)) {
          return;
        }
        this.schedules.push(row);
      });
      this.$rootScope.$broadcast('$schedulesLoaded', true);
    });
  }

  addSchedule() {
    if (this.creating) {
      return;
    }

    this.$scope.$evalAsync(() => {
      this.creating = true;
    });

    sendEvent({
      category: 're-engage',
      action: 'add',
      label: 'schedule for later',
    });

    this.loadBot().then((bot) => {
      const broadcasts = this.$getBroadcasts();
      const now = moment
        .utc(moment.utc() + bot.timezone_offset * 1000)
        .hours(12)
        .minutes(0)
        .seconds(0)
        .milliseconds(0);

      const date = moment.utc(now.add(1, 'd'));
      let title;

      const matchBroadcastByTitle = (broadcast) =>
        broadcast.block_title.split('|')[0] === title;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        title = date.format('MMM Do HH:mm');
        if (!broadcasts.find(matchBroadcastByTitle)) {
          break;
        }
        // add half hour and try again
        date.add(30, 'm');
      }

      title = addRandomPostfix(title);

      const def = {
        broadcast_start_timestamp: +date / 1000,
        type: 'no_repeat',
        use_bot_timezone: false,
      };
      return this.BlockService.createBroadcastBlock()
        .then((block) => {
          block.title = title;
          return this.BlockService.update(block);
        })
        .then((block) => {
          def.block_id = block.id;
          return this.BlockService.createBroadcastScheduleBlock(def).then(
            (broadcast) => ({
              block,
              broadcast,
            }),
          );
        })
        .then(({ block, broadcast }) => {
          this.$scope.$evalAsync(() => {
            this.creating = false;
          });
          broadcast.block_id = block.id;
          broadcast.block_title = block.title;
          broadcast.broadcast_start_timestamp = +date;
          broadcast.type = 'no_repeat';
          broadcast.enabled = false;

          this.schedules.push(broadcast);
          this.$rootScope.stateHistory.push(
            `/bot/${this.$rootScope.stateParams.botId}/broadcast/${block.id}/${broadcast.id}/schedule`,
          );
        });
    });
  }

  deleteSchedule(id) {
    const broadcast = this.schedules.find((item) => item.id === id);
    if (!broadcast) {
      return;
    }
    this.BlockService.remove(broadcast.block_id)
      .then(() => this.BroadcastService.removeSchedule(broadcast.id))
      .then(() => {
        const i = this.schedules.findIndex((item) => item.id === id);
        if (i !== -1) {
          this.schedules.splice(i, 1);
        }
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/broadcast`,
        );
      });
  }

  copySchedule(data) {
    const def = { ...data.broadcast };
    if (def.type === 'everyday') {
      def.type = 'weekly_repeat';
    }
    if (def.type === 'everyweek') {
      def.type = 'weekly_repeat';
    }
    if (def.type === 'workdays') {
      def.type = 'weekly_repeat';
    }

    delete def.id;
    delete def.botDeadline;
    def.block_id = data.block.id;
    def.enabled = false;
    if (def.type === 'no_repeat') {
      const now = moment.utc();
      const tempTimestamp = moment
        .utc(def.broadcast_start_timestamp)
        .set({
          year: now.year(),
          month: now.month(),
          date: now.date(),
        })
        .add(1, 'd');
      //     const tempTimestamp = moment.utc(def.broadcast_start_timestamp);
      const title = tempTimestamp.format('MMM Do HH:mm');
      def.broadcast_start_timestamp = tempTimestamp / 1000;
      def.block_title = title;
      this.BlockService.update(
        Object.assign(data.block, {
          title: `${title} | ${String(Math.random()).substr(2, 12)}`,
        }),
      );
    } else {
      def.broadcast_start_timestamp /= 1000;
      def.block_title = data.block.title;
    }
    this.BlockService.createBroadcastScheduleBlock(def).then((broadcast) => {
      const nBroadcast = Object.assign(broadcast, def, {
        broadcast_start_timestamp: def.broadcast_start_timestamp * 1000,
      });
      this.schedules.push(nBroadcast);
      this.$rootScope.stateHistory.push(
        `/bot/${this.$rootScope.stateParams.botId}/broadcast/${data.block.id}/${nBroadcast.id}/schedule`,
      );
    });
  }

  $getBroadcasts() {
    return sortWith(
      [ascend(prop('type')), ascend(prop('broadcast_start_timestamp'))],
      this._filterBroadcasts(this.date),
    );
  }

  _filterBroadcasts(date) {
    const now = moment.utc();
    const from = moment
      .utc({ year: now.year(), month: now.month(), day: 1 })
      .subtract(1, 'month');

    const to = moment.utc({
      year: date.getFullYear() + 2,
      month: 11,
      day: 31,
      hour: 24,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    return this.schedules.filter((item) => {
      if (item.type === 'no_repeat') {
        return (
          item.broadcast_start_timestamp >= from &&
          item.broadcast_start_timestamp < to
        );
      }
      return true;
    });
  }

  onMouseDownBlockItem(e) {
    const act = document.querySelector('.bc-temp-white.act');
    if (act && act !== e.currentTarget) {
      act.classList.remove('act');
      e.currentTarget.classList.add('act');
    }
  }

  onSchedulelick = ({ id, broadcast_id }) => {
    this.$rootScope.stateHistory.push(
      `/bot/${this.$rootScope.stateParams.botId}/broadcast/${id}/${broadcast_id}/schedule`,
    );
  };
}
