import React from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import { pathOr, propEq } from 'ramda';
import {
  LoginsListQuery,
  LoginsListQuery_bot_invites as IInvite,
  LoginsListQuery_whiteLabelUserLogins as ILogin,
  LoginsListQueryVariables,
} from './@types/LoginsListQuery';
import { defaultFilterItemsToShow, SimpleCombobox } from '@ui/SimpleCombobox';
import { Input } from '../../../../../../modern-ui/Input';
import { MenuItem } from '../../../../../../modern-ui/Menu';
import * as css from './WLLoginsSuggest.css';
import { ReactComponent as AddIcon } from '../../../../../../modern-ui/_deprecated/Icon/icons/ic_add_small.svg';
import { Flex } from '../../../../../../modern-ui/Flex';
import { Autofocus } from '../../../../../../modern-ui/Autofocus';

export interface ILoginState {
  login: string;
  isNew?: boolean;
}

interface IWLLoginsSuggestProps extends React.HTMLProps<HTMLInputElement> {
  onChangeLogin: (loginState: ILoginState) => void;
  botId: string;
}

export const LOGINS_LIST_QUERY = gql`
  query LoginsListQuery($botId: String!) {
    whiteLabelUserLogins {
      id
      login
      user_id
      user_bots {
        id
        title
      }
    }
    bot(id: $botId) {
      id
      invites {
        id
        author
        bot
        created
        roleId
        login
      }
    }
  }
`;

const CREATE_OPTION_ID = 'createOption';
const itemToString = pathOr('', ['login']);

export const WLLoginsSuggest: React.FC<IWLLoginsSuggestProps> = ({
  onChangeLogin,
  botId,
  ...props
}) => {
  return (
    <Query<LoginsListQuery, LoginsListQueryVariables>
      query={LOGINS_LIST_QUERY}
      variables={{
        botId,
      }}
    >
      {({ data, loading }) => {
        const items = pathOr<ILogin[]>([], ['whiteLabelUserLogins'], data);
        const invites = pathOr<IInvite[]>([], ['bot', 'invites'], data);
        const availableLogins = items.filter((item: ILogin) => {
          const userIsNotAddedToBot =
            !item.user_bots || !item.user_bots.some(propEq('id', botId));

          const userInvitationIsNotPending =
            !invites || !invites.some(propEq('login', item.login));

          const userHasBeenRegistered = !!item.user_id;

          return (
            userIsNotAddedToBot &&
            userInvitationIsNotPending &&
            userHasBeenRegistered
          );
        });
        return (
          <SimpleCombobox<ILogin>
            itemToString={itemToString}
            items={availableLogins}
            autoSelectFirstItem
            onSelect={(item) => {
              if (item) {
                const { login } = item;
                onChangeLogin({
                  login,
                  isNew: !items.some(propEq('login', login)),
                });
              }
            }}
            filter={(itemToString, items, selectedItem, inputValue) => {
              const matchingItems = defaultFilterItemsToShow<ILogin>(
                itemToString,
                items,
                selectedItem,
                inputValue,
              );
              const noItemsMatchFully =
                inputValue &&
                !matchingItems.some(
                  (item) => itemToString(item) === inputValue,
                );
              if (noItemsMatchFully) {
                return [
                  {
                    __typename: 'WhiteLabelLogin',
                    id: CREATE_OPTION_ID,
                    login: inputValue as string,
                    user_id: null,
                    user_bots: null,
                  },
                  ...matchingItems,
                ];
              }

              return matchingItems;
            }}
            renderInput={({ getInputProps, openMenu, clearSelection }) => (
              <Autofocus
                shouldFocus={!loading}
                render={({ bind }) => (
                  <Input
                    data-testid="admins__wl-login-input"
                    {...getInputProps({
                      placeholder: loading
                        ? 'Loading logins...'
                        : 'Select login or create new',
                      onFocus: openMenu,
                      onChange: () => {
                        onChangeLogin({
                          login: '',
                        });
                        clearSelection();
                      },
                      disabled: loading,
                      ...bind,
                      ...props,
                    })}
                  />
                )}
              />
            )}
            renderItem={({ item, index, highlightedIndex, getItemProps }) => {
              const isCreateItem = item.id === CREATE_OPTION_ID;
              const menuItemCommonProps = {
                title: itemToString(item),
                active: index === highlightedIndex,
                key: item.id,
              };
              return isCreateItem ? (
                <MenuItem
                  data-testid="admins__new-wl-login-item"
                  leftElement={<AddIcon className={css.addIcon} />}
                  subtitle="create new"
                  {...getItemProps({ item, ...menuItemCommonProps })}
                />
              ) : (
                <MenuItem
                  data-testid="admins__wl-login-item"
                  comment={
                    <Flex justifyContent="flex-end">
                      <div className={css.botsNames}>
                        {(item.user_bots || []).map((b) => b.title).join(', ')}
                      </div>
                    </Flex>
                  }
                  {...getItemProps({ item, ...menuItemCommonProps })}
                />
              );
            }}
          />
        );
      }}
    </Query>
  );
};
