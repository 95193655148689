import ng from 'angular';

function selectText() {
  return (scope, element) => {
    element.on('click', () => {
      if (window.getSelection().empty) {
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        window.getSelection().removeAllRanges();
      }
      element.get(0).select();
    });

    scope.$on('$destroy', () => {
      element.off('click');
    });
  };
}

export default ng
  .module('app.common.selectText', [])
  .directive('selectText', selectText).name;
