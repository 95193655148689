import { useHistory } from 'react-router-dom';
import * as H from 'history';
import { Level, log } from 'cf-common/src/logger';
import { BotTabs, useCurrentBotId, useCurrentTab } from '@utils/Routing';
import { canView, Domain, loadPermissionsMemoized } from './RoleService';

const DOMAINS_ORDER: Domain[] = [
  'grow',
  'flows',
  'groups',
  'inbox',
  'ai',
  'people',
  'broadcasting',
  'configure',
  'analyze',
];

export const DOMAIN_TO_TAB_STATE_NAME: { [domain: string]: string } = {
  flows: BotTabs.flows,
  groups: BotTabs.automate,
  inbox: BotTabs.liveChat,
  ai: BotTabs.keywords,
  people: BotTabs.people,
  broadcasting: BotTabs.reEngage,
  configure: BotTabs.configure,
  grow: BotTabs.home,
  analyze: BotTabs.analyze,
};

export const TAB_STATE_NAME_TO_DOMAIN = {
  [BotTabs.flows]: 'flows',
  [BotTabs.automate]: 'groups',
  [BotTabs.liveChat]: 'inbox',
  [BotTabs.keywords]: 'ai',
  [BotTabs.people]: 'people',
  [BotTabs.reEngage]: 'broadcasting',
  [BotTabs.messagesTemplates]: 'broadcasting',
  [BotTabs.configure]: 'configure',
  [BotTabs.home]: 'grow',
  [BotTabs.analyze]: 'analyze',
} as const;

/**
 *  Can view tab
 */
export const viewTab = (domain: Domain, botId?: string) => {
  switch (domain) {
    case 'configure':
      return (
        canView('configure', { botId }) ||
        canView('pro', { botId }) ||
        canView('roles', { botId })
      );
    default:
      return canView(domain, { botId });
  }
};

/**
 *  Redirect to available tab if need
 */

const getFirstVisibleTab = (botId: string) =>
  DOMAINS_ORDER.find((domain) => viewTab(domain, botId));

export const redirectToAvailableTabIfNeed = async (
  botId: string,
  domain: Domain,
  history: H.History,
  location?: H.Location,
) => {
  await loadPermissionsMemoized(botId);

  // TODO Не поятно почему в мапке BotTabs -> Domain мы пробрасываем ключ который domain
  // и при этом результат кастуем к Domain, зачем нужен этот маппинг Domain -> Domain?
  // Есть предположение что это работало из-за того, что ключи в BotTabs и Domain совпадали
  // 1 в 1. Например домен flows и таба тоже flows, но сейчас есть расхожения:
  //   таба home домен grow
  //   таба settings домен configure
  // @ts-expect-error
  if (!viewTab(TAB_STATE_NAME_TO_DOMAIN[domain] as Domain)) {
    const visibleTab = getFirstVisibleTab(botId);
    if (!visibleTab) {
      log({
        msg: 'Redirect to bots page because there is no tabs to view',
        level: Level.warn,
        data: {
          botId,
          currentStateName: domain,
        },
      });
      history.replace('/bots');
    } else {
      const route = `/bot/${botId}/${DOMAIN_TO_TAB_STATE_NAME[visibleTab]}`;
      history.replace({ ...location, pathname: route });
    }
    return true;
  }
  return false;
};

export const useRedirectToAvailableTabIfNeed = (
  initialBotId?: string | null,
) => {
  const currentBotId = useCurrentBotId();
  const currentDomain = useCurrentTab() as Domain;
  const history = useHistory();
  const botId = initialBotId || currentBotId;
  if (!botId) {
    return;
  }
  redirectToAvailableTabIfNeed(botId, currentDomain, history);
};
