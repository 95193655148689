import template from './menu-local.html';
import controller from './controller.js';

export const Component = () => ({
  controller,
  template,
  controllerAs: '$menuLocal',
  restrict: 'E',
  replace: true,
  bindToController: true,
  transclude: true,
  scope: {
    menuItems: '=',
    item: '=',
  },
});
