import { trim, clone } from 'ramda';
import memoize from 'memoize-one';
import { unescape } from '../../../utils/escape';
import { sendEvent } from '../../../utils/Analytics';
import { getAdminFeatures } from '../../../utils/Data/Admin';

export default class CardButtonsItemController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $element,
    BlockService,
    BotService,
    UserService,
    ButtonService,
    CurrencyService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.$element = $element;
    this.BotService = BotService;
    this.BlockService = BlockService;
    this.UserService = UserService;
    this.ButtonService = ButtonService;
    this.CurrencyService = CurrencyService;
    this.targetTypes = this.ButtonService.targetTypesWithoutSubmenu;
    this.$maxDiscount = this.ButtonService.maxButtonTitleLength;
    this.validatePatterns = this.ButtonService.validatePatterns;
    this.webViewSizes = this.ButtonService.webViewSizes;
    this.paymentsEnabled = undefined;

    this.listeners = [];

    this.listeners.push(
      $rootScope.$on('$closeOther', () => {
        if (this.$closeOtherEventSended) {
          this.$closeOtherEventSended = false;
        } else {
          this.closePopup();
        }
      }),
    );

    this.listeners.push(
      $rootScope.$on('$set-payment-status-on', (e, type) => {
        if (type === 'bot') {
          this.bot.status.payments_status = 'enabled';
          this.botStatusPaymentEnabled = true;
          this.UserService.nativePaymentsForCurrentBot(false, this.user);
        } else if (type === 'native') {
          this.user.payments_access = true;
          this.UserService.nativePaymentsForCurrentBot(true, this.user);
        } else if (type === 'stripe') {
          this.bot.payments_stripe = true;
          this.UserService.nativePaymentsForCurrentBot(false, this.user);
        }

        this.paymentsAccess =
          this.user.payments_access || this.bot.payments_stripe;
      }),
    );

    this.listeners.push(
      $scope.$on('gallery-sort-stop', () => {
        this.$timeout(() => {
          this.dataInit();
        });
      }),
    );

    this.listeners.push(
      $rootScope.$on('buttons-update', () => {
        this.$timeout(() => {
          this.dataInit();
        });
      }),
    );

    this.listeners.push(
      $rootScope.$on('card-sort-stop', () => {
        this.dataInit();
      }),
    );

    this.listeners.push(
      $scope.$on('buttons-sort-stop', () => {
        this.$timeout(() => {
          this.dataInit();
          if (this.index === 0) {
            this.save();
          }
        });
      }),
    );

    const decideToClose = (event) => {
      const isInDOM = document.contains(event.target);
      const isOutside = !this.$element[0].contains(event.target);
      if (isInDOM && isOutside && !this.wizardOpen) {
        this.$scope.$evalAsync(() => {
          this.closePopup();
        });
      }
    };
    document.addEventListener('click', decideToClose);
    this.listeners.push(() =>
      document.removeEventListener('click', decideToClose),
    );

    this.listeners.push(
      this.$scope.$on('$updateBlocksInSuggest', () => {
        this.testError();
      }),
    );

    this.listeners.push(
      this.$scope.$on('$suggestBlured', () => {
        if (this.tmpItem.block_id && this.tmpItem.block_id.length > 0) {
          // this.doneButton.focus();
          this.ButtonService.placeCaretAtEnd(this.titleObj);
        }
      }),
    );

    this.listeners.push(
      this.$scope.$on('$setButtonData', (event, newItem) => {
        if (this.index === 0) {
          this.dataInit(newItem);
          this.$timeout(() => {
            this.animateOff = true;
            this.openPopup();
            this.$timeout(() => {
              this.animateOff = false;
            }, 100);
          });
        }
      }),
    );

    this.listeners.push(
      this.$scope.$on('$openButtonPopUp', (event, index) => {
        if (this.index === index) {
          this.$timeout(() => {
            this.animateOff = true;
            this.openPopup();
            this.$timeout(() => {
              this.animateOff = false;
              const inp = this.$element[0].querySelector(
                '.cost-item-value input',
              );
              if (inp) {
                inp.focus();
              }
            }, 100);
          });
        }
      }),
    );

    this.listeners.push(
      this.$scope.$on('$moveToFirst', () => {
        this.closePopup(null, true);
      }),
    );

    this.blocksTitles = [];
    this.doneFocused = true;
    this.wizardOpen = false;
    this.totalCost = 0;
    this.unescape = unescape;

    this.loadAdminFeatures();
  }

  $onInit() {
    this.$timeout(() => {
      this.doneButton = this.$element[0].querySelector('.orange-rounded');
    });

    if (this.item.new) {
      this.$timeout(() => {
        this.openPopup();
      }, 0);
    }

    this.dataInit();
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
    this.backUp = null;
  }

  async loadAdminFeatures() {
    this.paymentsEnabled = (await getAdminFeatures()).payments_enabled;
    this.$scope.$evalAsync();
  }

  onTutorialShow = (status) => {
    this.wizardOpen = status && this.$rootScope.isAutomateEnabled;
  };

  onKeyDownDone($event) {
    if ($event.keyCode === 13) {
      $event.preventDefault();

      if (this.testError().length === 0 && this.doneFocused) {
        this.donePopup($event);
      }
    }
  }

  getSupplementaryActionOptions = memoize(
    (supplementaryButtonActions, currentActionIds) => {
      // return only those actions which are not yet selected
      return Object.keys(supplementaryButtonActions)
        .filter((id) => !currentActionIds.includes(id))
        .map((id) => supplementaryButtonActions[id]);
    },
  );

  handleSelectAction = (supplementaryAction) => {
    // NOTE: important not to mutate to rerender react view
    this.urlTabState.supplementaryButtonActionIds = [
      ...this.urlTabState.supplementaryButtonActionIds,
      supplementaryAction.id,
    ];
  };

  canAddAction = () => {
    return (
      this.urlTabState.supplementaryButtonActionIds.length <
      Object.keys(this.supplementaryButtonActions).length
    );
  };

  onBlurTitle() {
    //  this.titleFocused = false;
    this.$timeout(() => {
      this.beenChange = true;
      this.$scope.$broadcast('$setFocusOutsideStatus');
    }, 200);
  }

  dataInit(data) {
    if (data) {
      this.tmpItem = data;
    } else {
      this.tmpItem = clone(this.item);
    }

    if (this.tmpItem.title) {
      this.tmpItem.title = this.tmpItem.title.trim();
      this.tmpTilte = this.tmpItem.title;
      this.$discount = this.$maxDiscount - this.tmpTilte.length;
    } else {
      this.$discount = this.$maxDiscount;
    }

    this.setTargetType(this.ButtonService.typeDetect(this.tmpItem, 'block_id'));

    this.updateBotPaymentsStatus(false, true);
  }

  openPopup($event) {
    if (!this.open) {
      this.open = true;
      this.changed = false;
      this.setTitleFocus();
      this.$closeOtherEventSended = true;
      this.$rootScope.$broadcast('$closeOther');
      this.titleFocused = true;
      this.$rootScope.buttonEditorOpen = true;
      this.$scope.$broadcast('$scrollToStart');
    }
  }

  closePopup($event, noremove) {
    if ($event) {
      $event.stopPropagation();
    }

    if (this.open) {
      this.open = false;
      this.infoPopUpOpen = false;
      this.$rootScope.buttonEditorOpen = false;
      this.$scope.$emit('$validateCard');

      if (this.targetType === 'url' && this.tmpItem.url !== 'undefined') {
        this.tmpItem.url = trim(this.tmpItem.url);
      }

      if (
        !noremove &&
        this.testError().length > 1 &&
        this.targetType !== this.targetTypes.share &&
        this.tmpItem.new
      ) {
        this.$timeout(() => {
          this.remove();
        }, 50);
      } else {
        //     this.dataInit();
        this.save();
      }
    }

    this.backUp = null;
  }

  donePopup($event) {
    if ($event) {
      $event.stopPropagation();
    }

    if (this.targetType === 'url' && this.tmpItem.url !== 'undefined') {
      this.tmpItem.url = trim(this.tmpItem.url);
    }
    this.open = false;
    this.$rootScope.buttonEditorOpen = false;
    this.save();
  }

  setTargetType(targetType, needSendNGEvent) {
    const { plugin } = this.$scope.$parent.vm;
    const buttons = plugin.config.buttons || [];
    if (needSendNGEvent) {
      sendEvent({
        category: 'plugin',
        action: 'button action add',
        label: plugin.plugin_id,
        value: buttons.length,
        propertyBag: {
          plugin: plugin.plugin_id,
        },
      });
      if (this.targetType) {
        sendEvent({
          category: 'plugin',
          action: 'button action remove',
          label: plugin.plugin_id,
          value: buttons.length,
          propertyBag: {
            plugin: plugin.plugin_id,
          },
        });
      }
    }

    this.targetType = targetType;

    this.$timeout(() => {
      this.titleObj = this.$element[0].querySelector(
        ".title[contenteditable='true']",
      );
      this.testError();
    }, 0);

    this.ButtonService.setTargetType(
      targetType,
      needSendNGEvent,
      this.$element[0],
      this.$scope,
      this.tmpItem,
      'block_id',
    );
  }

  setTargetTypeWrapper = (targetType) => {
    if (targetType === 'blocks' || targetType === 'url') {
      this.setTargetType(targetType, true);
    } else {
      this.setTargetType(targetType);
    }
  };

  onTitleChange = (title) => {
    this.tmpItem.title = title;
  };

  updateDiscount($event) {
    this.$discount = this.$maxDiscount - $event.target.innerText.length;
    if (this.$discount < 0) {
      $event.target.innerText = $event.target.innerText
        .trim()
        .substring(0, this.$maxDiscount);
      this.$discount = 0;
    }
  }

  onTitleKeyDown($event) {
    const titleKeyDownResult = this.ButtonService.onTitleKeyDown(
      $event,
      this.$element[0],
      this.$scope,
      this.targetType,
      this.$maxDiscount,
      this.$discount,
      this.tmpItem,
      'block_id',
      this.titleObj,
    );

    if (titleKeyDownResult === 'donePopup') {
      this.donePopup();
    }
  }

  onDonePopup = () => {
    this.donePopup();
  };

  onTitlePaste($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.tmpItem.title = $event.target.innerText.substr(0, this.$maxDiscount);
  }

  onUrlTabChange = (newConfig) => {
    if (newConfig.attributeUpdates !== undefined) {
      this.tmpItem.attribute_update_list = newConfig.attributeUpdates;
    }
    if (newConfig.blockId !== undefined) {
      this.tmpItem.block_id = newConfig.blockId;
    }
    if (newConfig.url !== undefined) {
      this.tmpItem.url = newConfig.url;
    }
    if (newConfig.webviewHeightRatio !== undefined) {
      this.tmpItem.webview_height_ratio = newConfig.webviewHeightRatio;
    }
  };

  onBlockTabChange = (newConfig) => {
    if (newConfig.attributeUpdates !== undefined) {
      this.tmpItem.attribute_update_list = newConfig.attributeUpdates;
    }
    if (newConfig.blockId !== undefined) {
      this.tmpItem.block_id = newConfig.blockId;
    }
  };

  onPhoneCallTabChange = (newConfig) => {
    if (newConfig.phone_number !== undefined) {
      this.tmpItem.phone_number = newConfig.phone_number;
    }
  };

  onBlocksTitlesChange = (titles) => {
    this.$scope.$evalAsync(() => {
      this.blocksTitles = titles;
    });
  };

  save() {
    if (this.tmpItem && this.testError().length < 2) {
      this.ButtonService.save(
        this.tmpItem,
        this.targetType,
        this.titleObj,
        this.item,
      );
      Object.assign(this.item, clone(this.tmpItem));

      if (this.item.title) {
        this.item.title = this.item.title.substr(0, this.$maxDiscount);
      }

      this.ButtonsListController.save();
      this.$scope.$emit('$validateCard');
    }
  }

  remove($event) {
    if ($event) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
    }
    this.ButtonsListController.remove(this.index);
    this.$scope.$emit('$validateCard');

    this.$timeout(() => {
      this.$rootScope.$broadcast('buttons-update');
    });
  }

  testError() {
    this.error = this.ButtonService.testError(
      this.tmpItem,
      'block_id',
      this.titleObj,
      this.targetType,
    );
    return this.error;
  }

  setTitleFocus() {
    this.ButtonService.setTitleFocus(this.$element[0]);
  }

  onKeyDownActionInput($event, phoneMode) {
    if ($event.keyCode === 13 && this.error.length === 0) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      if (!this.error.length) {
        this.donePopup($event);
      }
    }
  }

  updateBotPaymentsStatus(paymentEnabled, useCache) {
    if (paymentEnabled) {
      // this.botStatusPaymentEnabled = true;
      // this.paymentsAccess = true;
      // if (this.bot) {
      //   if (!this.bot.status) {
      //     this.bot.status = {};
      //   }
      //   this.bot.status.payments_status = 'enabled';
      // }
    } else {
      this.BotService.cachedList().then((bots) => {
        this.bot = bots.find(
          (item) => item.id === this.$rootScope.stateParams.botId,
        );
        if (this.bot) {
          this.botStatusConnected = Boolean(this.bot.status.page);
          this.botStatusPaymentEnabled =
            this.botStatusConnected &&
            this.bot.status.payments_status === 'enabled';

          if (!this.paymentsAccess) {
            this.paymentsAccess = this.bot.payments_stripe;
          }
        }
      });

      this.UserService.show(!useCache).then((res) => {
        this.user = res;
        if (!this.paymentsAccess) {
          this.paymentsAccess = this.user.payments_access;
        }
      });
    }
  }

  testShowBuy() {
    return (
      (!this.tmpItem.payment_summary &&
        this.items.find((item) => item.payment_summary)) ||
      !this.paymentsEnabled
    );
  }

  getWarringTTText() {
    return this.ButtonService.getWarringTTText(
      this.botStatusConnected,
      this.botStatusPaymentEnabled,
    );
  }
}
