import { sendEvent } from '../../../utils/Analytics';

export default class CardAddController {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  add($event) {
    $event.preventDefault();
    this.items.push({
      title: '',
      subtitle: '',
      item_url: '',
      buttons: [],
      new: true,
    });
    this.$scope.$emit('$buttonUpdate');
    sendEvent({
      category: 'plugin gallery',
      action: 'item add',
      value: this.items.length,
    });
  }
}
