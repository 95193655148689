import gql from 'graphql-tag';

export const BOT_FRAGMENT = gql`
  fragment botFragment on Bot {
    id
    workspace_id
    title
    date_added
    disconnected_date
    last_opened_date
    last_opened_date_for_current_user
    allowedPlatforms
    admins {
      id
      name
      picture
    }
    botLivelinessStatus
    status {
      status
      page
      charging_entity_type
      page_info {
        id
        title
        picture
        trial_end_date
        whatsapp_business_account {
          id
          name
        }
        whatsapp_phone {
          id
          display_phone_number
        }
      }
    }
    statistic {
      current_week_total_subscribed_users
      last_week_total_subscribed_users
      current_week_active_users
      last_week_active_users
    }
    pro {
      is_paused
      status
      manual
      debt
      days_before_cancel
      millis_before_cancel
    }
    limits {
      usersLimit
      notificationsLimit
    }
    time_limited_trial
  }
`;

export const BOT_QUERY = gql`
  query BotQuery($botId: String!) {
    bot(id: $botId) {
      ...botFragment
    }
  }
  ${BOT_FRAGMENT}
`;

export const BOTS_LIST_QUERY = gql`
  query BotsListQuery {
    bots {
      ...botFragment
    }
  }
  ${BOT_FRAGMENT}
`;

export const UPDATE_BOT_TITLE_MUTATION = gql`
  mutation updateBotTitleMutation($botId: String!, $title: String!) {
    updateBotTitle(id: $botId, title: $title)
  }
`;

export const CREATE_NEW_BOT_MUTATION = gql`
  mutation createNewBotMutation($title: String!) {
    createBot(title: $title) {
      id
    }
  }
`;

export const CREATE_NEW_BOT_BY_TEMPLATE_MUTATION = gql`
  mutation createNewBotByTemplateMutation($title: String!, $idFrom: String!) {
    createBotByTemplate(title: $title, idFrom: $idFrom) {
      ...botFragment
    }
  }
  ${BOT_FRAGMENT}
`;

export const CREATE_NEW_BLANK_BOT_MUTATION = gql`
  mutation createNewBlankBotMutation(
    $title: String!
    $workspaceId: String
    $platforms: [Platform!]
  ) {
    createBlankBot(
      title: $title
      workspaceId: $workspaceId
      platforms: $platforms
    ) {
      ...botFragment
    }
  }
  ${BOT_FRAGMENT}
`;

export const SET_BOT_LAST_OPENED_DATE = gql`
  mutation SetBotLastOpenedDate($botId: String!) {
    setBotLastOpenedDate(id: $botId) {
      id
      last_opened_date
      last_opened_date_for_current_user
    }
  }
`;

export const CLONE_BOT_MUTATION = gql`
  mutation cloneBotMutation($id: String!, $idFrom: String!, $title: String) {
    cloneBot(id: $id, idFrom: $idFrom, title: $title) {
      id
    }
  }
`;

export const BOT_ALLOWED_PLATFORMS = gql`
  query BotAllowedPlatforms($botId: String!) {
    bot(id: $botId) {
      id
      allowedPlatforms
    }
  }
`;

export const FULL_NATIVE_BOT_FRAGMENT = gql`
  fragment nativeBotFragment on Bot {
    id
    workspace_id
    title
    date_added
    timezone_offset
    timezone_name
    default_group_id
    default_block
    first_block
    ai_block
    external_api_token
    allowedPlatforms
    limits {
      usersLimit
      notificationsLimit
    }
    status {
      read_only
      status
      page
      page_info {
        id
        title
        picture
        username
        eu_restricted
        owner {
          id
          name
          roles {
            bot_id
            role_id
          }
          date_added
          picture
          flags
          number_of_bots
        }
        not_enough_perms
        bot_id
      }
      payments_status
    }
    admins {
      id
      name
      roles {
        bot_id
        role_id
      }
      date_added
      picture
      flags
      number_of_bots
    }
    pro {
      is_paused
      status
      debt
      days_before_cancel
      millis_before_cancel
      manual
    }
    user_limit_reached
    has_botan_app
    domains_whitelist_max
    payments_stripe
    button_whitelist
    domains_whitelist
  }
`;

export const FULL_NATIVE_BOT_QUERY = gql`
  query FullNativeBot($botId: String!) {
    bot(id: $botId) {
      ...nativeBotFragment
    }
  }
  ${FULL_NATIVE_BOT_FRAGMENT}
`;

export const FULL_NATIVE_BOTS_LIST_QUERY = gql`
  query FULL_BOTS_LIST_QUERY {
    bots {
      ...nativeBotFragment
    }
  }
  ${FULL_NATIVE_BOT_FRAGMENT}
`;
