import select from 'selection-range';

export default class CaretService {
  getCaretPosition(element) {
    if (document.activeElement !== element) {
      return undefined;
    }

    return select(element);
  }

  setCaretPosition(element, caretPosition) {
    if (document.activeElement !== element || !caretPosition) {
      return;
    }

    select(element, caretPosition);
  }
}
