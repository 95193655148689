/* eslint-disable chatfuel/no-use-localStorage */
import $ from 'jquery';
import debounce from 'lodash-es/debounce';
import { path } from 'ramda';
import { stripTags } from '@utils/stripTags';
import {
  canEdit,
  canView,
  getLoading,
} from '../../common/services/RoleService';
import {
  OPTIMISTIC_BLOCK_ID_PREFIX,
  deleteBlock as deleteBlockMutation,
} from '../../modern-components/Aside/Mutations/BlockMutations';
import {
  getBlocksGroupsObservable,
  updateBlockTitleInGqlCache,
} from '../../modern-components/Aside/BlocksGroupsQueries';
import { sendEvent } from '../../utils/Analytics';
import { PLUGIN_TYPE as ABANDONED_CART_PLUGIN_TYPE } from '../../modern-components/Plugins/AbandonedCartPlugin/AbandonedCartPluginConst';
import { getBlockStatsForEvents } from '../../utils/BlocksUtils';
import ApolloService from '../../common/services/ApolloService';
import { BLOCK_LINKS_QUERY } from '../../modern-components/BlockLinks/BlockLinks.queries';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';

/**
 *  Structure content controller
 */
export default class ContentController {
  /**
   * @description constructor
   * @param {*} $element -
   * @param {*} $rootScope -
   * @param {*} $scope -
   * @param {*} $document -
   * @param {*} $filter -
   * @param {*} $timeout -
   * @param {*} $interpolate -
   * @param {*} $sce -
   * @param {*} $window -
   * @param {*} BotService -
   * @param {*} PageService -
   * @param {*} ModalService -
   * @param {*} BlockService -
   * @param {*} StoreService -
   * @param {*} BroadcastService -
   * @param {*} PluginCardService -
   * @param {*} GroupService -
   * @param {*} MonetizationService -
   * @param {*} StatisticService -
   * @param {*} API_ERROR_MSG -
   */
  constructor(
    $element,
    $rootScope,
    $scope,
    $document,
    $filter,
    $timeout,
    $interpolate,
    $sce,
    $window,
    BotService,
    PageService,
    ModalService,
    BlockService,
    StoreService,
    BroadcastService,
    PluginCardService,
    GroupService,
    MonetizationService,
    StatisticService,
    API_ERROR_MSG,
  ) {
    'ngInject';

    this.StatisticService = StatisticService;
    this.$sce = $sce;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$document = $document;
    this.$filter = $filter;
    this.$element = $element;
    this.$timeout = $timeout;
    this.BotService = BotService;
    this.PageService = PageService;
    this.$interpolate = $interpolate;
    this.BlockService = BlockService;
    this.StoreService = StoreService;
    this.ModalService = ModalService;
    this.BroadcastService = BroadcastService;
    this.PluginCardService = PluginCardService;
    this.GroupService = GroupService;
    this.MonetizationService = MonetizationService;
    this.block = {};
    this.$error = '';
    this.messageUs = '';
    this.API_ERROR_MSG = API_ERROR_MSG;
    this.RoleService = { canEdit, canView, getLoading };
    this.ABANDONED_CART_PLUGIN_TYPE = ABANDONED_CART_PLUGIN_TYPE;
    this.hideNotification = debounce(this.hideNotification, 5000);

    this.list(this.$rootScope.stateParams.blockId);

    //    this.loadPage();
    this.loadBot();

    this.listeners = [];

    this.listeners.push(
      $scope.$on('$onQrMouseOver', ($event, data) => {
        if (data.flag) {
          this.qrMouseOverId = data.id;
        } else {
          this.qrMouseOverId = null;
        }
      }),
    );

    this.listeners.push(
      $rootScope.$on('$pluginLimitReach', async ($event) => {
        this.showPluginLimitNotification = true;
        this.hideNotification();
      }),
    );

    this.listeners.push(
      $scope.$on('$onActionListOver', ($event, data) => {
        if (data.flag) {
          this.actionListOver = data.id;
        } else {
          this.actionListOver = null;
        }
      }),
    );
    this.listeners.push(
      $rootScope.$on('$sequence.block:time-change', () => {
        this.BlockService.detail(this.$rootScope.stateParams.blockId).then(
          (data) => {
            this.block = data;
          },
        );
      }),
    );

    this.listeners.push(
      $scope.$on('currentBlock:update', () => {
        // refetch and rerender
        this.list(this.$rootScope.stateParams.blockId);
      }),
    );

    this.firstSort = true;

    this.loading = true;
  }

  /**
   * @description init callback
   */
  $onInit() {
    this.$sortable = {
      handle: '.ui-sortable-handle',
      'ui-floating': true,
      start: (e, ui) => {
        this.$rootScope.$broadcast('$startDrag');

        if (this.firstSort) {
          $(e.target).sortable('refreshPositions');
          this.firstSort = false;
        }
        ui.placeholder.width(ui.helper.width());

        this.sortedCard = this.block.cards[ui.item.index()];
        this.oldPosCard = ui.item.index();
      },
      axis: 'y',
      stop: () => {
        this.block.cards = this.block.cards.map((card, i) => {
          Object.assign(card, { position: i });
          return card;
        });

        if (
          this.oldPosCard !==
          this.block.cards.findIndex((item) => item === this.sortedCard)
        ) {
          this.sortedCard.blockId = this.block.id;
          this.PluginCardService.save(this.sortedCard);
          sendEvent({
            category: 'plugin',
            action: 'reorder',
            label: this.sortedCard.plugin_id,
            propertyBag: {
              plugin: this.sortedCard.plugin_id,
            },
          });
        }

        this.sortedCard = null;

        this.$scope.$emit('card-sort-stop');
        this.$scope.$broadcast('$buttonUpdate');
      },
    };

    this.$sortableInGal = {
      handle: '.ui-sortable-handle-in-gal',
      'ui-floating': true,
      axis: 'x',
      tolerance: 'pointer',
      containment: 'document',
      start: () => {
        this.$rootScope.$broadcast('$startDrag');
      },
      sort: (e, ui) => {
        this.$scope.$broadcast('gallery-sort-start', {
          id: ui.item.sortable.source[0].id,
        });
      },
      stop: (e, ui) => {
        const card = this.block.cards.find(
          (lcard) => lcard.id === ui.item.sortable.source[0].id,
        );
        const position = this.block.cards.findIndex(
          (lcard) => lcard.id === ui.item.sortable.source[0].id,
        );
        card.blockId = this.block.id;
        card.position = position;
        this.PluginCardService.save(card);

        this.$scope.$broadcast('gallery-sort-stop');
        this.$scope.$broadcast('$buttonUpdate');
      },
    };

    this.cardsList = this.$element.find('.cards-list');

    this.$element.on('mousedown', () => {
      this.cardsList.height(this.cardsList.height());
    });
    this.$element.on('mouseup', () => {
      setTimeout(() => {
        this.cardsList.height('auto');
      }, 300);
    });

    this.initTrashPos();
    this.$scope.RoleService = this.$rootScope.RoleService;

    this.blocksGroupsSubscription = getBlocksGroupsObservable(
      this.$rootScope.stateParams.botId,
    ).subscribe((groups) => {
      this.groups = groups;
      this.isSequence = this.isThisBlockSequence();
    });
  }

  onSelectTag = ({ id, purpose, topicId }) => {
    this.block.message_tag = id;
    this.block.otn_purpose = purpose;
    this.block.notification_topic_id = topicId;

    this.saveMessageTag();
  };

  /**
   * actually save full block;
   */
  saveMessageTag() {
    this.BlockService.save({
      id: this.block.id,
      title: this.block.title,
      message_tag: this.block.message_tag,
      otn_purpose: this.block.otn_purpose,
      notification_topic_id: this.block.notification_topic_id,
      parent_group: this.block.parent_group,
      referral: this.block.referral,
      referral_active: this.block.referral_active,
    });
  }

  setSafeBlockTitle() {
    this.block.title = this.clearTitle();
  }

  /**
   * this block can be part of the sequence or regular block;
   * @return {boolean} - is this block a sequence or not;
   */
  isThisBlockSequence() {
    const group = this.getCurrentGroup();
    return group && group.sequence;
  }

  /**
   * @description destroy callback
   */
  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
    this.$element.off('mousedown');
    this.$element.off('mouseup');
    if (this.rcol) {
      this.rcol.off('scroll');
      this.aWindow.off('resize');
    }

    if (this.blocksGroupsSubscription) {
      this.blocksGroupsSubscription.unsubscribe();
    }
  }

  /**
   * @description load current bot
   */
  loadBot() {
    this.BotService.cachedList().then((bots) => {
      this.bot = bots.find(
        (item) => item.id === this.$rootScope.stateParams.botId,
      );
    });
  }

  /**
   * @description return current group by block id
   * @return {Object} -
   */
  getCurrentGroup() {
    return this.groups.find((group) =>
      group.blocks.find(
        (block) => this.$rootScope.stateParams.blockId === block.id,
      ),
    );
  }

  /**
   * if block is built it welcome block
   * @param {Block} block -
   * @returns {*|boolean} -
   */
  isBlockWelcomeBlock(block) {
    return block && block.builtin && block.title === 'Welcome message';
  }

  /**
   * @description load block data by id
   * @param {String} id -
   * @return {void}
   */
  list(id) {
    /**
     * Prevent load optimistic created block (of GQL mutation)
     * For more info search by "OPTIMISTIC_BLOCK_ID_PREFIX"
     */
    if (id.includes(OPTIMISTIC_BLOCK_ID_PREFIX)) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return this.BlockService.detail(id)
      .then((data) => {
        if (!data) {
          return;
        }

        this.block = data;

        sendEvent({
          category: 'block',
          action: 'view',
          propertyBag: getBlockStatsForEvents(this.block),
        });

        const urlParams = new URLSearchParams(
          this.$rootScope.stateLocation.search,
        );

        if (urlParams.get('new') === 'true') {
          this.$focusBlockTitle();
          this.$rootScope.stateHistory.replace(
            `/bot/${this.$rootScope.stateParams.botId}/structure/${this.block.id}`,
          );
        }

        this.$timeout(() => {
          this.$scope.$broadcast('$dataReady');
          this.$timeout(() => {
            this.loading = false;

            this.$timeout(() => {
              this.loaderRenderOff = true;
            }, 500);
          });
        }, 10);
      })
      .catch((error) => {
        const { status } = error;
        if (status === 410) {
          const newBlockId = path(['data', 'result', 'newId'], error);
          if (newBlockId) {
            this.$rootScope.stateHistory.push(
              `/bot/${this.$rootScope.stateParams.botId}/structure/${newBlockId}`,
            );
          }
        }
        if (status === 404) {
          this.BlockService.reCreateRemovedBlockById(id);
        }
      });
  }

  /**
   * @description load page for current bot
   */
  loadPage() {
    this.PageService.cachedList(true).then((pages) => {
      this.page = pages.find(
        (item) => item.bot_id === this.$rootScope.stateParams.botId,
      );
    });
  }

  /**
   * @description Remove tags and spaces in block title
   * @return {string} -
   */
  clearTitle() {
    return (this.block.title || '')
      .replace(/( |&nbsp;)+/g, ' ')
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/&amp;/gi, '&')
      .trim();
  }

  getErrorMessage(errorValue) {
    const knownServerErrorsToUserErrorsMap = {
      'Conflict: Block with requested title already exists':
        'A block with this name already exists',
      'title Name this block please': 'Name is empty',
    };

    const DEFAULT_ERROR_MESSAGE = 'Unknown error';

    return (
      knownServerErrorsToUserErrorsMap[errorValue] || DEFAULT_ERROR_MESSAGE
    );
  }

  onLinkLabelClick = () => {
    this.ModalService.confirmBlockLink(
      {
        page: this.getPage(),
        block: this.block,
        title: this.blockTitle,
      },
      {
        ok: 'DONE',
      },
    ).then(() =>
      ApolloService.query({
        query: BLOCK_LINKS_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          botId: this.bot.id,
          blockId: this.block.id,
        },
      }),
    );
  };

  getPage() {
    return this.bot.status.page_info.username || this.bot.status.page;
  }

  /**
   * @description save block title (add increment number for unique)
   * @param {Boolean} isNew -
   */
  saveBlockTitle(isNew) {
    if (this.block.builtin) {
      return;
    }
    this.setSafeBlockTitle();
    this.onSaveBlockTitle();

    sendEvent({
      category: 'block',
      action: 'rename',
      propertyBag: getBlockStatsForEvents(this.block),
    });

    this.BlockService.save({
      id: this.block.id,
      title: this.block.title,
      parent_group: this.block.parent_group,
      referral: this.block.referral,
      referral_active: this.block.referral_active,
      message_tag: this.block.message_tag,
      otn_purpose: this.block.otn_purpose,
      notification_topic_id: this.block.notification_topic_id,
    })
      .then(() => {
        this.$error = '';
        this.onSaveBlockTitle();
      })
      .catch((err) => {
        if (isNew && err.status === 409) {
          let { title } = this.block;
          const nF = title.match(/(\d+)/g);
          const num = nF ? nF[0] : 1;
          if (num > 100000) {
            return;
          }
          title = title.replace(String(num), '');
          this.block.title = `${title} ${String(num)}0`;
          this.saveBlockTitle(true);
          return;
        }

        const { data } = err;
        if (data) {
          if (!data.errors) {
            this.$error = data.result;
          } else {
            // eslint-disable-next-line prefer-destructuring
            this.$error = data.errors[0];
          }
        }
      });
  }

  /**
   * @description handler on save block title (send events for update controls)
   */
  onSaveBlockTitle() {
    const { title } = this.block;
    this.$scope.$emit(
      'block:update',
      this.block.id,
      title,
      this.block.referral,
      this.block.referral_active,
    );

    updateBlockTitleInGqlCache({
      blockId: this.block.id,
      title,
    });

    try {
      window.localStorage.setItem('updateBlocks', `event ${Math.random()}`);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * onDeleteBlock
   * @param  {Object} e -
   * @return {undefined} -
   */
  onDeleteBlock(e) {
    e.preventDefault();

    const context = this.$interpolate(this.ModalService.templates.block())({
      block: `${truncateWithEllipses(this.block.title, 20)}`,
    });

    this.ModalService.confirm(context).then(
      (isConfirmed) => isConfirmed && this.deleteBlock(),
    );
  }

  /**
   *
   */
  deleteBlock() {
    deleteBlockMutation({
      blockId: this.block.id,
      group: this.getCurrentGroup(),
      botId: this.$rootScope.stateParams.botId,
    });
    this.$rootScope.stateHistory.push(
      `/bot/${this.$rootScope.stateParams.botId}/structure`,
    );
  }

  /**
   *
   */
  $remove() {
    const context = this.$interpolate(this.ModalService.templates.block())();
    this.ModalService.confirm(context).then(
      (isConfirmed) => isConfirmed && this.deleteBlock(),
    );
  }

  /**
   *
   */
  $focusBlockTitle() {
    this.$timeout(() => {
      const blockTitleEl = document.getElementById('block-title-input');
      if (blockTitleEl) {
        blockTitleEl.focus();
        document.execCommand('selectAll', false, null);
      }
    }, 500);
  }

  /**
   *
   */
  cropTitle() {
    if (this.block.title.replace(/<\S[^><]*>/g, '').length === 0) {
      const el = document.querySelector('h1 .text');
      el.innerHTML = '';
    }
    if (this.block.title.length > 60) {
      this.block.title = this.block.title
        .replace(/( |&nbsp;)+/g, ' ')
        .replace(/(<([^>]+)>)/gi, '')
        .trim()
        .substring(0, 60);

      this.$timeout(() => {
        const el = document.querySelector('h1 .text');
        const range = document.createRange();
        const sel = window.getSelection();
        if (el.childNodes.length > 0) {
          const el2 = el.childNodes[el.childNodes.length - 1];
          range.setStartAfter(el2);
        } else {
          range.setStartAfter(el);
        }
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      });
    }

    this.updateTrashPos();
  }

  /**
   * move trash icon on window resize
   */
  initTrashPos() {
    setTimeout(() => {
      const { angular } = window;
      this.trashCont = angular.element('.but-container.remove');
      this.rcol = angular.element('.rcol');
      this.aWindow = angular.element(window);
      this.rcol.on('scroll', () => this.updateTrashPos());
      this.aWindow.on('resize', () => this.updateTrashPos());
      this.updateTrashPos();
    }, 200);

    this.$scope.$watch('vm.bot', () => {
      if (this.trashCont) {
        setTimeout(() => {
          this.updateTrashPos();
        }, 0);
      }
    });
  }

  /**
   * hide notification
   */
  hideNotification() {
    this.$scope.$evalAsync(() => {
      this.showPluginLimitNotification = false;
    });
  }

  /**
   *  * move trash icon on window resize
   */
  updateTrashPos() {
    if (this.bot && this.trashCont.length) {
      const mw =
        document.body.clientWidth -
        this.trashCont[0].getBoundingClientRect().left -
        25;
      this.trashCont.css('max-width', mw < 0 ? 0 : mw);
    }
  }

  /**
   * cancel timeout and got to settings to enable pro;
   */
  goToPro() {
    this.hideNotification();
    this.MonetizationService.navigateToSettingsAndScrollToMonetization();
  }

  /**
   * -
   */
  onUpgradeToProInUndeletebleWelcomeMessage() {
    sendEvent('/pro_events/pro_button_click/undeleteble_welcome_message');
    this.MonetizationService.navigateToSettingsAndScrollToMonetization();
  }

  isBlockDisabled() {
    const isSyncClonedBlockInChildrenBot =
      this.block.bot !== this.$rootScope.stateParams.botId;

    return (
      !this.RoleService.canEdit('groups', {
        groupId: this.block.parent_group,
      }) || isSyncClonedBlockInChildrenBot
    );
  }
}
