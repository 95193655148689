import { showConnectPageDialog } from '../../../modern-components/FlowBuilder/views/entry-points/common/utils/showConnectPageDialog';
import {
  connectPage,
  ConnectPageField,
} from '../../../modern-components/ConnectPageDialog';

/**
 * Важно! Не тестировалось с текущими пейментами. Возможны баги!!!
 */

export default class BuyButtonController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $element,
    ModalService,
    CurrencyService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$element = $element;
    this.$rootScope = $rootScope;

    this.ModalService = ModalService;
    this.CurrencyService = CurrencyService;
    $scope.$on('gallery-sort-stop', () => {
      this.$timeout(() => {
        this.updateTotal();
      }, 10);
    });
  }

  $onInit() {
    this.updateTotal();

    if (!this.item.payment_summary.currency) {
      this.item.payment_summary.currency =
        this.CurrencyService.getCurrencyForBot();
    }

    this.$timeout(() => {
      const inp = this.$element[0].querySelector('.cost-item-value input');
      if (inp) {
        inp.focus();
      }
    });
  }

  $onDestroy() {}

  addCostItem() {
    this.item.payment_summary.price_list.push({ amount: '', label: '' });

    this.$timeout(() => {
      const pInp = this.$element[0].querySelectorAll('.cost-item-name input');
      if (pInp.length) {
        pInp[pInp.length - 1].focus();
      }
    });
  }

  removeCostItem($index) {
    this.item.payment_summary.price_list.splice($index, 1);
    this.updateTotal();
  }

  updateTotal() {
    this.totalCost = 0;
    this.item.payment_summary.price_list.forEach((lPrice) => {
      if (lPrice.label && lPrice.label.length > 0) {
        const cp = parseFloat(lPrice.amount);
        if (!Number.isNaN(cp)) {
          this.totalCost += Math.round(cp * 100);
        }
      }
    });

    this.$timeout(() => {
      this.testError();
    });
  }

  goConnectPage() {
    const { botId } = this.$rootScope.stateParams;
    connectPage({
      botId,
      urlParams: {
        [ConnectPageField.botId]: botId,
      },
      onPageConnected: () => {
        this.updateBotPaymentsStatus();
      },
    });
  }

  moveToFirst() {
    this.wizardOpen = true;

    this.ModalService.confirmAdv(
      'Are you sure you want to move Buy button to the first position?',
      { ok: 'MOVE', close: 'CANCEL' },
    ).then((res) => {
      if (res && this.item) {
        delete this.item.phone_number;
        delete this.item.url;
        delete this.item.block_id;
        delete this.item.webview_height_ratio;
        delete this.item.show_share_screen;

        this.$scope.$emit('$moveToFirst', this.index, this.item);

        this.$timeout(() => {
          this.wizardOpen = false;
        });
      }
    });
  }

  onKeyDownPriceLabel($event, $index) {
    if ($event.keyCode === 13 && $event.target.value.trim().length > 0) {
      const pInp = this.$element[0].querySelectorAll('.cost-item-value input');
      if (pInp[$index]) {
        pInp[$index].focus();
      }
    }
  }

  onKeyDownPriceAmount($event, $index) {
    if ($event.keyCode === 13 && $event.target.value.trim().length > 1) {
      const pInp = this.$element[0].querySelectorAll('.cost-item-name input');
      if (pInp[$index + 1]) {
        pInp[$index + 1].focus();
      } else {
        //  this.addCostItem();
        this.donePopup();
      }
    }
  }

  validateURL(val) {
    return (
      !val ||
      val.length === 0 ||
      new RegExp(
        '^(https?://)?(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+(?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?)|localhost|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})(?::\\d+)?(?:/?|[/?]\\S+)$',
        'i',
      ).test(val)
    );
  }

  openWizard() {
    if (this.botStatusConnected) {
      this.wizardOpen = true;
      this.ModalService.wizardPayments().then((res) => {
        // if (res) {
        //   this.updateBotPaymentsStatus();
        // }
        this.$timeout(() => {
          this.wizardOpen = false;
        }, 500);
      });
    }
  }

  advToggle() {
    this.advOpen = !this.advOpen;
    if (this.advOpen) {
      this.aBoxShow = true;
    } else {
      this.$timeout(() => {
        this.aBoxShow = false;
      }, 100);
    }
  }

  _onCurrencyChange() {
    this.CurrencyService.setCurrencyForBot(this.item.payment_summary.currency);
  }

}
