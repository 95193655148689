import ng from 'angular';
import 'angularjs-slider';

import './assets/less/style.less';
import './assets/less/rzslider.less';

import Component from './component';

export default ng
  .module('app.common.plugins.typing', ['rzModule'])
  .directive('typingPlugin', Component).name;
