import ng from 'angular';
import controller from './controller';
import template from './template.html';
import './segments-list.less';

export default ng
  .module('app.components.users.segmentsList', [])
  .component('segmentsList', {
    bindings: {
      segments: '<',
      selectedSegmentIndex: '<',
      /**
       *
       *  {$event:DOMEvent, segment: Segment | null, $index: number};
       *
       */
      onSegmentSelect: '&',
      /**
       *
       *
       * {$evemnt: DOMEvent, segment: Segment, $index: number}
       *
       */
      onSegmentDelete: '&',
    },
    controllerAs: 'vm',
    controller,
    template: () => template,
  }).name;
