import { pluralNumberFormatter } from '../../../common/services/PluralsService';

import { renderDropdownIcon } from '../DropdownIcon';

export default class sequencesSubscribeEditorController {
  constructor($scope, ModalService) {
    'ngInject';

    this.ModalService = ModalService;
    this.showSequenceDropDown = false;
  }

  handleButtonClick = () => {
    this.showSequenceDropDown = true;
    this.startLoadSequences();
  };

  handleButtonClickAway = () => {
    setTimeout(() => {
      this.showSequenceDropDown = false;
    });
  };

  async openSubscribeEditorPopup(mode) {
    const { affectedUsersCount, sequences } = this;
    const sequenceName = await this.ModalService.sequencesSubscribeEditorPopup({
      mode,
      sequences,
      users: this.getUsers(),
      usersAffectedCount: pluralNumberFormatter(
        affectedUsersCount,
        'user',
        'users',
      ),
    });

    this.onSequenceSelected({
      sequenceName,
      mode,
    });
  }

  startLoadSequences() {
    this.getSequences();
  }

  renderDropdownIcon() {
    return renderDropdownIcon();
  }
}
