import ng from 'angular';
import './assets/less/menu-tree-item.less';

import menuSubitem from './menu-subitem';

import { Component } from './component';

export default ng
  .module('app.common.settings.persistentMenu.menuTree.menuTreeItem', [
    menuSubitem,
  ])
  .component('menuTreeItem', Component).name;
