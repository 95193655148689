import ng from 'angular';
import template from './unbind-popup.html';
import controller from './controller';
import './assets/less/unbind-popup.less';

export default ng
  .module('app.components.unbindPopup', [])
  .component('unbindPopup', {
    template: () => template,
    controllerAs: 'vm',
    controller,
    bindings: {
      showPopup: '<',
      eventName: '<',
      page: '<',
      onRequestClose: '&',
      onDisconnectButtonClick: '&',
    },
  }).name;
