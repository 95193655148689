import { Platform } from '@globals';
import { ATTRIBUTE_TYPE_CODES } from '../../consts';

export const getDefaultColumns = (platform?: Platform) => {
  switch (platform) {
    case Platform.whatsapp:
      return [
        {
          code: 'checkbox',
          title: '',
          enabled: true,
          width: 56,
        },
        {
          code: 'whatsapp name',
          title: 'whatsapp name',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 190,
          sort: 'whatsapp_name',
          sort_desc: false,
        },
        {
          code: 'whatsapp phone',
          title: 'whatsapp phone',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 130,
          sort: 'whatsapp_phone',
          sort_desc: false,
        },
        {
          code: 'tags',
          title: 'tags',
          type: ATTRIBUTE_TYPE_CODES.tag,
          enabled: true,
          width: 190,
        },
        {
          code: 'updated_date',
          title: 'last seen',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 120,
          sort: 'updated_date',
          sort_desc: true,
        },
        {
          code: 'created_date',
          title: 'signed up',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 105,
          sort: 'created_date',
          sort_desc: true,
        },
        {
          code: 'source',
          title: 'source',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 142,
          sort: 'source',
          sort_desc: false,
        },
        {
          code: 'sessions',
          title: 'sessions',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 90,
          sort: 'sessions_count',
          sort_desc: true,
        },
        {
          code: 'vars_count',
          title: 'attributes',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 100,
          sort: 'vars_count',
          sort_desc: false,
        },
        {
          code: 'status',
          title: 'status',
          type: ATTRIBUTE_TYPE_CODES.system,
          enabled: true,
          width: 160,
        },
        {
          code: 'empty',
          title: '',
          enabled: true,
          width: 'auto',
        },
      ];

    case Platform.facebook:
    case Platform.instagram:
    default:
      return [
        {
          code: 'checkbox',
          title: '',
          enabled: true,
          width: 56,
        },
        {
          code: 'name',
          title: 'name',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 190,
          sort: 'first_name',
          sort_desc: false,
        },
        {
          code: 'locale',
          title: 'locale',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 90,
          sort: 'locale',
          sort_desc: false,
        },
        {
          code: 'updated_date',
          title: 'last seen',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 120,
          sort: 'updated_date',
          sort_desc: true,
        },
        {
          code: 'created_date',
          title: 'signed up',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 105,
          sort: 'created_date',
          sort_desc: true,
        },
        {
          code: 'source',
          title: 'source',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 142,
          sort: 'source',
          sort_desc: false,
        },
        {
          code: 'sessions',
          title: 'sessions',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 90,
          sort: 'sessions_count',
          sort_desc: true,
        },
        {
          code: 'vars_count',
          title: 'attributes',
          enabled: true,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 100,
          sort: 'vars_count',
          sort_desc: false,
        },
        {
          code: 'status',
          title: 'status',
          type: ATTRIBUTE_TYPE_CODES.system,
          enabled: true,
          width: 160,
        },
        {
          code: 'gender',
          title: 'gender',
          enabled: false,
          type: ATTRIBUTE_TYPE_CODES.system,
          width: 90,
          sort: 'gender',
          sort_desc: false,
        },
        {
          code: 'timezone',
          title: 'timezone',
          type: ATTRIBUTE_TYPE_CODES.system,
          enabled: false,
          width: 90,
          sort: 'timezone',
          sort_desc: false,
        },
        {
          code: 'empty',
          title: '',
          enabled: true,
          width: 'auto',
        },
      ];
  }
};
