import gql from 'graphql-tag';
import client from '../services/ApolloService';
import {
  CardUploadStatus,
  CardUploadStatus_cardUploadStatus,
  CardUploadStatusVariables,
} from './@types/CardUploadStatus';
import { isEuRulesError } from '@utils/EuRules/isEuRulesError';
import { UploadStatus } from '@globals';

interface sequentialPollingProps {
  interval?: number;
  taskFn: () => Promise<void>;
}

const BOT_NOT_CONNECTED_REG_EXP = /Bot \w+ not connected to page/;

export function sequentialPolling({
  interval = 1000,
  taskFn,
}: sequentialPollingProps) {
  let active = true;
  let timeoutId: number;

  function poll() {
    taskFn().then(() => {
      if (!active) {
        return;
      }
      timeoutId = window.setTimeout(poll, interval);
    });
  }

  poll();

  return () => {
    clearTimeout(timeoutId);
    active = false;
  };
}

const CARD_UPLOAD_STATUS_QUERY = gql`
  query CardUploadStatus($id: String!) {
    cardUploadStatus(id: $id) {
      status
      errorMessage
    }
  }
`;

export function pollForCardUploadStatus(cardId: string): {
  cancel: () => void;
  promise: Promise<Omit<CardUploadStatus_cardUploadStatus, '__typename'>>;
} {
  let cancel: () => void;
  return {
    cancel: () => cancel(),
    promise: new Promise((resolve, reject) => {
      cancel = sequentialPolling({
        interval: 1000,
        async taskFn() {
          return client
            .query<CardUploadStatus, CardUploadStatusVariables>({
              query: CARD_UPLOAD_STATUS_QUERY,
              variables: { id: cardId },
              fetchPolicy: 'network-only',
            })
            .then(({ data }) => {
              return data.cardUploadStatus!;
            })
            .then(({ status, errorMessage }) => {
              if (status === 'Ok') {
                cancel();
                resolve({ status, errorMessage });
              } else if (status === 'Failed') {
                cancel();
                reject(new Error(errorMessage || status));
              }
            })
            .catch((error) => {
              cancel();
              const { graphQLErrors } = error;
              const commonError = graphQLErrors?.length
                ? new Error(graphQLErrors[0].extensions.response.body.result)
                : error;
              if (
                BOT_NOT_CONNECTED_REG_EXP.test(commonError.message) ||
                isEuRulesError(error.message)
              ) {
                resolve({ status: UploadStatus.Ok, errorMessage: null });
                return;
              }
              reject(commonError);
            });
        },
      });
    }),
  };
}
