import { path } from 'ramda';

export default class PluginAddController {
  constructor(
    $scope,
    $rootScope,
    ModalService,
    PluginCardService,
    BroadcastService,
    $timeout,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.ModalService = ModalService;
    this.PluginCardService = PluginCardService;
    this.BroadcastService = BroadcastService;
    this.$rootScope = $rootScope;
    $scope.$on('plugin:add', ($event, type) => {
      this.add($event, type);
    });

    this.listener = $rootScope.$on('plugin:add.adv', ($event, param) => {
      this._addCard(param.type, param.index);
    });
  }

  $onDestroy() {
    this.listener();
  }

  add($event, type) {
    $event.preventDefault();
    this._addCard(type);
  }

  addNew = (type, index) => {
    this.$timeout(() => {
      // add plugin in next tic and call ng digest
      this._addCard(type, index);
    });
  };

  _addCard = (type, index = undefined) => {
    if (
      type === 'plugin' &&
      this.$rootScope.stateParams.broadcastType === 'auto'
    ) {
      this.ModalService.pluginBrowserAutopost().then((result) => {
        if (result) {
          this.PluginListController.addPlugin(result.type, null, index);
        }
      });
    } else if (type === 'plugin') {
      this.ModalService.pluginBrowser().then((result) => {
        if (result) {
          if (!result.param) {
            this.PluginListController.addPlugin(result.type, null, index);
          } else {
            this.PluginListController.addPlugin('plugin', result, index);
          }
        }
      });
    } else {
      this.PluginListController.addPlugin(type, null, index);
    }
  };

  qrAvailable() {
    return (
      this.block &&
      this.block.cards &&
      this.block.cards.length > 0 &&
      this.PluginCardService.isQuickReplyAvailableInThisIndex(
        this.block.cards.length,
        this.block.cards,
      )
    );
  }

  shouldShowAddSkillButton = () => {
    return (
      !path(['block', 'hide_add_skill'], this) &&
      this.$rootScope.botPageTab === 'structure'
    );
  };
}
