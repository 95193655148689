/**
 *  Service for colors work
 */
export default class ColorService {
  /**
   * @description Get brightness by color
   * @param {String} _color - Hex or RGB
   * @return {Number} -
   */
  brightnessByColor(_color) {
    const { r, g, b } = this.parseColor(_color);
    return r !== undefined ? (r * 299 + g * 587 + b * 114) / 1000 : 0;
  }

  /**
   * @description parse color to RGB components
   * @param {String} _color -
   * @return {{r:Int, g: Int, b: Int}} -
   */
  parseColor(_color) {
    const color = String(_color).toLowerCase();
    const isHEX = color.indexOf('#') === 0;
    const isRGB = color.indexOf('rgb') === 0;
    let r;
    let g;
    let b;
    if (isHEX) {
      const m = color
        .substr(1)
        .match(color.length === 7 ? /(\S{2})/g : /(\S{1})/g);
      if (m) {
        r = parseInt(m[0], 16);
        g = parseInt(m[1], 16);
        b = parseInt(m[2], 16);
      }
    }
    if (isRGB) {
      const m = color.match(/(\d+){3}/g);
      if (m) {
        /* eslint-disable prefer-destructuring */
        r = m[0];
        g = m[1];
        b = m[2];
        /* eslint-enable prefer-destructuring */
      }
    }

    return { r, g, b };
  }

  /**
   * @description generate style string for tile gradient
   * @param {String} _color -
   * @return {String} -
   * @private
   */
  generateTileMaskGradientStyle(_color) {
    const { r, g, b } = this.parseColor(_color);
    const rgbString = `${r},${g},${b}`;
    return `background: linear-gradient(to bottom, rgba(${rgbString},0) 0%,rgba(${rgbString},1) 100%);`;
  }
}
