import debounce from 'lodash-es/debounce';
import { IScope } from 'angular';

const angularContext: { $angularScope: IScope | undefined } = {
  $angularScope: undefined,
};

export const setAngularContext = ($angularScope: IScope) => {
  angularContext.$angularScope = $angularScope;
};

export const angularDigestFire = debounce(() => {
  const { $angularScope } = angularContext;
  if ($angularScope) {
    $angularScope.$evalAsync();
  }
}, 50);
