import nanoid from 'nanoid';
import OneElementQueue from '../../common/services/OneElementQueueService';

const oneElementQueue = new OneElementQueue();

export function UsersFilterComponentDataLoaderConstructorController(
  ApolloService,
  UserFilterComponentService,
  OMNIBOX_BOT_FRAGMENT_SEGMENTS_AND_VAR_SUGGEST_QUERY,
  OMNIBOX_BOT_FRAGMENT_VARS_VALUE_SUGGEST_QUERY,
  OMNIBOX_BOT_FRAGMENT_BLOCKS_SUGGEST_QUERY,
  Segmentation,
  $rootScope,
  UserFilterService,
  $scope,
) {
  'ngInject';

  this.segmentsAndVarSuggestQuery = OMNIBOX_BOT_FRAGMENT_SEGMENTS_AND_VAR_SUGGEST_QUERY;
  this.blocksQueryData = undefined;
  this.varValueSuggestQuerySubscription = undefined;
  this.segmentsAndVarSuggestQueryData = undefined;
  this.varSuggestQueryData = undefined;

  const variableQueryObservalbe = ApolloService.watchQuery({
    query: OMNIBOX_BOT_FRAGMENT_VARS_VALUE_SUGGEST_QUERY,
  });
  const { botId } = $rootScope.stateParams;

  this.$onChanges = (changesObj) => {
    if (changesObj.filter) {
      if (UserFilterService.isFilterNotEmpty(this.filter)) {
        this.preloadSequences(this.filter);
      } else {
        this.onReady();
      }
    }
  };

  this._onOmniboxChange = ({ value, type, parameterIndex }) => {
    this.subscribeOnSequences.queue(value, parameterIndex);
    this.onChange({ $filter: value });
  };

  this.preloadSequences = (filter) => {
    if (filter && filter.parameters) {
      for (let i = 0; i < filter.parameters.length; i++) {
        const parameter = filter.parameters[i];
        if (
          parameter &&
          parameter.type === Segmentation.SEGMENTATION_PARAMETERS_CODE.sequence
        ) {
          this.subscribeOnSequences.queue(filter, i);
        }
      }
    }
  };

  const queueId = nanoid();

  this.subscribeOnSequences = oneElementQueue.getQueue(
    queueId,
    async (filter, parameterIndex = 0) => {
      if (!filter) {
        return null;
      }

      const variables = UserFilterComponentService.getVarSuggestVariablesForGQL(
        { filter, parameterIndex },
        botId,
      );
      if (!variables.variable) {
        return null;
      }
      if (this.varValueSuggestQuerySubscription) {
        return variableQueryObservalbe.fetchMore({
          updateQuery: UserFilterComponentService.variableSuggestUpdate,
          variables,
        });
      }
      variableQueryObservalbe.setVariables(variables);
      this.varValueSuggestQuerySubscription = variableQueryObservalbe.subscribe(
        ({ data }) => {
          this.varSuggestQueryData = data;
          this._loadFinish();
        },
      );
      return null;
    },
  );

  $scope.$on('$destroy', () => {
    oneElementQueue.removeQueue(queueId);
  });

  this.subscribeOnSegments = () => {
    ApolloService.watchQuery({
      query: OMNIBOX_BOT_FRAGMENT_SEGMENTS_AND_VAR_SUGGEST_QUERY,
      variables: {
        botId,
      },
      errorPolicy: 'all',
    }).subscribe(({ data }) => {
      this.segmentsAndVarSuggestQueryData = data;
      this._loadFinish();
    });
  };

  this.subscribeOnBlocks = () => {
    ApolloService.watchQuery({
      query: OMNIBOX_BOT_FRAGMENT_BLOCKS_SUGGEST_QUERY,
      variables: {
        botId,
      },
      errorPolicy: 'all',
    }).subscribe(({ data }) => {
      this.blocksQueryData = data;
      this._loadFinish();
    });
  };

  this._loadFinish = () => {
    if (this.segmentsAndVarSuggestQueryData && this.blocksQueryData) {
      this.onReady();
    }
  };

  this.subscribeOnSegments();
  this.subscribeOnBlocks();
}
