import escapeStringRegexp from 'escape-string-regexp';

export default class UsersTableColSelectorController {
  constructor($scope, $element) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;

    this.showColSelector = false;

    this.filteredCols = [];
    this.colsFilterString = '';
  }

  $onInit() {
    this.$scope.$on('$angularClick', () => {
      if (this.showColSelector) {
        this.$scope.$evalAsync(() => {
          this.showColSelector = false;
          this.resetPosition();
        });
      }
    });

    this.elPositionBox = this.$element.find('.position-box');
    this.elButton = this.$element.find('.arrow-down');

    this.$element.on('click', (event) => {
      event.stopPropagation();
    });

    this.elWindow = window.angular.element(window);
    this.elWindow.on('scroll', () => {
      this.calculateElementPosition();
    });
  }

  $onDestroy() {
    this.$element.off('click');
    this.elWindow.off('scroll');
  }

  colsFilter() {
    this.filteredCols = [];

    const cleanColsFilterString = this.colsFilterString.trim().toLowerCase();

    this.cols.forEach((col) => {
      const cleanColTitle = col.title.trim().toLowerCase();
      if (
        cleanColTitle &&
        (!cleanColsFilterString ||
          cleanColTitle.indexOf(cleanColsFilterString) !== -1)
      ) {
        col.showTitle = col.title.replace(
          new RegExp(escapeStringRegexp(cleanColsFilterString), 'ig'),
          '<i>$&</i>',
        );
        this.filteredCols.push(col);
      }
    });
  }

  toggleShow() {
    this.showColSelector = !this.showColSelector;
    if (this.showColSelector) {
      this.colsFilterString = '';
      this.colsFilter();
      this.calculateElementPosition();
      setTimeout(() => {
        this.$element.find('.col-filter').focus();
      }, 0);
    } else {
      this.resetPosition();
    }
  }

  resetPosition() {
    this.elPositionBox.attr('style', '');
  }

  calculateElementPosition() {
    this.elementPositionLeft = this.getElementLeftPosition();
  }

  updatePosition() {
    const currentElementPositionLeft = this.getElementLeftPosition();
    const deltaPosition = this.elementPositionLeft - currentElementPositionLeft;
    this.elPositionBox.css({
      transform: `translateX(${deltaPosition}px)`,
    });
  }

  getElementLeftPosition() {
    return this.elButton[0].getBoundingClientRect().left;
  }
}
