import { NestedMenuItem } from '@ui/NestedMenu/types';

export enum NavigationAction {
  premiumPromo = 'premiumPromo',
  premiumPayment = 'premiumPayment',
  premiumStatus = 'premiumStatus',
  paymentsHistory = 'paymentsHistory',
  updatePaymentMethod = 'updatePaymentMethod',
  logout = 'logout',
  authorizationToken = 'authorizationToken',
  billingDetails = 'billingDetails',
  languageSelector = 'languageSelector',
  en = 'en',
  pt = 'pt',
  es = 'es',
}

export interface MenuOption extends NestedMenuItem {
  id: string;
  title: string;
  fullTitle?: string;
  warning?: string;
  icon?: string;
  nestingLevel?: number;
  needTermsAccepted?: boolean;
  label?: string;
  children?: MenuOption[];
  rightElement?(active: boolean): React.ReactNode;
}
