export default ($timeout) => {
  'ngInject';

  return (scope, element, attrs) => {
    const watcher = scope.$watch(
      attrs.showFocus,
      (newValue) => {
        $timeout(() => {
          if (element.find('input')[0]) {
            element.find('input')[0].focus();
          }
          if (newValue) {
            element[0].focus();
          }
        });
      },
      true,
    );

    scope.$on('$destroy', () => {
      watcher();
    });
  };
};
