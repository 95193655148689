import controller from './controller';
import template from './template.html';

export default () => ({
  scope: {
    timestamp: '=',
    seconds: '=',
    timeChange: '&',
    broadcast: '=',
  },
  template,
  controller,
  bindToController: true,
  controllerAs: 'vm',
  restrict: 'E',
  replace: true,
});
