import memoize from 'lodash-es/memoize';
import { clone } from 'ramda';
import { sendEvent } from '@utils/Analytics';
import { addAttributeToGQLCaches } from '@utils/AttributesUtils/AttributesUtils';
import { Platform } from '../../../../../@types/globalTypes';
import { MAX_NUMBER_OF_QUICK_REPLIES } from '../../../../modern-components/FlowBuilder/views/plugins/QuickReplyPlugin/constants';

export default class QuickRepliesController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $document,
    $element,
    BlockService,
    ModalService,
    BotService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.$element = $element;
    this.$document = $document;
    this.BlockService = BlockService;
    this.ModalService = ModalService;
    this.BotService = BotService;

    this.editedItemIndex = null;
    this.editedItemIsValid = true;
    this.saveTimeout = null;

    this.aiMode = true;
    this.blocksTitles = [];
    this.maxQrItems = MAX_NUMBER_OF_QUICK_REPLIES;
  }

  $onInit() {
    this.$sortable = {
      handle: '.h-drag',
      'ui-floating': true,
      axis: 'x',
      tolerance: 'pointer',
      containment: 'document',
      start: () => {
        this.$scope.$evalAsync(() => {
          this.cancel();
        });
      },
      stop: (e, ui) => {
        this.save();
      },
    };

    this.$document.on('mousedown', () => {
      this.$scope.$evalAsync(() => {
        this.toolTipOpen = null;
      });
    });

    this.listeners = [];

    this.listeners.push(
      this.$scope.$on('$angularClick', (_, event) => {
        const isPopupClick = !!event.target.closest('.quick-replies');
        this.$scope.$evalAsync(() => {
          if (this.editedItemIndex !== null) {
            if (this.editedItemIsValid) {
              if (!isPopupClick) {
                this.done();
              }
            } else if (!isPopupClick) {
              this.cancel();
            }
          }
        });
      }),
    );

    this.listeners.push(
      this.$scope.$on('$buttonUpdate', () => {
        this.validate();
      }),
    );

    this.listeners.push(
      this.$scope.$on('$updateBlocksInSuggest', () => {
        this.validate();
      }),
    );

    this.listeners.push(
      this.$scope.$on('$suggestBlured', () => {
        if (this.editedItemIndex !== null && this.editedItemIsValid) {
          this.placeCaretAtEnd(
            this.$element[0].querySelectorAll('.art-textarea-expand')[
              this.editedItemIndex
            ],
          );
        }
      }),
    );

    this.listeners.push(
      this.$scope.$on('$startDrag', () => {
        this.cancel();
      }),
    );

    if (!this.plugin.id) {
      this.$timeout(() => {
        if (this.autoAdd === 'true') {
          this.add();
        }
      }, 50);
    }
  }

  $onDestroy() {
    this.$document.off('mousedown');
    this.listeners.forEach((fn) => fn.call());
  }

  validate = () => {
    if (
      !this.plugin.config.buttons ||
      !this.plugin.config.buttons[this.editedItemIndex]
    ) {
      return;
    }
    this.plugin.config.buttons[this.editedItemIndex].title =
      this.plugin.config.buttons[this.editedItemIndex].title.slice(0, 20);
    this.editedItemIsValid =
      this.plugin.config.buttons[this.editedItemIndex].title &&
      this.plugin.config.buttons[this.editedItemIndex].title.trim().length > 0;
  };

  onKeyDownDone($event) {
    if ($event.keyCode === 13) {
      this.done();
    }
  }

  done($event) {
    if (!this.editedItemIsValid) {
      return;
    }

    if (this.editedItemIsNew) {
      sendEvent({
        category: 'plugin quick reply',
        action: 'button add',
        value: this.plugin.config.buttons.length,
        propertyBag: {
          length: this.plugin.config.buttons.length,
        },
      });
    }

    this.editedItemIsNew = false;
    this.editedItemBackup = null;

    this.editedItemIndex = null;
    if ($event) {
      $event.stopPropagation();
    }

    this.$timeout(() => {
      const addButton = this.$element[0].querySelector('.items-list .add.item');
      if (addButton) {
        addButton.focus();
      }
    });

    this.save();

    if (document.activeElement) {
      document.activeElement.blur();
    }
  }

  cancel($event) {
    if (this.plugin.config && this.plugin.config.buttons) {
      if (this.editedItemIsNew) {
        this.editedItemIsNew = false;
        this.plugin.config.buttons.pop();
      } else if (this.plugin.config.buttons[this.editedItemIndex]) {
        this.plugin.config.buttons[this.editedItemIndex] =
          this.editedItemBackup;
      }
    }

    this.editedItemBackup = null;
    this.editedItemIndex = null;

    if ($event) {
      $event.stopPropagation();
    }

    if (document.activeElement) {
      document.activeElement.blur();
    }
  }

  edit(index, $event) {
    if (this.editedItemIndex === index) {
      return false;
    }

    if ($event) {
      $event.stopPropagation();
    }

    this.editedItemIndex = index;

    this.editedItemBackup = clone(
      this.plugin.config.buttons[this.editedItemIndex],
    );

    this.validate();

    this.$timeout(() => {
      this.placeCaretAtEnd(
        this.$element[0].querySelectorAll('.art-textarea-expand')[
          this.editedItemIndex
        ],
      );
    }, 100);
    this.$scope.$broadcast('$scrollToStart');

    this.$timeout.cancel(this.saveTimeout);
    return undefined;
  }

  onKeyDownTitle($event) {
    if ($event.keyCode === 13) {
      $event.preventDefault();
      if (
        this.plugin.config.buttons[this.editedItemIndex].title &&
        this.plugin.config.buttons[this.editedItemIndex].title.trim().length &&
        (!this.plugin.config.buttons[this.editedItemIndex].block_ids ||
          this.plugin.config.buttons[this.editedItemIndex].block_ids.length ===
            0)
      ) {
        this.$element.find('input').focus();
      } else if (
        this.plugin.config.buttons[this.editedItemIndex].title &&
        this.plugin.config.buttons[this.editedItemIndex].title.trim().length &&
        this.plugin.config.buttons[this.editedItemIndex].block_ids &&
        this.plugin.config.buttons[this.editedItemIndex].block_ids.length > 0
      ) {
        this.done();
      }
    }
  }

  onKeyDownAdd($event) {
    if ($event.keyCode === 13) {
      this.add();
    }
  }

  add() {
    if (!this.plugin.config.quick_replies) {
      this.plugin.config.quick_replies = {};
    }

    if (!this.plugin.config.buttons) {
      this.plugin.config.buttons = [];
    }

    if (this.plugin.config.buttons.length > MAX_NUMBER_OF_QUICK_REPLIES) {
      return;
    }

    this.plugin.config.buttons.push({ title: '', block_ids: [] });

    this.$scope.$emit('$buttonUpdate');

    this.testHelp(2);

    this.editedItemIsNew = true;

    this.$timeout(() => {
      this.edit(this.plugin.config.buttons.length - 1);
    });
  }

  remove(index, $event) {
    $event.stopPropagation();
    this.plugin.config.buttons.splice(index, 1);
    this.save();
    this.$scope.$emit('$buttonUpdate');
    sendEvent({
      category: 'plugin quick reply',
      action: 'button remove',
      value: this.plugin.config.buttons.length,
      propertyBag: {
        length: this.plugin.config.buttons.length,
      },
    });
  }

  removeAll() {
    this.ModalService.confirm('Do you really want to delete quick reply?').then(
      (res) => {
        if (res) {
          this.plugin.config.quick_replies = { buttons: [] };
          this.inValid = [];
          this.save();
          this.$scope.$emit('$buttonUpdate');
        }
      },
    );
  }

  qrMouseOver(flag) {
    this.$rootScope.$broadcast('$onQrMouseOver', { id: this.plugin.id, flag });
    //  this.testHelp(1);
  }

  save() {
    if (!this.plugin.id) {
      return;
    }

    this.$timeout.cancel(this.saveTimeout);

    this.saveTimeout = this.$timeout(() => {
      delete this.plugin.validation_details;
      if (
        this.plugin.config.quick_replies &&
        (!this.plugin.config.buttons || this.plugin.config.buttons.length === 0)
      ) {
        delete this.plugin.config.quick_replies;
      } else {
        this.plugin.config.buttons.forEach((button) => {
          if (button.block_ids && button.block_ids.length === 0) {
            delete button.block_ids;
          }
        });
      }

      this.superSave();
    }, 1000);
  }

  openPopUp(openHelp) {
    let varName;

    if (this.plugin.config.property) {
      varName = this.plugin.config.property;
    }

    this.ModalService.userProperty(varName, openHelp).then((res) => {
      if (res) {
        if (res.varName && res.varName.length > 0) {
          this.plugin.config.property = res.varName;
          addAttributeToGQLCaches(
            this.$rootScope.stateParams.botId,
            res.varName,
            Platform.facebook,
          );
        } else {
          delete this.plugin.config.property;
        }
        this.sendAttributeStatusEvent();
        this.save();
      }
    });
  }

  removeProperty($event) {
    $event.stopPropagation();
    delete this.plugin.config.property;
    this.sendAttributeStatusEvent();
    this.save();
  }

  onHelpClick() {
    if (
      this.plugin.config.quick_replies &&
      this.plugin.config.buttons &&
      this.plugin.config.buttons.length > 0
    ) {
      this.openPopUp(true);
    } else {
      this.toolTipOpen = this.toolTipOpen === 1 ? null : 1;
    }
  }

  testHelp(tt) {
    // eslint-disable-next-line chatfuel/no-use-localStorage
    if (!window.localStorage.getItem(`qr_tool_tip_showed_${tt}`)) {
      if (
        tt === 1 &&
        this.plugin.config.quick_replies &&
        this.plugin.config.buttons &&
        this.plugin.config.buttons.length
      ) {
        return;
      }

      this.toolTipOpen = tt;

      // eslint-disable-next-line chatfuel/no-use-localStorage
      window.localStorage.setItem(`qr_tool_tip_showed_${tt}`, 'true');
    }
  }

  placeCaretAtEnd(el) {
    if (!el) {
      return;
    }

    el.focus();
    if (
      typeof window.getSelection !== 'undefined' &&
      typeof document.createRange !== 'undefined'
    ) {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  sendAiStatusEvent(status) {
    sendEvent({
      category: 'plugin quick reply',
      action: 'status change',
      label: status,
      propertyBag: {
        status,
      },
    });
  }

  sendAttributeStatusEvent() {
    sendEvent({
      category: 'plugin quick reply',
      action: 'set attribute',
      label: this.plugin.config.property,
      propertyBag: {
        attribute: this.plugin.config.property,
      },
    });
  }

  handleBlocksSelectorChange = memoize((index) => ({ blockId }) => {
    this.$scope.$evalAsync(() => {
      this.plugin.config.buttons[index].block_ids = blockId;
    });
  });

  handleBlocksSelectorTitlesChange = memoize((index) => (titles) => {
    this.$scope.$evalAsync(() => {
      this.blocksTitles[index] = titles;
    });
  });
}
