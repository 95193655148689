import ng from 'angular';

export default ng
  .module('app.common.safePaste', [])

  .directive('safePaste', () => {
    'ngInject';

    return (scope, element, attrs) => {
      const specialCharacters = ['–', '’', '<.*?>', '\\r', '\\n', '\\s+'];
      const normalCharacters = ['-', "'", '', ' ', ' ', ' '];

      const replaceInvalidCharacters = (string) => {
        let regEx;
        for (let x = 0; x < specialCharacters.length; x++) {
          regEx = new RegExp(specialCharacters[x], 'g');
          string = string.replace(regEx, normalCharacters[x]);
        }
        return string;
      };
      const handlePaste = (event) => {
        event.preventDefault();
        const plainText = event.originalEvent.clipboardData.getData(
          'text/plain',
        );
        const cleanText = replaceInvalidCharacters(plainText);
        document.execCommand('inserttext', false, cleanText);
        return false;
      };
      element.on('paste', handlePaste);
      scope.$on('$destroy', () => {
        element.off('paste', handlePaste);
      });
    };
  }).name;
