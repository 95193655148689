/* eslint-disable require-jsdoc */

import moment from 'moment'; // TODO: move to service??
import { isNil } from 'ramda';
import { ATTRIBUTE_TYPE_CODES } from '../consts';
import {
  addAttributeToGQLCaches,
  attributeIdFromNameAndType,
  getAttributesAsync,
  getAttributesQueryObservable,
  refetchAttributesQueryObservables,
} from '../../../utils/AttributesUtils/AttributesUtils';
import {
  getAttributeValuesQueryObservable,
  updateAttributeValuesQueryObservable,
} from '../../../utils/AttributesUtils/AttributeValuesUtils';
import { canView, canEdit } from '../../../common/services/RoleService';
import { getAbsoluteConversationUrl } from '../../../pages/LiveChat/routing';
import { sendEvent } from '../../../utils/Analytics';
import {
  Platform,
  VariablesType,
  VariableSuggestType,
} from '../../../../@types/globalTypes';
import { getDefaultColumns } from './helpers/getDefaultColumns';
import { globalHistory } from '@utils/Routing';

const STAT_COUNT_TIME_FORMAT = 'MMM Do YYYY';
const customAttributeIdFromName = attributeIdFromNameAndType(
  ATTRIBUTE_TYPE_CODES.custom,
);

// hack for mask botId from appearing in localStorage fully
const maskBotId = (botId) => botId.substring(8);

/* eslint-disable chatfuel/no-use-localStorage */
export default class UsersTableController {
  constructor(
    $scope,
    $element,
    $document,
    $timeout,
    $rootScope,
    BotService,
    PeopleService,
    OmniboxService,
    $log,
  ) {
    'ngInject';

    this.log = $log.log.bind($log, 'user table: ');
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$element = $element;
    this.$document = $document;
    this.$timeout = $timeout;
    this.RoleService = { canEdit, canView };

    this.currentlyEditedUser = null;
    this.currentlyEditedUserAttributes = null;
    this.PeopleService = PeopleService;
    this.BotService = BotService;
    this.OmniboxService = OmniboxService;
    this.statCountDateString = moment(
      PeopleService.getTimestampOfStatsCountRelease(),
    ).format(STAT_COUNT_TIME_FORMAT);

    $scope.RoleService = $rootScope.RoleService;

    this.colsRady = false;

    this.usersPlaceholder = new Array(3);

    this.cols = [];

    this.usedSystemColsName = [
      'shopify delivery address',
      'shopify order id',
      'shopify abandoned cart link',
      'shopify first name',
      'shopify last name',
      'shopify estimated delivery date',
      'messenger user id',
      'chatfuel user id',
      'page id',
      'bot id',
      'ref',
      'last clicked button name',
      'last user freeform input',
      'last visited block id',
      'last visited block name',
      'latitude',
      'longitude',
      'map url',
      'zip',
      'address',
      'country',
      'state',
      'city',
      'last purchased item',
      'last payment name',
      'last payment email',
      'last payment phone',
      'last payment address',
      'last payment charge id',
    ];

    this.filteredCols = [];

    this.checkboxHead = false;

    BotService.cachedList().then((bots) => {
      this.bot = bots.find((lBot) => lBot.id === $rootScope.stateParams.botId);
      this.attributesSubscribe = getAttributesQueryObservable(
        this.$rootScope.stateParams.botId,
        VariableSuggestType.omnibox,
        null,
      ).subscribe((attributes) => {
        this.attributes = attributes;
        this.suggestedCustomAttributesNames = attributes
          .filter(({ type }) => type === VariablesType.custom)
          .map(({ name }) => name);
        this._prepareCols();
      });
    });

    $scope.$on('$tableDataUpdated', (event, offset) => {
      if (offset === 0) {
        this.checkboxHead = false;
        this.checkboxes = [];
      }
    });

    $scope.$watch(
      () => this.cols,
      () => {
        if (this.colsRady) {
          this.filteredCols.length = 0;
          this.cols.forEach((col) => {
            if (col.enabled) {
              this.filteredCols.push({ ...col });
            }
          });
          this.localSaveCols();
        }
      },
      true,
    );

    this.localSorageColsKey = `_cf_user-tab-cols-order-3-${maskBotId(
      this.$rootScope.stateParams.botId,
    )}`;
  }

  $onInit() {
    this.elBody = window.angular.element('body');
    this.elTHead = this.$element.find('thead');
    this.elAttributesPopup = this.$element.find('.user-attributes-popup');

    this.elTHead.on('mousedown', (e) => {
      if (e.target.classList.contains('resize-drag-hover')) {
        this.resizeColumns(e);
      } else if (
        e.target.classList.contains('th-box') ||
        e.target.parentNode.classList.contains('th-box')
      ) {
        this.dragColumns(e);
      }
    });
  }

  $onDestroy() {
    this.$document.off('mousemove');
    this.elBody.removeClass('cf-drag-col-resize');
    this.elBody.removeClass('cf-drag-col');
    this.attributesSubscribe?.unsubscribe();
    if (this.listener) {
      this.listener();
    }
  }

  _prepareCols() {
    if (this.colsRady) {
      return;
    }
    this.cols = getDefaultColumns(this.bot?.allowedPlatforms[0]);

    const forAddCols = [];
    const isSystemAttr = (attr) => attr.type === ATTRIBUTE_TYPE_CODES.system;

    const attributesCustomFirst = this.PeopleService.sortAttributesCustomFirst(
      this.PeopleService.filterAttributesByTypes(this.attributes, [
        ATTRIBUTE_TYPE_CODES.system,
        ATTRIBUTE_TYPE_CODES.custom,
        ATTRIBUTE_TYPE_CODES.tag,
      ]),
    );

    attributesCustomFirst.forEach((attr) => {
      const isSystem = isSystemAttr(attr);
      if (
        (!isSystem || this.usedSystemColsName.indexOf(attr.name) !== -1) &&
        !this.cols.find((item) => item.name === attr.name)
      ) {
        const col = {
          code: attr.name.replace(/\s/gi, '_'),
          title: attr.name,
          enabled: false,
          width: this.getTextWidth(attr.name),
        };

        if (isSystem) {
          col.type = ATTRIBUTE_TYPE_CODES.system;
          col.sort = col.code;
          col.sort_desc = false;
        } else {
          col.type = ATTRIBUTE_TYPE_CODES.custom;
        }

        forAddCols.push(col);
      }
    });

    this.cols.splice(this.cols.length - 1, 0, ...forAddCols);

    const savedColsJSON = window.localStorage.getItem(this.localSorageColsKey);

    if (savedColsJSON) {
      const sColsObj = JSON.parse(savedColsJSON);
      const localeSavedCols = sColsObj.cols;
      this.sortOpt = sColsObj.sortOpt;

      this.cols.forEach((item) => {
        item.enabled = false;
      });

      localeSavedCols.splice(
        localeSavedCols.length - 1,
        0,
        ...this.cols.filter(
          (col) => !localeSavedCols.find((fCol) => fCol.code === col.code),
        ),
      );
      this.cols = localeSavedCols;
    } else {
      this.sortOpt = {
        col: this.cols.find((col) => col.code === 'updated_date'),
        desc: true,
      };
    }
    this.sortUpdate({ $sortOpt: this.sortOpt });
    this.$scope.$evalAsync(() => {
      this.colsRady = true;
    });
  }

  contactLink(user) {
    const url = new URL(window.location);
    const relativeCurrentUrl = url.pathname + url.search + url.hash;
    return `/bot/${this.$rootScope.stateParams.botId}/contacts/${
      user.user_id
    }?backUrl=${encodeURIComponent(relativeCurrentUrl)}`;
  }

  _onUserVarCountClick($event, user) {
    $event.preventDefault();
    $event.stopPropagation();
    sendEvent({
      category: 'people tab',
      action: 'click',
      label: 'user',
      propertyBag: {
        contactId: user.user_id,
      },
    });
    const link = this.contactLink(user);

    if ($event.metaKey) {
      window.open(link, '_blank');
    } else {
      globalHistory.push(link);
    }
  }

  _setCurrentlyEditUser = (user) => {
    this.currentlyEditedUser = user;
    this.currentlyEditedUserAttributes = user && user.variables;
  };

  async _saveUserAttributes(attributesToSave, user) {
    try {
      await this.PeopleService.updateAttributesForUser(attributesToSave, user);
      user.variables = attributesToSave;
      refetchAttributesQueryObservables(
        this.$rootScope.stateParams.botId,
        null,
      );
    } catch (e) {
      console.error('error during _saveUserAttributes', e);
    }
  }

  sort(col) {
    if (!col.sort) {
      return;
    }
    if (!this.sortOpt.col || this.sortOpt.col.code !== col.code) {
      this.sortOpt.col = col;
      this.sortOpt.desc = col.sort_desc;
    } else {
      this.sortOpt.desc = !this.sortOpt.desc;
    }
    this.sortUpdate({ $sortOpt: this.sortOpt });

    this.localSaveCols();
  }

  tagMap(tag) {
    return tag.name;
  }

  resizeColumns(e) {
    this.elBody.addClass('cf-drag-col-resize');
    const dragColIndex = Number(e.target.parentNode.getAttribute('index'));
    const dragColEl = this.$element.find(`th[index=${dragColIndex}]`)[0];
    const nextColIndex = dragColIndex + 1;
    const nextColEl = this.$element.find(`th[index=${nextColIndex}]`)[0];

    this.dragState = {
      dragStartPos: e.clientX,
      dragColIndex,
      nextColIndex,
      cutTh: {
        el: dragColEl,
        width: Number(dragColEl.getAttribute('width')),
        minWidth: dragColEl.querySelector('span').offsetWidth + 35,
      },
      nextTh: {
        el: nextColEl,
        width: Number(nextColEl.getAttribute('width')),
        minWidth: nextColEl.querySelector('span').offsetWidth + 35,
      },
    };

    const wLimiter = (w) => {
      // return w > 150 ? 150 : w;
      return 100;
    };

    this.dragState.cutTh.minWidth = wLimiter(this.dragState.cutTh.minWidth);
    this.dragState.nextTh.minWidth = wLimiter(this.dragState.nextTh.minWidth);

    this.dragState.cutTh.el.classList.add('resize-drag');

    this.$document.on('mousemove', (emm) => {
      const delta = emm.clientX - this.dragState.dragStartPos;
      if (delta) {
        let curNewWidth = this.dragState.cutTh.width + delta;
        let nextNewWidth = this.dragState.nextTh.width - delta;
        if (curNewWidth < this.dragState.cutTh.minWidth) {
          curNewWidth = this.dragState.cutTh.minWidth;
          nextNewWidth =
            this.dragState.cutTh.width +
            this.dragState.nextTh.width -
            curNewWidth;
        } else if (nextNewWidth < this.dragState.nextTh.minWidth) {
          nextNewWidth = this.dragState.nextTh.minWidth;
        }
        this.dragState.cutTh.el.setAttribute('width', curNewWidth);
        this.filteredCols[this.dragState.dragColIndex].width = curNewWidth;
        if (this.filteredCols.length - 3 > this.dragState.dragColIndex) {
          this.dragState.nextTh.el.setAttribute('width', nextNewWidth);
          this.filteredCols[this.dragState.nextColIndex].width = nextNewWidth;
        }
      }
    });

    this.listener = this.$scope.$on('$angularMouseUp', () => {
      this.listener();
      this.$document.off('mousemove');
      this.elBody.removeClass('cf-drag-col-resize');
      this.dragState.cutTh.el.classList.remove('resize-drag');
      this.dragState = null;
      this.localSaveCols();
    });
  }

  dragColumns(e) {
    const dragHeadCell = e.target.classList.contains('th-box')
      ? e.target
      : e.target.parentNode;

    this.dragState = {
      enabled: false,
      dragStartPos: e.clientX,
      dragHeadCell,
      dragColIndex: Number(dragHeadCell.parentNode.getAttribute('index')),
      dragColElements: this.$element.find(
        `td.${dragHeadCell.parentNode.getAttribute('class')} .td-inner`,
      ),
    };

    this.$document.on('mousemove', (emm) => {
      const delta = emm.clientX - this.dragState.dragStartPos;
      if (Math.abs(delta) > 5 && !this.dragState.enabled) {
        this.elBody.addClass('cf-drag-col');
        this.dragState.dragHeadCell.classList.add('drag');
        this.dragState.dragColElements.addClass('drag');
        this.dragState.enabled = true;
      }

      if (this.dragState.enabled) {
        const pos = emm.clientX + window.pageXOffset - 150;
        const ths = this.elTHead.find('th');
        let caret = 0;

        this.dragState.dragHeadCell.setAttribute(
          'style',
          `transform: translateX(${delta - 10}px)`,
        );
        this.dragState.dragColElements.css(
          'transform',
          `translateX(${delta}px)`,
        );
        this.filteredCols.some((col, i) => {
          if (pos > caret && pos <= caret + col.width) {
            if (i > 0) {
              ths.removeClass('drag-target');
              ths[i].classList.add('drag-target');
              this.targetIndex = i;
            }
            return true;
          }
          caret += col.width;
          return false;
        });
      }
    });

    this.listener = this.$scope.$on('$angularMouseUp', () => {
      if (
        this.targetIndex &&
        this.targetIndex !== this.dragState.dragColIndex
      ) {
        const movedCols = this.filteredCols.splice(
          this.dragState.dragColIndex,
          1,
        );
        this.filteredCols.splice(this.targetIndex, 0, movedCols[0]);
      }
      this.listener();
      this.$document.off('mousemove');
      this.elBody.removeClass('cf-drag-col');
      this.dragState.dragHeadCell.classList.remove('drag');
      this.dragState.dragHeadCell.setAttribute('style', '');
      this.dragState.dragColElements.attr('style', '');
      this.dragState.dragColElements.removeClass('drag');
      this.elTHead.find('.drag-target').removeClass('drag-target');
      this.dragState = null;
      this.targetIndex = null;

      this.$timeout(() => {
        this.targetIndex = null;
      });

      this.$scope.$evalAsync();
      this.localSaveCols();
    });
  }

  localSaveCols() {
    try {
      window.localStorage.setItem(
        this.localSorageColsKey,
        JSON.stringify({
          cols: this.filteredCols,
          sortOpt: this.sortOpt,
        }),
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  onCheckboxChange(n) {
    const total = this.usersList.users.length;
    if (window.angular.isNumber(n)) {
      this.checkboxHead =
        this.checkboxes.reduce(
          (count, item) => (item ? count + 1 : count),
          0,
        ) === total;
    } else {
      for (let i = 0; i < total; i++) {
        this.checkboxes[i] = this.checkboxHead;
      }
    }
    if (window.angular.isNumber(n) && this.checkboxes[n]) {
      sendEvent({
        category: 'people tab',
        action: 'choose',
        label: 'user',
        propertyBag: {
          'number of users': this.selectedUsers,
        },
      });
    }
  }

  onLineClick(index) {
    if (!this.cfDisabled) {
      if (index > -1) {
        this.checkboxes[index] = !this.checkboxes[index];
        this.onCheckboxChange(index);
      }
    } else if (canView('pro')) {
      this.showProPopUp();
    }
  }

  getMessageUserUrl(user) {
    const { botId } = this.$rootScope.stateParams;
    const platform = this.platformByUser(user);
    const conversationId = this.getConversationId(user, platform);
    return getAbsoluteConversationUrl(botId, conversationId, platform);
  }

  getConversationId(user, platform) {
    switch (platform) {
      case Platform.whatsapp:
        return user.whatsapp_user_id.startsWith('wa_')
          ? user.whatsapp_user_id
          : `wa_${user.whatsapp_user_id}`;
      case Platform.instagram:
        return user.instagram_user_id.startsWith('ig_')
          ? user.instagram_user_id
          : `ig_${user.instagram_user_id}`;
      default:
        return user.messenger_user_id;
    }
  }

  getColValue(user, col) {
    const attr = user.variables.find((item) => item.name === col.title);
    return attr ? this.OmniboxService.attributeValueToString(attr) : '—';
  }

  getTextWidth(text) {
    if (!this.canvas) {
      this.canvas = document.createElement('canvas');
    }
    const context = this.canvas.getContext('2d');
    context.font =
      "normal 14px 'Inter', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', Arial, sans-serif"; // can't use css variables here
    const metrics = context.measureText(text);
    let width = metrics.width + 35;
    if (width > 150) {
      width = 150;
    } else if (width < 100) {
      width = 100;
    }
    return width;
  }

  _isUserNotAuthorizedYet(user) {
    const notAuthorizedYetUsersStatus = 'reachable, profile info pending';
    return user.status === notAuthorizedYetUsersStatus;
  }

  onChangeAttribute = (attributes) => {
    this.$scope.$evalAsync(() => {
      this.currentlyEditedUserAttributes = attributes;
    });
  };

  onCancelSaveAttributes = () => {
    this.$scope.$evalAsync(() => this._setCurrentlyEditUser(undefined));
  };

  onSaveAttributes = () => {
    this.log('save');
    this._saveUserAttributes(
      this.currentlyEditedUserAttributes,
      this.currentlyEditedUser,
    );
    addAttributeToGQLCaches(
      this.bot.id,
      this.currentlyEditedUserAttributes
        .filter(({ type }) => type === ATTRIBUTE_TYPE_CODES.custom)
        .map(({ name }) => name),
      null,
    );
    this._setCurrentlyEditUser(undefined);
  };

  handleRequestAttributeValuesLoad = (attributeName, value) => {
    const { attributeValuesSubscription } = this;
    this.attributeId = customAttributeIdFromName(attributeName);
    this.attributesSuggestsValues = [];
    if (!attributeValuesSubscription) {
      this.attributeValuesSubscription = getAttributeValuesQueryObservable(
        attributeName,
        value,
        this.$rootScope.stateParams.botId,
      ).subscribe((attributesSuggestsValues) => {
        this.$scope.$evalAsync(() => {
          this.attributesSuggestsValuesMap = attributesSuggestsValues;
        });
      });
    } else {
      updateAttributeValuesQueryObservable(
        attributeName,
        value,
        this.$rootScope.stateParams.botId,
      );
    }
  };

  isNil = isNil;

  platformByUser = (user) => {
    if (
      user.instagram_handle ||
      user.instagram_name ||
      user.instagram_user_id
    ) {
      return Platform.instagram;
    }
    if (user.whatsapp_user_id) {
      return Platform.whatsapp;
    }
    return Platform.facebook;
  };
}
