import CardTextController from '../card-text-plugin/controller';

export default class CardCommunicationSubtitleController extends CardTextController {
  init() {
    this.discount = 80;
    this.$errors = {};
  }

  $onInit() {
    this.$scope.$evalAsync(() => {
      this.textarea = this.$element[0].querySelector('textarea');
      this.updateDiscount();
    });
  }

  save() {
    if (!this.validate()) {
      this.CardCommunicationController.validation.subtitle = false;
      //  return;
    }
    this.CardCommunicationController.validation.subtitle = true;
    this.CardCommunicationController.saveCommunication();
  }
}
