import { loadPermissionsMemoized, canView, canEdit } from './RoleService';

export default ($http, StoreService, $rootScope) => {
  'ngInject';

  return {
    list(botId, refresh) {
      // some problem to get bot_id in resolve section of ui.router
      if (!botId) {
        // eslint-disable-next-line prefer-destructuring
        botId = $rootScope.stateParams.botId;
      }

      const groupsPromise = $http({
        url: `${StoreService.getApiUrl()}/bots/${botId}/groups`,
        method: 'get',
        // cacheOptions: {
        //   need: true,
        //   refresh
        // }
      }).then((res) => {
        return res.data.result;
      });

      return Promise.all([groupsPromise, loadPermissionsMemoized(botId)]).then(
        ([groupsRes]) => {
          return groupsRes.map((group) => ({
            ...group,
            hidden:
              !canView('groups', {
                groupId: group.id,
              }) && !canEdit('broadcasting'),
          }));
        },
      );
    },

    /**
     * save
     * @param {String} groupId
     * @param {Object} data
     * @return {Object}
     */
    update(groupId, data) {
      return $http({
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/groups/${groupId}`,
        method: 'post',
        data,
      }).then((res) => {
        data.id = res.data.result;
        return data;
      });
    },

    create(data) {
      return $http({
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/groups`,
        method: 'post',
        data,
      }).then((res) => {
        data.id = res.data.result.id;
        data.blocks = res.data.result.blocks;
        return data;
      });
    },

    remove(id) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/groups/${id}/delete`,
      }).then((response) => response.data);
    },

    cloneToAnotherBot(id, botId) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/groups/${id}/clone_to_bot/${botId}`,
      }).then((response) => {
        response.data.updateTemplate = botId === $rootScope.stateParams.botId;
        response.data.id = response.data.result;
        return response.data;
      });
    },

    updateTimePeriod(blockId, data) {
      return $http({
        url: `${StoreService.getApiUrl()}/blocks/${blockId}`,
        method: 'post',
        data,
      }).then((res) => {
        return data;
      });
    },

    updateCollapsedState(groupId, data) {
      return $http({
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/groups/${groupId}`,
        method: 'post',
        data,
      }).then((res) => {
        return data;
      });
    },

    getClonedGroup(groupId) {
      return $http({
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/groups/${groupId}`,
        method: 'get',
      }).then((res) => {
        return res.data.result;
      });
    },
  };
};
