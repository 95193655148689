import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { AdminQuery } from './@types/AdminQuery';

export const ADMIN_QUERY = gql`
  query AdminQuery {
    me {
      id
      name
      roles {
        bot_id
        role_id
      }
      picture
      terms_accepted
      white_label_role
      premium_account_id
      premiumAccount {
        id
        old_premium
        plan_info {
          id
          amount
          interval
          currency
        }
        product_info {
          id
          name
        }
        subscription_info {
          id
          status
          current_period_end
        }
      }
      workspacesAvailable
      email
      fbPermissions {
        hasMinimalPermissions
      }
    }
  }
`;

export const useAdminQuery = () => {
  return useQuery<AdminQuery>(ADMIN_QUERY);
};
