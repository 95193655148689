import ng from 'angular';
import gql from 'graphql-tag';
import template from './template.html';
import { UsersFilterComponentController } from './controller';
import './user-filter-component.less';

export const OMNIBOX_SEQUENCES_TITLES_MAP_QUERY = gql`
  query OmniboxSequencesTitlesMapQuery($botId: String!) {
    bot(id: $botId) {
      id
      sequencesTitlesMap {
        id
        title
        removed
      }
    }
  }
`;

export default ng
  .module('app.components.users.userFilterComponent', [])
  // this is whats need to be loaded to display omnibox (mainly static data);
  .constant(
    'OMNIBOX_BOT_FRAGMENT_SEGMENTS_AND_VAR_SUGGEST_QUERY',
    gql`
      query OMNIBOX_BOT_FRAGMENT_SEGMENTS_AND_VAR_SUGGEST($botId: String!) {
        bot(id: $botId) {
          id
          variableSuggest(
            suggestType: omnibox
            platform: null
            allPlatforms: false
          ) {
            id
            name
            type
            count
          }
          segments {
            id
            name
            removed
            additional_ids
          }
        }
      }
    `,
  )
  .constant(
    'OMNIBOX_BOT_FRAGMENT_BLOCKS_SUGGEST_QUERY',
    gql`
      query OMNIBOX_BOT_FRAGMENT_BLOCKS_SUGGEST($botId: String!) {
        bot(id: $botId) {
          id
          archiveBlocks {
            id
            title
            removed
            reachable
            is_flow
          }
        }
      }
    `,
  )
  // this needs to suggest vars values;
  // needs to be separate query -> loads dynamic data with pagination;
  // also should be done query
  .constant(
    'OMNIBOX_BOT_FRAGMENT_VARS_VALUE_SUGGEST_QUERY',
    gql`
      query OMNIBOX_BOT_FRAGMENT_VARS_VALUE_SUGGEST(
        $variable: String!
        $prefix: String
        $type: VariablesType
        $botId: String!
      ) {
        bot(id: $botId) {
          id
          variableValueSuggest(
            variable: $variable
            type: $type
            prefix: $prefix
          )
            @connection(
              key: "variableValueSuggest"
              filter: ["variable", "type"]
            ) {
            id
            suggests {
              id
              value
              count
              type
            }
          }
          sequencesTitlesMap {
            id
            title
            removed
          }
        }
      }
    `,
  )
  .constant(
    'OMNIBOX_SEQUENCES_TITLES_MAP_QUERY',
    OMNIBOX_SEQUENCES_TITLES_MAP_QUERY,
  )
  .component('userFilterComponent', {
    template: () => template,
    controllerAs: 'vm',
    controller: UsersFilterComponentController,
    bindings: {
      /**
       * @property: segments data same shape as OMNIBOX_BOT_FRAGMENT_SEGMENTS_AND_VAR_SUGGEST_QUERY
       */
      segmentsQueryData: '<',

      blocksQueryData: '<',
      /**
       *  variable
       */
      variableValueSuggestQueryData: '<',

      /**
       * @property: {Segmentation} filter - use GraphQL segmentation object;
       */

      filter: '<',

      /**
       * @callback: {Segmentation} value - new updated filter object;
       */
      onChange: '&',

      onDataPreloadRequest: '&',

      onFilterItemAdd: '&',

      onFilterItemRemove: '&',

      type: '@',

      includeOnlyParameters: '<',
    },
  }).name;
