import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { getPluginName } from '../../pluginNames';

export default class CardCommunicationController extends CardPluginBaseController {
  $onInit() {
    this.pluginName = getPluginName('communication');
    this.loadBot();
    this.$timeout(() => {
      if (!this.item.id) {
        this.save();
      }
      if (!this.item.validation_details) {
        this.item.validation_details = {};
      }
      if (this.item.is_valid === false) {
        this.data = this.item.validation_details || {};
        this.data.card_id = this.item.id;
        this.$rootScope.$broadcast('$pluginSaveResultError', {
          data: this.data,
        });
      } else {
        this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
      }
    });

    this.docLink =
      'https://docs.chatfuel.com/plugins/connect-users-to-a-human/live-chat';
  }

  loadBot() {
    this.BotService.show().then((bots) => {
      this.bot = bots;
      if (this.bot.status.page) {
        this.page = this.bot.status.page;
        this.showFooter = true;
      }
    });
  }

  saveCommunication() {
    this.save();
  }

  onTimeoutInputChange = (config) => {
    this.item.config.timeout_config = config;
    this.save();
  };

  save() {
    if (
      this.item.config.idle_time_in_hours < 0 ||
      this.item.config.idle_time_in_hours === undefined
    ) {
      this.item.config.idle_time_in_hours = 1;
    }
    super.save();
  }
}
