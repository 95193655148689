import React from 'react';
import {
  Button,
  ButtonIntent,
  ButtonSize,
} from '../../../../modern-ui/_deprecated/Button';
import { ReactComponent as AddIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_add_small.svg';

export const NewItemButton: React.SFC<{}> = () => (
  <Button
    tall
    size={ButtonSize.s}
    intent={ButtonIntent.default}
    renderIcon={() => <AddIcon />}
  />
);
