import { getBlocksTitlesArchiveObservable } from '../../../../../../modern-components/Aside/BlocksGroupsQueries';

export default class MenuSubitemController {
  constructor(
    $scope,
    $rootScope,
    $element,
    $timeout,
    $document,
    ModalService,
    StoreService,
    ButtonService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$element = $element;
    this.$timeout = $timeout;
    this.$document = $document;

    this.ModalService = ModalService;
    this.StoreService = StoreService;
    this.ButtonService = ButtonService;
  }

  $onInit() {
    this.$scope.$on('$saveMenu', (event) => {
      if (this.subitem.thothSubmenu) {
        delete this.subitem.thothSubmenu;
        if (this.subitem.items) {
          this.$scope.$emit('$editSubmenu', {
            index: this.index,
            level: Number(this.level),
          });
        } else {
          this.$scope.$emit('$editSubmenu', {
            index: null,
            level: Number(this.level) + 1,
          });
        }
      }
      this.error = this.ButtonService.testError(this.subitem, 'block_ids');
    });

    this.$scope.$on('$closePopup', (event) => {
      event.stopPropagation();
      if (this.subitem.new) {
        this.removeItemGo();
      }
    });

    this.$timeout(() => {
      if (this.subitem.new) {
        this.openPopUp();
      }

      this.error = this.ButtonService.testError(this.subitem, 'block_ids');
    });

    this.blockTitles = [];

    this.$scope.$watch('$menuSubitem.subitem.block_ids', () => {
      this.blockTitles.length = 0;
      if (!(!this.subitem.block_ids || this.subitem.block_ids.length === 0)) {
        this.mapBlockTitles();
      }
    });

    this.blockListSubscription = getBlocksTitlesArchiveObservable(
      this.$rootScope.stateParams.botId,
    ).subscribe((blocksList) => {
      this.mapIdToTitle = {};
      this.mapTitleToId = {};
      blocksList.forEach((block) => {
        this.mapIdToTitle[block.id] = block.title;
      });

      this.mapBlockTitles();
    });
  }

  $onDestroy() {
    if (this.blockListSubscription) {
      this.blockListSubscription.unsubscribe();
    }
  }

  mapBlockTitles() {
    if (this.mapIdToTitle) {
      this.blockTitles = [];

      if (this.subitem.block_ids) {
        this.subitem.block_ids.forEach((item) => {
          if (this.mapIdToTitle[item]) {
            this.blockTitles.push(this.mapIdToTitle[item]);
          }
        });
      }
    }
  }

  editSubmenu() {
    setTimeout(() => {
      this.$document.trigger('click');
    }, 1);

    this.$scope.$emit('$editSubmenu', {
      index: this.index,
      level: Number(this.level),
    });
  }

  removeItem() {
    if (!this.allowDeletion) {
      this.ModalService.alert('To delete last menu item enable user input');
    } else if (this.subitem.items && this.subitem.items.length) {
      this.ModalService.confirm(
        'Do you really want to delete menu item and related submenu?',
      ).then((res) => {
        if (res) {
          this.removeItemGo();
        }
      });
    } else {
      this.removeItemGo();
    }
  }

  removeItemGo() {
    this.$scope.$emit('$removeSubmenu', {
      index: this.index,
      level: Number(this.level),
    });

    this.$timeout(() => {
      this.$rootScope.$emit('$closeOther');
    });
  }

  openPopUp() {
    this.$rootScope.$emit('$onButtonPopupEditor', {
      item: this.subitem,
      element: this.$element,
      scope: this.$scope,
      level: Number(this.level),
      parent_elem: false,
      hide_tabs: ['buy', 'share', 'phone'],
    });
  }
}
