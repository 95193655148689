import ng from 'angular';
import controller from './controller';
import template from './template.html';
import './segment-name-editing.less';

export default ng
  .module('app.components.users.segmentNameEditing', [])
  .component('segmentNameEditing', {
    bindings: {
      segment: '<',
      /* I will use it to validate new segments name */
      allSegments: '<',
      /* change fired only on input blur not on every keystroke so you cannot filter input */
      onSegmentChange: '&',
    },
    controllerAs: 'vm',
    controller,
    template: () => template,
  }).name;
