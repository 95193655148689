import template from './svp-variables.html';
import controller from './controller';

export default () => ({
  template,
  controllerAs: '$svpVariables',
  controller,
  bindToController: true,
  restrict: 'E',
  replace: true,
  scope: {
    value: '=',
    vars: '<',
    items: '=',
    index: '@',
    isNew: '@',
    save: '&',
  },
});
