import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { getPluginName } from '../../pluginNames';

export default class CardSubscriptionsmanagerController extends CardPluginBaseController {
  $onInit() {
    this.pluginName = getPluginName('subscriptions_manager_plugin');
  }

  save() {
    super.saveInSub();
    super.save();
  }
}
