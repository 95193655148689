/**
 * Helps to understand what current user can do
 */

import memoize from 'lodash-es/memoize';
import gql from 'graphql-tag';
import { anyPass, propEq } from 'ramda';
import { log } from 'cf-common/src/logger';
import client from './ApolloService';
import {
  CURRENT_ROLE_QUERY as CurrentRoleType,
  CURRENT_ROLE_QUERY_bot_currentRole_permissions,
  CURRENT_ROLE_QUERY_bot_currentRole_permissions as GQLPermissions,
  CURRENT_ROLE_QUERY_bot_currentRole_permissions_groups as GQLGroupsPermission,
} from './@types/CURRENT_ROLE_QUERY';
import { PERMISSIONS_FRAGMENT } from '@utils/Data/Permissions/fragments';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type PermissionsWithoutGroups = Omit<GQLPermissions, 'groups' | '__typename'>;
type GroupsPermissionWithoutList = Omit<
  GQLGroupsPermission,
  'whitelistedGroupIds' | '__typename'
>;
type GroupsPermissionTransformed = GroupsPermissionWithoutList & {
  whitelistedGroupIdsSet?: Set<String>;
};
export type Permissions = PermissionsWithoutGroups & {
  groups: GroupsPermissionTransformed;
};

export enum Permission {
  FORBIDDEN = 'FORBIDDEN',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export enum AccessType {
  FULL = 'FULL',
}

export type Domain =
  | 'bot'
  | 'inbox'
  | 'ai'
  | 'people'
  | 'broadcasting'
  | 'configure'
  | 'grow'
  | 'analyze'
  | 'roles'
  | 'groups'
  | 'pro'
  | 'flows';

type ExtraParams = {
  botId?: string;
  groupId?: string;
  accessType?: AccessType;
};

interface CanDefaultDomainParams {
  botId: string;
  domain: Domain;
  requestedPermission: Permission;
  permissions: Permissions;
}

interface CanGroupParams extends CanDefaultDomainParams {
  groupId?: string;
  accessType?: AccessType;
}

const CURRENT_ROLE_QUERY = gql`
  query CURRENT_ROLE_QUERY($botId: String!) {
    bot(id: $botId) {
      id
      currentRole {
        permissions {
          ...permissionsFragment
        }
      }
    }
  }

  ${PERMISSIONS_FRAGMENT}
`;
const botIdToPermissionsMap: Map<string, Permissions> = new Map();
const botIdsPermissionIsLoading: Set<string> = new Set();
const botIdsRulesUnsupported: Set<string> = new Set();
let permissionSubscribers: Function[] = [];
let currentGlobalBotId: string = '';

export const getCurrentGlobalBotId = () => {
  return currentGlobalBotId;
};

/**
 * Check if permissions for bot are loading
 */
export const getLoading = (botId = currentGlobalBotId) => {
  if (botIdsRulesUnsupported.has(botId)) {
    return false;
  }

  return (
    botIdsPermissionIsLoading.has(botId) || !botIdToPermissionsMap.has(botId)
  );
};

/**
 * Subscribe to event when permissions has been changed. e.g. they have been loaded.
 */
export const onPermissionsUpdate = (callback: Function) => {
  permissionSubscribers.push(callback);
};

export const unPermissionsUpdate = (callback: Function) => {
  permissionSubscribers = permissionSubscribers.filter(
    (subscriber) => callback !== subscriber,
  );
};

const triggerPermissionsUpdate = () => {
  permissionSubscribers.forEach((callback) => callback.call(null));
};

/**
 * Transform data from API to our internal format.
 */
const transformData = (permissions: GQLPermissions) => {
  const whitelistedGroupIds =
    permissions && permissions.groups && permissions.groups.whitelistedGroupIds;
  const whitelistedGroupIdsSet = whitelistedGroupIds
    ? new Set(whitelistedGroupIds)
    : undefined;
  const { groups, ...untransformedPermissions } = permissions;

  return {
    ...untransformedPermissions,
    groups: {
      whitelistedGroupIdsSet,
      permission: groups.permission,
    },
  };
};

/**
 * Load permissions from API
 */
const loadPermissionsInternal = async (botId: string) => {
  if (!botId) {
    return;
  }

  botIdsPermissionIsLoading.add(botId);

  try {
    const permissionsFragment =
      client.readFragment<CURRENT_ROLE_QUERY_bot_currentRole_permissions>({
        fragment: PERMISSIONS_FRAGMENT,
        id: `$Bot:${botId}.currentRole.permissions`,
      });
    const queryResult = permissionsFragment
      ? null
      : await client.query<CurrentRoleType>({
          query: CURRENT_ROLE_QUERY,
          variables: {
            botId,
          },
        });

    const permissions =
      permissionsFragment ?? queryResult?.data.bot.currentRole.permissions;
    if (!permissions) throw new Error('Permissions not requested');
    botIdToPermissionsMap.set(botId, transformData(permissions));
  } catch (error) {
    botIdsRulesUnsupported.add(botId);
    log.warn({ error, msg: 'Error on loading permissions' });
  } finally {
    botIdsPermissionIsLoading.delete(botId);
    triggerPermissionsUpdate();
  }
};

export const loadPermissionsMemoized = memoize(loadPermissionsInternal);

/*
 * Set global botId
 */
export const setCurrentGlobalBotId = (botId: string) => {
  const prevBotId = currentGlobalBotId;
  currentGlobalBotId = botId;
  if (prevBotId !== currentGlobalBotId) {
    triggerPermissionsUpdate();
  }
};

/*
  Simple comparator for permissions
 */
const hasEnoughPermission = (
  requestedPermission: Permission,
  allowedPermission: Permission,
) => {
  if (requestedPermission === Permission.VIEW) {
    return (
      allowedPermission === Permission.EDIT ||
      allowedPermission === Permission.VIEW
    );
  }

  if (requestedPermission === Permission.EDIT) {
    return allowedPermission === Permission.EDIT;
  }

  return false;
};

/*
  Check if user has permissions to default domain
 */
export const hasAccessToDefaultDomain = ({
  domain,
  requestedPermission,
  permissions,
}: CanDefaultDomainParams) => {
  if (typeof domain === 'undefined') return true; // for Billing tab pages, Upgrade page
  const { permission } = permissions[domain];
  return hasEnoughPermission(requestedPermission, permission as Permission);
};

const hasAccessToDefaultDomainMemoized = memoize(
  hasAccessToDefaultDomain,
  (params: { botId: string; domain: string; requestedPermission: string }) => {
    const { botId, domain, requestedPermission } = params;
    return botId + domain + requestedPermission;
  },
);

/*
  Check if user has permissions to groups
 */
const hasAccessToGroups = ({
  groupId,
  requestedPermission,
  permissions,
  accessType,
}: CanGroupParams) => {
  const { permission, whitelistedGroupIdsSet } = permissions.groups;
  const haveEnoughPermissions = hasEnoughPermission(
    requestedPermission,
    permission as Permission,
  );

  if (accessType === AccessType.FULL) {
    return haveEnoughPermissions && !whitelistedGroupIdsSet;
  }

  if (!groupId) {
    return haveEnoughPermissions;
  }

  if (haveEnoughPermissions && !whitelistedGroupIdsSet) {
    return true;
  }

  if (
    haveEnoughPermissions &&
    whitelistedGroupIdsSet &&
    whitelistedGroupIdsSet.has(groupId)
  ) {
    return true;
  }

  return false;
};

const hasAccessToGroupsMemoized = memoize(
  hasAccessToGroups,
  (params: {
    botId: string;
    domain: string;
    groupId: string;
    requestedPermission: string;
    accessType: string;
  }) => {
    const { botId, domain, groupId, requestedPermission, accessType } = params;
    return botId + domain + groupId + requestedPermission + accessType;
  },
);

/**
 * Check if current user can do something.
 * It uses memoized functions.
 */
export const can = (
  requestedPermission: Permission,
  domain: Domain,
  params: ExtraParams = {},
): boolean => {
  const GROUPS_DOMAIN_NAME = 'groups';

  const { groupId, accessType } = params;

  const botId = params.botId || currentGlobalBotId;

  if (botIdsRulesUnsupported.has(botId)) {
    return true;
  }

  const permissions = botIdToPermissionsMap.get(botId);

  if (!permissions) {
    loadPermissionsMemoized(botId);
    return false;
  }

  if (domain === GROUPS_DOMAIN_NAME) {
    return hasAccessToGroupsMemoized({
      botId,
      domain,
      groupId,
      accessType,
      requestedPermission,
      permissions,
    });
  }

  return hasAccessToDefaultDomainMemoized({
    botId,
    domain,
    requestedPermission,
    permissions,
  });
};

/**
 * Aliases for can
 */
export const canView = can.bind(null, Permission.VIEW);
export const canEdit = can.bind(null, Permission.EDIT);

const permissionEq = propEq('permission');

export const hasAnyAccess = (permissions: Partial<Permissions>) =>
  Object.values(permissions).some(
    anyPass([permissionEq(Permission.EDIT), permissionEq(Permission.VIEW)]),
  );
