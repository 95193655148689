export const DEFAULT_ATTRIBUTE_TYPE_CODE = 'custom';

export const ATTRIBUTE_TYPE_CODES = {
  custom: 'custom',
  system: 'system',
  segment: 'segment',
  sequence: 'sequence',
  block: 'block',
  tag: 'tag',
};

export const LOGIC_ITEMS = [
  {
    name: 'and',
    code: 'and',
  },
  {
    name: 'or',
    code: 'or',
  },
];

export const STRING_OPERATIONS_CODES = ['starts_with'];

export const MULTIVALUES_OPERATIONS_CODES = ['is', 'is_not', 'was', 'was_not'];

export const OPERATION_ATTRIBUTE_ITEMS = [
  {
    name: 'is not',
    code: 'is_not',
  },
  {
    name: 'is',
    code: 'is',
  },
  {
    name: 'starts with',
    code: 'starts_with',
  },
  {
    name: '>',
    code: 'gt',
  },
  {
    name: '<',
    code: 'lt',
  },
];

export const OPERATION_SEQUENCE_ITEMS = [
  {
    name: 'is not',
    code: 'is_not',
  },
  {
    name: 'is',
    code: 'is',
  },
  {
    name: 'was',
    code: 'was',
  },
  {
    name: 'was not',
    code: 'was_not',
  },
];

export const OPERATION_SEGMENT_ITEMS = [
  {
    name: 'is not',
    code: 'is_not',
  },
  {
    name: 'is',
    code: 'is',
  },
];

export const OPERATION_TAG_ITEMS = [
  {
    name: 'is not',
    code: 'is_not',
  },
  {
    name: 'is',
    code: 'is',
  },
];

export const OPERATION_BLOCK_IS_ITEMS = [
  {
    name: 'is not',
    code: 'is_not',
  },
  {
    name: 'is',
    code: 'is',
  },
];

export const OPERATION_BLOCK_ITEMS = [
  {
    name: 'attempted',
    code: 'attempted',
  },
  {
    name: 'sent',
    code: 'sent',
  },
  {
    name: 'seen',
    code: 'seen',
  },
  {
    name: 'clicked',
    code: 'clicked',
  },
  {
    name: 'failed',
    code: 'failed',
  },
  {
    name: 'blocked',
    code: 'blocked',
  },
];

export const DATE_ATTRIBUTES_MAP = {
  'signed up': true,
  'last seen': true,
  'blocked date': true,
};

export const ATTRIBUTES_TO_FORMAT_FROM_NOW = ['last seen'];

export const DEFAULT_OPERATION_CODES = {
  attribute: 'is',
  sequence: 'is',
  segment: 'is',
  block: 'is',
  tag: 'is',
};
