import { isNewPlugin } from '@components/Plugins/common/createPlugin';
import CardPluginBaseController from '../card-plugin-base-controller/controller';
import {
  addAttributeToGQLCaches,
  getAttributesAsync,
  getAttributesQueryObservable,
} from '../../../utils/AttributesUtils/AttributesUtils';
import { Platform } from '../../../../@types/globalTypes';
import { VariableSuggestType } from '../../../utils/AttributesUtils/AttributesUtilsTypes';

const INPUT_SELECTOR = '.svp-variables .cvp-input';

export default class CardRssController extends CardPluginBaseController {
  constructor(
    $scope,
    $rootScope,
    $interpolate,
    ModalService,
    BotService,
    BlockService,
    $sce,
    $timeout,
    $element,
    StoreService,
    PluginCardService,
    PeopleService,
  ) {
    'ngInject';

    super(
      $scope,
      $rootScope,
      $interpolate,
      ModalService,
      BotService,
      BlockService,
      $sce,
      $timeout,
      $element,
      StoreService,
      PluginCardService,
    );

    this.PeopleService = PeopleService;

    this.placeholderText =
      'To paste attributes, type {{name_of_the_attribute}}';

    this.attributes = [];
  }

  $onInit() {
    this.isNewArray = [];

    if (!this.item.id || isNewPlugin(this.item.id)) {
      this.save(true);
      this.isNewArray.push(0);

      this.$timeout(() => {
        const input = this.$element[0].querySelector(INPUT_SELECTOR);

        if (input) {
          input.focus();
        }
      }, 100);

      this.hasValidationError = false;
    } else {
      this.item.config.items.forEach((item) => {
        if (!('value' in item)) {
          item.value = null;
        }
      });

      this.validate();
    }

    this.attributesSubscription = getAttributesQueryObservable(
      this.$rootScope.stateParams.botId,
      VariableSuggestType.template,
      Platform.facebook,
    ).subscribe((allBotAttributesForSuggest) => {
      this.attributes = this.PeopleService.filterAndSortCustomFirstAttributes(
        allBotAttributesForSuggest,
      );
    });

    this.docLink =
      'https://docs.chatfuel.com/plugins/collect-user-data/set-user-attribute';
  }

  $onDestroy() {
    if (this.attributesSubscription) {
      this.attributesSubscription.unsubscribe();
    }

    if (this.listeners) {
      this.listeners.forEach((fn) => fn.call());
    }
  }

  addVar() {
    this.isNewArray.push(this.item.config.items.length);
    this.item.config.items.push({
      variable_name: '',
      value: '',
    });
    this.save();

    this.$timeout(() => {
      const inp = this.$element[0].querySelectorAll(INPUT_SELECTOR);

      if (inp.length) {
        inp[inp.length - 1].focus();
      }
    }, 100);
  }

  removeVar($index) {
    this.item.config.items.splice($index, 1);
    this.save();
  }

  validate() {
    this.item.is_valid = true;
    this.hasValidationError = false;

    this.item.config.items.every((item) => {
      if (!item.variable_name || item.value === '') {
        this.item.is_valid = false;
        this.hasValidationError = true;
        return false;
      }
      return true;
    });
  }

  save(noValidate) {
    if (!noValidate) {
      this.$timeout(() => {
        this.validate();
      });
    }
    this.$timeout.cancel(this.saveTimeout);
    this.saveTimeout = this.$timeout(() => {
      super.save();
    }, 1000);
  }

  async _loadValues(attributeName) {
    await getAttributesAsync(
      this.$rootScope.stateParams.botId,
      undefined,
      Platform.facebook,
    );
    const attr = this.attributes.find((item) => item.name === attributeName);
    if (attr) {
      const res = await this.BotService.getSuggestValuesAll(attr, null, false);
      if (window.angular.isArray(res)) {
        attr.values = res;
      }
    }
  }

  // eslint-disable-next-line consistent-return
  _getValues(attributeName) {
    if (this.attributes) {
      const attr = this.attributes.find((item) => item.name === attributeName);
      return attr ? attr.values : null;
    }
  }

  addAttributeToGQLCaches(attributeName) {
    addAttributeToGQLCaches(
      this.$rootScope.stateParams.botId,
      attributeName,
      Platform.facebook,
    );
  }
}
