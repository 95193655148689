/* eslint-disable no-return-assign */
import {
  uniq,
  prop,
  compose,
  map,
  pick,
  filter,
  flatten,
  pathOr,
  pipe,
  clone,
} from 'ramda';
import nanoid from 'nanoid';
import { stripTags } from '@utils/stripTags';
import { canEdit, canView } from '../../common/services/RoleService';
import { getBlocksGroupsObservable } from '../../modern-components/Aside/BlocksGroupsQueries';
import { sendEvent } from '../../utils/Analytics';
import { createSetupCustomerChatButton } from '../../pages/BotPage/HomeTab/components/SetUpCustomerChatButton/SetUpCustomerChatButton';

const blockHasActiveReferral = (block) =>
  block.referral_active && block.referral && block.referral.length;
// ref prams
const _getReferenceParametersFromGroups = compose(
  map(pick(['referral', 'title'])),
  filter(blockHasActiveReferral),
  flatten,
  map(prop('blocks')),
);

export default class GrowController {
  constructor(
    $window,
    $scope,
    $element,
    $rootScope,
    $timeout,
    $interpolate,
    $document,
    ClipboardService,
    ModalService,
    GrowService,
    UserService,
    BotService,
    PageService,
    NamingService,
    MonetizationService,
    BotGQService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$window = $window;
    this.$interpolate = $interpolate;
    this.$document = $document;
    this.$timeout = $timeout;
    this.$element = $element;
    this.ModalService = ModalService;
    this.GrowService = GrowService;
    this.UserService = UserService;
    this.BotService = BotService;
    this.PageService = PageService;
    this.NamingService = NamingService;
    this.RoleService = { canEdit, canView };
    this.MonetizationService = MonetizationService;
    this.BotGQService = BotGQService;
    this.copyTextToClipboard =
      ClipboardService.copyTextToClipboard.bind(ClipboardService);
    this.createSetupCustomerChatButton = createSetupCustomerChatButton;

    this.className = 'grow-wrapper settings';
    // reference parameters used but checkbox plugin and live chat plugin;
    this.refParameters = null;
    this.loadingRefParameters = false;

    $scope.RoleService = $rootScope.RoleService;
  }

  $onInit() {
    this.currentBotID = undefined;
    this.userData = null;
    this.rulesForComments = [];
    this.currentBotData = null;
    this.uiSortableParams = null;
    this.refreshBtnTitle = 'Refresh';
    this.proPopupVisible = false;
    this._assign();
    this.commentsAccessCheck();

    this.commentsAutoreplyPopupData = undefined;
    this.commentsAutoreplySwitchToFlowsPopupShow = false;

    this.blocksGroupsSubscription = getBlocksGroupsObservable(
      this.$rootScope.stateParams.botId,
    ).subscribe((groups) => {
      this.refParameters = _getReferenceParametersFromGroups(groups);
      this.loadingRefParameters = false;
    });
  }

  $onDestroy() {
    if (this.blocksGroupsSubscription) {
      this.blocksGroupsSubscription.unsubscribe();
    }
    if (this.subscriptionToRulesList) {
      this.subscriptionToRulesList.unsubscribe();
    }
  }

  _whiteListDomainForBot(domain, bot) {
    const { domains_whitelist: currentWhiteList } = bot;
    bot.domains_whitelist = uniq([...(currentWhiteList || []), domain]);
    this.BotService.domainWhitelist(bot.domains_whitelist).then(
      () => {
        // nothing?
      },
      (e) => {
        console.error('error during domain whitelist', e);
      },
    );
  }

  _assign() {
    this.currentBotID = this.$rootScope.stateParams.botId;

    this.BotGQService.getObservableForBot(this.currentBotID).subscribe(
      ({ data }) => {
        if (data) {
          this.$scope.$evalAsync(() => {
            this.currentBotData = data.bot;
          });
        }
      },
    );

    const extractRulesList = pipe(
      pathOr([], ['bot', 'commentsAutoreplyRules']),
      clone,
    );
    this.subscriptionToRulesList = this.GrowService.subscribeToRulesList(
      this.currentBotID,
      ({ data }) => {
        this.$scope.$evalAsync(() => {
          this.rulesForComments = extractRulesList(data);
          this.$timeout(() => {
            this.navigateToHash();
          });
          return undefined;
        });
      },
    );
    this.UserService.show().then((res) => {
      this.userData = res;
    });
    this.uiSortableParams = this.getSortableParams();
  }

  addRuleHandler() {
    sendEvent({
      category: 'acquire users from comments',
      action: 'add rule',
    });

    if (
      this.permissionCheck(
        this.currentBotData.status.status,
        this.userData.comments_access,
      )
    ) {
      this.addRule();
    }
  }

  duplicateRuleHandler(data) {
    sendEvent({
      category: 'customer chat plugin',
      action: 'copy',
    });
    this.duplicateRule(data);
  }

  deleteRuleHandler(id, name) {
    this.showDeleteConfirmModal(name).then((isConfirmed) => {
      if (isConfirmed) {
        if (this.rulesForComments.length === 1) {
          this.commentsAutoreplySwitchToFlowsPopupShow = true;
        }
        this.deleteRule(id);
      }
    });
  }

  editRuleHandler(data, position) {
    this.openRuleItemModal(data, position);
  }

  addRule() {
    this.openRuleItemModal(
      {
        id: `new_${nanoid()}`, // use prefix `new_` for mark new Rule (need for remove id before send data to backend)
        title: this.NamingService.getIncrementName(
          'Rule',
          this.rulesForComments.map(prop('title')),
        ),
        post_url: null,
        phrases: null,
        response: 'Hey, {{first name}}! How can I help you?',
      },
      0,
    );
  }

  deleteRule(id) {
    sendEvent({
      category: 'acquire users from comments',
      action: 'delete rule',
    });

    this.GrowService.remove(this.currentBotID, id);
  }

  duplicateRule(data) {
    const dataForCloning = clone(data);
    dataForCloning.id = `new_${nanoid()}`;
    this.openRuleItemModal(dataForCloning, 0);

    sendEvent({
      category: 'acquire users from comments',
      action: 'clone rule',
    });
  }

  showDeleteConfirmModal(name) {
    return this.ModalService.confirm(
      `Are you sure you want to delete ${stripTags(name)} rule?`,
    );
  }

  // eslint-disable-next-line consistent-return
  permissionCheck(botStatus, isTherePermission) {
    if (botStatus === 'draft') {
      this.ModalService.alert(
        `Please <a class="grow__link" href="/bot/${this.currentBotID}/settings/">connect your bot</a> to a page to create an autoreply rule`,
      );
    } else if (!isTherePermission) {
      this.GrowService.getPermissionLink(this.currentBotID).then((res) => {
        // eslint-disable-next-line chatfuel/no-use-localStorage
        window.localStorage.setItem('commentsAccess', true);
        window.location.assign(res);
      });
    } else {
      return true;
    }
    return false;
  }

  openRuleItemModal(rule, position) {
    this.commentsAutoreplyPopupData = {
      rule,
      position,
    };
  }

  findBotById(bots, id) {
    return bots.find((bot) => bot.id === id);
  }

  commentsAccessCheck() {
    // eslint-disable-next-line chatfuel/no-use-localStorage
    if (window.localStorage.getItem('commentsAccess')) {
      this.GrowService.permissionConfirm().then((result) => {
        if (result.data.result === 'ok') {
          this.userData.comments_access = true;
          // eslint-disable-next-line chatfuel/no-use-localStorage
          window.localStorage.removeItem('commentsAccess');
          this.addRule();
        }
      });
    }
  }

  changePosition(data, postion) {
    this.GrowService.save(this.currentBotID, data, postion);
  }

  getSortableParams() {
    return {
      handle: '.grow-rules-item__drag-button',
      axis: 'y',
      revert: 240,
      distance: 6,
      tolerance: 'pointer',
      animation: 280,
      forceHelperSize: true,
      update: (e, ui) =>
        this.changePosition(ui.item.sortable.model, ui.item.sortable.dropindex),
    };
  }

  clearSelection() {
    if (document.selection) {
      document.selection.empty();
    } else if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }
  }

  closeCommentsAutoreplyPopup = () => {
    this.$scope.$evalAsync(() => {
      this.commentsAutoreplyPopupData = undefined;
    });
  };

  closeCommentsAutoreplySwitchToFlowsPopup = () => {
    this.$scope.$evalAsync(() => {
      this.commentsAutoreplySwitchToFlowsPopupShow = false;
    });
  };

  onUpdateBotRequest = (bot) => {
    this.$rootScope.$emit('$updateBot', bot);
  };

  navigateToHash() {
    const { hash } = window.location;
    if (hash) {
      window.location.hash = '';
      window.location.hash = hash;
    }
  }
}
