import React from 'react';
import gql from 'graphql-tag';
import { Flex } from '../../../../modern-ui/Flex';
import { Permission } from '../../../../common/services/RoleService';
import { Button, ButtonIntent, ButtonSize } from '../../../../modern-ui/_deprecated/Button';
import { SegmentedGroupTrack } from '../../../../modern-ui/SegmentedGroup';
import { ROLE_QUERY_bot_currentRole_permissions as IPermissions } from './@types/ROLE_QUERY';
import * as css from './AccessSettings.css';
import { SegmentedGroupTrackColorWay } from '../../../../modern-ui/SegmentedGroup/SegmentedGroupTrack';

interface IPermission {
  __typename: 'Permission';
  permission: string;
}

interface IAccessSettingsProps {
  rolePermissions: IPermissions;
  onChange?: (rolePermissions: IPermissions) => void;
}

export const ROLE_QUERY = gql`
  query ROLE_QUERY($botId: String!) {
    bot(id: $botId) {
      id
      currentRole {
        permissions {
          inbox {
            permission
          }
          people {
            permission
          }
          broadcasting {
            permission
          }
          analyze {
            permission
          }
        }
      }
    }
  }
`;

const DOMAINS_AVAILABLE_PERMISSIONS = [
  {
    id: 'inbox',
    title: 'Live Chat',
    permissions: [Permission.FORBIDDEN, Permission.VIEW, Permission.EDIT],
  },
  {
    id: 'people',
    title: 'People',
    permissions: [Permission.FORBIDDEN, Permission.VIEW, Permission.EDIT],
  },
  {
    id: 'broadcasting',
    title: 'Reengage',
    permissions: [Permission.FORBIDDEN, Permission.VIEW, Permission.EDIT],
  },
  {
    id: 'analyze',
    title: 'Analyze',
    permissions: [Permission.FORBIDDEN, Permission.VIEW],
  },
];

const PERMISSIONS_NAMES = {
  [Permission.FORBIDDEN]: 'hidden',
  [Permission.VIEW]: 'can view',
  [Permission.EDIT]: 'can edit',
};

export const AccessSettings: React.FC<IAccessSettingsProps> = ({
  onChange,
  rolePermissions,
}) => {
  return (
    <div>
      {DOMAINS_AVAILABLE_PERMISSIONS.map((domain) => (
        <Flex
          data-testid={`admins__${domain.id}-permissions-line`}
          key={domain.id}
          alignItems="center"
          className={css.permissionsLine}
        >
          <div className={css.domainTitle}>{domain.title}</div>
          <SegmentedGroupTrack
            role="tablist"
            data-testid="admins__permission-list"
            className={css.permissions}
            colorWay={SegmentedGroupTrackColorWay.grey}
          >
            {domain.permissions.map((permission) => (
              <Button
                data-testid={`admins__${permission}-permission-button`}
                key={permission}
                role="tab"
                intent={ButtonIntent.toggle}
                size={ButtonSize.s}
                aria-selected={
                  permission ===
                  (rolePermissions[
                    domain.id as keyof IPermissions
                  ] as IPermission).permission
                }
                onClick={() => {
                  const updatedRolePermissions = {
                    ...rolePermissions,
                    [domain.id]: { permission },
                  } as IPermissions;
                  if (onChange) {
                    onChange(updatedRolePermissions);
                  }
                }}
              >
                {PERMISSIONS_NAMES[permission]}
              </Button>
            ))}
          </SegmentedGroupTrack>
        </Flex>
      ))}
    </div>
  );
};
