import ng from 'angular';
import controller from './controller';
import template from './fb-error-popup.html';
import './assets/less/fb-error-popup.less';

export default ng
  .module('app.components.fbErrorPopup', [])
  .component('fbErrorPopup', {
    controllerAs: 'vm',
    controller,
    template: () => template,
    bindings: {
      onRequestClose: '&',
    },
  }).name;
