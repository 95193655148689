import { find, propEq, curry } from 'ramda';

export const findByProp = curry((prop, value, list) =>
  find(propEq(prop, value), list || []),
);
export const findById = findByProp('id');
/**
 * @description: the way to go over array in cicle; for example  nextElement(1, 2,[1,3,4]) => 0;
 * @param: {number} - stepsInDirection - positive or negative number of steps to perform
 * @param: {number} - currentIndex - from what index to count;
 * @param: {array}  -  list
 */
export const nextIndex = curry(
  (stepsInDirection, currentIndex, list) =>
    (currentIndex + list.length + stepsInDirection) % list.length,
);
