import React from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Router } from 'react-router-dom';
import { globalHistory, useCurrentBotId } from '@utils/Routing';
import { ApolloProvider } from '@apollo/react-common';
import client from '@common/services/ApolloService';
import { Button } from '@ui/Button';
import {
  IntegrationContainer,
  IntegrationContainerAnchor,
} from '../IntegrationContainer';
import { HubSpotIntegrationStatus } from '@globals';
import { Anchor } from '@ui/Links';
import { useBotFeatures } from '@utils/Data/Bot';
import { ReactComponent as HubSpotLogo } from './HubSpotLogo.svg';
import { useHubSpotIntegrationStatus } from '@utils/Data/HubSpot/useHubSpotIntegrationStatus';
import { useDisconnectHubSpot } from '@utils/Data/HubSpot/useDisconnectHubSpot';
import { useHubSpotOauthUrl } from '@utils/Data/HubSpot/useHubSpotOauthUrl';
import { IntegrationContainerEnabledNote } from '../IntegrationContainerNote';

export const HubSpotIntegration: React.FC = () => {
  const botId = useCurrentBotId();
  const { botFeatures } = useBotFeatures(botId);
  const { t } = useSafeTranslation();
  const {
    data: hubSpotStatus,
    refetch,
    loading: statusLoading,
  } = useHubSpotIntegrationStatus();
  const { accountName, accountLink, status } =
    hubSpotStatus?.hubSpotIntegrationStatus || {};

  const [disconnectHubSpot, { loading: disconnectLoading }] =
    useDisconnectHubSpot({
      onCompleted() {
        refetch();
      },
    });

  const [getUrl, { data: hubSpotUrl }] = useHubSpotOauthUrl({
    onCompleted(result) {
      window.open(result.hubSpotOauthUrl);
    },
  });

  if (!status || !botFeatures?.hubspot) return null;

  return (
    <IntegrationContainer
      defaultExtended={status === HubSpotIntegrationStatus.none}
      loading={statusLoading}
      anchor={(extended) => (
        <IntegrationContainerAnchor
          extended={extended}
          imageView={<HubSpotLogo />}
          noteView={<IntegrationContainerEnabledNote />}
          isConnected={status === HubSpotIntegrationStatus.connected}
          disconnectButtonView={({ getButtonProps }) => (
            <Button
              {...getButtonProps({ onClick: () => disconnectHubSpot() })}
              loading={disconnectLoading || statusLoading}
            >
              {t('Integrations.disconnect')}
            </Button>
          )}
        />
      )}
    >
      {
        {
          [HubSpotIntegrationStatus.connected]: accountName && (
            <Type weight="medium">
              <Anchor href={accountLink ?? undefined} hideArrow noUnderline>
                {accountName}
              </Anchor>{' '}
              {t('HubSpot-connect.isConnected')}
            </Type>
          ),
          [HubSpotIntegrationStatus.none]: (
            <Button
              fullWidth
              onClick={() => {
                if (hubSpotUrl?.hubSpotOauthUrl)
                  window.open(hubSpotUrl.hubSpotOauthUrl);
                else getUrl();
              }}
            >
              {t('Integrations.connect')}
            </Button>
          ),
        }[status]
      }
    </IntegrationContainer>
  );
};

export const HubSpotIntegrationWrapper: React.FC = () => {
  return (
    <Router history={globalHistory}>
      <ApolloProvider client={client}>
        <HubSpotIntegration />
      </ApolloProvider>
    </Router>
  );
};
