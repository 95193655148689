import React from 'react';
import { ApolloProvider, Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Level, log } from 'cf-common/src/logger';
import client from '../../../common/services/ApolloService';
import { ReactComponent as DownloadIcon } from '../../../modern-ui/_deprecated/Icon/icons/download.svg';
import { sendEvent } from '../../../utils/Analytics';
import { redirect } from '../../../utils/UrlUtils';
import {
  GenerateBroadcastDocument,
  GenerateBroadcastDocumentVariables,
} from './@types/GenerateBroadcastDocument';
import * as s from './ExportBroadcast.css';

const GENERATE_BROADCAST_DOCUMENT = gql`
  mutation GenerateBroadcastDocument($botId: String!) {
    generateBroadcastExportDocument(botId: $botId) {
      generatedId
      downloadUrl
    }
  }
`;

export const ExportBroadcast: React.SFC<{ botId: string }> = ({ botId }) => {
  if (!botId) {
    log({
      msg: `ExportBroadcast: Expected bot id, instead got: ${botId}`,
      level: Level.warn,
    });
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <Mutation<GenerateBroadcastDocument, GenerateBroadcastDocumentVariables>
        mutation={GENERATE_BROADCAST_DOCUMENT}
        update={(_, { data }) => {
          if (!data) {
            throw new Error('Failed to receive download url');
          }
          const { downloadUrl } = data.generateBroadcastExportDocument;
          redirect(downloadUrl);
        }}
      >
        {(generateDocumentId, { loading }) => (
          <button
            className={s.unstyledButton}
            disabled={loading}
            onClick={() => {
              generateDocumentId({ variables: { botId } });
              sendEvent({
                category: 're-engage',
                action: 'download csv',
              });
            }}
          >
            <DownloadIcon style={{ verticalAlign: 'middle' }} />{' '}
            <span className={s.text}>download csv</span>
          </button>
        )}
      </Mutation>
    </ApolloProvider>
  );
};
