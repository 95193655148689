import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { getPluginName } from '../../pluginNames';

export default class CardAudioController extends CardPluginBaseController {
  $onInit() {
    this.pluginName = getPluginName('audio');
    this.loading = false;
    if (!this.item.id) {
      this.save();
    }
    if (this.item.is_valid === false) {
      this.data = this.item.validation_details || {};
      this.data.card_id = this.item.id;
      this.$rootScope.$broadcast('$pluginSaveResultError', { data: this.data });
    } else {
      this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
    }
  }

  isInValid() {
    if (!this.item.config || !this.item.config.url) {
      // cannot be sure it is invalid;
      return false;
    }
    return (
      this.item.config.url.length &&
      (!this.item.is_valid || this.hasValidationError)
    );
  }

  handleInputBlur() {
    this.loading = true;
    this.saveMediaUpload({ cardId: this.item.id }).then(() => {
      this.loading = false;
    });
  }
}
