import moment from 'moment';
import throttle from 'lodash-es/throttle';

export default class TimePickerController {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;

    this.watcher = this.$scope.$watch('vm.broadcast', (bc) => {
      if (bc.id) {
        this.init();
      }
    });
  }

  init() {
    this.throttleSaveTime = throttle(
      () => {
        this.broadcast.broadcast_start_timestamp = this.timestampTemp;
        this.timeChange();
      },
      1000,
      { leading: false },
    );

    if (this.timestamp) {
      this.bcTime = moment.utc(this.timestamp).local(true).valueOf();

      if (this.seconds !== undefined) {
        // need to be tested
        this.bcTime = moment
          .utc(this.timestamp)
          .local(true)
          .startOf('day')
          .add({ second: this.seconds })
          .valueOf();
      }
    } else {
      this.bcTime = moment()
        .startOf('day')
        .add({ second: this.seconds })
        .valueOf();
    }
  }

  $onDestroy() {
    this.watcher();
  }

  change() {
    if (this.bcTime) {
      const bcTimeMoment = moment(this.bcTime);
      if (this.seconds !== undefined) {
        this.seconds = moment
          .duration({
            hour: bcTimeMoment.hour(),
            minute: bcTimeMoment.minute(),
          })
          .asSeconds();

        if (this.timestamp !== undefined) {
          this.timestampTemp = moment(this.bcTime).utc(true);
        }
      } else {
        this.timestampTemp = moment(this.bcTime).utc(true);
      }
      this.throttleSaveTime();
    }
  }
}
