import React from 'react';
import { Router } from 'react-router-dom';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ApolloProvider } from '@apollo/react-common';
import { globalHistory } from '@utils/Routing';
import { sendEvent } from '@utils/Analytics';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { Modal } from '@services/index';
import { GlobalAttributeDialog } from '@components/GlobalAttributesDialog';
import client from '../../../common/services/ApolloService';

import * as css from './GlobalAttributes.css';

const GlobalAttributes: React.FC = () => {
  const { t } = useSafeTranslation();
  return (
    <>
      <div className="border-box">
        <Type size="24px" weight="light">
          {t('components.settings.GlobalAttributes.title')}
        </Type>

        <Spacer factor={5} />

        <div className={css.note}>
          {t('components.settings.GlobalAttributes.description')}
        </div>
        <Spacer factor={3} />

        <Button
          onClick={() => {
            sendEvent({
              category: 'global attributes',
              action: 'click manage list',
            });

            Modal.show(({ close }) => (
              <GlobalAttributeDialog onDismiss={close} />
            ))?.onClose(() => {
              sendEvent({
                category: 'global attributes',
                action: 'close popup',
              });
            });
          }}
          intent="secondary"
          data-testid="global-attributes__manage-list"
        >
          {t('components.settings.GlobalAttributes.button')}
        </Button>
      </div>
    </>
  );
};

export const GlobalAttributesWrapper: React.FC = () => {
  return (
    <Router history={globalHistory}>
      <ApolloProvider client={client}>
        <GlobalAttributes />
      </ApolloProvider>
    </Router>
  );
};
