import { COMMENTS_AUTOREPLY_QUERY } from '../../pages/GrowPage/CommentsAutoreply/commentsAutoreplyConsts';
import client from '../../common/services/ApolloService';
import {
  removeCommentsAutoreplyRule,
  saveCommentsAutoreplyRule,
  subscribeToRulesList,
} from '../../pages/GrowPage/CommentsAutoreply/commentsAutoreplyMutations';

export default ($http, StoreService) => {
  'ngInject';

  return {
    rulesList(botId) {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/${botId}/comment_subscriptions`,
      }).then((res) => res.data.result);
    },

    getPermissionLink(botId) {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/access_comments?bot=${botId}`,
      }).then((res) => res.data.result);
    },

    permissionConfirm() {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/confirm_comments`,
      });
    },

    remove(botId, commentsAutoreplyRuleId) {
      return removeCommentsAutoreplyRule(botId, commentsAutoreplyRuleId);
    },

    save(botId, commentsAutoreplyRule, position) {
      return saveCommentsAutoreplyRule(botId, commentsAutoreplyRule, position);
    },

    subscribeToRulesList(botId, callback) {
      subscribeToRulesList(botId, callback);
    },
  };
};
