import ng from 'angular';
import './assets/less/menu-subitem.less';

import { Component } from './component';

export default ng
  .module(
    'app.common.settings.persistentMenu.menuTree.menuTreeItem.menuSubitem',
    [],
  )
  .component('menuSubitem', Component).name;
