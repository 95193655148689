import {
  pipe,
  map,
  prop,
  contains,
  find,
  propEq,
  curry,
  lensProp,
  lensPath,
  reduce,
  ifElse,
  identity,
  and,
  converge,
  over,
  always,
  filter,
  complement,
  eqProps,
  reject,
  defaultTo,
} from 'ramda';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { SYNCED_SEGMENTS_QUERY } from '../../utils/MarketingApi/useFacebookSyncedSegments';
import { removeTypename } from '../../utils/GQL/utils';

const getNames = map(prop('name'));
const NEW_SEGMENT_NAME = 'New Segment';
const removed = prop('removed');
const rejectRemoved = reject(removed); // do i need to modify this?
const segmentToDatasource = ({ name }) => ({ value: name });
export const segmentsToDatasource = pipe(
  defaultTo([]),
  rejectRemoved,
  map(segmentToDatasource),
);
const findById = curry((id, list) => find(propEq('id', id), list));

class OneElementQueue {
  constructor(factory) {
    // function (T) => Promise<V>  create promises;
    this._factory = factory;
    // used to track last request in a queue; basicaly id of the last request byt we use Symbol for that;
    this._lastRequestSymbolForSynk = null;
    // promise< void | any> holds link on currently executed request (chained request in order);
    this._currentRequest = null;
  }

  // args => Promise<void>
  async queue(...args) {
    const syncSymbol = Symbol('OneElementQueue sync symbol');
    this._lastRequestSymbolForSynk = syncSymbol;
    const currentRequest = this._currentRequest || Promise.resolve();
    const errorHandler = (e) => {
      const isThisLastRequest = this._lastRequestSymbolForSynk === syncSymbol;
      console.error('gql error', isThisLastRequest, e);
      // it we must resolve promise for memory concerns
      // but we should  fire request or report error only for last one in the queue;
      if (isThisLastRequest) {
        // clear rejected promise chaine or next promise will be rejected;
        this._currentRequest = null;
      }

      return isThisLastRequest ? Promise.reject(e) : undefined;
    };
    const sucsessHandler = (res) => {
      const isThisLastRequest = this._lastRequestSymbolForSynk === syncSymbol;
      // it we must resolve promise for memory concerns
      // but we should  fire request or report error only for last one in the queue;
      return isThisLastRequest ? this._factory(...args) : undefined;
    };
    this._currentRequest = currentRequest.then(sucsessHandler, errorHandler);
    return this._currentRequest;
  }
}

function omitDeep(obj, key) {
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (Array.isArray(val)) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        newObj[i] = omitDeepArrayWalk(val, key);
      } else if (typeof val === 'object' && val !== null) {
        newObj[i] = omitDeep(val, key);
      } else {
        newObj[i] = val;
      }
    }
  });
  return newObj;
}

function omitDeepArrayWalk(arr, key) {
  return arr.map((val) => {
    if (Array.isArray(val)) {
      return omitDeepArrayWalk(val, key);
    }
    if (typeof val === 'object') {
      return omitDeep(val, key);
    }
    return val;
  });
}

export const deleteSegmentMutation = gql`
  mutation deleteSegment($botId: String!, $segmentId: String!) {
    deleteSegment(botId: $botId, segmentId: $segmentId) {
      id
    }
  }
`;

export const createSegmentsMutation = gql`
  mutation createSegment($botId: String!, $segment: SegmentInput!) {
    createSegment(botId: $botId, segment: $segment) {
      id
      name
      removed
      additional_ids
      segmentation {
        operation
        parameters {
          name
          values
          type
          operation
        }
      }
    }
  }
`;

export const updateSegmentMutation = gql`
  mutation updateSegmentName(
    $segmentId: String!
    $botId: String!
    $segment: SegmentInput!
  ) {
    updateSegment(botId: $botId, segment: $segment, segmentId: $segmentId) {
      id
    }
  }
`;

export const LoadSegmentsQuery = gql`
  query segments($botId: String!) {
    bot(id: $botId) {
      id
      title
      segments {
        id
        name
        removed
        additional_ids
        segmentation {
          operation
          parameters {
            name
            values
            type
            operation
          }
        }
      }
    }
  }
`;

export const useSegments = (botId) => {
  const { data, loading, refetch, error } = useQuery(LoadSegmentsQuery, {
    variables: { botId },
  });

  return {
    segments: data?.bot.segments.filter(({ removed }) => !removed) || [],
    loading,
    refetch,
    error,
  };
};

export default class SegmentService {
  constructor(
    NamingService,
    ApolloService,
    Segmentation,
    OneElementQueueService,
  ) {
    'ngInject';

    const { getIncrementName } = NamingService;

    /**
     *  Segments[] => string - returns new name for Segments;
     */
    this.generateNextNameForSegments = pipe(
      getNames,
      getIncrementName(NEW_SEGMENT_NAME),
    );
    this.generateNameDuringCloning = (segment, segments) =>
      getIncrementName(`${segment.name} copy`, getNames(segments));
    this.ApolloService = ApolloService;
    this.Segmentation = Segmentation;
    // for each segment we will create separeate queue

    this.queue = {};
    this.segmentsToDatasource = segmentsToDatasource;
  }

  /**
   *
   * we create a little save queue for each segment "save" or "mutate" segment in queue and error for each one;
   *
   */
  _getSavingQueueForSegment(segment) {
    this.queue[segment.id] =
      this.queue[segment.id] || new OneElementQueue(this.ApolloService.mutate);
    return this.queue[segment.id];
  }

  /**
   *
   * You need to have __typename to add object to apollo store so apollo store knows how to save segment;
   * also apollo has strict types so if prop is nullable it must  = null; this method also fix this;
   *
   */

  _addTypenameAndFixNullPropertiesForGQLSegment(segment) {
    const newSegment = {
      __typename: 'Segment',
      additional_ids: [],
      ...segment,
      segmentation: {
        __typename: 'Segmentation',
        ...segment.segmentation,
        parameters: segment.segmentation.parameters.map((param) => ({
          ...param,
          type: param.type ? param.type : null,
          name: param.name ? param.name : null,
          __typename: 'SegmentationParameter',
        })),
      },
    };
    return newSegment;
  }

  /**
   *
   * @description: note: you can rename segment to already removed segment... and this removed segment should become renamed segment;
   * not easy :) here you can find more details https://paper.dropbox.com/doc/People-tab-checklist-WFHogO8vVgcI437VzIAuP#:h2=%D0%9D%D1%8C%D1%8E%D0%B0%D0%BD%D1%81%D1%8B
   *
   */
  async updateSegment(botId, segment) {
    const {
      ApolloService,
      Segmentation: { removeParametersWithEmptyName, removeEmptyFromValues },
    } = this;
    const cleanSegments = pipe(
      removeParametersWithEmptyName,
      removeEmptyFromValues,
    );
    const newSegment =
      this._addTypenameAndFixNullPropertiesForGQLSegment(segment);

    // save segment to the store;
    const data = ApolloService.readQuery({
      query: LoadSegmentsQuery,
      variables: {
        botId,
      },
    });

    const segmentToUpdate = findById(segment.id, data.bot.segments);
    const segmentNameChanged = segmentToUpdate.name !== segment.name;
    if (segmentNameChanged) {
      // it can be that this segments will be rename to removed sements; so if there are removed segment with same name it will be replaced with this this segemnt;
      // do it locally to not refetch all segments;
      // we can refetch all segments but we don't want to block UI and user can change one of the segments during refetch and it will be overwrited by the refetch;

      const { segments } = data.bot;
      const segmentsMapById = reduce(
        (cache, item) => {
          cache[item.id] = item;
          return cache;
        },
        {},
        segments,
      );
      const eqlByNameToChangedSegment = eqProps('name', segment);

      // params => replaced params;
      const isSegmentType = propEq('type', 'segment');
      const segmentRemovedAndEqlByName = converge(and, [
        eqlByNameToChangedSegment,
        removed,
      ]);
      const replaceIfSameName = ifElse(
        segmentRemovedAndEqlByName,
        always(segment),
        identity,
      );
      const mapToId = prop('id');
      const valuesToSegment = (value) =>
        segmentsMapById[value] || { id: value }; // not existed segment;;

      const replaceValuesWithNewSegment = pipe(
        valuesToSegment,
        replaceIfSameName,
        mapToId,
      );
      const replaceEachValue = over(
        lensProp('values'),
        map(replaceValuesWithNewSegment),
      );
      const replaceSegmentsInParams = ifElse(
        isSegmentType,
        replaceEachValue,
        identity,
      );
      const onlyForNotRemovedSegments = ifElse(
        removed,
        identity,
        over(
          lensPath(['segmentation', 'parameters']),
          map(replaceSegmentsInParams),
        ),
      );
      const replaceInEachSegment = map(onlyForNotRemovedSegments);
      data.bot.segments = pipe(
        replaceInEachSegment,
        filter(complement(segmentRemovedAndEqlByName)),
      )(data.bot.segments);
    }

    data.bot.segments = data.bot.segments.map((_segment) =>
      _segment.id === segment.id ? newSegment : _segment,
    ); // this is strange need to look at it by fresh head;
    this.ApolloService.writeQuery({
      query: LoadSegmentsQuery,
      variables: {
        botId,
      },
      data,
    });

    const segmentToSave = {
      ...segment,
      segmentation: cleanSegments(segment.segmentation),
    };
    delete segmentToSave.id;

    const mutateOptions = {
      mutation: updateSegmentMutation,
      variables: {
        botId,
        segment: segmentToSave,
        segmentId: segment.id,
      },
    };

    const queue = this._getSavingQueueForSegment(segment);
    return queue.queue(mutateOptions);
  }

  // ( (data:{segments})=> void  )=> Subscription
  subscribeForSegments(botId, callback) {
    return this.ApolloService.watchQuery({
      query: LoadSegmentsQuery,
      variables: {
        botId,
      },
    }).subscribe(({ data: { bot } }) => {
      const _bot = omitDeep(bot, '__typename');
      callback(_bot.segments);
    });
  }

  async createNewSegment(botId, segment) {
    segment.additional_ids = [];
    return this.ApolloService.mutate({
      mutation: createSegmentsMutation,
      variables: {
        botId,
        segment: removeTypename(segment),
      },
      refetchQueries: [
        { query: SYNCED_SEGMENTS_QUERY, variables: { botId } },
        {
          query: LoadSegmentsQuery,
          variables: { botId },
        },
      ],
      optimisticResponse: {
        createSegment: {
          __typename: 'Segment',
          additional_ids: [],
          ...segment,
          removed: false,
          id: String(Math.random()), // fixme save global id generator ??
          segmentation: {
            __typename: 'Segmentation',
            ...segment.segmentation,
            parameters: segment.segmentation.parameters.map((param) => ({
              ...param,
              __typename: 'SegmentationParameter',
            })),
          },
        },
      },
      update: (store, { data: { createSegment } }) => {
        // Read the data from the cache for this query.
        const data = store.readQuery({
          query: LoadSegmentsQuery,
          variables: {
            botId,
          },
        });
        data.bot.segments.push(createSegment);
        // Write the data back to the cache.
        store.writeQuery({
          query: LoadSegmentsQuery,
          variables: {
            botId,
          },
          data,
        });
      },
    });
  }

  async deleteSegment(botId, segment, segmentIndex) {
    await this.ApolloService.mutate({
      mutation: deleteSegmentMutation,
      variables: {
        botId,
        segmentId: segment.id,
      },
      refetchQueries: [
        { query: SYNCED_SEGMENTS_QUERY, variables: { botId } },
        {
          query: LoadSegmentsQuery,
          variables: { botId },
        },
      ],
      optimisticResponse: {
        deleteSegment: {
          __typename: 'Segment',
          additional_ids: [],
          ...segment,
        },
      },
      update: (store, { data: { deleteSegment } }) => {
        // Read the data from the cache for this query.
        const data = store.readQuery({
          query: LoadSegmentsQuery,
          variables: {
            botId,
          },
        });
        const mutateSegment = findById(segment.id, data.bot.segments);
        if (mutateSegment) {
          mutateSegment.removed = true;
        }

        // Write the data back to the cache.
        store.writeQuery({
          query: LoadSegmentsQuery,
          variables: {
            botId,
          },
          data,
        });
      },
    });
  }

  isSegmentNameIsValidForSegments(name, segments) {
    // check for name uniqueness;
    const nameArray = getNames(segments);
    const nameContaine = contains(name, nameArray);
    return nameContaine;
  }
}
