/*
 * provide helper to simplify naming of the UI element when creating new Blocks,Segments etc;
 *
 */

import {
  curry,
  filter,
  identity,
  startsWith,
  last,
  split,
  pipe,
  map,
  max,
  reduce,
  inc,
  not,
  toString,
} from 'ramda';

const filterEmpty = filter(identity);

export const getIncrementName = (template, arrayOfNames) => {
  const token = template.toLocaleLowerCase();
  const forEachToLowerCase = map((str) => str.toLocaleLowerCase());
  const splitByToken = split(token);
  const isNotNaN = pipe(Number.isNaN, not);
  const filterNameThatHasOneOccurrenceOfToken = filter(
    (name) => splitByToken(name).length === 2,
  );
  const filterNamesThatHasTokenAtStart = filter(startsWith(token));
  const forEachTakeLastPartAfterToken = map(pipe(splitByToken, last));
  const forEachParseInt = map((string) => parseInt(string, 10));
  const filterNaN = filter(isNotNaN);
  const getMaxNumberInArrayOr1 = reduce(max, 1);
  const contactToToken = (valueToConcat) => `${template} ${valueToConcat}`;

  const filteredNames = pipe(
    filterEmpty,
    forEachToLowerCase,
    filterNameThatHasOneOccurrenceOfToken,
    filterNamesThatHasTokenAtStart,
  )(arrayOfNames);

  if (filteredNames.length === 0) {
    return template;
  }

  return pipe(
    forEachTakeLastPartAfterToken,
    forEachParseInt,
    filterNaN,
    getMaxNumberInArrayOr1,
    inc,
    toString,
    contactToToken,
  )(filteredNames);
};

export default class NamingService {
  constructor() {
    this.getIncrementName = curry(this.getIncrementName);
  }
  /**
   * get massive of strings and token and return unique name within strings starting with token. if array contains token. it will autoicrement it;
   */
  // (token: string, names:string[]) => string;
  getIncrementName(token, arrayOfNames) {
    return getIncrementName(token, arrayOfNames);
  }
}
