import ng from 'angular';
import './assets/less/menu-tree.less';

import menuTreeItem from './menu-tree-item';

import { Component } from './component';

export default ng
  .module('app.common.settings.persistentMenu.menuTree', [menuTreeItem])
  .component('menuTree', Component).name;
