import { PluginType } from '@components/FlowBuilder/types';
import i18next from 'i18next';
import memoize from 'lodash-es/memoize';

type PluginNames = {
  [pluginId: string]: string;
};

const autopostingViaZapier = () =>
  i18next.t('common.pluginNames.autopostingViaZapier');
const importContentViaZapier = () =>
  i18next.t('common.pluginNames.importViaZapier');

const pluginNames: () => PluginNames = memoize(() => ({
  // TODO: use this map in Automate tab plugin list
  // TODO: this is an incomplete list
  text: i18next.t('common.pluginNames.text'),
  go_to_block_plugin: i18next.t('common.pluginNames.go_to_block_plugin'),
  image: i18next.t('common.pluginNames.image'),
  video: i18next.t('common.pluginNames.video'),
  audio: i18next.t('common.pluginNames.audio'),
  chatroom: i18next.t('common.pluginNames.chatroom'),
  list: i18next.t('common.pluginNames.list'),
  comment: i18next.t('common.pluginNames.comment'),
  email: i18next.t('common.pluginNames.email'),
  google_sheet: i18next.t('common.pluginNames.google_sheet'),
  handover: i18next.t('common.pluginNames.handover'),
  add_to_sequence: i18next.t('common.pluginNames.add_to_sequence'),
  remove_from_sequence: i18next.t('common.pluginNames.remove_from_sequence'),
  collect_phone: i18next.t('common.pluginNames.collect_phone'),
  collect_email: i18next.t('common.pluginNames.collect_email'),
  gallery: i18next.t('common.pluginNames.gallery'),
  json_plugin: i18next.t('common.pluginNames.json_plugin'),
  form_v2: i18next.t('common.pluginNames.form_v2'),
  communication: i18next.t('common.pluginNames.communication'),
  location_quick_reply_plugin: i18next.t(
    'common.pluginNames.location_quick_reply_plugin',
  ),
  setup_variable_plugin: i18next.t('common.pluginNames.setup_variable_plugin'),
  'search/google_plugin': i18next.t('common.pluginNames.search_google_plugin'),
  'search/bing_plugin': i18next.t('common.pluginNames.search_bing_plugin'),
  'search/swiftype_plugin': i18next.t(
    'common.pluginNames.search_swiftype_plugin',
  ),
  'search/rss_plugin': i18next.t('common.pluginNames.search_rss_plugin'),
  subscribe_user_plugin: i18next.t('common.pluginNames.subscribe_user_plugin'),
  subscriptions_manager_plugin: i18next.t(
    'common.pluginNames.subscriptions_manager_plugin',
  ),
  subscription_broadcasting_plugin: i18next.t(
    'common.pluginNames.subscription_broadcasting_plugin',
  ),
  'integration/ifttt_instagram_plugin': importContentViaZapier(),
  'integration/ifttt_twitter_plugin': importContentViaZapier(),
  'integration/ifttt_youtube_plugin': importContentViaZapier(),
  'integration/zapier_plugin': importContentViaZapier(),
  'integration/zapier_rss_plugin': importContentViaZapier(),
  'integration/zapier_youtube_plugin': importContentViaZapier(),
  'integration/zapier_sheets_plugin': importContentViaZapier(),
  'integration/zapier_calendar_plugin': importContentViaZapier(),
  'integration/zapier_wordpress_plugin': importContentViaZapier(),
  'integration/zapier_slack_plugin': importContentViaZapier(),
  'integration/zapier_twitter_plugin': importContentViaZapier(),
  'integration/zapier_instagram_plugin': importContentViaZapier(),
  'integration/zapier_vimeo_plugin': importContentViaZapier(),
  'autoposting/ifttt_plugin': autopostingViaZapier(),
  'autoposting/ifttt_instagram_plugin': autopostingViaZapier(),
  'autoposting/ifttt_twitter_plugin': autopostingViaZapier(),
  'autoposting/ifttt_youtube_plugin': autopostingViaZapier(),
  'autoposting/zapier_plugin': autopostingViaZapier(),
  'autoposting/zapier_rss_plugin': autopostingViaZapier(),
  'autoposting/zapier_youtube_plugin': autopostingViaZapier(),
  'autoposting/zapier_sheets_plugin': autopostingViaZapier(),
  'autoposting/zapier_calendar_plugin': autopostingViaZapier(),
  'autoposting/zapier_wordpress_plugin': autopostingViaZapier(),
  'autoposting/zapier_slack_plugin': autopostingViaZapier(),
  'autoposting/zapier_twitter_plugin': autopostingViaZapier(),
  'autoposting/zapier_instagram_plugin': autopostingViaZapier(),
  'autoposting/zapier_vimeo_plugin': autopostingViaZapier(),
  event_trigger: i18next.t('common.pluginNames.event_trigger'),
}));

export function getPluginName(pluginId: string) {
  const pluginNamesDict = pluginNames();
  if (pluginId in pluginNamesDict) {
    return pluginNamesDict[pluginId];
  }

  if (window.flowBuilderModule.hasPlugin(pluginId as PluginType)) {
    return window.flowBuilderModule
      .getPlugin(pluginId as PluginType)
      .getPluginDisplayName();
  }

  return '';
}
