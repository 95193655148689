import escapeStringRegexp from 'escape-string-regexp';
import { filterXSS } from 'xss';
import { escape } from '../../utils/escape';
import { getAttributesQueryObservable } from '../../utils/AttributesUtils/AttributesUtils';
import { Platform } from '../../../@types/globalTypes';
import { VariableSuggestType } from '../../utils/AttributesUtils/AttributesUtilsTypes';

export default ($timeout, $compile, $rootScope, PeopleService) => {
  'ngInject';

  return (scope, element) => {
    let input = element.find('input');

    if (!input.length) {
      input = element.find('textarea');
    }

    const wrapMask = window.angular.element('<div class="wrap-mask"></div>');
    const mask = window.angular.element('<div class="mask"></div>');
    const box = window.angular.element('<ul class="auto-box"></ul>');
    const rcol = window.angular.element(document.querySelector('.rcol'));
    const reg = new RegExp('\\{\\{([^{}]+?)\\}\\}', 'gi');
    const sk = { l: '{{', r: '}}' };

    let botParamsCache = [];
    let currentSubstring = '';
    let currentParam = '';
    let currentNTagParam = 0;
    let caretPosition = 0;
    let popupShowed = false;
    let posInterval = 0;

    let mouseOnBox = false;

    const showPopup = (query) => {
      currentNTagParam = 0;
      currentSubstring = query;
      query = query.toLowerCase();

      let botParams = [];

      if (query === '') {
        botParams = botParamsCache;
      } else {
        botParamsCache.forEach((item) => {
          if (item.toLowerCase().indexOf(query) !== -1) {
            botParams.push(item);
          }
        });
      }

      if (botParams.length === 0) {
        return;
      }

      //      botParams = botParams.sort();

      const marker = mask.find('b');
      if (marker) {
        box.css('left', marker.prop('offsetLeft') - input.scrollLeft());

        let out = '';
        botParams.forEach((item, i) => {
          if (i === 0) {
            currentParam = item;
            out += `<li class="act" system-attribute system-attribute-text="${escape(
              item,
            )}"><span>${escape(item)}</span></li>`;
          } else {
            out += `<li system-attribute system-attribute-text="${escape(
              item,
            )}"><span>${escape(item)}</span></li>`;
          }
        });
        const list = $compile(window.angular.element(filterXSS(out)))(scope);

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        revmoveTT();

        box.html(list);
      }

      if (!popupShowed) {
        popupShowed = true;
        box.addClass('act');
      }

      if (popupShowed && mouseOnBox !== false) {
        $timeout(() => {
          window.angular
            .element(box.find('li')[mouseOnBox])
            .trigger('mouseenter');
        });
      }
    };

    const hidePopup = () => {
      if (popupShowed) {
        popupShowed = false;
        box.removeClass('act');
        mouseOnBox = false;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        revmoveTT();
      }
    };

    const revmoveTT = () => {
      [].forEach.call(document.querySelectorAll('.system-tooltip'), (tt) => {
        tt.remove();
      });
    };

    const addParam = () => {
      if (currentParam) {
        const dividPos =
          input.val().substring(0, caretPosition).lastIndexOf(sk.l) + 2;
        input.val(
          input.val().substring(0, dividPos) +
            input
              .val()
              .substring(dividPos, input.val().length)
              .replace(currentSubstring, currentParam + sk.r)
              .replace(
                new RegExp(
                  `${escapeStringRegexp(currentParam)}\\}\\}[^}]+?\\}\\}`,
                  'ig',
                ),
                currentParam + sk.r,
              ),
        );
        input.trigger('input');
        currentParam = '';
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        render();
      }
    };

    const htmlspecialchars = (str) => {
      if (typeof str === 'string') {
        str = filterXSS(str);
        str = str.replace(/&/g, 'U');
        str = str.replace(/"/g, '"');
        str = str.replace(/'/g, "'");
        str = str.replace(/</g, '1');
        str = str.replace(/>/g, '1');
      }
      return str;
    };

    const showAllSpan = () => {
      const spans = mask.find('span');
      window.angular.forEach(spans, (span) => {
        window.angular.element(span).attr('style', '');
      });
    };

    const render = (event) => {
      const val = htmlspecialchars(input.val());
      let maskVal = val;
      caretPosition = input[0].selectionStart;
      if (!event || event.type !== 'keydown') {
        if (
          val.lastIndexOf(sk.l, caretPosition - 2) !== -1 &&
          (val.lastIndexOf(sk.r, caretPosition - 1) === -1 ||
            val.lastIndexOf(sk.r, caretPosition - 1) <
              val.lastIndexOf(sk.l, caretPosition - 2))
        ) {
          const substr = val.substring(
            val.lastIndexOf(sk.l, caretPosition - 2) + 2,
            caretPosition,
          );
          if (substr.indexOf('{') === -1 && substr.indexOf('}') === -1) {
            const dividPos = input
              .val()
              .substring(0, caretPosition)
              .lastIndexOf(sk.l);
            maskVal = `${maskVal.substring(
              0,
              dividPos,
            )}<b></b>${maskVal.substring(dividPos, maskVal.length)}`;
            mask.html(maskVal);
            showPopup(substr);
          } else {
            hidePopup();
          }
        } else {
          hidePopup();
        }
      }

      revmoveTT();

      maskVal = maskVal.replace(
        reg,
        '<span system-attribute system-attribute-text="$1" system-attribute-top="true">$1</span>',
      );
      maskVal = maskVal.replace(/([{}])/g, '<x>$1</x>');

      mask.html(`${maskVal}&nbsp;`);
      $compile(mask)(scope);

      if (caretPosition) {
        if (
          val.lastIndexOf(sk.l, caretPosition) !== -1 &&
          (val.lastIndexOf(sk.r, caretPosition - 2) === -1 ||
            val.lastIndexOf(sk.r, caretPosition - 2) <
              val.lastIndexOf(sk.l, caretPosition)) &&
          val.indexOf(sk.r, caretPosition - 1) !== -1 &&
          (val.indexOf(sk.l, caretPosition + 1) === -1 ||
            val.indexOf(sk.r, caretPosition - 1) <
              val.indexOf(sk.l, caretPosition + 1))
        ) {
          let pos = val.substring(0, caretPosition - 1).match(reg);
          pos = pos ? pos.length : 0;
          showAllSpan();

          const aEl = window.angular.element(mask.find('span')[pos]);
          aEl.css('opacity', '0');
          aEl.trigger('mouseleave');
          revmoveTT();
        }
      }
      mask.css('transform', `translateX(-${input.scrollLeft()}px)`);
    };

    const attributesSubscription = getAttributesQueryObservable(
      $rootScope.stateParams.botId,
      VariableSuggestType.template,
      Platform.facebook,
    ).subscribe((allBotAttributesForSuggest) => {
      botParamsCache = PeopleService.filterAndSortCustomFirstAttributes(
        allBotAttributesForSuggest,
      ).map((attr) => attr.name);
    });

    wrapMask.append(mask);
    element.append(wrapMask);
    element.append(box);

    $timeout(() => {
      render();
    });

    box.on('mousedown', (event) => {
      if (event.target.tagName.toLowerCase() === 'li') {
        currentParam = event.target.firstChild.innerText;
        addParam();
        hidePopup();
      }
      if (event.target.tagName.toLowerCase() === 'span') {
        currentParam = event.target.innerText;
        addParam();
        hidePopup();
      }
    });

    input.on('keyup keydown mouseup', (event) => {
      if ([13, 38, 40].indexOf(event.keyCode) !== -1 && popupShowed) {
        event.preventDefault();
        if (event.type === 'keyup') {
          return;
        }
        switch (event.keyCode) {
          case 13:
            addParam();
            hidePopup();
            return;
          case 38:
          case 40:
            // eslint-disable-next-line no-case-declarations
            const lis = box.find('li');
            currentNTagParam += event.keyCode === 38 ? -1 : 1;
            if (currentNTagParam < 0) {
              currentNTagParam = 0;
            } else if (currentNTagParam >= lis.length) {
              currentNTagParam = lis.length - 1;
            }
            window.angular.forEach(lis, (li, i) => {
              const el = window.angular.element(li);
              if (i === currentNTagParam) {
                el.addClass('act');
                currentParam = el.text();
              } else {
                el.removeClass('act');
              }
            });
            return;
          default:
        }
      }
      render(event);
    });

    input.on('focus', () => {
      clearInterval(posInterval);
      posInterval = setInterval(() => {
        if (mask) {
          mask.css('transform', `translateX(-${input.scrollLeft()}px)`);
        }
      }, 50);
    });

    input.on('blur', (event) => {
      clearInterval(posInterval);
      $timeout(() => {
        if (input[0] !== document.activeElement) {
          caretPosition = null;
        }
        render(event);
        showAllSpan();
      });
    });

    input.on('scroll selectionchange', () => {
      if (mask) {
        mask.css('transform', `translateX(-${input.scrollLeft()}px)`);
      }
    });

    const listeners = [];

    $timeout(() => {
      render();
    });

    listeners.push(
      scope.$on('$angularClick', () => {
        scope.$apply(() => {
          hidePopup();
        });
      }),
    );

    box.on('mouseover', (event) => {
      if (event.target.tagName.toLowerCase() === 'li') {
        mouseOnBox = window.angular.element(event.target).index();
      }
    });
    box.on('mouseleave', (event) => {
      if (event.target.tagName.toLowerCase() === 'li') {
        mouseOnBox = false;
      }
    });

    let mouseenterElement = null;

    element.on('mousemove', (event) => {
      let hover = false;

      window.angular.forEach(mask.find('span'), (el) => {
        const rect = el.getBoundingClientRect();
        const aEl = window.angular.element(el);
        if (
          event.clientX > rect.left &&
          event.clientX < rect.right &&
          event.clientY > rect.top &&
          event.clientY < rect.bottom &&
          aEl.css('opacity') > 0
        ) {
          hover = true;
          if (mouseenterElement !== el) {
            aEl.trigger('mouseenter');
            mouseenterElement = el;
          }
        }
      });

      if (!hover) {
        if (mouseenterElement) {
          window.angular.element(mouseenterElement).trigger('mouseleave');
          mouseenterElement = null;
        }
      }
    });

    rcol.on('scroll', () => {
      if (mouseenterElement) {
        window.angular.element(mouseenterElement).trigger('mouseleave');
        mouseenterElement = null;
      }
    });

    scope.$on('$destroy', () => {
      element.off('mousemove');
      rcol.off('scroll');
      input.off('keyup keydown mouseup');
      input.off('blur');
      input.off('scroll selectionchange');
      box.off('click');
      box.off('mouseover');
      box.off('mouseleave');
      listeners.forEach((fn) => fn.call());
      attributesSubscription.unsubscribe();
    });
  };
};
