import template from './persistent-menu.html';
import controller from './controller.js';

export const Component = {
  controller,
  template: () => template,
  controllerAs: 'vmp',
  bindings: {
    bot: '=',
  },
};
