import { find, propEq, curry } from 'ramda';
import { Observable } from 'rxjs/Observable';
import ApolloService from './ApolloService';

import 'rxjs/add/observable/from';

import {
  BOTS_LIST_QUERY,
  UPDATE_BOT_TITLE_MUTATION,
  CREATE_NEW_BOT_MUTATION,
  CREATE_NEW_BOT_BY_TEMPLATE_MUTATION,
  CLONE_BOT_MUTATION,
  CREATE_NEW_BLANK_BOT_MUTATION,
  SET_BOT_LAST_OPENED_DATE,
  FULL_NATIVE_BOT_QUERY,
} from './GQLqueries/BotGQService.const';
import { sendEvent } from '../../utils/Analytics';

// TODO Move to collection servise ???
const findById = curry((id, list) => find(propEq('id', id), list));

const BLANK_BOT_NAME = 'Untitled';

/**
 *  Bot service (used GraphQL)
 */
export default class BotGQService {
  /**
   * @description -
   * @param {*} NamingService -
   * @param {*} BotService -
   */
  constructor(NamingService, BotService) {
    'ngInject';

    this.getIncrementName = NamingService.getIncrementName;
    this.ApolloService = ApolloService;
    this.BotService = BotService;
  }

  /**
   * @description Get Observable on bots data
   * @param {function([Object])} _callback -
   * @return {Observable} -
   */
  getObservableForBots() {
    return Observable.from(
      this.ApolloService.watchQuery({
        query: BOTS_LIST_QUERY,
      }),
    );
  }

  getObservableForBot(botId) {
    return Observable.from(
      this.ApolloService.watchQuery({
        query: FULL_NATIVE_BOT_QUERY,
        variables: {
          botId,
        },
      }),
    );
  }

  /**
   * @description Reload bot list cache
   */
  async reloadBotListCache() {
    await this.ApolloService.query({
      query: BOTS_LIST_QUERY,
      fetchPolicy: 'network-only',
    });
  }

  /**
   * @description update bot title
   * @param {String} botId -
   * @param {String} title -
   */
  async updateBotTitle(botId, title) {
    sendEvent({
      category: 'bot',
      action: 'rename',
      propertyBag: {
        id: botId,
      },
    });
    await this.ApolloService.mutate({
      mutation: UPDATE_BOT_TITLE_MUTATION,
      variables: {
        botId,
        title,
      },
      optimisticResponse: {
        updateBotTitle: true,
      },
      update: (store, { data: { updateBotTitle } }) => {
        if (updateBotTitle) {
          const data = store.readQuery({
            query: BOTS_LIST_QUERY,
          });
          const mutateBot = findById(botId, data.bots);
          mutateBot.title = title;
          store.writeQuery({
            query: BOTS_LIST_QUERY,
            data,
          });
        }
      },
    });
    this._reloadBotsListOldCache();
  }

  /**
   * @description create bot
   * @param {String} title -
   * @return {Promise<Object>} -
   */
  async createBot(title) {
    sendEvent({
      category: 'bot',
      action: 'add',
    });
    const res = await this.ApolloService.mutate({
      mutation: CREATE_NEW_BOT_MUTATION,
      variables: {
        title,
      },
      refetchQueries: [
        {
          query: BOTS_LIST_QUERY,
        },
      ],
    });
    this._reloadBotsListOldCache();
    return res;
  }

  /**
   * @description clone bot
   * @param {String} id -
   * @param {String} idFrom -
   * @param {String} title -
   * @return {Promise<Object>} -
   */
  async cloneBot(id, idFrom, title) {
    sendEvent({
      category: 'bot',
      action: 'copy',
      propertyBag: {
        id,
      },
    });
    const res = await this.ApolloService.mutate({
      mutation: CLONE_BOT_MUTATION,
      variables: {
        id,
        idFrom,
        title,
      },
      refetchQueries: [
        {
          query: BOTS_LIST_QUERY,
        },
      ],
    });
    this._reloadBotsListOldCache();
    return res;
  }

  /**
   * @description Create bot from source bot
   * @param {String} title -
   * @param {String} idFrom -
   * @return {Promise<Object>} -
   */
  async createBotFromSourceBot(title, idFrom) {
    sendEvent({
      category: 'bot',
      action: 'add from template',
      propertyBag: {
        idFrom,
      },
    });
    return this.ApolloService.mutate({
      mutation: CREATE_NEW_BOT_BY_TEMPLATE_MUTATION,
      variables: {
        title,
        idFrom,
      },
      refetchQueries: [
        {
          query: BOTS_LIST_QUERY,
        },
      ],
      update: (store, { data: { createBotByTemplate: newBot } }) => {
        this._updateBotListQuery(store, newBot);
      },
    });
  }

  /**
   * @description Create and clone bot (with uniq name)
   * @param {String} idFrom -
   * @param {String} token -
   * @param {[String]} arrayOfNames -
   */
  async createBotFromSourceBotWithIncrementedName(idFrom, token, arrayOfNames) {
    const newBotName = this.getIncrementName(token, arrayOfNames);
    await this.createBotFromSourceBot(newBotName, idFrom);
  }

  /**
   * @description Create blank bot (with uniq name)
   * @param  {[String]} arrayOfNames -
   */
  async createBlankBotWithIncrementedName(arrayOfNames) {
    const newBlankBotName = this.getIncrementName(BLANK_BOT_NAME, arrayOfNames);
    sendEvent({
      category: 'bot',
      action: 'add',
    });
    await this.ApolloService.mutate({
      mutation: CREATE_NEW_BLANK_BOT_MUTATION,
      variables: {
        title: newBlankBotName,
      },
      refetchQueries: [
        {
          query: BOTS_LIST_QUERY,
        },
      ],
      update: (store, { data: { createBlankBot: newBot } }) => {
        this._updateBotListQuery(store, newBot);
      },
    });
    this._reloadBotsListOldCache();
  }

  async setBotLastOpenedDate(botId) {
    await this.ApolloService.mutate({
      mutation: SET_BOT_LAST_OPENED_DATE,
      variables: {
        botId,
      },
    });
  }

  /**
   * @description Update GQL cache for BOTS_LIST_QUERY
   * @param {Object} store -
   * @param {Object} newBot - created bot
   * @private
   */
  _updateBotListQuery(store, newBot) {
    if (newBot) {
      const data = store.readQuery({
        query: BOTS_LIST_QUERY,
      });
      newBot.pro = {
        __typename: 'Pro',
        status: '',
        manual: false,
        debt: 0,
        days_before_cancel: 0,
      };
      data.bots.unshift(newBot);
      store.writeQuery({
        query: BOTS_LIST_QUERY,
        data,
      });
    }
  }

  // TODO need remove after full migrate to graphQL /bots request
  /**
   * @description Reload old bot cache
   * @private
   */
  _reloadBotsListOldCache() {
    this.BotService.cachedList(true);
  }
}
