import CardTextController from '../card-text-plugin/controller';

export default class CardCommunicationButtonController extends CardTextController {
  init() {
    this.discount = 20;
    this.$errors = { message: 'Title is Empty' };
  }

  $onInit() {
    this.$scope.$evalAsync(() => {
      this.textarea = this.$element[0].querySelector('textarea');
      this.updateDiscount();
    });
  }

  save() {
    if (!this.validate()) {
      this.CardCommunicationController.validation.button = false;
      // return;
    } else {
      this.CardCommunicationController.validation.button = true;
    }

    this.CardCommunicationController.saveCommunication();
  }
}
