import { prop, sortBy } from 'ramda';
import { canEdit } from '../../../common/services/RoleService';
import { sendEvent } from '../../../utils/Analytics';

const timeToMinutes = (broadcast) => {
  const time_unit = broadcast.wait_interval_time_unit;
  return (
    broadcast.wait_interval *
    (time_unit === 'day' ? 60 * 24 : time_unit === 'hour' ? 60 : 1)
  );
};

const sortBroadcastsFunction = (broadcast) => {
  return `${broadcast.trigger_type}_${`${timeToMinutes(broadcast)}`.padStart(
    8,
  )}`;
};

export default class BroadcastSequenceController {
  constructor($scope, $rootScope, BotService, BlockService, BroadcastService) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.BroadcastService = BroadcastService;
    this.BlockService = BlockService;
    this.BotService = BotService;

    this.RoleService = { canEdit };

    this.listeners = [];
    this.schedules = [];

    this.creating = false;

    this.listeners.push(
      $rootScope.$on('broadcast:removeSequence', (event, id) => {
        this.deleteSchedule(id);
      }),
    );

    this.listeners.push(
      $scope.$on('$pluginHasErrorValidation', (e, data) => {
        // this.updateValidateStatus(data.blockId);
      }),
    );

    this.listeners.push(
      $scope.$on('$pluginUpdated', (e, data, cards) => {
        this.updateValidateStatus(data.blockId, cards);
      }),
    );

    this.listeners.push(
      $rootScope.$on('broadcast:update', (event, id, updated) => {
        const broadcast = this.schedules.find((item) => item.id === id);
        if (broadcast) {
          if (updated.block_title) {
            broadcast.block_title = updated.block_title;
          }
          broadcast.use_bot_timezone = updated.use_bot_timezone;
          broadcast.wait_interval = updated.wait_interval;
          broadcast.enabled = updated.enabled;
          broadcast.wait_interval_time_unit = updated.wait_interval_time_unit;
          broadcast.trigger_type = updated.trigger_type;
        } else if (updated.trigger_type) {
          this.schedules.push(updated);
        }
      }),
    );

    this.listeners.push(
      $rootScope.$on('broadcast:copySequence', (event, data) => {
        this.copySchedule(data);
      }),
    );

    this.loadBot();
    this.loadSchedules();
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
  }

  updateValidateStatus(blockId, cards) {
    if (!blockId) {
      // eslint-disable-next-line prefer-destructuring
      blockId = this.$rootScope.stateParams.blockId;
    }
    const bc = this.schedules.find((item) => item.block_id === blockId);
    if (bc) {
      const isValid = cards.every((item) => {
        return item.is_valid === true;
      });
      bc.is_valid = isValid;
    }
  }

  loadSchedules() {
    this.BroadcastService.listSequence().then((rows) => {
      rows.forEach((row) => {
        if (this.schedules.find((item) => item.id === row.id)) {
          return;
        }
        this.schedules.push(row);
      });

      this.$rootScope.$broadcast('$sequenceLoaded', true);
    });
  }

  loadBot() {
    return this.BotService.show().then((bots) => {
      this.bot = bots;
      return this.bot;
    });
  }

  addSchedule() {
    if (this.creating) {
      return;
    }
    this.$scope.$evalAsync(() => {
      this.creating = true;
    });

    sendEvent({
      category: 're-engage',
      action: 'add',
      label: 'add a trigger',
    });

    this.loadBot().then((bot) => {
      const title = '1 Minute after First Interaction';

      const def = {
        trigger_type: 'first_interaction',
        wait_interval: 1,
        wait_interval_time_unit: 'minute',
        use_bot_timezone: false,
        user_filter_trigger: {},
        enabled: false,
      };

      return this.BlockService.createBroadcastBlock()
        .then((block) => {
          block.title = `${title}|${String(Math.random()).substr(2, 12)}`;
          return this.BlockService.update(block);
        })
        .then((block) => {
          def.block_id = block.id;
          return this.BlockService.createBroadcastSequenceBlock(def).then(
            (broadcast) => ({
              block,
              broadcast,
            }),
          );
        })
        .then(({ block, broadcast }) => {
          this.$scope.$evalAsync(() => {
            this.creating = false;
          });
          broadcast.block_id = block.id;
          broadcast.block_title = block.title;
          broadcast.enabled = false;
          broadcast.use_bot_timezone = false;

          this.schedules.push({ ...broadcast, ...def });

          this.$rootScope.stateHistory.push(
            `/bot/${this.$rootScope.stateParams.botId}/broadcast/${block.id}/${broadcast.id}/sequence`,
          );
        });
    });
  }

  copySchedule(data) {
    const def = data.broadcast;
    delete def.id;
    def.block_id = data.block.id;
    def.enabled = false;

    this.BlockService.update(
      Object.assign(data.block, {
        title: `${def.block_title} | ${String(Math.random()).substr(2, 12)}`,
      }),
    );
    this.BlockService.createBroadcastSequenceBlock(def).then((broadcast) => {
      const nBroadcast = Object.assign(broadcast, def);
      this.schedules.push(nBroadcast);
      this.$rootScope.stateHistory.push(
        `/bot/${this.$rootScope.stateParams.botId}/broadcast/${data.block.id}/${nBroadcast.id}/sequence`,
      );
    });
  }

  $getBroadcasts() {
    return sortBy(sortBroadcastsFunction, this.schedules);
  }

  deleteSchedule(id) {
    const broadcast = this.schedules.find((item) => item.id === id);
    if (!broadcast) {
      return;
    }
    this.BroadcastService.removeSequence(broadcast.id)
      .then(() => {
        this.BlockService.remove(broadcast.block_id);
      })
      .then(() => {
        const i = this.schedules.findIndex((item) => item.id === id);
        if (i !== -1) {
          this.schedules.splice(i, 1);
        }
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/broadcast`,
        );
      });
  }

  onMouseDownBlockItem(e) {
    const act = document.querySelector('.bc-temp-white.act');
    if (act && act !== e.currentTarget) {
      act.classList.remove('act');
      e.currentTarget.classList.add('act');
    }
  }

  onSequenceClick = ({ id, broadcast_id }) => {
    this.$rootScope.stateHistory.push(
      `/bot/${this.$rootScope.stateParams.botId}/broadcast/${id}/${broadcast_id}/sequence`,
    );
  };
}
