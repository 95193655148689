/**
 * @description: all HTML DOM specific operation;
 * */
import {
  prop,
  pipe,
  subtract,
  converge,
  useWith,
  when,
  complement as not,
  identity,
} from 'ramda';
// helpers;
// const log = tap(console.log.bind(console.log));

// HTMLElement => Int;
const getTopOffset = prop('offsetTop');
// HTMLElement => HTMLElement;
const getParent = prop('parentNode');
// HTMLElement => Int;
const getParentOffset = pipe(
  getParent,
  getTopOffset,
);
// get element offset and substract from parent offset;
const getParentOffsetDiff = converge(subtract, [getTopOffset, getParentOffset]);
const setScrollTop = (scrollTop, element) => {
  element.scrollTop = scrollTop;
  return element;
};
// HTMLElement => Rect
const getBoundingClientRect = (element) => element.getBoundingClientRect();

/**
 * @description: gets parent offest and scroll parent so this element is visible;
 * @param {HTMLElement} element
 * @return parent;
 */
export const scrollToElement = converge(setScrollTop, [
  getParentOffsetDiff,
  getParent,
]);

export const isRectInsideRect = (rect, containingRect) => {
  const topSideOfTestElementIsHigherThenTopSideContainingElement =
    rect.top >= containingRect.top;
  const bottomSideOfTestElLowerThenBottomSideOfContainer =
    rect.top + rect.height <= containingRect.top + containingRect.height;
  const leftSideOfTestElIsNotMoreThenLeftOfContainer =
    rect.left >= containingRect.left;
  const rightSideOfTestElIsNoMoreThenRightOfcontainer =
    rect.left + rect.width <= containingRect.width + containingRect.left;

  return (
    topSideOfTestElementIsHigherThenTopSideContainingElement &&
    bottomSideOfTestElLowerThenBottomSideOfContainer &&
    leftSideOfTestElIsNotMoreThenLeftOfContainer &&
    rightSideOfTestElIsNoMoreThenRightOfcontainer
  );
};

const isElementVisible = useWith(isRectInsideRect, [
  getBoundingClientRect,
  getBoundingClientRect,
]);
const isElementVisibleInParent = converge(isElementVisible, [
  identity,
  getParent,
]);
export const scrollToElementIfNeeded = when(
  not(isElementVisibleInParent),
  scrollToElement,
);
