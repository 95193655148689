import React from 'react';
import cx from 'classnames';
import { ReactComponent as ErrorInfoIcon } from '../../../../modern-ui/_deprecated/Icon/icons/error-info.svg';
import { Flex } from '../../../../modern-ui/Flex';
import { ErrorDescription } from './ErrorDescription';
import { Error } from './ErrorDescription/ErrorDescription';
import * as s from './StatDetails.css';

interface Props {
  errors: Error[];
}

export const StatDetails: React.FC<Props> = ({ errors }) => {
  return (
    <Flex alignItems="baseline">
      <div className={s.iconCell}>
        <ErrorInfoIcon className={s.icon} style={{ width: 12, height: 12 }} />
      </div>
      <div>
        {errors.map((error) => (
          <div key={error.code} className={cx(s.text, s.description)}>
            <ErrorDescription key={error.code} error={error} />{' '}
          </div>
        ))}
      </div>
    </Flex>
  );
};
