import throttle from 'lodash-es/throttle';
import { sendEvent } from '../../../utils/Analytics';

export default class WhitelistController {
  constructor($scope, $timeout, BotService) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.BotService = BotService;
    this.invalidUrls = [];
    this.botWatcher = this.$scope.$watch('vmw.bot', (bot) => {
      if (bot && !bot.domains_whitelist) {
        this.bot.domains_whitelist = [];
      }
    });
  }

  $onInit() {
    this.throttleHandler = throttle((domainsWhitelist, url) => {
      this.BotService.domainWhitelist(domainsWhitelist).then((res) => {
        this.invalidUrls = [];
        this.validatingField = null;
        // eslint-disable-next-line no-prototype-builtins
        if (res.result.hasOwnProperty(url)) {
          const urlIndex = this.bot.domains_whitelist.findIndex(
            (elem) => elem === url,
          );
          this.bot.domains_whitelist[urlIndex] = res.result[url]
            ? res.result[url]
            : url;
          this.urlInd = res.result[url] && urlIndex;
        }
        if (!res.errors) {
          return;
        }
        this.invalidUrls = res.errors || [];
      });
    }, 500);
  }

  $onDestroy() {
    this.botWatcher();
  }

  addDomain() {
    sendEvent({
      category: 'domain whitelisting',
      action: 'add domain',
    });

    this.bot.domains_whitelist.push('');
    this.$timeout(() => {
      const newDomainFields = angular.element.find('.domain-input');
      newDomainFields[newDomainFields.length - 1].focus();
    });
  }

  remove(index) {
    this.bot.domains_whitelist.splice(index, 1);
    this.urlInd = null;
    this.save();
  }

  save(index, url) {
    this.validatingField = index;
    this.throttleHandler(this.bot.domains_whitelist, url);
  }

  validate(url) {
    return this.invalidUrls.includes(url);
  }

  testShowDomain(domain) {
    return domain !== window.location.hostname;
  }
}
