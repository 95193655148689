import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { getPluginName } from '../../pluginNames';

export default class CardRssController extends CardPluginBaseController {
  $onInit() {
    this.pluginName = getPluginName('search/rss_plugin');
    if (!this.item.config.allow_read_in_bot) {
      this.item.config.allow_read_in_bot = false;
    }
    if (!this.item.config.subscriptions_available) {
      this.item.config.subscriptions_available = false;
    }
    if (!this.item.config.number_of_pages) {
      this.item.config.number_of_pages = 3;
    }
    if (!this.item.id) {
      this.save();
    }

    if (!this.item.validation_details) {
      this.item.validation_details = {};
    }

    if (this.item.is_valid === false) {
      this.data = this.item.validation_details || {};
      this.data.card_id = this.item.id;
      this.$rootScope.$broadcast('$pluginSaveResultError', { data: this.data });
    } else {
      this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
    }
  }

  save() {
    if (!this.item.config.title) {
      this.item.config.title = '';
    }
    super.save();
  }
}
