import CardPluginBaseController from '../card-plugin-base-controller/controller';
import { createSetUpZapierInFlowsButton } from '../../../modern-components/FlowBuilder/views/components/Zapier/SetUpZapierInFlows/SetUpZapierInFlows';

export default class CardPluginController extends CardPluginBaseController {
  $onInit() {
    this.$timeout(() => {
      if (!this.item.id) {
        this.save();
      }
      if (!this.item.validation_details) {
        this.item.validation_details = {};
      }
      if (this.item.is_valid === false) {
        this.data = this.item.validation_details || {};
        this.data.card_id = this.item.id;
        this.$rootScope.$broadcast('$pluginSaveResultError', {
          data: this.data,
        });
      } else {
        this.$rootScope.$broadcast('$pluginSaveResultOk', this.item);
      }
    });
  }
}
