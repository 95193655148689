import { Observable } from 'rxjs/Rx';
import gql from 'graphql-tag';
import { find, propEq, curry } from 'ramda';
import { getBotFeatures } from '@utils/Data/Bot';
import { getBillingPageUrl } from '@utils/Routing';
import { TEMPLATES_LIST } from './GQLqueries/TemplateService.const';
import ApolloService from './ApolloService';

// TODO Move to collection servise ???
const findById = curry((id, list) => find(propEq('id', id), list));

const meQuery = gql`
  query ME_STRIPE {
    me {
      id
      name
      email
    }
  }
`;

const BOT_ENABLED_PRO_STATUS = 'enabled';
const BOT_ENABLED_PRO_WITH_DEBT_STATUS = 'enabled_with_debt';
const STAT_EVENTS = {
  stripe_popup_show: 'monetization_stripe-popup-show',
  pro_enabled: 'monetization_pro-enabled',
  pro_disabled: 'monetization_pro-disabled',
};
const PAY_TYPES = {
  template: 'template',
};

const STRIPE_PROCESSING_EVENTS = {
  tokenReceived: 'token received',
  paymentDataSaved: 'payment data saved',
  paymentDataSavedError: 'payment data saved error',
};

const SAVE_TEMPLATE_ORDER_RESULT = gql`
  mutation SAVE_TEMPLATE_ORDER_RESULT(
    $templateStripePaymentInfo: StripeOrderInput!
  ) {
    templatesOrder(templateStripePaymentInfo: $templateStripePaymentInfo) {
      result
      success
    }
  }
`;

/**
 * responsible for Stripe && Payments
 */
export default class MonetizationService {
  /**
   *
   * @param {*} $http -
   * @param {*} $rootScope -
   * @param {*} $timeout -
   * @param {*} $injector -
   * @param {*} StoreService -
   * @param {*} ModalService -
   * @param {*} UserService -
   */
  constructor(
    $http,
    $rootScope,
    $timeout,
    $injector,
    StoreService,
    ModalService,
    UserService,
  ) {
    'ngInject';

    this.$http = $http;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.$injector = $injector;
    this.StoreService = StoreService;
    this.ModalService = ModalService;
    this.UserService = UserService;
    this.ApolloService = ApolloService;
    this.stat_events = STAT_EVENTS;
    this.stripeProcessingEvents = STRIPE_PROCESSING_EVENTS;
    this.payTypes = PAY_TYPES;

    this.isStripeReady = false;
    this.dialogsPricingEnabled = false;
    getBotFeatures(this.getBotId()).then(({ dialogs_pricing_enabled }) => {
      this.dialogsPricingEnabled = dialogs_pricing_enabled;
    });

    this.initStripe();
    this.loadUserPromise = this.loadUser();
  }

  getBotId() {
    return this.$rootScope.stateParams.botId;
  }

  /**
   * loads currently logged it users;
   * @return {void} -
   */
  async loadUser() {
    return this.UserService.show();
  }

  /**
   * -
   */
  initStripe() {
    /**
     * -
     */
    if (this.isStripeReady) {
      return;
    }

    const insertStripeCode = () => {
      if (!window.StripeCheckout) {
        ((d, w) => {
          const n = d.getElementsByTagName('script')[0];
          const s = d.createElement('script');
          // eslint-disable-next-line require-jsdoc
          const f = () => {
            n.parentNode.insertBefore(s, n);
          };
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://checkout.stripe.com/checkout.js';

          if (w.opera === '[object Opera]') {
            d.addEventListener('DOMContentLoaded', f, false);
          } else {
            f();
          }
        })(document, window);
      }
    };

    insertStripeCode();
    this.stripeHandlerPromise = this._configureStripe();
  }

  /**
   * -
   * @return {Promise<any>} -
   * @private
   */
  // eslint-disable-next-line consistent-return
  async _configureStripe() {
    try {
      const stripeKeyPromise = this.getStripeKey();
      const gqlUserRequest = this.ApolloService.query({
        query: meQuery,
        errorPolicy: 'none',
      });
      const promises = [stripeKeyPromise, gqlUserRequest];
      const [stripeKey, gqlResult] = await Promise.all(promises);
      const email = gqlResult && gqlResult.data && gqlResult.data.me.email;

      return new Promise((resolve) => {
        /**
         * -
         */
        const init = () => {
          if (window.StripeCheckout && window.StripeCheckout.configure) {
            const stripeHandler = window.StripeCheckout.configure({
              key: stripeKey,
              name: '200 Labs, Inc.',
              description: 'Chatfuel',
              image:
                'https://chatfuel.com/files/favicon/apple-icon-120x120.png',
              locale: 'auto',
              email,
              panelLabel: 'Subscribe',
              zipCode: false,
              billingAddress: false,
            });

            this.isStripeReady = true;
            resolve(stripeHandler);
          } else {
            setTimeout(() => {
              init();
            }, 100);
          }
        };
        init();
      });
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @description Start stripe pay
   * @param {String} payType -
   * @param {Object} params -
   * @returns {Observable} - stripe event's stream
   */
  stripeGoPay(payType, params) {
    return new Observable((observer) =>
      this._stripePayObservableHandler(observer, payType, params),
    );
  }

  /**
   * -
   * @param {*} observer -
   * @param {String} payType -
   * @param {Object} params -
   * @private
   */
  async _stripePayObservableHandler(observer, payType, params) {
    try {
      const stripeHandler = await this.stripeHandlerPromise;
      const stripeResultsHandlers = {
        [PAY_TYPES.template]: (payResult) => {
          this._stripeTemplatesResultHandler(payResult, observer, params);
        },
      };
      const isTemplate = payType === PAY_TYPES.template;
      stripeHandler.open({
        token: stripeResultsHandlers[payType],
        allowRememberMe: true,
        panelLabel: isTemplate ? 'Pay {{amount}}' : 'Subscribe',
        // params is template;
        amount: isTemplate ? params.price : undefined,
      });
    } catch (e) {
      console.error('error during _stripePayObservableHandler', e);
    }
  }

  /**
   * -
   * @param {Object} payResult -
   * @param {Observer} observer -
   * @param {Object} template -
   * @private
   */
  async _stripeTemplatesResultHandler(payResult, observer, template) {
    observer.next({ event: STRIPE_PROCESSING_EVENTS.tokenReceived });
    await this._saveTemplateOrderResult(payResult, template);
    observer.next({ event: STRIPE_PROCESSING_EVENTS.paymentDataSaved });
    observer.complete();
  }

  /**
   * -
   * @return {*} -
   */
  async getStripeKey() {
    const res = await this.$http({
      method: 'get',
      url: `${this.StoreService.getApiUrl()}/page_payments/stripe/key`,
      cacheOptions: {
        need: true,
      },
    });
    return res.data.result;
  }

  /**
   * -
   * @return {Promise<*>} -
   */
  async cancelProPlan() {
    const res = await this.$http({
      method: 'post',
      url: `${this.StoreService.getApiUrl()}/page_payments/${this.getBotId()}/cancel`,
    });
    return res.data.result;
  }

  /**
   * @deprecated use useBotProStatus instead
   */
  isBotPro(bot) {
    if (bot && bot.pro) {
      return (
        (bot.pro.status &&
          [BOT_ENABLED_PRO_STATUS, BOT_ENABLED_PRO_WITH_DEBT_STATUS].includes(
            bot.pro.status,
          )) ||
        bot.pro.manual
      );
    }
    return false;
  }

  /**
   * -
   * @param {Bot} bot -
   * @return {boolean} -
   */
  isBotTrial(bot) {
    return bot?.pro?.status === 'trial';
  }

  /**
   * @deprecated use useBotProStatus instead
   */
  isBotProOrTrial(bot) {
    return this.isBotPro(bot) || this.isBotTrial(bot);
  }

  /**
   * -
   */
  navigateToSettingsAndScrollToMonetization() {
    this.$rootScope.stateHistory.push(
      getBillingPageUrl(this.getBotId(), this.dialogsPricingEnabled),
    );
  }

  /**
   * @description Save template order info
   * @param {Object} orderResult -
   * @param {Object} template -
   * @return {Promise<T>} -
   * @private
   */
  _saveTemplateOrderResult(orderResult, template) {
    const firstAdressExixt =
      orderResult.card &&
      orderResult.card.address_country &&
      orderResult.card.address_city &&
      `${orderResult.card.address_country}, ${orderResult.card.address_city}`;

    return this.ApolloService.mutate({
      mutation: SAVE_TEMPLATE_ORDER_RESULT,
      variables: {
        templateStripePaymentInfo: {
          stripeToken: orderResult.id,
          stripeEmail: orderResult.email,
          templateId: template.id,
          priceInCents: template.price,
          addressFirstLine: firstAdressExixt,
          addressSecondLine: orderResult.card.address_line1,
          zipCode: orderResult.card.address_zip,
          country: orderResult.country,
        },
      },
      optimisticResponse: {
        templatesOrder: {
          success: true,
          result: 'ok',
          __typename: 'OrderResult',
        },
      },
      update: (store, { data: { templatesOrder } }) => {
        const data = store.readQuery({
          query: TEMPLATES_LIST,
        });
        const mutateTemplate = findById(template.id, data.templates);
        if (mutateTemplate) {
          mutateTemplate.purchased = true;
        }
        store.writeQuery({
          query: TEMPLATES_LIST,
          data,
        });
      },
    });
  }
}
