import { isEmpty, trim, compose, defaultTo, clone } from 'ramda';
import {
  DEFAULT_ATTRIBUTE_TYPE_CODE,
  ATTRIBUTE_TYPE_CODES,
} from './OmniboxServiceConsts';

const isEmptyString = compose(isEmpty, trim, defaultTo(''));

export class UserFilterService {
  constructor(SEGMENTATION_PARAMETER_TYPES) {
    'ngInject';

    this.SEGMENTATION_PARAMETER_TYPES = SEGMENTATION_PARAMETER_TYPES;
  }

  isFilterEmpty(filter) {
    if (!filter || !filter.parameters) {
      // if filter is undefined it is empty;
      return true;
    }
    return filter.parameters.length === 0;
  }

  createEmptyFilter(filter) {
    return {
      parameters: [],
      operation: (filter && filter.operation) || 'and',
    };
  }

  filterRemoveEmptyItems(filter) {
    const nFilter = this.createEmptyFilter(filter);

    nFilter.parameters = filter.parameters
      .map((item) => {
        const lItem = clone(item);
        if (lItem && lItem.values) {
          lItem.values = lItem.values.filter(Boolean);
        }
        return lItem;
      })
      .filter((item) => item && item.values && item.values.length);

    return nFilter;
  }

  filterRemoveNulls(filter) {
    const nFilter = this.createEmptyFilter(filter);

    if (filter && filter.parameters) {
      nFilter.parameters = filter.parameters.map((item) => {
        const outItem = {
          values: item.values,
          operation: item.operation,
        };
        if (item.name) {
          outItem.name = item.name;
        }
        if (item.type) {
          outItem.type = item.type;
        }
        return outItem;
      });
    }

    return nFilter;
  }

  isFilterNotEmpty(filter) {
    return (
      filter &&
      filter.parameters &&
      this.filterRemoveEmptyItems(filter).parameters.length > 0
    );
  }

  isFilterTouched(filter) {
    return filter && filter.parameters && filter.parameters.length > 0;
  }

  getNeedPreloadFilterItemsQty(filterItems, attributeItems = []) {
    const needPreloadFilterItems = filterItems.filter((item) => {
      const attribute = attributeItems.find((attr) => attr.code === item.name);
      return (
        !isEmptyString(item.name) &&
        item.type !== ATTRIBUTE_TYPE_CODES.segment &&
        (!attribute || !attribute.values || attribute.values.length === 0)
      );
    });
    return needPreloadFilterItems ? needPreloadFilterItems.length : 0;
  }

  addUnknownAttributesToSuggest(filterItems, attributeItems = []) {
    const attributeItemsOut = [...attributeItems];

    if (attributeItemsOut.length) {
      filterItems.forEach((filterItem) => {
        const type = filterItem.type || DEFAULT_ATTRIBUTE_TYPE_CODE;
        if (
          filterItem.name &&
          !this.getAttributeByNameAndType(
            attributeItemsOut,
            filterItem.name,
            type,
          )
        ) {
          // To fix old filters (without attribute types)
          // TODO Delete later
          filterItem.type = DEFAULT_ATTRIBUTE_TYPE_CODE;
          attributeItemsOut.push({
            type: filterItem.type,
            name: filterItem.name,
            code: null,
            count: 0,
          });
        }
      });
    }

    return attributeItemsOut;
  }

  // To fix old filters (without attribute types)
  // TODO Delete later
  addTypeToFilterItems(filterItems, attributeItems = []) {
    return filterItems.map((item) => {
      if (attributeItems.length && !item.type) {
        const attr = attributeItems.find((attr) => attr.name === item.name);
        item.type = attr ? attr.type : DEFAULT_ATTRIBUTE_TYPE_CODE;
      }
      return item;
    });
  }

  getAttributeByNameAndType(attributes, name, type) {
    return attributes.find(
      (attribute) => attribute.name === name && attribute.type === type,
    );
  }

  isNotSet(value) {
    return value && value.trim().toLowerCase() === 'not set';
  }
}
