export default class SystemAttributeController {
  constructor($scope, $timeout, $element, $compile, StoreService, $attrs) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.StoreService = StoreService;
    this.$timeout = $timeout;
    this.$attrs = $attrs;
    this.$compile = $compile;
  }

  $onInit() {
    if (!this.$element.attr('tooltip-class')) {
      if (this.$element.attr('system-attribute-bind')) {
        this.$element.attr({
          'uib-tooltip-html': `${this.$element.attr(
            'system-attribute-bind',
          )} | attributeDescription`,
          'tooltip-class': 'system-tooltip',
          'tooltip-placement': this.$element.attr('system-attribute-top')
            ? 'top'
            : 'right',
          'tooltip-append-to-body': 'true',
        });

        this.$compile(this.$element)(this.$scope.$parent);
      } else {
        const attributeName = this.clear(
          this.$element.attr('system-attribute-text'),
        );
        this._setSystemStatus(attributeName);
      }
    } else if (this.$element.attr('uib-tooltip-html')) {
      this.watcher = this.$scope.$watch(
        '$sysAttr.systemAttributeBind',
        (attr) => {
          if (attr !== undefined) {
            this._defineAttribute(this.systemAttributeBind);
          }
        },
      );
    }
  }

  async _setSystemStatus(attr) {
    if (await this.StoreService.getPromiseIsSystemAttribute(attr)) {
      this.$element.addClass('system');

      if (this.$element.attr('system-attribute-no-popup') !== 'true') {
        this.$element.attr({
          'uib-tooltip': this.StoreService.getSystemAttributeTooltip(
            attr,
          ).replace(/{{(.*?)}}/g, "{{ '{'+'{$1}'+'}'}}"),
          'tooltip-class': 'system-tooltip',
          'tooltip-placement': this.$element.attr('system-attribute-top')
            ? 'top'
            : 'right',
          'tooltip-append-to-body': 'true',
        });

        this.$compile(this.$element)(this.$scope.$parent);
      }
    }
  }

  async _defineAttribute(attr) {
    if (await this.StoreService.getPromiseIsSystemAttribute(attr)) {
      this.$element.addClass('system');
    } else {
      this.$element.removeClass('system');
    }
  }

  $onDestroy() {
    if (this.watcher) {
      this.watcher();
    }
  }

  clear(str) {
    return (
      str &&
      str
        .replace(/( |&nbsp;)/g, ' ')
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/&amp;/gi, '&')
        .trim()
    );
  }
}
