import { canEdit } from '../../common/services/RoleService';
import { sendEvent } from '../../utils/Analytics';

export default class SidebarController {
  constructor(
    $rootScope,
    BroadcastService,
    BlockService,
    BotService,
    API_ERROR_MSG,
    $scope,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.BotService = BotService;
    this.BlockService = BlockService;
    this.$scope = $scope;
    this.creating = false;

    this.listeners = [];

    this.RoleService = { canEdit };

    this.listeners.push(
      $rootScope.$on('block:remove', (event, id) => {
        this.deleteBlock(id);
      }),
    );
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
  }

  addNowBlock($event) {
    if (this.creating) {
      return;
    }

    sendEvent({
      category: 're-engage',
      action: 'add',
      label: 'deliver your message now',
    });

    if ($event) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
    }

    this.$scope.$evalAsync(() => {
      this.creating = true;
    });

    this.BlockService.createBroadcastNowBlock().then((block) => {
      this.goBlock(block.id, 0, 'now');
      this.$scope.$evalAsync(() => {
        this.creating = false;
      });
    });
  }

  deleteBlock() {
    if (this.$rootScope.stateParams.broadcastType === 'now') {
      this.BlockService.removeBroadcastNowBlock().then(() => {
        this.$rootScope.stateHistory.push(
          `/bot/${this.$rootScope.stateParams.botId}/broadcast`,
        );
      });
    }
  }

  goBlock(id, broadcastId, type = 'schedule') {
    this.$rootScope.stateHistory.push(
      `/bot/${this.$rootScope.stateParams.botId}/broadcast/${id}/${broadcastId}/${type}`,
    );
  }

  onMouseDownBlockItem(e) {
    const act = document.querySelector('.bc-temp-white.act');
    if (act && act !== e.currentTarget) {
      act.classList.remove('act');
      e.currentTarget.classList.add('act');
    }
  }

  onNowClick = ({ id, broadcast_id }) => {
    this.$rootScope.stateHistory.push(
      `/bot/${this.$rootScope.stateParams.botId}/broadcast/${id}/${broadcast_id}/now`,
    );
  };
}
