export type Argument = {
  type: 'hyperlink';
  value: string;
  text: string;
};

export type TextNode = { type: 'text'; text: string };
export type AnchorNode = { type: 'hyperlink'; value: string; text: string };
export type Node = TextNode | AnchorNode;
export type NodeList = Node[];

export function createMarkupNodes(descriptionTemplate: string, args: Argument[]): NodeList {
  const re = /\$(\d+)/g;
  let pos = 0;
  let match = re.exec(descriptionTemplate);
  const nodes = [];
  while (match) {
    const captured = match[1];
    const before = descriptionTemplate.slice(pos, match.index);
    const argumentIndex = Number(captured) - 1;
    const arg = args[argumentIndex];
    if (!arg) {
      throw new Error(`Expected argument at index ${argumentIndex}`);
    }

    if (before.length) {
      nodes.push({ type: 'text', text: before } as TextNode);
    }
    nodes.push({ type: arg.type, value: arg.value, text: arg.text });

    pos = re.lastIndex;
    match = re.exec(descriptionTemplate);
  }
  const after = descriptionTemplate.slice(pos);
  if (after.length) {
    nodes.push({ type: 'text', text: after } as TextNode);
  }

  return nodes;
}
