/**
 *  Service for Collection work
 */
export default class CollectionService {
  /**
   * @description get item by field
   * @param {String} fieldName -
   * @param {String} fieldValue -
   * @param {[Object]} items -
   * @return {String} -
   * @private
   */
  getItemByField(fieldName, fieldValue, items) {
    return items && items.find((_item) => _item[fieldName] === fieldValue);
  }
}
