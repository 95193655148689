import React from 'react';
import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import Downshift from 'downshift';
import { ApolloProvider } from '@apollo/react-hooks';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import client from '../../services/ApolloService';
import {
  Button,
  ButtonIntent,
  ButtonSize,
} from '../../../modern-ui/_deprecated/Button';
import { ReactComponent as DotsIcon } from '../../../modern-ui/_deprecated/Icon/icons/ic_dots.svg';
import { MenuItem, Menubox, Divider } from '../../../modern-ui/Menu';
import { Modal } from '../../../modern-ui/Modal';
import { RemoveEntityDialog } from '../../../modern-components/RemoveEntityDialog';
import { CardActionsDialog } from '../../../modern-components/CardActionsDialog';
import { toaster as userActionsToaster } from '../../../services/MessageService';
import { getPluginName } from '../../pluginNames';
import { CloneCard, CloneCardVariables } from './@types/CloneCard';
import { sendEvent } from '../../../utils/Analytics';

type MenuItem =
  | {
      index: number;
      type: DialogAction;
      title: string;
    }
  | { type: 'divider' };

const menuItems: () => MenuItem[] = memoize(() => [
  {
    index: 0,
    type: 'clone',
    title: i18next.t('common.plugins.OptionsButton.copy'),
  },
  {
    index: 1,
    type: 'move',
    title: i18next.t('common.plugins.OptionsButton.move'),
  },
  { type: 'divider' },
  {
    index: 2,
    type: 'remove',
    title: i18next.t('common.plugins.OptionsButton.delete'),
  },
]);

type DialogAction = 'move' | 'remove' | 'clone';

type NoDialog = { showDialog: false; dialogAction: null };
type SomeDialog = { showDialog: true; dialogAction: DialogAction };
type IOptionsButtonState = NoDialog | SomeDialog;

interface IOptionsButtonProps {
  pluginType: string;
  itemId: string;
  blockId: string;
  blockTitle: string;
  botId: string;
  onRemove: () => Promise<any>;
  onOpenChange: () => void;
  onUpdate: () => void; // for notifying angular that something within has changed
}

function itemToString(item?: MenuItem) {
  if (!item || item.type === 'divider') {
    return '';
  }
  return item.title;
}

const cloneCardMutation = gql`
  mutation CloneCard($blockId: String!, $cardId: String!) {
    cloneCard(blockId: $blockId, cardId: $cardId)
  }
`;

export class OptionsButton extends React.Component<
  IOptionsButtonProps,
  IOptionsButtonState
> {
  constructor(props: IOptionsButtonProps) {
    super(props);
    this.state = {
      showDialog: false,
      dialogAction: null,
    };
    this.handleMenuSelect = this.handleMenuSelect.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleMenuSelect(menuItem: MenuItem) {
    this.setState({
      showDialog: true,
      dialogAction: menuItem.type,
    } as IOptionsButtonState);
  }

  handleDismiss() {
    this.setState({
      showDialog: false,
      dialogAction: null,
    } as IOptionsButtonState);
  }

  handleRemove() {
    this.setState({
      showDialog: false,
      dialogAction: null,
    } as IOptionsButtonState);
    this.props.onRemove();
  }

  render() {
    const { pluginType, botId, blockId, blockTitle, onOpenChange, onUpdate } =
      this.props;
    const { showDialog, dialogAction } = this.state;
    return (
      <ApolloProvider client={client}>
        <Downshift
          // do not save "selected" item to downshift state,
          // because this is not a "select", but a "dropdown"
          selectedItem={null}
          itemToString={itemToString}
          onChange={this.handleMenuSelect}
          onStateChange={(change) => {
            if ('isOpen' in change) {
              onOpenChange();
            }
          }}
        >
          {({
            isOpen,
            getToggleButtonProps,
            getItemProps,
            highlightedIndex,
          }) => (
            <div style={{ position: 'relative' }}>
              <Button
                {...getToggleButtonProps()}
                intent={ButtonIntent.default}
                size={ButtonSize.s}
                tall
                renderIcon={() => <DotsIcon />}
              />
              {isOpen ? (
                <Menubox
                  style={{
                    position: 'absolute',
                    top: 38,
                    zIndex: 20,
                    width: 180,
                  }}
                >
                  {menuItems().map((item, index) =>
                    item.type === 'divider' ? (
                      // eslint-disable-next-line react/no-array-index-key
                      <Divider key={index} />
                    ) : (
                      <MenuItem
                        key={item.title}
                        {...getItemProps({ item })}
                        active={item.index === highlightedIndex}
                        title={item.title}
                      />
                    ),
                  )}
                </Menubox>
              ) : null}
            </div>
          )}
        </Downshift>
        {showDialog ? (
          <Modal onDismiss={this.handleDismiss}>
            {dialogAction === 'remove' && (
              <RemoveEntityDialog
                renderHeading={() => `Delete ${getPluginName(pluginType)} Card`}
                renderActionText={() => `Delete Card`}
                onSubmit={this.handleRemove}
                onDismiss={this.handleDismiss}
              />
            )}
            {dialogAction === 'clone' && (
              <Mutation<CloneCard, CloneCardVariables>
                mutation={cloneCardMutation}
              >
                {(cloneCard) => (
                  <CardActionsDialog
                    currentBotId={botId}
                    currentBlockId={blockId}
                    currentBotBlocks={[
                      {
                        id: blockId,
                        title: blockTitle,
                      },
                    ]}
                    renderHeading={() =>
                      `Copy ${getPluginName(pluginType)} Card`
                    }
                    renderActionText={() => `Copy Card`}
                    onSubmit={({ blockId: targetBlockId }) => {
                      const cardId = this.props.itemId;
                      this.setState({
                        showDialog: false,
                        dialogAction: null,
                      } as IOptionsButtonState);
                      cloneCard({
                        variables: { cardId, blockId: targetBlockId },
                      }).then(() => {
                        userActionsToaster.show({
                          payload: {
                            message: 'Card successfully copied',
                          },
                        });
                        if (targetBlockId === blockId) {
                          onUpdate();
                        }
                        sendEvent({
                          category: 'plugin',
                          action: 'clone',
                          label: pluginType,
                          propertyBag: {
                            type: pluginType,
                          },
                        });
                      });
                    }}
                    onDismiss={this.handleDismiss}
                  />
                )}
              </Mutation>
            )}
            {dialogAction === 'move' && (
              <Mutation<CloneCard, CloneCardVariables>
                mutation={cloneCardMutation}
              >
                {(cloneCard) => (
                  <CardActionsDialog
                    currentBotId={botId}
                    currentBlockId={blockId}
                    currentBotBlocks={[
                      {
                        id: blockId,
                        title: blockTitle,
                      },
                    ]}
                    renderHeading={() =>
                      `Move ${getPluginName(pluginType)} Card`
                    }
                    renderActionText={() => `Move Card`}
                    onSubmit={({ blockId: targetBlockId }) => {
                      /**
                       * There is no universal "move" handle on the backend,
                       * so a "move" means to "clone, then remove the original"
                       */
                      const cardId = this.props.itemId;
                      this.setState({
                        showDialog: false,
                        dialogAction: null,
                      } as IOptionsButtonState);
                      cloneCard({
                        variables: { cardId, blockId: targetBlockId },
                      })
                        .then(this.props.onRemove)
                        .then(() => {
                          userActionsToaster.show({
                            payload: {
                              message: 'Card successfully moved',
                            },
                          });
                          if (targetBlockId === blockId) {
                            onUpdate();
                          }
                          sendEvent({
                            category: 'plugin',
                            action: 'move',
                            label: pluginType,
                            propertyBag: {
                              pluginType,
                            },
                          });
                        });
                    }}
                    onDismiss={this.handleDismiss}
                  />
                )}
              </Mutation>
            )}
          </Modal>
        ) : null}
      </ApolloProvider>
    );
  }
}
