import { UN_BIND_PAGE_MUTATION } from '@utils/FacebookPages/queries';
import { CONNECTED_FACEBOOK_AD_ACCOUNT_QUERY } from '@utils/MarketingApi';
import ApolloService from '../services/ApolloService';

export default (
  $q,
  $timeout,
  $http,
  $rootScope,
  StoreService,
  BotGQService,
) => {
  'ngInject';

  return {
    /**
     * @description get pages list (no cache)
     * @return {Promise<[Object]>} -
     */
    list() {
      return this.cachedList(true);
    },

    /**
     * @description get pages list
     * @param {Boolean} refresh -
     * @return {Promise<[Object]>} -
     */
    cachedList(refresh) {
      return $http({
        url: `${StoreService.getApiUrl()}/pages?refresh=true&bot_id=${
          $rootScope.stateParams.botId
        }`,
        method: 'get',
        cacheOptions: {
          need: true,
          refresh,
        },
      }).then((res) => res.data.result);
    },

    /**
     * @description bind bot to Api
     * @param {String} botId -
     * @param {String} accessToken -
     * @return {Promise<Object>} -
     */
    bindBot(botId, accessToken) {
      return $http({
        method: 'post',
        data: { accessToken },
        url: `${StoreService.getApiUrl()}/pages/new/bind/${botId}`,
      }).then((res) => res.data.result);
    },

    /**
     * @description get api id by page id
     * @param {String} pageId -
     * @return {Promise<Object>} -
     */
    getAppId(pageId) {
      return $http({
        url: `${StoreService.getApiUrl()}/pages/${pageId}/apps`,
        method: 'get',
      }).then((res) => {
        if (res.data.result.length) {
          return {
            id: res.data.result[0].id,
            chatfuel_app: !!res.data.result[0].chatfuel_app,
          };
        }
        return {
          id: 0,
          chatfuel_app: true,
        };
      });
    },

    /**
     * @description get access_pages status
     * @return {Promise<Object>} -
     */
    getAccessPages() {
      return $http({
        url: `${StoreService.getApiUrl()}/access_pages`,
        method: 'get',
      }).then((res) => {
        return res;
      });
    },

    /**
     * @description bind bot to page
     * @param {String} pageId -
     * @param {String} botId -
     * @return {Promise<Object>} -
     */
    bind(pageId, botId) {
      return $http({
        url: `${StoreService.getApiUrl()}/pages/${pageId}/bind/${botId}`,
        method: 'post',
      }).then((res) => {
        BotGQService.reloadBotListCache();
        return res;
      });
    },

    /**
     * @description unbind bot
     * @param {String} pageId -
     * @param {String} botId -
     * @return {Promise<Object>} -
     */
    async unbind(pageId, botId) {
      const res = await ApolloService.mutate({
        mutation: UN_BIND_PAGE_MUTATION,
        variables: {
          pageId,
        },
        refetchQueries: [
          { query: CONNECTED_FACEBOOK_AD_ACCOUNT_QUERY, variables: { botId } },
        ],
      });
      BotGQService.reloadBotListCache();
      return res;
    },

    /**
     * @description connect external analytics to page
     * @param {String} pageId -
     * @param {String} analytics - analytics type
     * @param {Object} data -
     * @return {Promise<Object>} -
     */
    connectTPAnalytics(pageId, analytics, data) {
      return $http({
        url: `${StoreService.getApiUrl()}/pages/${pageId}/analytics/${analytics}`,
        method: 'post',
        data,
      }).then((res) => res);
    },

    /**
     * @description disconnect external analytics on page
     * @param {String} pageId -
     * @param {String} analytics - analytics type
     * @return {Promise<Object>} -
     */
    disconnectTPAnalytics(pageId, analytics) {
      return $http({
        url: `${StoreService.getApiUrl()}/pages/${pageId}/analytics/${analytics}`,
        method: 'delete',
      }).then((res) => res);
    },
  };
};
