import ng from 'angular';
import { react2angular } from 'react2angular';
import {
  AnalyticsPlatformSelector,
  AnalyticsPlatformSelectorProps,
} from './AnalyticsPlatformSelector';
import { ApolloProvider } from '@apollo/react-common';
import client from '@common/services/ApolloService';
import React from 'react';
import { globalHistory } from '@utils/Routing';
import { Router } from 'react-router-dom';

const AnalyticsPlatformSelectorWrapper = (
  props: AnalyticsPlatformSelectorProps,
) => (
  <Router history={globalHistory}>
    <ApolloProvider client={client}>
      <AnalyticsPlatformSelector {...props} />
    </ApolloProvider>
  </Router>
);

export const ngAnalyticsPlatformSelector = ng
  .module('app.modernUi.ngAnalyticsPlatformSelector', [])
  .component(
    'analyticsPlatformSelector',
    react2angular(AnalyticsPlatformSelectorWrapper, ['onChange'], []),
  ).name;
