import ng from 'angular';
import component from './component';
import sequenceUserFilter from './sequence-user-filter';

export default ng
  .module('app.broadcast.sequence', [sequenceUserFilter])
  .directive('broadcastSequence', component)
  .filter('filterRBlockName', () => (text) => text.split('|')[0])
  .filter('filterBCTimeConvertor', () => (text) => {
    const time = /\s[0-9]{1,2}:[0-9]{2}\s[ap]m$/.exec(text);
    if (time) {
      const convertTo24Hour = (tl) => {
        const hours = parseInt(tl.substr(0, 2)); // eslint-disable-line radix
        if (tl.indexOf('am') !== -1 && hours === 12) {
          tl = tl.replace('12', '0');
        }
        if (tl.indexOf('pm') !== -1 && hours < 12) {
          tl = tl.replace(hours, hours + 12);
        }
        return tl.replace(/(am|pm)/, '');
      };
      text = text.replace(time[0], ` ${convertTo24Hour(time[0].trim())}`);
    }
    return text;
  }).name;
