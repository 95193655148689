import React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { Placement } from 'popper.js';
import { MenuOption } from './types';
import { UserNavigationMenu } from './UserNavigationMenu';

interface MenuGroup {
  name: string | null;
  children: MenuOption[];
}

export interface OpenableMenu {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

export interface PopperModifiers {
  offset: string;
  placement: Placement;
}

interface UserNavigationItemProps extends OpenableMenu, PopperModifiers {
  menuOptions: MenuGroup[];
  onSelect: (selectedItem: MenuOption) => void;
}

export const UserNavigationItem: React.FC<UserNavigationItemProps> = ({
  isOpen,
  setOpen,
  menuOptions,
  onSelect,
  offset,
  placement,
  children,
}) => {
  return (
    <Manager>
      <Reference>{({ ref }) => <div ref={ref}>{children}</div>}</Reference>
      <Popper
        placement={placement}
        positionFixed
        modifiers={{
          offset: { offset },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
      >
        {({ ref, style }) =>
          isOpen && (
            <div ref={ref} style={style}>
              <UserNavigationMenu
                menuOptions={menuOptions}
                onSelect={onSelect}
                setOpen={setOpen}
              />
            </div>
          )
        }
      </Popper>
    </Manager>
  );
};
