export class CommonValidationService {
  constructor($http, $log, StoreService) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.StoreService = StoreService;
  }

  get() {
    return this.fetch();
  }

  fetch() {
    return this.$http({
      url: `${this.StoreService.getApiUrl()}/validator_config`,
      method: 'get',
      cacheOptions: {
        need: true,
      },
    })
      .then((res) => res.data.result)
      .catch((e) => {
        this.$log.error(e);
      });
  }
}
